import React, { useState, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import InputMask from 'react-input-mask';
import { format } from 'date-fns';
import axios from 'axios';

import CollapsibleSection from 'components/CollapsibleSection';

import {
  RgMask,
  cpfMask,
  onlyNumbers,
} from 'client/components/ToNormalize/ToNormalize';

import { getDateOnlyFromDate } from 'utils/dateHelpers';

import addressRepository from 'repositories/Addresses';
import customersRepository from 'repositories/Customers';

import {
  FINANCING_CUSTOMER_PHONE,
  FINANCING_LOADING,
  FINANCING_LOADING_MESSAGE,
  FINANCING_TRIGGER_SAVE_CLIENT,
  FINANCING_TRIGGER_SAVE_SALE,
} from '../../recoil';

import financingStrings from '../../strings';

import './index.css';

async function loadCities(callback) {
  try {
    const cities = await addressRepository.getAllCities();
    callback(cities);
  } catch (err) {
    toastr.error(
      'OS Digital',
      'Ocorreu um erro ao carregar as cidades. Tente novamente!'
    );
  }
}

async function loadStates(callback) {
  try {
    const states = await addressRepository.getAllStates();
    callback(states);
  } catch (err) {
    toastr.error(
      'OS Digital',
      'Ocorreu um erro ao carregar os estados. Tente novamente!'
    );
  }
}

function handleValidateClientData(data) {
  if (
    !data.cpf ||
    !data.rg ||
    !data.name ||
    !data.email ||
    !data.birthdate ||
    !data.phoneNumber ||
    !data.homePhoneNumber ||
    !data.zipcode ||
    !data.address ||
    !data.neighborhood ||
    !data.addressNumber ||
    !data.state ||
    !data.city
  ) {
    return false;
  }

  return true;
}

function handleFormatClientData(clientData) {
  return {
    identifiers: {
      PrincipalAddress_id: clientData.addressId,
      Phone_id: clientData.phoneId,
    },
    customer: {
      Cpf_Cnpj: clientData?.cpf ? onlyNumbers(clientData.cpf) : null,
      Company_Name: clientData.name,
      Email: clientData.email,
      Date_Birth: clientData.birthdate,
      Rg: clientData?.rg ? onlyNumbers(clientData.rg) : null,
    },
    principalAddress: {
      State: clientData.state,
      City: clientData.city,
      Address_Number: clientData.addressNumber,
      Neighborhood: clientData.neighborhood,
      Reference_Point: clientData.referencePoint,
      Address: clientData.address,
      Complement: clientData.complement,
      Zipcode: clientData.zipcode,
      Country: 'Brasil',
      Type_Address: 'Principal',
    },
    phones: {
      Number_Phone1: clientData?.homePhoneNumber
        ? onlyNumbers(clientData.homePhoneNumber)
        : null,
      Number_Phone2: clientData?.phoneNumber
        ? onlyNumbers(clientData.phoneNumber)
        : null,
    },
  };
}

function handleCity(city) {
  switch (city) {
    case 'Birigüi':
      return 'Birigui';
    case 'dias D`Ávila':
      return `Dias D'Ávila`;
    default:
      return city;
  }
}

async function handleSearchAddressByZipcode(zipcode, callback) {
  try {
    const onlyNumbersZipCode = zipcode ? onlyNumbers(zipcode) : '';
    const addressSearch = await axios.get(
      `https://viacep.com.br/ws/${onlyNumbersZipCode}/json/`
    );

    callback('address', addressSearch.data.logradouro);
    callback('neighborhood', addressSearch.data.bairro);
    callback('state', addressSearch.data.uf);
    callback('city', handleCity(addressSearch.data.localidade));
  } catch (err) {
    console.log(err);
    toastr.warning(
      'OS Digital',
      'Endereço não localizado. Por favor, insira manualmente.'
    );
  }
}

export default function FinancingClientStep() {
  const { selectedClient } = useSelector((state) => state.saleReducer);

  const [isLoading, setIsLoading] = useRecoilState(FINANCING_LOADING);
  const [loadingMessage, setLoadingMessage] = useRecoilState(
    FINANCING_LOADING_MESSAGE
  );

  const [customerPhone, setCustomerPhone] = useRecoilState(
    FINANCING_CUSTOMER_PHONE
  );

  const [triggerSaveSale, setTriggerSaveSale] = useRecoilState(
    FINANCING_TRIGGER_SAVE_SALE
  );

  const [triggerSaveClient, setTriggerSaveClient] = useRecoilState(
    FINANCING_TRIGGER_SAVE_CLIENT
  );

  const { register, control, setValue, getValues } = useForm({
    defaultValues: {
      cpf: '',
      rg: '',
      name: '',
      email: '',
      birthdate: null,
      phoneId: '',
      phoneNumber: '',
      homePhoneNumber: '',
      zipcode: '',
      address: '',
      neighborhood: '',
      addressId: null,
      addressNumber: '',
      state: '',
      referencePoint: '',
      city: '',
      complement: '',
    },
  });

  const watchedZipcode = useWatch({
    control,
    name: 'zipcode',
  });

  const [statesOptions, setStatesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);

  async function handleLoadClientData() {
    setIsLoading(true);

    try {
      const client = await customersRepository.show(selectedClient?.id);

      const address = client?.Address?.find(
        (address) => address.Type_Address === 'Principal'
      );

      handleSetClientData({ client, address });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar os dados do cliente. Tente novamente!'
      );
    }
  }

  async function handleUpdateClientData() {
    try {
      const clientData = getValues();
      const isValid = handleValidateClientData(clientData);

      if (!isValid) {
        return toastr.warning(
          'OS Digital',
          financingStrings.FINANCING_CLIENT_BLANK_FIELDS
        );
      }

      setIsLoading(true);

      const formattedCustomerData = handleFormatClientData(clientData);
      await customersRepository.update(
        selectedClient.id,
        formattedCustomerData
      );

      setCustomerPhone(formattedCustomerData.phones.Number_Phone2);
      setIsLoading(false);
      setTriggerSaveSale(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        financingStrings.FINANCING_SAVING_CLIENT_ERROR
      );
    }
  }

  function handleSetClientData({ client, address }) {
    setValue('cpf', client?.Cpf_Cnpj ? cpfMask(client.Cpf_Cnpj) : '');
    setValue('rg', client?.Rg ? RgMask(client.Rg) : '');
    setValue('name', client?.Company_Name);
    setValue('email', client?.Email);

    setValue(
      'birthdate',
      client?.Date_Birth
        ? format(getDateOnlyFromDate(new Date(client.Date_Birth)), 'yyyy-MM-dd')
        : null
    );

    setValue(
      'phoneNumber',
      client?.Phones ? client?.Phones[0]?.Number_Phone2 : ''
    );

    setValue(
      'homePhoneNumber',
      client?.Phones ? client?.Phones[0]?.Number_Phone1 : ''
    );

    setValue('phoneId', client?.Phones[0].id);

    setValue('zipcode', address?.Zipcode);
    setValue('addressId', address?.id);
    setValue('complement', address?.Complement);
    setValue('addressNumber', address?.Address_Number);
    setValue('referencePoint', address?.Reference_Point);
  }

  function handleZipcode() {
    let zipCodeNumbers = watchedZipcode ? onlyNumbers(watchedZipcode) : '';

    if (zipCodeNumbers.length === 8) {
      handleSearchAddressByZipcode(zipCodeNumbers, setValue);
    }
  }

  useEffect(() => {
    loadCities(setCitiesOptions);
    loadStates(setStatesOptions);
  }, []);

  useEffect(() => {
    setLoadingMessage(financingStrings.FINANCING_LOADING_CLIENT);
    handleLoadClientData();
  }, []);

  useEffect(() => {
    handleZipcode();
  }, [watchedZipcode]);

  useEffect(() => {
    if (triggerSaveClient) {
      setTriggerSaveClient(false);
      setLoadingMessage(financingStrings.FINANCING_SAVING_CLIENT);
      handleUpdateClientData();
    }
  }, [triggerSaveClient]);

  return (
    <div>
      <p style={{ fontSize: '11px', color: '#d9534f', textAlign: 'center' }}>
        Para avaliação de crédito, é necessário que todos os campos do cadastro
        do cliente estejam preenchidos e com dados corretos
      </p>

      <CollapsibleSection
        title="Dados Básicos"
        isExpanded={true}
        handleExpand={null}
      >
        <div className="financing-client-form basic-data">
          <div className="row-1">
            <div className="input-block">
              <label htmlFor="cpf">CPF:</label>
              <Controller
                defaultValue={''}
                name={'cpf'}
                control={control}
                render={({ field }) => {
                  return (
                    <InputMask
                      {...field}
                      mask={'999.999.999-99'}
                      formatChars={{
                        9: '[0-9]',
                      }}
                    >
                      {() => (
                        <input
                          id={'id'}
                          type="text"
                          className="form-control foco-input"
                        />
                      )}
                    </InputMask>
                  );
                }}
              />
            </div>
            <div className="input-block">
              <label htmlFor="rg">RG:</label>
              <Controller
                defaultValue={''}
                name={'rg'}
                control={control}
                render={({ field }) => {
                  return (
                    <InputMask
                      {...field}
                      mask={'99.999.999-x'}
                      formatChars={{
                        9: '[0-9]',
                        x: '[0-9xX]',
                      }}
                    >
                      {() => (
                        <input
                          id={'id'}
                          type="text"
                          className="form-control foco-input"
                        />
                      )}
                    </InputMask>
                  );
                }}
              />
            </div>
            <div className="input-block">
              <label htmlFor="name">Nome Completo:</label>
              <input
                type="text"
                className="form-control foco-input"
                id="name"
                {...register('name')}
              />
            </div>
            <div className="input-block">
              <label htmlFor="email">E-mail:</label>
              <input
                type="email"
                className="form-control foco-input"
                id="email"
                {...register('email')}
              />
            </div>
          </div>
          <div className="row-2">
            <div className="input-block">
              <label htmlFor="homePhoneNumber">Telefone Residencial:</label>
              <Controller
                defaultValue={''}
                name={'homePhoneNumber'}
                control={control}
                render={({ field }) => {
                  return (
                    <InputMask
                      {...field}
                      mask={'(99) 9999-9999'}
                      formatChars={{
                        9: '[0-9]',
                      }}
                    >
                      {() => (
                        <input
                          id={'homePhoneNumber'}
                          type="text"
                          className="form-control foco-input"
                        />
                      )}
                    </InputMask>
                  );
                }}
              />
            </div>
            <div className="input-block">
              <label htmlFor="phoneNumber">Telefone Celular:</label>
              <Controller
                defaultValue={''}
                name={'phoneNumber'}
                control={control}
                render={({ field }) => {
                  return (
                    <InputMask
                      {...field}
                      mask={'(99) 9 9999-9999'}
                      formatChars={{
                        9: '[0-9]',
                      }}
                    >
                      {() => (
                        <input
                          id={'phoneNumber'}
                          type="text"
                          className="form-control foco-input"
                        />
                      )}
                    </InputMask>
                  );
                }}
              />
            </div>
            <div className="input-block">
              <label htmlFor="birthdate">Data de Nascimento:</label>
              <input
                type="date"
                className="form-control foco-input"
                id="birthdate"
                {...register('birthdate')}
              />
            </div>
          </div>
        </div>
      </CollapsibleSection>

      <br />

      <CollapsibleSection
        title="Endereço Principal"
        isExpanded={true}
        handleExpand={null}
      >
        <div className="financing-client-form address-data">
          <div className="row-1">
            <div className="input-block">
              <label htmlFor="zipcode">
                CEP:
                <a
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                  target="_blank"
                >
                  Não sei meu CEP
                </a>
              </label>

              <Controller
                defaultValue={''}
                name={'zipcode'}
                control={control}
                render={({ field }) => {
                  return (
                    <InputMask
                      {...field}
                      mask={'99999-999'}
                      formatChars={{
                        9: '[0-9]',
                      }}
                    >
                      {() => (
                        <input
                          id={'zipcode'}
                          type="text"
                          className="form-control foco-input"
                        />
                      )}
                    </InputMask>
                  );
                }}
              />
            </div>
            <div className="input-block">
              <label htmlFor="address">Endereço:</label>
              <input
                type="text"
                className="form-control foco-input"
                id="address"
                {...register('address')}
              />
            </div>
            <div className="input-block">
              <label htmlFor="addressNumber">Nº:</label>
              <input
                type="text"
                className="form-control foco-input"
                id="addressNumber"
                {...register('addressNumber')}
              />
            </div>
            <div className="input-block">
              <label htmlFor="neighborhood">Bairro:</label>
              <input
                type="text"
                className="form-control foco-input"
                id="neighborhood"
                {...register('neighborhood')}
              />
            </div>
            <div className="input-block">
              <label htmlFor="complement">Complemento:</label>
              <input
                type="text"
                className="form-control foco-input"
                id="complement"
                {...register('complement')}
              />
            </div>
          </div>
          <div className="row-2">
            <div className="input-block">
              <label htmlFor="referencePoint">Ponto de Referência:</label>
              <input
                type="text"
                className="form-control foco-input"
                id="referencePoint"
                {...register('referencePoint')}
              />
            </div>
            <div className="input-block">
              <label htmlFor="city">Cidade:</label>
              <Controller
                defaultValue={''}
                control={control}
                name="city"
                render={({ field }) => (
                  <select
                    className="form-control foco-input"
                    id="city"
                    {...field}
                  >
                    <option value="" disabled>
                      Selecione:
                    </option>
                    {citiesOptions.map((c) => {
                      return (
                        <option key={c.id} value={c.Name}>
                          {c.Name}
                        </option>
                      );
                    })}
                  </select>
                )}
              />
            </div>
            <div className="input-block">
              <label htmlFor="state">Estado:</label>
              <Controller
                defaultValue={''}
                control={control}
                name="state"
                render={({ field }) => (
                  <select
                    className="form-control foco-input"
                    id="state"
                    {...field}
                  >
                    <option value="" disabled>
                      Selecione:
                    </option>
                    {statesOptions.map((e) => {
                      return (
                        <option key={e.id} value={e.Initials}>
                          {e.Name}
                        </option>
                      );
                    })}
                  </select>
                )}
              />
            </div>
          </div>
        </div>
      </CollapsibleSection>
    </div>
  );
}
