import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import NFCesRepository from 'repositories/NFCes';
import SATCFeRepository from 'repositories/SATCFe';

import stoneClaimCheckRepository from 'repositories/StoneClaimCheck';
import companyRepository from 'repositories/Companies';

import './styles.css';

const ClosedSaleModal = ({
  show,
  saleId,
  invoiceId,
  onInitNewSale,
  onEmitInvoice,
  hasSettingsNFCe,
  NFCeSettings,
  fiscalDocumentType,
  transactionId,
  companyId,
  setLoading,
}) => {
  const [isModalButtonLoading, setIsModalButtonLoading] = useState(false);

  async function handleCreateInvoce() {
    setLoading(true);
    try {
      const fiscalRepo =
        fiscalDocumentType === 'SAT' ? SATCFeRepository : NFCesRepository;

      const fiscalDocument = await fiscalRepo.generateBySale(saleId);

      setLoading(false);
      onEmitInvoice(fiscalDocument.id);
    } catch (err) {
      setLoading(false);
      toastr.warning(
        `Ocorreu um erro ao gerar ${fiscalDocumentType}. Por favor, tente novamente`
      );
    }
  }

  async function handleOpenSaleReceipt() {
    setIsModalButtonLoading(true);
    try {
      const hash = `Vend=v723dha5tHSSpS83711${saleId}5422hsGsdAQLk8423oPL31`;

      const link = `${window.location.origin}/sheets/${hash}`;
      window.open(link, '_blank');

      if (transactionId) {
        await stoneClaimCheckRepository.store({ transactionId, companyId });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsModalButtonLoading(false);
    }
  }

  async function handleSubmitInvoice() {
    if (NFCeSettings.fiscalDocumentType === 'SAT') {
      const validadeteFields = Object.values(NFCeSettings).every(
        (value) => !!value
      );

      if (!validadeteFields) {
        return toastr.error(
          'Realize toda o preenchimento das configurações do SAT para emitir o SAT'
        );
      }
    }
    handleCreateInvoce();
  }

  return (
    <Modal show={show} animation dialogClassName="closed-sale-modal-wrapper">
      <Modal.Header>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          Sua venda foi realizada com sucesso. Selecione a opção desejada:
        </span>
        <div>
          {hasSettingsNFCe && (
            <button className="warning" onClick={() => handleSubmitInvoice()}>
              {NFCeSettings.fiscalDocumentType === 'SAT'
                ? 'Emitir SAT'
                : 'Emitir NFC-e'}
            </button>
          )}

          <button
            className="info"
            onClick={handleOpenSaleReceipt}
            disabled={isModalButtonLoading}
          >
            <span
              className={
                isModalButtonLoading
                  ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                  : 'update-spinner-disabled'
              }
            />
            Imprimir Comprovante de Venda
          </button>
          <button
            className="success"
            onClick={onInitNewSale}
            disabled={isModalButtonLoading}
          >
            <span
              className={
                isModalButtonLoading
                  ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                  : 'update-spinner-disabled'
              }
            />
            Iniciar Nova Venda
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ClosedSaleModal;
