import React, { useState } from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { addDays, format, isAfter } from 'date-fns';
import { Link } from 'react-router-dom';

import CustomButton from 'client/components/CustomButton/CustomButton.jsx';
import useFilters from '../../../../hooks/useFilters';
import constants from '../../../../utils/constants';
import AlertModal from '../../../../components/AlertModal';
import { getDateOnlyFromDate } from '../../../../utils/dateHelpers';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import Button from 'client/components/CustomButton/Button.jsx';

const BillsToPayTable = ({ billsToPay, handleCancel, onReClosing }) => {
  const [isConfirmReclosingModalOpen, setIsConfirmReclosingModalOpen] =
    useState(false);
  const { queryFilter, statusFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  const handleFilters = (bill) => {
    const searchQuery = [bill['code']];
    return filterByQuery(searchQuery) && filterByStatus('billStatusId', bill);
  };

  function handleSubmitReclosingMOdal() {
    setIsConfirmReclosingModalOpen(false);
    onReClosing();
  }

  return (
    <div id="purchases-bills-to-pay-modal-body">
      <header>
        <div style={{ minWidth: '215px' }}>
          <i
            id="iconepesquisar"
            style={{ cursor: 'pointer' }}
            className="fa fa-search"
          />
          <input
            type="text"
            style={{ paddingLeft: '30px' }}
            className="form-control"
            placeholder="Pesquisa por Nº do Título"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: '5px' }}>
          <span>
            <strong>Status: </strong>
          </span>
          <select
            className="form-control foco-input"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Todos</option>
            <option value={constants.BILLS_STATUS.OPEN}>Aberto</option>
            <option value={constants.BILLS_STATUS.CLOSED}>Finalizado</option>
            <option value={constants.BILLS_STATUS.CANCELED}>Cancelado</option>
          </select>
        </div>
      </header>

      <main>
        <ReactTable
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '30px',
            width: '700px',
          }}
          data={billsToPay.filter(handleFilters)}
          columns={[
            {
              Header: 'Título',
              accessor: 'code',
              width: 80,
              Cell: (props) => (
                <Link to={{ pathname: `bill-to-pay#${props.original.id}` }}>
                  <button className="btn-link">{props.value}</button>
                </Link>
              ),
            },
            {
              Header: 'Compra',
              accessor: 'entryNumber',
              width: 80,
              Cell: (props) =>
                props.value ? <span>{props.value}</span> : <span>---</span>,
            },
            {
              Header: 'Valor',
              accessor: 'liquidValue',
              width: 80,
              Cell: (props) => currency(props.value),
            },
            {
              Header: 'Vencimento',
              accessor: 'dueDate',
              headerClassName: 'text-center',
              className: 'texto',
              width: 150,
              Cell: (props) => (
                <span
                  style={{
                    color: isAfter(
                      new Date(),
                      addDays(getDateOnlyFromDate(props.value), 1)
                    )
                      ? 'red'
                      : '',
                  }}
                >
                  {format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy')}
                </span>
              ),
            },
            {
              Header: 'Status',
              accessor: 'billStatusId',
              width: 90,
              Cell: (props) => (
                <Button
                  id="botaoReactTable"
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor:
                      props.value === constants.BILLS_STATUS.OPEN
                        ? '#ffcc00'
                        : props.value === constants.BILLS_STATUS.CLOSED
                        ? 'green'
                        : 'red',
                  }}
                >
                  {constants.BILLS_STATUS_DESCRIPTION[props.value]}
                </Button>
              ),
            },
            {
              Header: 'Ações',
              accessor: 'id',
              width: 100,
              headerClassName: 'text-left',
              filterable: false,
              Cell: (props) => (
                <Link to={{ pathname: `bill-to-pay#${props.value}` }}>
                  <FontAwesomeIcon
                    title="Editar"
                    cursor="pointer"
                    color="black"
                    style={{ height: '1.5em', width: '1.5em' }}
                    icon={faEdit}
                  />
                </Link>
              ),
            },
          ]}
          defaultPageSize={5}
          showPagination={true}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          defaultSorted={[
            {
              id: 'code',
              desc: true,
            },
          ]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText="Nenhum Produto encontrado"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </main>

      <hr />
      <footer>
        <CustomButton
          bsStyle="primary"
          fill
          onClick={() => setIsConfirmReclosingModalOpen(true)}
        >
          Realizar Novo Fechamento
        </CustomButton>
        <CustomButton bsStyle="danger" fill onClick={handleCancel}>
          Voltar
        </CustomButton>
      </footer>

      <AlertModal
        show={isConfirmReclosingModalOpen}
        title="Novo Fechamento de Compra"
        message="Deseja cancelar todos os títulos dessa compra e efetuar um fechamento? Todos os títulos dessa compra serão cancelados, independente do status. O processo é irreversível."
        onCancel={() => setIsConfirmReclosingModalOpen(false)}
        onHide={() => setIsConfirmReclosingModalOpen(false)}
        onSubmit={handleSubmitReclosingMOdal}
      />
    </div>
  );
};

export default BillsToPayTable;
