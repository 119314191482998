import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { ContainerWhite, Table } from './styles';
import Skeleton from 'react-loading-skeleton';

export const TableRank = ({
  rows,
  labelKey,
  valueKey,
  labelName,
  valueName,
  valueWidth,
  loading,
}) => {
  const renderMedal = (position) => {
    if (position === 1)
      return (
        <FontAwesomeIcon
          icon={faMedal}
          style={{ color: 'gold', width: '20px', height: '20px' }}
        />
      );
    if (position === 2)
      return (
        <FontAwesomeIcon
          icon={faMedal}
          style={{ color: 'silver', width: '20px', height: '20px' }}
        />
      );
    if (position === 3)
      return (
        <FontAwesomeIcon
          icon={faMedal}
          style={{ color: '#CD7F32', width: '20px', height: '20px' }}
        />
      );
    return null;
  };

  return (
    <ContainerWhite>
      <Table.Header>
        <Table.Label>
          {loading ? <Skeleton width={100} height={20} /> : labelName}
        </Table.Label>
        <Table.Value width={valueWidth}>
          {loading ? <Skeleton width={50} height={20} /> : valueName}
        </Table.Value>
      </Table.Header>
      {rows.map((row, index) => (
        <Table.Row key={index}>
          <Table.Medal>{renderMedal(index + 1)}</Table.Medal>
          <Table.Rank>{index + 1}</Table.Rank>
          <Table.RowContent>
            <Table.LabelText>{row[labelKey]}</Table.LabelText>
            <Table.ValueText width={valueWidth}>
              {row[valueKey]}
            </Table.ValueText>
          </Table.RowContent>
        </Table.Row>
      ))}
    </ContainerWhite>
  );
};
