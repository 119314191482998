import React, { useEffect, useState } from 'react';
import { InputContainer, InputLabel } from 'v2/components/Input';
import { SelectMulti } from 'v2/components/Select/Multi';
import { getBrandingName } from 'v2/helpers/brandingHelpers';
import { useFetch } from 'v2/hooks/useFetch/useFetch';
import { BrandingRepository } from 'v2/repositories/BrandingRepository';

export const SelectMultiBranding = ({
  width = 150,
  height,
  showAllOption = false,
  customAllOptionLabel,
  selectAllByDefault = false,
  fontSize,
  value = [],
  isDisabled = false,
  isLoading = false,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const { response, loading, error } = useFetch(BrandingRepository.getAll);

  const serializeOptions = (data) => {
    const serializedOptions = data.map((branding) => ({
      value: branding.id,
      label: getBrandingName(branding.product),
    }));

    if (showAllOption)
      serializedOptions.unshift({
        label: customAllOptionLabel || 'Todos',
        value: '*',
      });

    return serializedOptions;
  };

  useEffect(() => {
    if (response && !!response.length) {
      setOptions(serializeOptions(response));
    }
  }, [response]);

  return (
    <InputContainer>
      <SelectMulti
        id="select__branding"
        width={width}
        height={height}
        options={options}
        fontSize={fontSize}
        isMulti={true}
        isLoading={isLoading || loading}
        isDisabled={isDisabled || loading}
        value={value}
        selectAllByDefault={selectAllByDefault}
        {...props}
      />
    </InputContainer>
  );
};
