import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DownloadXlsCustomButton from 'components/DownloadXlsCustomButton';

import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

const PendingItemsModal = ({
  show,
  onHide,
  animation,
  title,
  subtitle,
  loading,
  message,
  onCancel,
  onSubmit,
  itemsToExport,
  columns,
  importType,
}) => {
  const [translatedImportType, setTranslatedImportType] = useState('');

  useEffect(() => {
    switch (importType) {
      case 'products':
        setTranslatedImportType('Produtos');
        break;
      case 'customers':
        setTranslatedImportType('Clientes');
        break;
      case 'services':
        setTranslatedImportType('Serviços');
        break;
      case 'providers':
        setTranslatedImportType('Fornecedores');
        break;
      default:
        return;
    }
  }, [importType]);

  return (
    <Modal show={show} onHide={onHide} animation={animation}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{!title ? 'OS Digital' : title}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {subtitle ? (
          <>
            <strong>{subtitle}</strong>
            <br></br>
            <br></br>
          </>
        ) : null}
        {message}
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            bsStyle="danger"
            disabled={loading}
            name="Não"
            onClick={(e) => onCancel(e)}
            fill
          >
            Fechar
          </Button>
          <DownloadXlsCustomButton
            archiveName={`${translatedImportType}-não-importados`}
            data={itemsToExport}
            columns={columns}
            bsStyle="info"
            disabled={loading}
            fill
            style={{ marginLeft: '10px' }}
          >
            <FontAwesomeIcon
              color="white"
              style={{ marginRight: '10px' }}
              icon={faCloudDownloadAlt}
            />
            Baixar Listagem de {translatedImportType}
          </DownloadXlsCustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PendingItemsModal;
