import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { useState } from 'react';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const ListCanceledItemsDocument = ({ responseData }) => {
  const [headerData] = useState(responseData.header);
  const [totalsData] = useState(responseData?.totals);
  const [reportData] = useState(responseData.report);

  return (
    <Document
      title={`impresso-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="listagem-de-itens-cancelados"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '10px',
          }}
        >
          <View
            style={{
              width: '40%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Listagem de Itens Cancelados</Text>
          </View>
          <Header headerData={headerData} />
        </View>

        {totalsData ? (
          <>
            <View style={styles.line} />
            <Totalizators totalsData={totalsData} />
          </>
        ) : (
          <View />
        )}
        <View style={styles.line} />
        <ReportListing reportData={reportData} />
      </Page>
    </Document>
  );
};

function FormatPercentage(number) {
  return (number * 100).toLocaleString('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
  });
}

const Header = ({ headerData }) => {
  return (
    <>
      <View style={{ width: '60%', flexDirection: 'row' }}>
        <View
          style={{
            flexDirection: 'column',
            width: '35%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Tipo de Venda: </Text>
            </View>
            <View>
              <Text>{headerData.typeOfSale}</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Tipo de Data: </Text>
            </View>
            <View>
              <Text>{headerData.typeOfDate}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '65%',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <View>
              <Text style={styles.strong}>Data Início: </Text>
            </View>
            <View style={{ marginRight: '5px' }}>
              <Text>
                {format(
                  getDateOnlyFromDate(new Date(headerData.initialDate)),
                  'dd/MM/yyyy'
                )}
              </Text>
            </View>

            <View>
              <Text style={styles.strong}>Data Fim: </Text>
            </View>
            <View>
              <Text>
                {format(
                  getDateOnlyFromDate(new Date(headerData.finalDate)),
                  'dd/MM/yyyy'
                )}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

const Totalizators = ({ totalsData }) => {
  return (
    <>
      <TotalizatorsHeader title={totalsData.title} />
      <TotalizatorsItems items={totalsData.items} />
      <TotalizatorsFinal
        qtd={totalsData.totalQuantity}
        value={totalsData.totalValue}
      />
    </>
  );
};
const TotalizatorsHeader = ({ title }) => {
  return (
    <>
      <View>
        <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
          {title}
        </Text>
      </View>
      <View style={[styles.line]} />
      <View
        style={{
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          left: '15px',
        }}
      >
        <Text style={[styles.boldText, { width: '30%' }]}>Descrição</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Marca</Text>
        <Text style={[styles.boldText, { width: '5%', textAlign: 'center' }]}>
          QTD
        </Text>
        <Text style={[styles.boldText, { width: '10%', textAlign: 'center' }]}>
          R$ Venda
        </Text>
      </View>
      <View style={styles.line} />
    </>
  );
};
const TotalizatorsItems = ({ items }) => {
  return (
    items &&
    items.map((item, key) => (
      <>
        <View
          key={key}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'left',
            padding: '1px',
          }}
        >
          <View style={{ width: '15px' }}>
            <Text style={styles.text}>{`${item.Position}.`}</Text>
          </View>
          <View style={{ width: '30%' }}>
            <Text style={styles.text}>{item.Description}</Text>
          </View>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>{item.Brand}</Text>
          </View>
          <View style={{ width: '5%', textAlign: 'center' }}>
            <Text style={styles.text}>{item.quantity}</Text>
          </View>
          <View style={{ width: '10%', textAlign: 'center' }}>
            <Text style={styles.text}>{currency(item.value)}</Text>
          </View>
        </View>
      </>
    ))
  );
};
const TotalizatorsFinal = ({ qtd, value }) => {
  return (
    <>
      <View style={[styles.line]} />
      <View
        style={{
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          left: '15px',
        }}
      >
        <Text style={[styles.boldText, { width: '50%' }]}>Total</Text>
        <Text style={[styles.boldText, { width: '5%', textAlign: 'center' }]}>
          {qtd}
        </Text>
        <Text style={[styles.boldText, { width: '10%', textAlign: 'center' }]}>
          {currency(value)}
        </Text>
      </View>
    </>
  );
};

const ReportListing = ({ reportData }) => {
  return (
    <>
      <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
        Listagem de Itens Por Venda
      </Text>
      <View style={[styles.line, { width: '100%' }]} />
      <View style={{ marginBottom: '10px' }} />
      {reportData &&
        reportData.map((sale, key) => (
          <>
            <View style={{ margin: '10px 0' }}>
              <ListingHeader sale={sale} key={key} />
              <ListingItems items={sale.items} />
              <View style={[styles.line, { width: '100%' }]} />
            </View>
          </>
        ))}
    </>
  );
};

const ListingHeader = ({ sale, key }) => {
  return (
    <>
      <View style={{ width: '100%', flexDirection: 'column' }} key={key}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: '70%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Venda/OS: </Text>
            </View>
            <View>
              <Text>{sale.Code}</Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '70%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Sub-total: </Text>
            </View>
            <View>
              <Text>{currency(sale.SubTotal)}</Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Desconto Geral: </Text>
            </View>
            <View>
              <Text>{currency(sale.Discount_Value)}</Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Total Venda: </Text>
            </View>
            <View>
              <Text>{currency(sale.Final_Value)}</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Data de Criação: </Text>
            </View>
            <View>
              <Text>
                {sale.createdAt &&
                  format(
                    getDateOnlyFromDate(new Date(sale.createdAt)),
                    'dd/MM/yyyy'
                  )}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Data de Fechamento: </Text>
            </View>
            <View>
              <Text>
                {sale.ClosingDate &&
                  format(
                    getDateOnlyFromDate(new Date(sale.ClosingDate)),
                    'dd/MM/yyyy'
                  )}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: '70%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Veículo: </Text>
            </View>
            <View>
              <Text>{sale.Vehicle}</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '50%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>KM: </Text>
            </View>
            <View>
              <Text>{sale.Kilometers}</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '50%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Montadora: </Text>
            </View>
            <View>
              <Text>{sale.Manufacturer}</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '50%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Chassi: </Text>
            </View>
            <View>
              <Text>{sale.Chassi}</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '50%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Combustível: </Text>
            </View>
            <View>
              <Text>{sale.Fuel_Type}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
const ListingItems = ({ items, key }) => {
  return (
    <>
      <ListingItemsTitle />
      {items &&
        items.map((item) => <ListingItemsContent item={item} key={key} />)}
    </>
  );
};
const ListingItemsTitle = () => {
  return (
    <>
      <View style={[styles.line, { width: '100%' }]} />
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          margin: '2px 0',
        }}
      >
        <View
          style={{
            width: '50px',
            flexDirection: 'row',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Código</Text>
        </View>
        <View
          style={{
            width: '30px',
            flexDirection: 'row',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Tipo</Text>
        </View>
        <View
          style={{
            width: '200px',
            flexDirection: 'row',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Descrição</Text>
        </View>
        <View
          style={{
            width: '150px',
            flexDirection: 'row',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Marca</Text>
        </View>
        <View
          style={{
            width: '60px',
            flexDirection: 'row',
            textAlign: 'center',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Quantidade</Text>
        </View>
        <View
          style={{
            width: '60px',
            flexDirection: 'row',
            textAlign: 'center',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>R$ Custo</Text>
        </View>
        <View
          style={{
            width: '60px',
            flexDirection: 'row',
            textAlign: 'center',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Preço Unit</Text>
        </View>
        <View
          style={{
            width: '60px',
            flexDirection: 'row',
            textAlign: 'center',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Desc. Unit %</Text>
        </View>
        <View
          style={{
            width: '60px',
            flexDirection: 'row',
            textAlign: 'center',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Total</Text>
        </View>
      </View>
      <View style={[styles.line, { width: '100%' }]} />
    </>
  );
};
const ListingItemsContent = ({ item, key }) => {
  return (
    <>
      <View
        key={key}
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '2px 0',
        }}
      >
        <View
          style={{
            width: '50px',
            flexDirection: 'row',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>{item.Code}</Text>
        </View>
        <View
          style={{
            width: '30px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Text style={[styles.boldTextOfType, { width: '100%' }]}>
            {item.Type === 'Produto' ? 'P' : 'S'}
          </Text>
        </View>
        <View
          style={{
            width: '200px',
            flexDirection: 'row',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {item.Description}
          </Text>
        </View>
        <View
          style={{
            width: '150px',
            flexDirection: 'row',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>{item.Brand}</Text>
        </View>
        <View
          style={{
            width: '60px',
            flexDirection: 'row',
            textAlign: 'center',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>{item.Quantity}</Text>
        </View>
        <View
          style={{
            width: '60px',
            flexDirection: 'row',
            textAlign: 'center',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {currency(item.Value_Cost)}
          </Text>
        </View>
        <View
          style={{
            width: '60px',
            flexDirection: 'row',
            textAlign: 'center',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {currency(item.Unit_Value)}
          </Text>
        </View>
        <View
          style={{
            width: '60px',
            flexDirection: 'row',
            textAlign: 'center',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {FormatPercentage(item.Unit_Discount_Value)}
          </Text>
        </View>
        <View
          style={{
            width: '60px',
            flexDirection: 'row',
            textAlign: 'center',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {currency(item.Amount)}
          </Text>
        </View>
      </View>
    </>
  );
};

export default ListCanceledItemsDocument;

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  mediumText: {
    fontSize: 10,
    fontWeight: 'medium',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  boldTextOfType: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
