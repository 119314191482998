import React from 'react';
export function PieChartGraphRoot({ children, style }) {
  return <div style={{ ...styles.container, ...style }}>{children}</div>;
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E6E6',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};
