import React, { useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import useManagementIndicatorsStore from '../../../store/management-indicators-store';
import RankingSalesBackground from '../components/RankingSalesBackground';

const SellersAndMechanics = () => {
  const { companyId } = useAuth();
  const { salesRanking, handleSalesRankingData, currentDate, isLoading } =
    useManagementIndicatorsStore((store) => ({
      salesRanking: store.salesRanking,
      handleSalesRankingData: store.handleSalesRankingData,
      currentDate: store.currentDate,
      isLoading: store.isLoading,
    }));

  useEffect(() => {
    handleSalesRankingData(companyId);
  }, [companyId, currentDate]);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      <RankingSalesBackground
        users={salesRanking.mechanicRanking}
        title="MECÂNICOS"
      />
      <RankingSalesBackground
        users={salesRanking.sellerRanking}
        title="VENDEDORES E OUTROS CARGOS"
      />
    </div>
  );
};

export default SellersAndMechanics;
