import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles, AntTabs, AntTab } from './Tabs.style';

export default function CustomizedTabs({ tabLabels, onChange, value }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs value={value} onChange={onChange} fontSize={14}>
          {tabLabels.map((label, index) => (
            <AntTab
              indicatorColor="black"
              textColor="red"
              key={index}
              label={
                typeof label === 'string' ? label : React.cloneElement(label)
              }
            />
          ))}
        </AntTabs>
        <Typography className={classes.padding} fontSize={14} />
      </div>
    </div>
  );
}
