import { toastr } from 'react-redux-toastr';
import { useLocation } from 'react-router-dom';
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  parseISO,
  format,
} from 'date-fns';

import { KanbanSchedulingRepository } from 'v2/repositories/KanbanSchedulingRepository';
import SchedulesRepository from 'repositories/Schedules';

export const useKanbanScheduling = () => {
  const location = useLocation();
  const handleDeleteColumn = async (companyId, columnIndexId) => {
    try {
      await KanbanSchedulingRepository.deleteColumn(companyId, columnIndexId);
      toastr.success(
        'Status excluído com sucesso',
        'O status/coluna foi excluído.'
      );
    } catch (error) {
      console.error(error);
      toastr.error('Não foi possível deletar o Status/Coluna');
    }
  };
  function formatDateTime(dateTimeString) {
    return format(parseISO(dateTimeString), 'dd/MM/yyyy HH:mm');
  }
  const getElapsedTime = (startDateTime, endDateTime) => {
    const start = parseISO(startDateTime);
    const end = parseISO(endDateTime);

    const totalMinutes = differenceInMinutes(end, start);

    if (totalMinutes < 60) {
      return `${totalMinutes}min`;
    }

    const totalHours = differenceInHours(end, start);
    if (totalHours < 24) {
      const minutes = totalMinutes % 60;
      return `${totalHours}h${minutes ? `${minutes}min` : ''}`;
    }

    const days = differenceInDays(end, start);
    const hours = totalHours % 24;
    const minutes = totalMinutes % 60;

    return `${days}d${hours ? `${hours}h` : ''}${
      minutes ? `${minutes}min` : ''
    }`;
  };

  async function deleteSchedulingCard(schedulingCardId) {
    try {
      await SchedulesRepository.destroy(schedulingCardId);
      toastr.success(
        'Agendamento excluído',
        'A exclusão do agendamento foi realizada com sucesso.'
      );
    } catch (error) {
      toastr.error('Não foi possível deletar o agendamento');
    }
  }
  async function moveCard(companyId, column) {
    try {
      await KanbanSchedulingRepository.statusCard(companyId, column);
      return;
    } catch (error) {
      console.error(error);
    }
  }
  const getCurrentDate = () => {
    return format(new Date(), 'yyyy-MM-dd');
  };
  const updateUrlWithDate = (key, value) => {
    const url = new URL(window.location.href);

    if (value) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }

    window.history.replaceState(null, '', url.toString());
  };
  const getInitialValuesFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return {
      initialDate: params.get('initialDate') || null,
      finalDate: params.get('finalDate') || null,
      showFinished: params.get('showFinished') === 'true' || false,
    };
  };

  return {
    handleDeleteColumn,
    formatDateTime,
    getElapsedTime,
    deleteSchedulingCard,
    getCurrentDate,
    moveCard,
    updateUrlWithDate,
    getInitialValuesFromUrl,
  };
};
