import React from 'react';
import { Modal } from 'react-bootstrap';

import Button from 'client/components/CustomButton/CustomButton';
import { useState } from 'react';
import { useEffect } from 'react';

import NFSePatternRepository from 'repositories/NFSePatterns';

export default function AddTributeModal({
  loading,
  handleClose,
  handleOperationTaxSubmit,
  operationTaxId,
}) {
  const [type, setType] = useState('Operação');
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (operationTaxId !== null) {
      loadTributeInfo(operationTaxId);
    }
  }, [operationTaxId]);

  async function loadTributeInfo(operationTaxId) {
    try {
      const data = await NFSePatternRepository.getOperationTaxById(
        operationTaxId
      );

      setType(data.type === 'O' ? 'Operação' : 'Tributação');
      setCode(data.typeCode);
      setDescription(data.description);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal show={true} onHide={handleClose} dialogClassName="modal-50w">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Adicionar Tributo</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '5px',
          }}
        >
          <fieldset
            style={{
              width: '20%',
            }}
          >
            <label>Tipo:</label>
            <select
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
              className="form-control foco-input"
            >
              <option value="Operação">Operação</option>
              <option value="Tributação">Tributação</option>
            </select>
          </fieldset>
          <fieldset
            style={{
              width: '15%',
            }}
          >
            <label>Código:</label>
            <input
              type="text"
              className="form-control foco-input"
              value={code}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.match(/^[a-zA-Z0-9]*$/)) {
                  setCode(newValue);
                }
              }}
            />
          </fieldset>
          <fieldset
            style={{
              width: '65%',
            }}
          >
            <label>Descrição:</label>
            <input
              type="text"
              className="form-control foco-input"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </fieldset>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ alignItems: 'end' }}>
          <Button
            bsStyle="danger"
            disabled={loading}
            onClick={() => handleClose()}
            fill
          >
            Cancelar
          </Button>

          <Button
            bsStyle="info"
            disabled={loading || !code || !description}
            onClick={() =>
              handleOperationTaxSubmit(operationTaxId, {
                type,
                code,
                description,
              })
            }
            fill
          >
            Salvar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
