import React from 'react';
import { useThemeContext } from 'v2/contexts/themeContext';
import '../styles.css';

const Footer = () => {
  const { getBrandingLogo } = useThemeContext();

  return (
    <>
      <div className="income-statement__footer-rectangle bg-primary">
        <center>
          <img
            style={{
              width: '150px',
              height: '45px',
            }}
            src={getBrandingLogo('horizontal-white')}
            alt={getBrandingLogo('alt')}
          />
        </center>
      </div>
    </>
  );
};

export default Footer;
