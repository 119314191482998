import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import FormProduto from './FormProduct';
import constants from '../../../../../utils/constants';
import { useAuth } from '../../../../../contexts/auth';
import { useDispatch } from 'react-redux';
import s3Repository from '../../../../../repositories/S3';
import inventoryMovementRepository from '../../../../../repositories/InventoryMovement';
import { getProductsUnderStock } from '../../../../components/MinStockAlertButton/redux/actions';
import productsRepository from '../../../../../repositories/Products';

const BundleFormProduto = ({
  ProductId,
  history,
  onSuccess,
  onCancel,
  productInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const { companyId, company } = useAuth();
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    if (
      !values.Description ||
      !values.TypeUnityItem ||
      !values.Family ||
      !values.Brand ||
      !values.Sale_Price ||
      (company.companyConfig.sellingPriceType === 'Preço Atacado e Varejo' &&
        (!values.salePriceWholesale || !values.profitMarginWholesale))
    ) {
      return toastr.warning('Por favor, informe os campos obrigatórios.');
    }

    if (parseInt(values.minStock) > parseInt(values.maxStock)) {
      return toastr.warning(
        'Valores de estoque mínimo e máximo incorretos. Por favor, verifique para prosseguir.'
      );
    }

    setLoading(true);
    const uploadedPhotos = await uploadPhotos(values);

    if (!ProductId) {
      create({ ...values, uploadedPhotos });
    } else {
      update({ ...values, uploadedPhotos });
    }
  };

  const uploadPhotos = async (values) => {
    try {
      if (values?.photos.length == 0) {
        return [];
      }

      const photosToUpload = values.photos.filter((photo) => !photo.uploaded);

      const { data } = await s3Repository.uploadImages(
        photosToUpload,
        company.Cpf_Cnpj + '/products'
      );

      return data;
    } catch (err) {
      return [];
    }
  };

  const create = async (values) => {
    setLoading(true);

    if (values.Sale_Price < values.Purchase_Price) {
      setLoading(false);
      return toastr.warning(
        'O preço de venda deve ser maior ou igual ao preço de compra'
      );
    }

    let stockQuantity = values.Stock_Quantity;

    if (String(stockQuantity).includes(',')) {
      stockQuantity = String(stockQuantity).replace(',', '.');
    }

    let NCM_ItemParsed = {};

    if (values.NCM_Item) {
      NCM_ItemParsed = JSON.parse(values.NCM_Item);
    }
    try {
      const response = await productsRepository.createProduct({
        Company_id: companyId,
        Description: values.Description.toUpperCase(),
        surname: values.surname,
        Sale_Price: values.Sale_Price,
        salePriceWholesale: values.salePriceWholesale,
        IsActive: values.Status,
        Family_id: values.Family,
        Brand_id: values.Brand,
        Warranty_id: values.Warranty,
        Review_id: values.Review,
        Unit_Type: values.TypeUnityItem,
        Stock_Quantity: 0,
        Profit_Margin: values.Profit_Margin,
        profitMarginWholesale: values.profitMarginWholesale,
        Multiplier_Base: values.Multiplier_Base,
        Purchase_Price: values.Purchase_Price,
        Location: values.Location,
        Manufacturer_Ref: values.Manufacturer_Ref,
        Bar_Code: values.Bar_Code,
        Additional_Info: values.Additional_Info,
        product_type: values.product_type,
        Type: values.product_type,
        Tribute_BarCode: values.Tribute_BarCode,
        Origin: values.Origin,
        CSOSN: values.CSOSN,
        CST: values.CST,
        CFOP_InState_Code: values.CFOP_InState_Code,
        CFOP_OutState_Code: values.CFOP_OutState_Code,
        NCM_Code: values.NCM_Code,
        CEST_Code: values.CEST_Code,
        PIS_Exit: values.PIS_Exit,
        COFINS_Exit: values.COFINS_Exit,
        PIS_Entry: values.PIS_Entry,
        calculateStOnSale: values.calculateStOnSale,
        COFINS_Entry: values.COFINS_Entry,
        IPI: values.IPI,
        ANP: values.ANP ?? null,
        ANP_Description: values.ANP_Description,
        ANP_UF_Code: values.ANP_UF_Code,
        minStock: values.minStock ?? null,
        maxStock: values.maxStock ?? null,
        Commission_Rate: values.Commission_Rate,
        photos: values.uploadedPhotos,
        updateDescriptionOnSale: values.updateDescriptionOnSale,
        Weight: values.weight,
        netWeight: values.netWeight,
        ncmNbsId: NCM_ItemParsed.id,
        production: values.production,
        singlePhase: values.singlePhase,
        hasDiscountLimit: values.hasDiscountLimit,
        discountLimit: values.discountLimit,
        OriginalFactoryCode: values.OriginalFactoryCode,
        AlternativeCode: values.AlternativeCode,
        // cstIpiEntryId: values.cstIpiEntryId,
        // cstIpiExitId: values.cstIpiExitId,
        // ipiFramework: values.ipiFramework,
      });

      if (!response.success) {
        if (
          response.message ===
          'Manufacturer reference already registered in another product'
        ) {
          return toastr.warning(
            'Já existe um produto com esta Ref. Fabricante e Marca. Por favor altere a mesma e tente novamente'
          );
        }
        return toastr.error(
          'Ocorreu um erro ao salvar o produto. Por favor, tente novamente'
        );
      }

      if (stockQuantity != 0) {
        try {
          const code = await inventoryMovementRepository.getCode(companyId);
          await inventoryMovementRepository.create({
            movement: {
              movementType: 'Entrada',
              code: code,
              registerDate: new Date(),
              finishedDate: new Date(),
              companyId,
              status: 'Finalizada',
            },
            items: [
              {
                code: response.product.Code,
                description: values.Description.toUpperCase(),
                productId: response.product.id,
                quantity: Number(stockQuantity),
                unitValue: values.Sale_Price,
              },
            ],
          });
        } catch (err) {
          toastr.warning('Erro ao cadastrar movimentação de estoque.');
          console.err(err);
        }
      }

      toastr.success('Produto cadastrado com sucesso!');
      history.push(constants.ROUTES.PRODUCTS);
    } catch (err) {
      if (err?.response?.status === 504) {
        return toastr.error(
          'Falha de conexão ao salvar',
          'Houve um erro no momento de salvar e o cadastro pode ter sido efetuado. Antes de salvar novamente, verifique se o produto não foi cadastrado. Caso a falha persista, entre em contato com o suporte.'
        );
      }

      if (err.response) {
        if (values?.uploadedPhotos?.length > 0) {
          await s3Repository.deleteImages(values.uploadedPhotos);
        }

        toastr.error(
          'Ocorreu um erro ao salvar o produto. Por favor, tente novamente'
        );
      } else {
        toastr.error(
          'Ocorreu um erro ao salvar o produto. Por favor, tente novamente'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const update = async (values) => {
    setLoading(true);

    if (values.Sale_Price < values.Purchase_Price) {
      setLoading(false);
      return toastr.warning(
        'O preço de venda deve ser maior ou igual ao preço de compra.'
      );
    }

    let NCM_ItemParsed = {};

    if (values.NCM_Item) {
      NCM_ItemParsed = JSON.parse(values.NCM_Item);
    }

    try {
      const response = await productsRepository.updateProduct(ProductId, {
        Company_id: companyId,
        Description: values.Description.toUpperCase(),
        surname: values.surname,
        Sale_Price: values.Sale_Price,
        IsActive: values.Status,
        Family_id: values.Family,
        Brand_id: values.Brand,
        Warranty_id: !values.Warranty ? null : values.Warranty,
        Review_id: !values.Review ? null : values.Review,
        Unit_Type: values.TypeUnityItem,
        Stock_Quantity: values.Stock_Quantity,
        Profit_Margin: values.Profit_Margin,
        profitMarginWholesale: values.profitMarginWholesale,
        Multiplier_Base: values.Multiplier_Base,
        Purchase_Price: values.Purchase_Price,
        salePriceWholesale: values.salePriceWholesale,
        Location: !values.Location ? '' : values.Location,
        Manufacturer_Ref: !values.Manufacturer_Ref
          ? ''
          : values.Manufacturer_Ref,
        Bar_Code: !values.Bar_Code ? '' : values.Bar_Code,
        Additional_Info: !values.Additional_Info ? '' : values.Additional_Info,
        Tribute_BarCode: values.Tribute_BarCode ?? '',
        Origin: values.Origin ?? '',
        CSOSN: values.CSOSN ?? '',
        CST: values.CST ?? '',
        CFOP_InState_Code: values.CFOP_InState_Code ?? '',
        CFOP_OutState_Code: values.CFOP_OutState_Code ?? '',
        NCM_Code: values.NCM_Code ?? '',
        CEST_Code: values.CEST_Code ?? '',
        PIS_Exit: values.PIS_Exit ?? '',
        COFINS_Exit: values.COFINS_Exit ?? '',
        PIS_Entry: values.PIS_Entry ?? '',
        calculateStOnSale: values.calculateStOnSale,
        COFINS_Entry: values.COFINS_Entry ?? '',
        IPI: values.IPI ?? '',
        ANP: values.ANP ?? null,
        ANP_Description: values.ANP_Description ?? '',
        ANP_UF_Code: values.ANP_UF_Code ?? '',
        photos: values.uploadedPhotos,
        photosToDelete: values.photosToDelete,
        product_type: values.product_type,
        Type: values.product_type,
        minStock: values.minStock ?? null,
        maxStock: values.maxStock ?? null,
        Commission_Rate: values.Commission_Rate ?? '',
        updateDescriptionOnSale: values.updateDescriptionOnSale,
        Weight: values.weight,
        netWeight: values.netWeight,
        ncmNbsId: NCM_ItemParsed.id,
        production: values.production,
        singlePhase: values.singlePhase,
        hasDiscountLimit: values.hasDiscountLimit,
        discountLimit: values.discountLimit,
        OriginalFactoryCode: values.OriginalFactoryCode,
        AlternativeCode: values.AlternativeCode,
        // cstIpiEntryId: values.cstIpiEntryId,
        // cstIpiExitId: values.cstIpiExitId,
        // ipiFramework: values.ipiFramework,
      });

      if (values?.photosToDelete?.length > 0) {
        await s3Repository.deleteImages(values.photosToDelete);
      }

      if (!response.success) {
        if (
          response.message ===
          'Manufacturer reference already registered in another product'
        ) {
          return toastr.warning(
            'Já existe um produto com esta Ref. Fabricante e Marca. Por favor altere a mesma e tente novamente'
          );
        }
        return toastr.error(
          'Ocorreu um erro ao atualizar o produto. Por favor, tente novamente'
        );
      }
      dispatch(getProductsUnderStock(companyId));
      toastr.success('Produto atualizado com sucesso!');
      if (onSuccess) return onSuccess();
      history.push(constants.ROUTES.PRODUCTS);
    } catch (err) {
      if (err?.response?.status === 504) {
        return toastr.error(
          'Falha de conexão ao salvar',
          'Houve um erro no momento de salvar mas as alterações podem ser sido salvas. Verifique e caso não tenha sido salvo, salve novamente. Caso a falha persista, entre em contato com o suporte.'
        );
      }

      if (err?.response) {
        if (values?.uploadedPhotos?.length > 0) {
          await s3Repository.deleteImages(values.uploadedPhotos);
        }

        toastr.warning(
          'Ocorreu um erro ao atualizar o produto. Por favor, tente novamente'
        );
      } else {
        toastr.warning(
          'Ocorreu um erro ao atualizar o produto. Por favor, tente novamente'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    PIS_Exit: '04',
    COFINS_Exit: '04',
    PIS_Entry: '70',
    COFINS_Entry: '70',
    Stock_Quantity: 0,
    Profit_Margin: '100',
    profitMarginWholesale: '100',
    Additional_Info: '',
    Multiplier_Base: 1,
    Status: 1,
    Commission_Rate: company.defaultProductCommissionRate,
    Purchase_Price: 0,
    Sale_Price: 0,
    salePriceWholesale: 0,
    // cstIpiExitId: 8,
    // cstIpiEntryId: null,
    // ipiFramework: '999',
  };

  return (
    <FormProduto
      onSubmit={handleSubmit}
      ProductId={ProductId}
      loading={loading}
      initialValues={initialValues}
      onCancel={onCancel}
      productInfo={productInfo}
    />
  );
};

export default withRouter(BundleFormProduto);
