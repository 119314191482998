import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { toastr } from 'react-redux-toastr';

import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from 'contexts/auth';
import { useAlerts } from 'v2/hooks/useAlerts';

import './index.css';
import { useModulesContext } from 'contexts/modules';

const RenderMaintenanceAlert = ({
  alert,
  handleRedirectToSale,
  handleVisualizeAlert,
}) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      fontSize: '10px',
      whiteSpace: 'nowrap',
    }}
    onClick={() =>
      handleRedirectToSale(alert.Maintenance.Sales.id, alert.id, 'maintenance')
    }
  >
    <span>A ordem de serviço foi finalizada e concluída.</span>
    <strong>OS: {alert?.Maintenance?.Sales?.Code}</strong>
  </div>
);

const RenderBoletoFlexAlert = ({
  alert,
  handleRedirectToSale,
  handleVisualizeAlert,
}) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      fontSize: '10px',
      whiteSpace: 'nowrap',
    }}
    onClick={() =>
      handleRedirectToSale(
        alert?.BoletoFlexTransaction?.Sale?.id,
        alert?.id,
        'boleto-flex'
      )
    }
  >
    <span>{alert.message}</span>
    <strong>Venda/OS: {alert?.BoletoFlexTransaction?.Sale?.Code}</strong>
  </div>
);

const RenderKoinAlert = ({
  alert,
  handleRedirectToSale,
  handleVisualizeAlert,
}) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      fontSize: '10px',
      whiteSpace: 'nowrap',
    }}
    onClick={() =>
      handleRedirectToSale(
        alert?.KoinTransactions?.Sales?.id,
        alert?.id,
        'koin'
      )
    }
  >
    <span>{alert.message}</span>
    <strong>Venda/OS: {alert?.KoinTransactions?.Sales?.Code}</strong>
  </div>
);

const AlertMessages = ({
  alerts,
  handleVisualizeAlert,
  handleVisualizeAllAlerts,
  handleRedirectToSale,
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '5px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {alerts?.length > 0 && (
            <ReadAllMessages
              handleVisualizeAllAlerts={handleVisualizeAllAlerts}
            />
          )}
        </div>
      </div>
      {alerts?.map((alert) => {
        return (
          <>
            <hr
              style={{
                backgroundColor: 'black',
                width: '100%',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                height: '50px',
                color: 'black',
              }}
              className="alert-list"
            >
              {alert.type === 'MAINTENANCE' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    gap: '10px',
                  }}
                >
                  <div style={{ width: '20px' }}>
                    <div
                      style={{
                        backgroundColor: '#d9534f',
                        borderRadius: '100%',
                        alignSelf: 'center',
                        width: '12px',
                        margin: '5px',
                        height: '12px',
                      }}
                    />
                  </div>
                  <RenderMaintenanceAlert
                    alert={alert}
                    handleRedirectToSale={handleRedirectToSale}
                    handleVisualizeAlert={handleVisualizeAlert}
                  />
                </div>
              )}
              {alert.type === 'BOLETO_FLEX' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    gap: '10px',
                  }}
                >
                  <div style={{ width: '20px' }}>
                    <div
                      style={{
                        backgroundColor: '#d9534f',
                        borderRadius: '100%',
                        alignSelf: 'center',
                        width: '12px',
                        margin: '5px',
                        height: '12px',
                      }}
                    />
                  </div>
                  <RenderBoletoFlexAlert
                    alert={alert}
                    handleRedirectToSale={handleRedirectToSale}
                    handleVisualizeAlert={handleVisualizeAlert}
                  />
                </div>
              )}
              {alert.type === 'KOIN' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    gap: '10px',
                  }}
                >
                  <div style={{ width: '20px' }}>
                    <div
                      style={{
                        backgroundColor: '#d9534f',
                        borderRadius: '100%',
                        alignSelf: 'center',
                        width: '12px',
                        margin: '5px',
                        height: '12px',
                      }}
                    />
                  </div>
                  <RenderKoinAlert
                    alert={alert}
                    handleRedirectToSale={handleRedirectToSale}
                    handleVisualizeAlert={handleVisualizeAlert}
                  />
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span
                  onClick={() => handleVisualizeAlert(alert.id, 'maintenance')}
                  className="visualize-alert-span"
                >
                  X
                </span>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

const ReadAllMessages = ({ handleVisualizeAllAlerts }) => {
  return (
    <a onClick={handleVisualizeAllAlerts} className="hyperlink">
      Marcar todos como lidos
    </a>
  );
};

const NoAlertsMessage = () => {
  return (
    <section
      style={{
        margin: 'auto',
      }}
    >
      <span className="no-notifications-message">
        Você não possui notificações.
      </span>
    </section>
  );
};

function AlertPopover() {
  const { allAlerts, getAlerts, getVisualizeAllAlerts, handleVisualizeAlert } =
    useAlerts();
  const [showSubMenu, setShowSubMenu] = useState(false);

  const { companyId } = useAuth();
  const history = useHistory();

  useEffect(() => {
    loadAlerts();
    reloadAlertList();
  }, [companyId]);

  const reloadAlertList = () => {
    setInterval(() => {
      loadAlerts();
    }, 500000);
  };

  const loadAlerts = async () => {
    await getAlerts(companyId);
  };

  const handleVisualizeAllAlerts = async () => {
    try {
      await getVisualizeAllAlerts(companyId);
    } catch (err) {
      toastr.error('Erro ao atualizar os status das notificações', `${err}.`);
    }
  };

  const handleRedirectToSale = async (saleId, alertId, alertType) => {
    history.push(`sale#${saleId}`, { saleId });
    handleVisualizeAlert(alertId, alertType);
    setShowSubMenu(false);
  };

  return (
    <div className="alert-popover">
      <div className="header-sub-menu-alert">
        <span onClick={() => setShowSubMenu(!showSubMenu)} className="bell">
          <FontAwesomeIcon
            icon={faBell}
            style={{ color: 'white', fontSize: '17px' }}
          />
          {allAlerts?.length > 0 && (
            <div className="bell-noticiation-circle">
              <span
                className={
                  allAlerts.length <= 9
                    ? 'bell-notification-counter'
                    : 'bell-notification-counter-two-digits'
                }
              >
                {allAlerts.length}
              </span>
            </div>
          )}
        </span>

        {showSubMenu && (
          <div className="header-sub-menu-alert-content">
            {allAlerts?.length === 0 ? (
              <NoAlertsMessage />
            ) : (
              <AlertMessages
                alerts={allAlerts}
                handleVisualizeAlert={handleVisualizeAlert}
                handleVisualizeAllAlerts={handleVisualizeAllAlerts}
                handleRedirectToSale={handleRedirectToSale}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AlertPopover;
