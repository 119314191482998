import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';

import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany';

const NoSignatureDetails = ({ signature, cancelled }) => {
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const signatureEndDate = !signature.End_Date
    ? ''
    : format(new Date(signature.End_Date), 'dd/MM/yyyy');

  return (
    <>
      <p id="bdPlanoBody">
        Você pode utilizar {cancelled ? 'o sistema' : 'a versão de testes'} até{' '}
        <strong>{signatureEndDate}</strong>
      </p>
      {noCompanyModal && (
        <ModalAlertCompany
          show={noCompanyModal}
          onHide={() => setNoCompanyModal(false)}
        />
      )}
    </>
  );
};

export default withRouter(NoSignatureDetails);
