import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';

import { format } from 'date-fns';
import { isAfter } from 'date-fns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from 'contexts/auth';
import useFilters from 'hooks/useFilters';

import { useSelector, useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { toastr } from 'react-redux-toastr';

import SearchInput from 'components/SearchInput';
import AlertModal from 'components/AlertModal';
import ReplicatePaymentModal from './ReplicatePaymentModal';

import { currency } from 'client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

import paymentFormsRepository from 'repositories/PaymentForms';
import cashierBanksRepository from 'repositories/CashierBank';

import '../../styles.css';
import CurrencyInput from 'react-currency-input';

const TitleDischarger = ({
  billType,
  previousPage,
  accountPlans,
  debitAccountPlans,
}) => {
  const [paymentForms, setPaymentForms] = useState([]);
  const [cashierBanks, setCashierBanks] = useState([]);
  const [filterHasPaymentForm, setFilterHasPaymentForm] = useState('both');
  const [isReplicatePaymentModalOpen, setIsReplicatePaymentModalOpen] =
    useState(false);
  const [isDeleteBillModalOpen, setIsDeleteBillModalOpen] = useState(false);
  const [billToDelete, setBillToDelete] = useState();

  const { queryFilter, dateFilter } = useFilters();

  const { query, setQuery, filterByQuery } = queryFilter;
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter;

  const { selectedTitles: bills } = useSelector(
    (state) => state.form.titleQuickDischarge.values
  );

  console.log({ bills });

  const dispatch = useDispatch();

  const { companyId } = useAuth();

  const todayDate = format(new Date(), 'yyyy-MM-dd');

  useEffect(() => {
    if (!companyId) return;
    loadPaymentForms(companyId);
    loadCashierBanks();
  }, [companyId]);

  const loadPaymentForms = async (companyId) => {
    let paymentForms = await paymentFormsRepository.getAllByCompany(companyId);

    if (billType === 'toReceive') {
      paymentForms = paymentForms.filter(
        (item) => item.TypeOfPayment !== 'Crédito Loja'
      );
    }

    setPaymentForms(paymentForms);
  };

  const loadCashierBanks = async () => {
    try {
      const cashierBanks =
        await cashierBanksRepository.getCashierBanksByCompany(companyId);

      const activeCashierBanks = cashierBanks.filter(
        (cashierBank) => cashierBank.isActive
      );

      setCashierBanks(activeCashierBanks);
    } catch (error) {
      toastr.error('Erro', 'Erro ao carregar bancos da empresa.');
    }
  };

  const handleChangeBill = (billToChange, attribute, value) => {
    const billsCopy = [...bills];
    const billIndex = billsCopy.findIndex(
      (bill) => bill.id === billToChange.id
    );
    billsCopy[billIndex][attribute] = value;

    dispatch(change('titleQuickDischarge', 'selectedTitles', billsCopy));
  };

  const handleFee = (billToChange, feeValue) => {
    feeValue = feeValue > 0 ? feeValue : 0;

    const billsCopy = [...bills];
    const billIndex = billsCopy.findIndex(
      (bill) => bill.id === billToChange.id
    );

    const addedValue =
      +billsCopy[billIndex].addedValue -
      +billsCopy[billIndex].originalAddedValue;
    const discountValue =
      +billsCopy[billIndex].discountValue -
      +billsCopy[billIndex].originalDiscountValue;

    billsCopy[billIndex].openValue =
      billsCopy[billIndex].originalOpenValue +
      (Number(feeValue) - Number(billsCopy[billIndex].originalFeeValue)) +
      addedValue -
      discountValue;

    dispatch(change('titleQuickDischarge', 'selectedTitles', billsCopy));
  };

  const handleAddedValue = (billToChange, addedValue) => {
    addedValue = addedValue > 0 ? addedValue : 0;

    const billsCopy = [...bills];
    const billIndex = billsCopy.findIndex(
      (bill) => bill.id === billToChange.id
    );

    const feeValue =
      +billsCopy[billIndex].feeValue - +billsCopy[billIndex].originalFeeValue;
    const discountValue =
      +billsCopy[billIndex].discountValue -
      +billsCopy[billIndex].originalDiscountValue;

    if (billType === 'toReceive') {
      billsCopy[billIndex].openValue =
        billsCopy[billIndex].originalOpenValue +
        (Number(addedValue) - Number(billsCopy[billIndex].originalAddedValue)) +
        feeValue -
        discountValue;
    } else {
      billsCopy[billIndex].openValue =
        billsCopy[billIndex].originalOpenValue +
        (Number(addedValue) - Number(billsCopy[billIndex].originalAddedValue)) -
        discountValue;
    }

    dispatch(change('titleQuickDischarge', 'selectedTitles', billsCopy));
  };

  const handleDiscountValue = (billToChange, discountValue) => {
    discountValue = discountValue > 0 ? discountValue : 0;

    const billsCopy = [...bills];
    const billIndex = billsCopy.findIndex(
      (bill) => bill.id === billToChange.id
    );

    const feeValue =
      +billsCopy[billIndex].feeValue - +billsCopy[billIndex].originalFeeValue;
    const addedValue =
      +billsCopy[billIndex].addedValue -
      +billsCopy[billIndex].originalAddedValue;

    if (billType === 'toReceive') {
      billsCopy[billIndex].openValue =
        billsCopy[billIndex].originalOpenValue +
        feeValue +
        addedValue -
        (Number(discountValue) -
          Number(billsCopy[billIndex].originalDiscountValue));
    } else {
      billsCopy[billIndex].openValue =
        billsCopy[billIndex].originalOpenValue +
        addedValue -
        (Number(discountValue) -
          Number(billsCopy[billIndex].originalDiscountValue));
    }

    dispatch(change('titleQuickDischarge', 'selectedTitles', billsCopy));
  };

  const handleLowDateChange = (date, billToChange) => {
    if (isAfter(new Date(date), new Date()))
      return toastr.warning('A data inserida deve ser no máximo a atual.');

    handleChangeBill(billToChange, 'lowDate', date);
  };

  const handleFilters = (bill) => {
    const querySearch = [
      bill.code,
      bill.Customer?.Company_Name,
      bill.Sales?.Code,
      bill.Provider?.companyName,
      bill.Purchases?.code,
    ];
    return (
      filterByQuery(querySearch) &&
      filterByDate('dueDate', bill) &&
      filterByPaymentForm(bill)
    );
  };

  const filterByPaymentForm = (bill) => {
    if (filterHasPaymentForm === 'both') return true;

    const hasPaymentForm = !!bill.paymentFormId;

    if (filterHasPaymentForm === 'true') return hasPaymentForm;
    return !hasPaymentForm;
  };

  const deleteBill = () => {
    const filteredBills = bills.filter((bill) => bill.billId !== billToDelete);
    dispatch(change('titleQuickDischarge', 'selectedTitles', filteredBills));

    setIsDeleteBillModalOpen(false);
    if (!filteredBills.length) return previousPage();
  };

  const getOpenValueColor = (props) => {
    if (Number(props.original.originalOpenValue) > Number(props.value)) {
      return '#4cae4c';
    } else if (Number(props.original.originalOpenValue) < Number(props.value)) {
      return '#d9534f';
    } else {
      return 'black';
    }
  };

  return (
    <>
      <section className="filters">
        <div className="filter-row">
          <div style={{ width: 600 }}>
            <SearchInput
              placeholder={
                billType === 'toPay'
                  ? 'Pesquisa por Título, Fornecedor ou Nº de Compra'
                  : 'Pesquisa por Título, Cliente ou  Nº da Venda/Ordem de Serviço'
              }
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div style={{ width: 300, display: 'flex', height: 35 }}>
            <label style={{ width: 200 }}>Possui forma de pagamento:</label>
            <select
              style={{ width: 100 }}
              className="form-control foco-input"
              value={filterHasPaymentForm}
              onChange={(e) => setFilterHasPaymentForm(e.target.value)}
            >
              <option value="both">Ambos</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>
        <div className="filter-row">
          <div style={{ width: 300, display: 'flex' }}>
            <label style={{ width: 150 }}>Data Inicial:</label>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              max={finalDate || '9999-12-31'}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </div>
          <div style={{ width: 300, display: 'flex' }}>
            <label style={{ width: 150 }}>Data Final:</label>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              min={initialDate}
              max="9999-12-31"
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </div>
          <button
            className="btn btn-primary btn-fill"
            style={{
              padding: '7px 30px',
              margin: '0 20px',
            }}
            onClick={() => setIsReplicatePaymentModalOpen(true)}
          >
            Replicar Forma de Pagamento
          </button>
        </div>
      </section>
      <ReactTable
        style={{
          fontWeight: 'bold',
          fontSize: '12px',
          textAlign: 'center',
          width: '100%',
        }}
        className="no-padding-react-table"
        data={bills.filter(handleFilters)}
        columns={[
          {
            Header: '',
            accessor: 'billId',
            width: 40,
            Cell: (props) => (
              <FontAwesomeIcon
                title="Excluir"
                cursor="pointer"
                style={{
                  height: '1.5em',
                  width: '1.5em',
                  color: '#bd362f',
                  marginRight: '5px',
                }}
                icon={faTrashAlt}
                onClick={() => {
                  setIsDeleteBillModalOpen(true);
                  setBillToDelete(props.value);
                }}
              />
            ),
          },
          {
            Header: 'Título',
            accessor: 'code',
            width: 100,
          },
          {
            Header: 'Vencimento',
            accessor: 'dueDate',
            width: 120,
            Cell: (props) =>
              format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy'),
          },
          {
            Header: billType === 'toReceive' ? 'Cliente' : 'Fornecedor',
            accessor:
              billType === 'toReceive'
                ? 'Customer.Company_Name'
                : 'Provider.companyName',
          },
          {
            Header: 'Valor',
            accessor: 'openValue',
            width: 100,
            Cell: (props) => (
              <span
                style={{
                  color: getOpenValueColor(props),
                }}
              >
                {currency(props.value)}
              </span>
            ),
          },
          {
            Header: 'Forma de Pagamento',
            accessor: 'paymentFormId',
            width: 160,
            Cell: (props) => (
              <select
                className="form-control foco-input"
                value={props.value}
                onChange={(e) =>
                  handleChangeBill(
                    props.original,
                    'paymentFormId',
                    e.target.value
                  )
                }
              >
                <option value="">Selecione</option>
                {paymentForms.map((paymentForm) => (
                  <option value={paymentForm.id} key={paymentForm.id}>
                    {paymentForm.Desciption}
                  </option>
                ))}
              </select>
            ),
          },
          {
            Header: 'Juros',
            show: billType === 'toReceive',
            accessor: 'feeValue',
            width: 100,
            Cell: (props) => (
              <CurrencyInput
                id={`feeValue-input-${props.index}`}
                className="form-control foco-input"
                value={props.value}
                onChangeEvent={(e) => {
                  handleChangeBill(
                    props.original,
                    'feeValue',
                    parseFloat(e.target.value)
                  );

                  handleFee(props.original, e.target.value);

                  setTimeout(() => {
                    document
                      .getElementById(`feeValue-input-${props.index}`)
                      .focus();
                  }, 100);
                }}
              />
            ),
          },
          {
            Header: 'Acrésc.',
            accessor: 'addedValue',
            width: 100,
            Cell: (props) => (
              <CurrencyInput
                id={`addedValue-input-${props.index}`}
                className="form-control foco-input"
                value={props.value}
                onChangeEvent={(e) => {
                  handleChangeBill(
                    props.original,
                    'addedValue',
                    parseFloat(e.target.value)
                  );

                  handleAddedValue(props.original, e.target.value);

                  setTimeout(() => {
                    document
                      .getElementById(`addedValue-input-${props.index}`)
                      .focus();
                  }, 100);
                }}
              />
            ),
          },
          {
            Header: 'Desc.',
            accessor: 'discountValue',
            width: 100,
            Cell: (props) => (
              <CurrencyInput
                id={`discountValue-input-${props.index}`}
                className="form-control foco-input"
                value={props.value}
                onChangeEvent={(e) => {
                  handleChangeBill(
                    props.original,
                    'discountValue',
                    parseFloat(e.target.value)
                  );

                  handleDiscountValue(props.original, e.target.value);

                  setTimeout(() => {
                    document
                      .getElementById(`discountValue-input-${props.index}`)
                      .focus();
                  }, 100);
                }}
              />
            ),
          },
          {
            Header: 'Conta',
            accessor: 'accountPlanId',
            width: 160,
            Cell: (props) => (
              <select
                className="form-control foco-input"
                value={props.value}
                onChange={(e) =>
                  handleChangeBill(
                    props.original,
                    'accountPlanId',
                    e.target.value
                  )
                }
              >
                <option value="">Selecione</option>
                {billType === 'toReceive' && (
                  <>
                    {accountPlans.map((accountPlan) => (
                      <option value={accountPlan.id} key={accountPlan.id}>
                        {accountPlan.description}
                      </option>
                    ))}
                  </>
                )}

                {billType === 'toPay' && (
                  <>
                    {debitAccountPlans.map((accountPlan) => (
                      <option value={accountPlan.id} key={accountPlan.id}>
                        {accountPlan.description}
                      </option>
                    ))}
                  </>
                )}
              </select>
            ),
          },
          {
            Header: 'Caixa/Banco',
            accessor: 'cashierBankId',
            width: 140,
            Cell: (props) => (
              <select
                className="form-control foco-input"
                value={props.value}
                onChange={(e) =>
                  handleChangeBill(
                    props.original,
                    'cashierBankId',
                    e.target.value
                  )
                }
              >
                <option value="">Selecione</option>
                {cashierBanks.map((cashierBank) => (
                  <option value={cashierBank.id} key={cashierBank.id}>
                    {cashierBank.description}
                  </option>
                ))}
              </select>
            ),
          },
          {
            Header: 'Data de Pagamento',
            accessor: 'lowDate',
            Cell: (props) => (
              <input
                type="date"
                value={props.value}
                className="form-control foco-input"
                max={todayDate}
                onChange={(e) =>
                  handleLowDateChange(e.target.value, props.original)
                }
              />
            ),
          },
        ]}
        defaultPageSize={5}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={
          !bills.length
            ? 'Nenhum título foi selecionado.'
            : 'Nenhum título foi selecionado nesses filtros.'
        }
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
      <ReplicatePaymentModal
        paymentForms={paymentForms}
        show={isReplicatePaymentModalOpen}
        onCancel={() => setIsReplicatePaymentModalOpen(false)}
      />
      <AlertModal
        show={isDeleteBillModalOpen}
        onHide={() => setIsDeleteBillModalOpen(false)}
        onCancel={() => setIsDeleteBillModalOpen(false)}
        onSubmit={deleteBill}
        subtitle="Você deseja excluir o título da listagem de baixa rápida?"
        message="Ao remover, o título permanecerá aberto até que uma baixa aconteça. Ele poderá ser localizado novamente na listagem de títulos."
      />
    </>
  );
};

export default TitleDischarger;
