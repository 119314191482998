import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';

import AlertModal from 'components/AlertModal';
import ImageUploadDropzone from 'client/components/ImagesUploadDropzone';
import { useLoading } from 'v2/hooks/useLoading/useLoading';

export const NewsRegisterImage = ({
  setImageFile,
  setValue,
  imageFile,
  watch,
  isLoading,
}) => {
  const [isConfirmRemoveImageModalOpen, setIsConfirmRemoveImageModalOpen] =
    useState(false);

  const { isLoading: loading, fetchLoading } = useLoading();

  const MAX_WIDTH = 690;
  const MAX_HEIGHT = 350;

  const imageUrl = watch('imageUrl');

  async function handleUploadPhoto(fileToUpload) {
    await fetchLoading(async () => {
      const file = fileToUpload[0];

      const image = await handleValidatePhoto(file);

      if (!image) {
        return;
      }

      if (imageUrl) {
        const url = imageUrl;
        setValue('imageUrlToRemove', url);
        setValue('imageUrl', url);
      }

      setImageFile({
        file,
        uploaded: false,
        preview: image.src,
      });
    });
  }

  const handleValidatePhoto = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        toastr.warning(`Selecione uma imagem válida!`);
        resolve(false);
        return;
      }

      URL.revokeObjectURL(file);

      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        if (image.height > MAX_HEIGHT || image.width > MAX_WIDTH) {
          toastr.warning(
            'O tamanho máximo da imagem é 690x350px. Verifique a imagem e tente novamente.'
          );
          resolve(false);
          return;
        }

        if (image.height < 200 || image.width < 200) {
          toastr.warning(
            'O tamanho mínimo da imagem é 200x200px. Verifique a imagem e tente novamente.'
          );
          resolve(false);
          return;
        }

        resolve(image); // Retorna a imagem apenas se todas as validações passarem
      };
    });
  };

  function handleRemoveImageFile() {
    if (imageFile) {
      URL.revokeObjectURL(imageFile.preview);
      setImageFile({ preview: null, file: null, uploaded: false });
      setValue('imageUrlToRemove', imageUrl);
    } else if (imageUrl) {
      setImageFile({ preview: null, file: null, uploaded: false });
      setValue('imageUrl', null);
    }
    setIsConfirmRemoveImageModalOpen(false);
  }

  return (
    <>
      <div
        className="flex start"
        style={{
          height: '100%',
          width: '100%',
          marginBottom: '2rem',
        }}
      >
        <ImageUploadDropzone
          image={imageFile}
          onRemove={() => setIsConfirmRemoveImageModalOpen(true)}
          onUpload={handleUploadPhoto}
          label="Adicionar foto"
          style={{
            height: '350px',
            width: '690px',
          }}
          disabled={isLoading || loading}
          loading={isLoading || loading}
        />
      </div>

      <AlertModal
        show={isConfirmRemoveImageModalOpen}
        title="Exclusão de imagem"
        message="Deseja excluir a imagem? Ao excluir a imagem, uma nova imagem deverá ser importada posteriormente."
        onHide={() => setIsConfirmRemoveImageModalOpen(false)}
        onCancel={() => setIsConfirmRemoveImageModalOpen(false)}
        onSubmit={handleRemoveImageFile}
      />
    </>
  );
};
