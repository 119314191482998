import React from 'react';
import Toggle from 'react-toggle';
import { format } from 'date-fns';
import ReactTable from 'react-table';
import { BRANDINGS } from 'v2/utils/constants';
import { BrandingLabel } from 'v2/components/BrandingLabel';
import NewsActions from './NewsActions';
import { formatDate } from 'v2/helpers/dateHelpers';

const renderBrandingLabels = (value) => {
  const branding = !!value?.length > 0 ? value.split(',') : false;

  if (!branding || branding.length === BRANDINGS.length) {
    return <BrandingLabel noBrandingText="Todos" width="120px" />;
  }

  if (value) {
    return branding
      .map(Number)
      .map((id, index) => (
        <BrandingLabel
          key={index}
          brandingId={id}
          noBrandingText="Todos"
          width="120px"
        />
      ));
  }
};

const NewsTable = ({
  news,
  totalPages,
  currentPage,
  setCurrentPage,
  pageLimit,
  setPageLimit,
  loading,
  handleUpdateStatus,
}) => {
  return (
    <div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={news}
        columns={[
          {
            Header: 'Dt. Criação',
            accessor: 'createdAt',
            width: 100,
            Cell: (props) => formatDate(props.value),
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            width: 300,
          },
          {
            Header: 'Vigência Inicial',
            accessor: 'initialDurationDate',
            width: 120,
            Cell: (props) => {
              return props.value ? formatDate(props.value) : '-';
            },
          },
          {
            Header: 'Vigência Final',
            accessor: 'finalDurationDate',
            width: 120,
            Cell: (props) => {
              return props.value ? formatDate(props.value) : '-';
            },
          },
          {
            Header: 'Produto',
            accessor: 'brandingIds',
            Cell: (props) => {
              return (
                <div className="flex center gap-025">
                  {renderBrandingLabels(props.value)}
                </div>
              );
            },
          },
          {
            Header: 'Clique',
            accessor: 'clickCount',
            width: 90,
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: 100,
            Cell: (props) => {
              return (
                <div className="flex center">
                  <Toggle
                    style={{ position: 'fixed' }}
                    checked={props.value}
                    onChange={() => handleUpdateStatus(props.original)}
                  />
                </div>
              );
            },
          },
          {
            Header: 'Ações',
            accessor: 'id',
            headerClassName: 'text-left',
            filterable: false,
            className: 'texto',
            width: 90,
            Cell: (props) => (
              <NewsActions
                id={props.value}
                clicks={props.original.clickCount}
                description={props.original.description}
              />
            ),
          },
        ]}
        loading={loading}
        defaultPageSize={10}
        pageSize={pageLimit}
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        page={currentPage}
        onPageChange={(value) => setCurrentPage(value)}
        pages={totalPages}
        manual
        showPagination={true}
        sortable={true}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma notícia encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

export default NewsTable;
