import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

export function PieChartGraphCustom({
  data,
  colors,
  tooltipNameFormatter,
  isTooltip = true,
  style,
}) {
  const defaultColors = [
    '#002344',
    '#17BC4F',
    '#FFBB28',
    '#FF5733',
    '#428BCA',
    '#808080',
    '#000000',
    '#8A2BE2',
    '#A52A2A',
    '#DEB887',
  ];

  // Filtra os dados para remover itens com valor 0
  const filteredData = data?.filter((item) => item.value !== 0) || [];

  // Verifica se há dados
  const hasData = filteredData.length > 0;

  // Função para renderizar o label
  const renderLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) / 2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: '14px', fontWeight: 'bold' }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  // Função de formatação do tooltip
  const formatTooltip = (value, name) => {
    const formattedName = tooltipNameFormatter
      ? tooltipNameFormatter(value)
      : value;
    return [formattedName, name];
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        ...style,
        borderRadius: '24px',
      }}
    >
      {hasData ? (
        <PieChart width={300} height={300}>
          <Pie
            data={filteredData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill="#8884d8"
            label={renderLabel}
            labelLine={false}
          >
            {filteredData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  colors
                    ? colors[index % colors.length]
                    : defaultColors[index % defaultColors.length]
                }
              />
            ))}
          </Pie>
          {isTooltip && <Tooltip formatter={formatTooltip} />}
        </PieChart>
      ) : (
        <div
          style={{
            textAlign: 'center',
            color: '#666',
            fontSize: '16px',
            marginTop: '1.5rem',
          }}
        >
          {`Não há dados para o mês selecionado`}
        </div>
      )}
    </div>
  );
}
