import React from 'react';
import { Field } from 'redux-form';
import { Col } from 'react-bootstrap';
import RenderField from '../../../../../components/RenderField';
import AdvertisingImage from './AdvertisingImage';
import { toastr } from 'react-redux-toastr';
import { SelectBranding } from 'v2/components/Select';
import { SelectMulti } from 'v2/components/Select/Multi';

const MainData = ({
  type,
  setType,
  handleBrandingChange,
  branding,
  isActive,
  productOptions,
  selectedProducts,
  setSelectedProducts,
}) => {
  function handleTypeChange(value) {
    if (isActive) {
      return toastr.warning(
        'A propaganda está ativa e a mudança do tipo poderá impactar na publicidade ativa. Desative a propaganda antes de editar.'
      );
    }
    setType(value);
    if (value === 'EXCLUSIVE') {
      setSelectedProducts([]);
    } else {
      handleBrandingChange(null);
    }
  }

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={6} sm={6} md={3} lg={3}>
          <label>
            Tipo:<span style={{ color: 'red' }}>*</span>
          </label>
          <select
            id="type"
            value={type}
            onChange={(e) => handleTypeChange(e.target.value)}
            className="form-control foco-input"
          >
            <option value="" disabled>
              Selecionar
            </option>
            <option value="GLOBAL">Global</option>
            <option value="EXCLUSIVE">Exclusivo</option>
          </select>
        </Col>
        <Col xs={6} sm={6} md={3} lg={3}>
          <label>
            Produto:<span style={{ color: 'red' }}>*</span>
          </label>
          {type === 'EXCLUSIVE' ? (
            <SelectBranding
              value={branding}
              onChange={handleBrandingChange}
              hideLabel
              height={35}
            />
          ) : (
            <SelectMulti
              options={productOptions}
              value={selectedProducts}
              onChange={setSelectedProducts}
              hasAllOption
              customAllLabel="Todos"
              placeholder="Todos"
              width={130}
              height={35}
            />
          )}
        </Col>
      </Col>

      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={6} sm={6} md={5} lg={5}>
          <Field
            label="Descrição:"
            name="description"
            component={RenderField}
            required
          />
        </Col>
        <Col xs={6} sm={6} md={5} lg={5}>
          <Field
            label="Link de Redirecionamento:"
            name="redirectUrl"
            component={RenderField}
          />
        </Col>
      </Col>

      <Col xs={12} sm={12} md={12} lg={12}>
        <label>
          Imagem do Banner:<span style={{ color: 'red' }}>*</span>
          <span style={{ fontSize: 10 }}> (max. 540x142px)</span>
        </label>
        <AdvertisingImage />
      </Col>
    </>
  );
};

export default MainData;
