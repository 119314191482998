import React from 'react';
import { InputSelectDefault } from 'v2/components/Input';
import { useFinanceCalendar } from '../hooks/useFinanceCalendar';
import { useFormContext } from 'react-hook-form';
import Button from 'client/components/CustomButton/CustomButton';
import { useFinanceCalendarStore } from '../store/useFinanceCalendarStore';
import { useAuth } from 'contexts/auth';
import { useEffect } from 'react';
import { format, set } from 'date-fns';

export function FinanceCalendarFilters({ calendarRef }) {
  const { companyId } = useAuth();
  const { setValue, watch, getValues } = useFormContext();
  const {
    typeOptions,
    typeRegisterOptions,
    months,
    years,
    goToSelectedMonthYear,
    getStartOfWeekInMonth,
  } = useFinanceCalendar();

  const month = watch('month');
  const typeDate = watch('typeDate');
  const typeRegister = watch('typeRegister');
  const year = watch('year');
  const selectedWeek = watch('selectedWeek');

  const { getBills, getWeeks, optionsWeeks, isLoading } =
    useFinanceCalendarStore((store) => {
      return {
        getBills: store.getBills,
        getWeeks: store.getWeeks,
        optionsWeeks: store.optionsWeeks,
        isLoading: store.isLoading,
      };
    });

  const handleProcess = () => {
    if (typeDate.label === 'Semanal') {
      const targetWeek = selectedWeek.value;
      const startDate = getStartOfWeekInMonth(
        year.value,
        month.value,
        targetWeek
      );

      const initialDate = format(new Date(startDate), 'yyyy-MM-dd');

      setValue('initialDate', initialDate);

      calendarRef.current.getApi().gotoDate(startDate);
      calendarRef.current.getApi().changeView('dayGridWeek');
      getBills(getValues, companyId);
      return;
    }

    calendarRef.current.getApi().changeView('dayGridMonth');

    goToSelectedMonthYear(calendarRef, getValues);
    getBills(getValues, companyId);
    return;
  };

  useEffect(() => {
    if (typeDate.label === 'Semanal') {
      setValue('selectedWeek', { value: 1, label: 'Semana 1' });
      getWeeks(getValues);
    }
  }, [typeDate.label, year.value]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        marginTop: '2.5rem',
        alignItems: 'end',
        position: 'relative',
        zIndex: 10,
      }}
    >
      <div>
        <span>Calendário:</span>
        <InputSelectDefault
          height="35px"
          options={typeOptions}
          value={typeDate}
          onChange={(value) => {
            setValue('typeDate', value);
          }}
          fontSize="12px"
          width="116px"
          menuPortalTarget={document.body}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>Selecione o período:</span>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <InputSelectDefault
            height="35px"
            options={months}
            value={month}
            onChange={(value) => {
              setValue('month', value);
            }}
            fontSize="12px"
            width="116px"
            menuPortalTarget={document.body}
          />
          <InputSelectDefault
            height="35px"
            options={years}
            value={year}
            onChange={(value) => {
              setValue('year', value);
            }}
            fontSize="12px"
            width="116px"
            menuPortalTarget={document.body}
          />
        </div>
      </div>
      <div>
        <span>Tipo Registro:</span>
        <InputSelectDefault
          height="35px"
          options={typeRegisterOptions}
          value={typeRegister}
          onChange={(value) => {
            setValue('typeRegister', value);
          }}
          fontSize="12px"
          width="116px"
        />
      </div>
      {typeDate.label === 'Semanal' && (
        <div>
          <span>Semana:</span>
          <InputSelectDefault
            height="35px"
            options={optionsWeeks.weeks}
            value={selectedWeek}
            onChange={(value) => {
              setValue('selectedWeek', value);
            }}
            fontSize="12px"
            width="116px"
          />
        </div>
      )}

      <Button
        disabled={isLoading}
        onClick={handleProcess}
        style={{
          margin: '0px',
          width: '112px',
          height: '35px',
          backgroundColor: '#5CB85C',
          border: '1px solid #4CAE4C',
        }}
        name="Processar"
        fill
      >
        Processar
      </Button>
    </div>
  );
}
