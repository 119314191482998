import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: ['Início', 'Financeiro', 'Calendário Financeiro'],
    path: [
      '/client/home',
      null,
      '/client/' + CLIENT_PATH_ROUTES.FINANCE_CALENDAR.BASE,
    ],
  };
};
