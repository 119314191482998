import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import PercentInput from 'client/components/Percent';
import api from '../../../../services/api';
import config from '../../../../config';
import brandsRepository from 'repositories/Brands';
import productsRepository from 'repositories/Products';
import ModalAssociateProduct from '../components/ModalAssociateProduct';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

import {
  currency,
  formatQuantityWithThousands,
  formatThousandWithPercentage,
} from '../../ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { InputContainer } from 'v2/components/Input';
import { InputSelectInTable } from 'v2/components/Input/InputSelect';
import { InputCurrency } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { usePurchaseXmlImport } from '../hook/PurchaseXmlImportHook';

function Prices({ nextPage, previousPage, onSubmit }) {
  const { companyId, company } = useAuth();
  const dispatch = useDispatch();
  const [itemToEdit, setItemToEdit] = useState({});
  const [query, setQuery] = useState('');
  const [modalAssociateProduct, setModalAssociateProduct] = useState(false);
  const [marginForAll, setMarginForAll] = useState(0);
  const [isCreateProductLoading, setIsCreateProductLoading] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const { vOutro, vSeg, vFrete } = useSelector(
    (state) => state.form.PurchaseXmlImport.values
  );
  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState([
    {
      value: '*',
      label: 'Selecione',
    },
  ]);

  const {
    items = [],
    providerInDB,
    loading,
  } = useSelector((state) => state.form.PurchaseXmlImport.values);

  const { values } = useSelector((state) => state.form.PurchaseXmlImport);

  const { handleFinish } = usePurchaseXmlImport(
    onSubmit,
    companyId,
    providerInDB
  );

  const onFinish = () => {
    handleFinish(values, items);
  };

  const handleLoadProducts = async (productId) => {
    try {
      const response = await productsRepository.getById(productId);

      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const handleLoadMarginAndSalePrice = async () => {
    const listProducts = await Promise.all(
      items
        .filter((item) => item.productId)
        .map(async (item) => {
          const product = await handleLoadProducts(item.productId);
          if (product) {
            dispatch(
              change(
                'PurchaseXmlImport',
                `items[${item.index}].margin`,
                product.Profit_Margin
              )
            );
            dispatch(
              change(
                'PurchaseXmlImport',
                `items[${item.index}].newMargin`,
                product.Profit_Margin
              )
            );
            dispatch(
              change(
                'PurchaseXmlImport',
                `items[${item.index}].profitMarginWholesale`,
                product.profitMarginWholesale
              )
            );
            dispatch(
              change(
                'PurchaseXmlImport',
                `items[${item.index}].salesPrice`,
                product.Sale_Price
              )
            );
            dispatch(
              change(
                'PurchaseXmlImport',
                `items[${item.index}].newSalesPrice`,
                product.Purchase_Price +
                  `items[${item.index}].newMargin` * product.Purchase_Price
              )
            );
            dispatch(
              change(
                'PurchaseXmlImport',
                `items[${item.index}].salePriceWholesale`,
                product.Purchase_Price +
                  `items[${item.index}].profitMarginWholesale` *
                    product.Purchase_Price
              )
            );
            const hasICMSST = product.ICMSSTValue && product.calculateICMSST;
            if (hasICMSST) {
              dispatch(
                change(
                  'PurchaseXmlImport',
                  `items[${item.index}].ICMSSTValue`,
                  product.ICMSSTValue
                )
              );
            }
          }
          return product;
        })
    );
    return listProducts;
  };

  const handleCreateItem = async (item) => {
    setIsCreateProductLoading(true);
    const product = handleFormatObjectToCreate(item);
    try {
      await handleCreateProducts([product]);
      setIsCreateProductLoading(false);
    } catch (err) {
      setIsCreateProductLoading(false);
      console.log(err);
    }
  };

  const handleOpenAssociateProduct = (item) => {
    setItemToEdit(item);
    setModalAssociateProduct(true);
  };

  const handleCloseAssociateProduct = () => {
    dispatch(change('PurchaseXmlImport', 'itemToAssociate', {}));
    setItemToEdit({});
    setModalAssociateProduct(false);
  };

  const handleCreateProducts = async (products) => {
    await dispatch([
      change('PurchaseXmlImport', 'loading', true),
      change(
        'PurchaseXmlImport',
        'loadingMessage',
        `Aguarde, cadastrando produto${products.length > 1 ? 's' : ''}`
      ),
    ]);

    try {
      const { data } = await api.patch(config.endpoint + `products`, {
        Company_id: companyId,
        providerId: providerInDB.id,
        products,
      });

      const filterdItems = [...items];

      for (let p of data) {
        filterdItems.map((product) => {
          if (
            product.description.toUpperCase() === p.Description &&
            p.Manufacturer_Ref === product.providerCode
          ) {
            product.productCode = p.Code;
            product.productId = p.id;
            product.salesPrice = product.newSalesPrice;
            product.margin = product.newMargin;
          }
        });
      }

      await dispatch(change('PurchaseXmlImport', 'items', filterdItems));

      toastr.success(
        `Produto${products.length > 1 ? 's' : ''} cadastrado${
          products.length > 1 ? 's' : ''
        } com sucesso`
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu algum erro inesperado.',
        'Por favor, tente novamente mais tarde.'
      );
    }

    dispatch(change('PurchaseXmlImport', 'loading', false));
  };

  const handleFormatObjectToCreate = (item) => {
    return {
      Description: item.description.toUpperCase(),
      Sale_Price: item.newSalesPrice,
      salePriceWholesale: item.salePriceWholesale,
      Purchase_Price: item.unitValue,
      IsActive: true,
      Unit_Type: item.unitType,
      Profit_Margin: item.newMargin,
      profitMarginWholesale: item.profitMarginWholesale,
      Bar_Code: item.cEAN,
      Tribute_BarCode: item.cEANTrib,
      Type: 'Produto',
      CFOP_InState_Code: item.CFOP_InState_Code,
      CFOP_OutState_Code: item.CFOP_OutState_Code,
      CSOSN: item.CSOSN,
      CST: item.CST,
      NCM_Code: item.ncm,
      Origin: item.origin,
      Manufacturer_Ref: item.providerCode,
      Stock_Quantity: 0,
      PIS_Exit: item.CSTPIS,
      COFINS_Exit: item.CSTCOFINS,
      productProviderCode: item.providerCode,
      surname: item.surname,
      Multiplier_Base: item.multiplierBase,
      ANP: item.CodANP,
      ANP_Description: item.DescANP,
      ANP_UF_Code: item.UFANP,
      Brand_id: item.brand && item.brand.value,
      CEST_Code: item.cest,
    };
  };

  const handleApplyMarginForAll = async () => {
    if (!isChecked.length) {
      toastr.warning(
        'Nenhum produto selecionado',
        'Selecione ao menos 1 produto para aplicar a nova margem'
      );
      return;
    }
    const margin = parseFloat(marginForAll);
    const modifiedItems = items.map((p) => {
      if (
        isChecked.some((checkedItem) => checkedItem.productId === p.productId)
      ) {
        const newSalesPrice = calculateAllMargin(p.unitValue, margin);
        const salePriceWholesale = calculateAllMargin(p.unitValue, margin);

        return {
          ...p,
          newMargin: margin,
          newSalesPrice,
          profitMarginWholesale: margin,
          salePriceWholesale,
        };
      }
      return p;
    });

    dispatch(change('PurchaseXmlImport', 'items', modifiedItems));
  };

  const handleFilters = (product) => {
    return handleFilterByQuery(product);
  };

  const handleCheckedItems = async (item) => {
    const isItemChecked = await isChecked.some(
      (checkedItem) => checkedItem.productId === item.productId
    );

    if (isItemChecked) {
      setIsChecked(
        await isChecked.filter(
          (checkedItem) => checkedItem.productId !== item.productId
        )
      );
    } else {
      setIsChecked((prevItems) => {
        return [...prevItems, item];
      });
    }
  };

  const calculateSalesPrice = (unitValue, newMargin) => {
    let value = parseFloat(
      (newMargin / 100) * unitValue + parseFloat(unitValue)
    ).toFixed(2);

    return value;
  };
  const calculateSalesPriceWholesale = (unitValue, profitMarginWholesale) => {
    let value = parseFloat(
      (profitMarginWholesale / 100) * unitValue + parseFloat(unitValue)
    ).toFixed(2);

    return value;
  };

  const calculateMargin = (unitValue, salesPrice) => {
    return ((salesPrice - unitValue) / unitValue) * 100;
  };

  const calculateMarginWholesale = (unitValue, salePriceWholesale) => {
    return ((salePriceWholesale - unitValue) / unitValue) * 100;
  };

  const calculateAllMargin = (unitValue, margin) => {
    let marginAll = (unitValue * margin) / 100;
    let value = parseFloat(unitValue) + parseFloat(marginAll);

    return value;
  };

  const handleChangeValueMargin = (index, props, newMargin) => {
    const marginPercentage = parseFloat(
      newMargin.replace('%', '').replace(',', '.')
    );

    const newSalesPriceCalc = calculateSalesPrice(
      props.unitValue,
      marginPercentage
    );

    const salesPriceValue = parseFloat(
      newSalesPriceCalc.replace(/[^\d.-]/g, '')
    );

    const newSalesPrice = isNaN(salesPriceValue) ? 0 : salesPriceValue;

    dispatch(
      change('PurchaseXmlImport', `items[${index}].newMargin`, marginPercentage)
    );
    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].newSalesPrice`,
        newSalesPrice
      )
    );
  };

  const handleChangeValueMarginWholesale = (
    index,
    props,
    profitMarginWholesale
  ) => {
    const marginWholesalePercentage = parseFloat(
      profitMarginWholesale.replace('%', '').replace(',', '.')
    );

    const newSalesPriceCalc = calculateSalesPriceWholesale(
      props.unitValue,
      marginWholesalePercentage
    );

    const salesPriceValue = parseFloat(
      newSalesPriceCalc.replace(/[^\d.-]/g, '')
    );

    const newSalesPriceWholesale = isNaN(salesPriceValue) ? 0 : salesPriceValue;

    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].profitMarginWholesale`,
        marginWholesalePercentage
      )
    );
    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].salePriceWholesale`,
        newSalesPriceWholesale
      )
    );
  };

  const handleChangeValueSalesPrice = (index, props, newSalesPrice) => {
    const newMargin = calculateMargin(props.unitValue, newSalesPrice);

    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].newSalesPrice`,
        newSalesPrice
      )
    );
    dispatch(
      change('PurchaseXmlImport', `items[${index}].newMargin`, newMargin)
    );

    setTimeout(() => {
      document.getElementById(`newSalesPrice${index}`).focus();
    }, 50);
  };

  const handleChangeValueSalesPriceWholesale = (
    index,
    props,
    salePriceWholesale
  ) => {
    const newMarginWholesale = calculateMarginWholesale(
      props.unitValue,
      salePriceWholesale
    );

    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].salePriceWholesale`,
        salePriceWholesale
      )
    );
    dispatch(
      change(
        'PurchaseXmlImport',
        `items[${index}].profitMarginWholesale`,
        newMarginWholesale
      )
    );

    setTimeout(() => {
      document.getElementById(`salePriceWholesale${index}`).focus();
    }, 50);
  };

  const handleFilterByQuery = (product) => {
    return (
      !query ||
      product.description.toLowerCase().includes(query.toLowerCase()) ||
      product.productCode?.toString()?.includes(query) ||
      product.providerCode?.toString()?.includes(query) ||
      product.cEAN?.toString()?.includes(query)
    );
  };

  function handleChangeBrand(value, index) {
    setBrandId(value);

    dispatch(change('PurchaseXmlImport', `items[${index}].brand`, value));
  }

  const prorateFreightValue = async () => {
    const totalCheckedItems = isChecked.length;

    if (totalCheckedItems === 0) {
      return items;
    }

    const modifiedItems = items.map((p) => {
      const proportionalFreight = vFrete / totalCheckedItems / p.quantity;
      const isItemChecked = isChecked.some(
        (checkedItem) => checkedItem.productId === p.productId
      );

      const newUnitValue =
        parseFloat(p.unitValue) -
        parseFloat(p.freightValue) +
        (isItemChecked ? parseFloat(proportionalFreight) : 0);

      return {
        ...p,
        freightValue: isItemChecked ? proportionalFreight : 0,
        unitValue: newUnitValue,
        isFreightProrated: isItemChecked ? true : false,
        newSalesPrice: parseFloat(
          (p.newMargin / 100) * newUnitValue + parseFloat(newUnitValue)
        ),
        salePriceWholesale: parseFloat(
          (p.profitMarginWholesale / 100) * newUnitValue +
            parseFloat(newUnitValue)
        ),
      };
    });
    dispatch(change('PurchaseXmlImport', 'items', modifiedItems));
  };

  const prorateInsuranceValue = async () => {
    const totalCheckedItems = isChecked.length;

    if (totalCheckedItems === 0) {
      return items;
    }

    const modifiedItems = items.map((p) => {
      const proportionalInsurance = vSeg / totalCheckedItems / p.quantity;
      const isItemChecked = isChecked.some(
        (checkedItem) => checkedItem.productId === p.productId
      );

      const newUnitValue =
        parseFloat(p.unitValue) -
        parseFloat(p.insurance) +
        (isItemChecked ? parseFloat(proportionalInsurance) : 0);

      return {
        ...p,
        insurance: isItemChecked ? proportionalInsurance : 0,
        unitValue: newUnitValue,
        isInsuranceProrated: isItemChecked ? true : false,
        newSalesPrice: parseFloat(
          (p.newMargin / 100) * newUnitValue + parseFloat(newUnitValue)
        ),
        salePriceWholesale: parseFloat(
          (p.profitMarginWholesale / 100) * newUnitValue +
            parseFloat(newUnitValue)
        ),
      };
    });
    dispatch(change('PurchaseXmlImport', 'items', modifiedItems));
  };

  const prorateOtherExpensesValue = async () => {
    const totalCheckedItems = isChecked.length;

    if (totalCheckedItems === 0) {
      return items;
    }

    const modifiedItems = items.map((p) => {
      const proportionalOtherExpenses = vOutro / totalCheckedItems / p.quantity;
      const isItemChecked = isChecked.some(
        (checkedItem) => checkedItem.productId === p.productId
      );

      const newUnitValue =
        parseFloat(p.unitValue) -
        parseFloat(p.otherExpenses) +
        (isItemChecked ? parseFloat(proportionalOtherExpenses) : 0);

      return {
        ...p,
        otherExpenses: isItemChecked ? proportionalOtherExpenses : 0,
        unitValue: newUnitValue,
        isOtherExpensesProrated: isItemChecked ? true : false,
        newSalesPrice: parseFloat(
          (p.newMargin / 100) * newUnitValue + parseFloat(newUnitValue)
        ),
        salePriceWholesale: parseFloat(
          (p.profitMarginWholesale / 100) * newUnitValue +
            parseFloat(newUnitValue)
        ),
      };
    });
    dispatch(change('PurchaseXmlImport', 'items', modifiedItems));
  };

  const handleInitialValuesProrate = () => {
    const modifiedItems = items.map((p) => {
      const calcAutomaticFreight = company.companyConfig
        .automaticFreightApportionment
        ? parseFloat(vFrete / items.length / p.quantity)
        : 0;
      const calcAutomaticInsurance = company.companyConfig
        .automaticInsurancePooling
        ? parseFloat(vSeg / items.length / p.quantity)
        : 0;
      const calcAutomaticOtherExpenses = company.companyConfig
        .automaticApportionmentOfOtherExpenses
        ? parseFloat(vOutro / items.length / p.quantity)
        : 0;

      const newUnitValue =
        parseFloat(p.unitValue) +
        parseFloat(calcAutomaticFreight) +
        parseFloat(calcAutomaticInsurance) +
        parseFloat(calcAutomaticOtherExpenses);

      return {
        ...p,
        freightValue: calcAutomaticFreight || p.freightValue,
        insurance: calcAutomaticInsurance || p.insurance,
        otherExpenses: calcAutomaticOtherExpenses || p.otherExpenses,
        unitValue: newUnitValue,
        newSalesPrice: parseFloat(
          (p.newMargin / 100) * newUnitValue + parseFloat(newUnitValue)
        ),
        salePriceWholesale: parseFloat(
          (p.profitMarginWholesale / 100) * newUnitValue +
            parseFloat(newUnitValue)
        ),
      };
    });

    dispatch(change('PurchaseXmlImport', 'items', modifiedItems));
  };

  const handleMultiplierBaseChange = (e, index, productId) => {
    const value = parseInt(e.target.value, 10);

    if (value === 0) {
      e.target.value = 1;
      toastr.warning(
        'Valor inválido',
        '0 não é um valor válido de estoque. Insira um valor correto.'
      );
    } else if (value > 999) {
      toastr.warning(
        'Valor inválido',
        'O valor deve ser um número inteiro entre 1 e 999. Insira um valor correto.'
      );
    } else {
      dispatch(
        change('PurchaseXmlImport', `items[${index}].multiplierBase`, value)
      );
    }

    setTimeout(() => {
      document.getElementById(`baseMulti${index}`).focus();
    }, 50);
  };

  const loadBrands = async () => {
    try {
      const brands = await brandsRepository.getAllByCompany(companyId);
      const serializedBrands = brands.map(
        (brand) => ({
          label: brand.Description,
          value: brand.id,
        }),
        [brands]
      );

      serializedBrands.unshift({ value: '*', label: 'Selecione' });
      setBrands(serializedBrands);
      setBrandId(serializedBrands);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  useEffect(() => {
    loadBrands();
    handleLoadMarginAndSalePrice();
    handleInitialValuesProrate();
  }, []);

  const columns = [
    {
      Header: '',
      accessor: 'id',
      width: 50,
      Cell: (props) =>
        !props.original.productId ? (
          <FontAwesomeIcon
            title="Cadastrar Produto"
            cursor="pointer"
            style={{
              height: '15px',
              width: '15px',
              color: 'green',
            }}
            icon={faPlusCircle}
            onClick={() => {
              if (isCreateProductLoading === false) {
                handleCreateItem(props.original);
              }
            }}
          />
        ) : (
          <div className="flex center flex.align-center">
            <FontAwesomeIcon
              title="Selecionar"
              cursor="pointer"
              icon={
                isChecked.some(
                  (checkedItem) =>
                    checkedItem.productId === props.original.productId
                )
                  ? faCheckSquare
                  : faSquare
              }
              style={{ width: '14px', height: '14px' }}
              onClick={() => handleCheckedItems(props.original)}
            />
          </div>
        ),
    },
    {
      Header: 'Código',
      accessor: 'productCode',
      headerClassName: 'text-center',
      width: 120,
      className: 'texto',
      Cell: (props) =>
        props.value ?? (
          <button
            className="btn-link"
            onClick={() => handleOpenAssociateProduct(props.original)}
          >
            Vincular Produto
          </button>
        ),
    },
    {
      Header: 'Cód. Fornecedor',
      accessor: 'providerCode',
      headerClassName: 'text-center',
      width: 100,
      className: 'texto',
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      headerClassName: 'text-center',
      width: 170,
      className: 'texto',
    },
    {
      Header: () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          Bs. Multi
          <span style={{ color: 'red' }}>*</span>
        </div>
      ),
      accessor: 'multiplierBase',
      width: 80,
      Cell: (props) => (
        <input
          id={`baseMulti${props.index}`}
          className="form-control new-foco-input"
          type="number"
          value={props.original.multiplierBase}
          style={{ fontSize: '12px' }}
          onChange={(e) =>
            handleMultiplierBaseChange(e, props.index, props.original.productId)
          }
        />
      ),
    },
    {
      Header: 'Qtd.',
      width: 80,
      accessor: 'quantity',
      headerClassName: 'text-center',
      Cell: ({ value }) => formatQuantityWithThousands(value),
    },
    {
      Header: 'Marca',
      width: 120,
      Cell: (props) => (
        <InputContainer variant="column">
          <InputSelectInTable
            style={{
              width: '100px',
              fontSize: '12px',
            }}
            width={'100%'}
            height={'35px'}
            options={brands}
            value={props.original.brand}
            fontSize={'12px'}
            onChange={(e) => handleChangeBrand(e, props.index)}
            isSearchable={true}
            isRowTable={false}
          />
        </InputContainer>
      ),
    },
    {
      Header: 'R$ Compra',
      accessor: 'unitValue',
      width: 70,
      Cell: ({ value }) => currency(value),
    },
    {
      Header: 'Margem',
      accessor: 'margin',
      width: 70,
      Cell: (props) => {
        if (!props.original.productId) {
          return '';
        } else {
          return formatThousandWithPercentage(props.value);
        }
      },
    },
    {
      Header: 'R$ Venda',
      accessor: 'salesPrice',
      width: 70,
      Cell: (props) => {
        if (!props.original.productId) {
          return '';
        } else {
          return currency(props.value);
        }
      },
    },
    {
      Header: () => (
        <div
          style={{
            alignItems: 'center',
            color: '#3E8AF7',
          }}
        >
          Nv. Margem <br />
          (Varejo)
          <span style={{ color: 'red' }}>*</span>
        </div>
      ),
      accessor: 'newMargin',
      width: 90,
      Cell: (props) => {
        return (
          <PercentInput
            className="form-control new-foco-input"
            value={props.original.newMargin}
            id={`items[${props.index}].newMargin`}
            name={`items[${props.index}].newMargin`}
            style={{
              fontSize: '12px',
              width: '80px',
            }}
            onChangeEvent={(e) => {
              dispatch(
                change(
                  'PurchaseXmlImport',
                  `items[${props.index}].newMargin`,
                  e.target.value
                )
              );

              handleChangeValueMargin(
                props.index,
                props.original,
                e.target.value
              );

              setTimeout(() => {
                document
                  .getElementById(`items[${props.index}].newMargin`)
                  .focus();
              }, 50);
            }}
          />
        );
      },
    },
    {
      Header: () => (
        <div
          style={{
            alignItems: 'center',

            color: '#3E8AF7',
          }}
        >
          Nv. R$ Venda <br />
          (Varejo)
          <span style={{ color: 'red' }}>*</span>
        </div>
      ),
      accessor: 'newSalesPrice',
      width: 90,
      Cell: (props) => {
        return (
          <InputCurrency
            id={`newSalesPrice${props.index}`}
            width={80}
            height={35}
            value={props.original.newSalesPrice}
            style={{ fontSize: '12px' }}
            borderColor={'#E3E3E3'}
            borderColorFocus={'#AAAAAA'}
            onChange={(value) => {
              dispatch(
                change(
                  'PurchaseXmlImport',
                  `items[${props.index}].newSalesPrice`,
                  value
                )
              );
              handleChangeValueSalesPrice(props.index, props.original, value);
            }}
          />
        );
      },
    },
    {
      Header: () => (
        <div
          style={{
            alignItems: 'center',
            color: '#3E8AF7',
          }}
        >
          Nv. Margem
          <br />
          (Atacado)
          <span style={{ color: 'red' }}>*</span>
        </div>
      ),
      accessor: 'profitMarginWholesale',
      width: 100,
      Cell: (props) => {
        return (
          <PercentInput
            className="form-control new-foco-input"
            value={props.original.profitMarginWholesale}
            id={`items[${props.index}].profitMarginWholesale`}
            name={`items[${props.index}].profitMarginWholesale`}
            style={{ fontSize: '12px', width: '80px' }}
            onChangeEvent={(e) => {
              dispatch(
                change(
                  'PurchaseXmlImport',
                  `items[${props.index}].profitMarginWholesale`,
                  e.target.value
                )
              );

              handleChangeValueMarginWholesale(
                props.index,
                props.original,
                e.target.value
              );

              setTimeout(() => {
                document
                  .getElementById(`items[${props.index}].profitMarginWholesale`)
                  .focus();
              }, 50);
            }}
          />
        );
      },
    },
    {
      Header: () => (
        <div
          style={{
            alignItems: 'center',
            color: '#3E8AF7',
          }}
        >
          Nv. R$ Venda
          <br />
          (Atacado)
          <span style={{ color: 'red' }}>*</span>
        </div>
      ),
      accessor: 'salePriceWholesale',
      width: 100,
      Cell: (props) => {
        return (
          <InputCurrency
            id={`salePriceWholesale${props.index}`}
            width={80}
            height={35}
            value={props.original.salePriceWholesale}
            style={{ fontSize: '12px' }}
            borderColor={'#E3E3E3'}
            borderColorFocus={'#AAAAAA'}
            onChange={(value) => {
              dispatch(
                change(
                  'PurchaseXmlImport',
                  `items[${props.index}].salePriceWholesale`,
                  value
                )
              );
              handleChangeValueSalesPriceWholesale(
                props.index,
                props.original,
                value
              );
            }}
          />
        );
      },
    },
    {
      Header: 'CFOP',
      id: 'cfop',
      accessor: 'cfop',
      width: 80,
    },
    {
      Header: 'ICMS ST',
      id: 'ICMSSTValue',
      accessor: 'ICMSSTValue',
      width: 80,
      Cell: ({ value }) => currency(value),
    },
    {
      Header: 'IPI',
      accessor: 'IPIValue',
      width: 80,
      Cell: ({ value }) => currency(value),
    },
    {
      Header: 'PIS',
      accessor: 'PISValue',
      width: 80,
      Cell: ({ value }) => currency(value),
    },
    {
      Header: 'COFINS',
      accessor: 'COFINSValue',
      width: 80,
      Cell: ({ value }) => currency(value),
    },
  ];

  if (
    company.companyConfig.automaticApportionmentOfOtherExpenses ||
    vOutro > 0
  ) {
    const cfopValueIndex = columns.findIndex(
      (column) => column.accessor === 'cfop'
    );

    columns.splice(cfopValueIndex + 1, 0, {
      Header: 'Outras Desp.',
      id: 'otherExpenses',
      accessor: 'otherExpenses',
      width: 80,
      Cell: ({ value, original }) => (
        <span
          style={{
            color: original.isOtherExpensesProrated ? '#428bca' : '#000',
          }}
        >
          {currency(value)}
        </span>
      ),
    });
  }

  if (company.companyConfig.automaticFreightApportionment || vFrete > 0) {
    const icmsstValueIndex = columns.findIndex(
      (column) => column.id === 'ICMSSTValue'
    );

    columns.splice(icmsstValueIndex, 0, {
      Header: 'Frete',
      id: 'freightValue',
      accessor: 'freightValue',
      width: 80,
      Cell: ({ value, original }) => (
        <span
          style={{
            color: original.isFreightProrated ? '#f0ad4e' : '#000',
          }}
        >
          {currency(value)}
        </span>
      ),
    });
  }

  if (company.companyConfig.automaticInsurancePooling || vSeg > 0) {
    columns.push({
      Header: 'Seguro',
      id: 'insurance',
      accessor: 'insurance',
      width: 80,
      Cell: ({ value, original }) => (
        <span
          style={{
            color: original.isInsuranceProrated ? '#addfee' : '#000',
          }}
        >
          {currency(value)}
        </span>
      ),
    });
  }

  return (
    <>
      <p id="span-products-with-icms">
        Caso sua entrada tenha ICMS ST e não tenha no produto, valide o campo
        Calcula ST no cadastro do produto e faça novamente a entrada.
      </p>

      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <span
          style={{ fontSize: '12px', color: '#d9534f', paddingRight: '2.5%' }}
        >
          *Campos Obrigatórios
        </span>
      </div>
      <div className="xml-import-prducts-container">
        <div
          style={{
            marginTop: '5px',
            paddingLeft: '10px',
            paddingRight: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{ marginLeft: '0px', paddingLeft: '0px', display: 'flex' }}
          >
            <i
              id="iconepesquisar"
              style={{ cursor: 'pointer' }}
              className="fa fa-search"
            />
            <input
              type="text"
              style={{
                width: '380px',
                paddingLeft: '35px',
                fontSize: '12px',
                height: '35px',
              }}
              className="form-control new-foco-input"
              placeholder="Pesquisa por Código / Descrição / Ref. Fab / Código de Barras"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />

            <div
              style={{
                marginLeft: '15px',
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
              }}
            >
              <InputLabel fontWeight={'normal'} fontSize={'12px'}>
                Frete:
              </InputLabel>
              <InputCurrency
                id="input__min-value"
                width={80}
                value={parseFloat(vFrete)}
                disabled
              />
              <InputLabel
                fontWeight={'normal'}
                fontSize={'12px'}
                style={{ width: '73px' }}
              >
                Outras Desp.:
              </InputLabel>
              <InputCurrency
                id="input__min-value"
                width={100}
                value={parseFloat(vOutro)}
                disabled
              />
              <InputLabel fontWeight={'normal'} fontSize={'12px'}>
                Seguro:
              </InputLabel>
              <InputCurrency
                id="input__min-value"
                width={80}
                value={parseFloat(vSeg)}
                disabled
              />
            </div>
          </div>

          <div id="import-xml-input-default-margin-prices">
            <label>Margem Padrão:</label>
            <PercentInput
              className="form-control new-foco-input"
              onChangeEvent={(e) => setMarginForAll(e.target.value)}
              value={marginForAll}
            />
            <button
              type="button"
              className="xml-import-button-success button-h35"
              disabled={!isChecked.length && marginForAll > 0}
              onClick={handleApplyMarginForAll}
              style={{ fontSize: '12px' }}
            >
              Aplicar Margem
            </button>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '1%',
          }}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const filteredItems = items
                .filter(handleFilters)
                .filter((item) => item.productId);
              if (isChecked.length === filteredItems.length) {
                setIsChecked([]);
              } else {
                setIsChecked(filteredItems);
              }
            }}
          >
            <span style={{ fontSize: '11px' }}>
              {isChecked.length ===
              items.filter(handleFilters).filter((item) => item.productId)
                .length
                ? 'Remover todos'
                : 'Selecionar todos'}{' '}
              (
              {
                items.filter(handleFilters).filter((item) => item.productId)
                  .length
              }
              )
            </span>
          </a>
        </div>
        <div
          style={{
            paddingRight: '10px',
            paddingLeft: '10px',
            height: '300px',
            overflowY: 'scroll',
            overflowX: 'auto',
          }}
        >
          <ReactTable
            style={{
              width: '2050px',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '12px',
              overflow: 'none',
            }}
            data={items.filter(handleFilters)}
            columns={columns}
            defaultPageSize={items.filter(handleFilters).length}
            loading={false}
            showPagination={false}
            sortable={true}
            showPaginationTop={false}
            showPaginationBottom={false}
            defaultSorted={[
              {
                id: 'providerCode',
                desc: true,
              },
            ]}
            loadingText="Carregando..."
            noDataText="Nenhum Produto encontrado"
          />
        </div>
      </div>
      <div className="xml-import-prices-buttons">
        <div className="xml-import-prices-buttons-left">
          {company.companyConfig.automaticFreightApportionment && (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '11px',
                color: '#4cae4c',
                gap: '5px',
              }}
            >
              <FontAwesomeIcon
                style={{
                  height: '15px',
                  width: '15px',
                  color: '#4cae4c',
                }}
                icon={faCircleCheck}
              />
              Frete rateado entre os itens selecionados
            </span>
          )}
          {company.companyConfig.automaticApportionmentOfOtherExpenses && (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '11px',
                color: '#4cae4c',
                gap: '5px',
              }}
            >
              <FontAwesomeIcon
                style={{
                  height: '15px',
                  width: '15px',
                  color: '#4cae4c',
                }}
                icon={faCircleCheck}
              />
              Outras despesas rateadas entre os itens selecionados
            </span>
          )}
          {company.companyConfig.automaticInsurancePooling && (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '11px',
                color: '#4cae4c',
                gap: '5px',
              }}
            >
              <FontAwesomeIcon
                style={{
                  height: '15px',
                  width: '15px',
                  color: '#4cae4c',
                }}
                icon={faCircleCheck}
              />
              Seguro rateado entre os itens selecionados
            </span>
          )}
        </div>
        <div className="xml-import-prices-buttons-right">
          <div
            style={{
              width: '20%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flexDirection: 'row',
            }}
          >
            <button
              type="button"
              className="xml-import-button-danger button-h35"
              onClick={() => previousPage()}
            >
              Voltar
            </button>
          </div>
          <div
            style={{
              width: '80%',
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <button
              type="button"
              className="xml-button-share-insurance button-h35"
              onClick={() => {
                if (isChecked.length === 0) {
                  toastr.warning(
                    'Nenhum item selecionado',
                    'Selecione ao menos 1 item para realizar o rateio do valor'
                  );
                } else {
                  prorateInsuranceValue();
                }
              }}
              disabled={vSeg <= 0}
            >
              Ratear Seguro
            </button>
            <button
              type="button"
              className="xml-button-share-shipping button-h35"
              onClick={() => {
                if (isChecked.length === 0) {
                  toastr.warning(
                    'Nenhum item selecionado',
                    'Selecione ao menos 1 item para realizar o rateio do valor'
                  );
                } else {
                  prorateFreightValue();
                }
              }}
              disabled={vFrete <= 0}
            >
              Ratear Frete
            </button>
            <button
              type="button"
              className="xml-button-share-other-expense button-h35"
              onClick={() => {
                if (isChecked.length === 0) {
                  toastr.warning(
                    'Nenhum item selecionado',
                    'Selecione ao menos 1 item para realizar o rateio do valor'
                  );
                } else {
                  prorateOtherExpensesValue();
                }
              }}
              disabled={vOutro <= 0}
            >
              Ratear Outras Despesas
            </button>
            <button
              type="button"
              className="xml-import-button-success button-h35"
              onClick={onFinish}
              disabled={loading}
            >
              Concluir
            </button>
          </div>
        </div>
      </div>

      {modalAssociateProduct && (
        <ModalAssociateProduct
          onHide={handleCloseAssociateProduct}
          onCancel={handleCloseAssociateProduct}
          onSubmit={handleCloseAssociateProduct}
          itemToEdit={itemToEdit}
        />
      )}
    </>
  );
}

export default Prices;
