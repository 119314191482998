import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const newDate = (date) => {
  // Verifica se a data é uma string no formato DATEONLY
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    // Cria a data no fuso horário de São Paulo
    return utcToZonedTime(new Date(date + 'T00:00:00'), 'America/Sao_Paulo');
  }
  return zonedTimeToUtc(new Date(date), 'America/Sao_Paulo'); // Para outros formatos
};

export const formatDate = (date) =>
  newDate(date).toLocaleString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
