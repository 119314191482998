import React, { useEffect } from 'react';
import { useState } from 'react';

import { HeaderExternalWorkshop } from './components/HeaderExternalWorkshop';
import { ExternalWorkshopContent } from './components/ExternalWorkshopContent';
import { FooterExternalWorkshop } from './components/FooterExternalWorkshop';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { MaintenanceServicesRepository } from 'v2/repositories/MaintenanceServicesRepository';

export const AppointmentByEmployeeExternalPanel = ({
  history,
  match,
  location,
}) => {
  const [maintenance, setMaintenance] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [companyId, setCompanyId] = useState(null);
  const [saleId, setSaleId] = useState(null);

  useEffect(() => {
    if (companyId && saleId) {
      loadMaintenance();
      reloadMaintenance();
    }
  }, [companyId, saleId]);

  useEffect(() => {
    readHash();
  }, []);

  const readHash = async () => {
    try {
      const url = decodeURIComponent(window.location.pathname).replace(
        '/by-appointment',
        ''
      );

      const companyIdHash = decodeURIComponent(url.split('/')[2]);
      const saleIdHash = decodeURIComponent(url.split('/')[3]);

      const decryptedCompanyId = await decrypt(
        companyIdHash,
        '@OS-dig:companyId'
      );
      const decryptedSaleId = await decrypt(saleIdHash, '@OS-dig:saleId');

      setCompanyId(decryptedCompanyId);
      setSaleId(decryptedSaleId);
    } catch (err) {
      console.log(err);
    }
  };

  const reloadMaintenance = () => {
    setInterval(() => {
      loadMaintenance();
    }, 9000);
  };

  const loadMaintenance = async () => {
    setIsLoading(true);
    try {
      const data = await MaintenanceServicesRepository.getAllByCompanyId(
        companyId,
        saleId
      );

      setMaintenance(data[0]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="external-workshop-panel">
        <HeaderExternalWorkshop />
        <div className="external-workshop-panel__content">
          <ExternalWorkshopContent
            maintenance={maintenance}
            isLoading={isLoading}
          />
        </div>
        <FooterExternalWorkshop />
      </div>
    </>
  );
};
