import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { KanbanStatusModal } from '../StatusModal';
import { useFormContext } from 'react-hook-form';
import AlertModal from 'components/AlertModal/AlertModal';
import { useAuth } from 'contexts/auth';
import { useKanbanScheduling } from '../../hooks/usekanbanScheduling';
import { toastr } from 'react-redux-toastr';

export const KanbanStatusHeader = ({
  name,
  color,
  total,
  haveOptionsButton,
  columnId,
  handleSearchScheduling,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { getValues } = useFormContext();
  const { companyId } = useAuth();
  const initialDate = getValues('initialDate');
  const finalDate = getValues('finalDate');
  const showFinished = getValues('showFinished');
  const { handleDeleteColumn } = useKanbanScheduling();

  const handleOptionsClick = () => {
    if (!columnId || columnId === 1) {
      toastr.warning(
        'Não permitido',
        'Exclusão e alteração não são permitidos para status/colunas padrões.'
      );
    } else {
      setIsOpenModal(true);
    }
  };

  const deleteScheduling = async () => {
    await handleDeleteColumn(companyId, columnId);
    handleSearchScheduling({
      initialDate,
      finalDate,
      showFinished,
    });
  };

  return (
    <div id="statusHeader" className="flex w100 justify-between align-center">
      <div className="flex start w100 gap-025">
        <div
          style={{
            width: '15px',
            height: '15px',
            backgroundColor: color,
            borderRadius: '2px',
          }}
        />
        <span className="fontSize-12" style={{ color: '#444444' }}>
          {name} ({total})
        </span>
      </div>
      {haveOptionsButton && (
        <FontAwesomeIcon
          icon={faEllipsis}
          onClick={() => {
            handleOptionsClick();
          }}
          className="button-icon flex end"
          style={{ width: '10px', color: '#444444' }}
        />
      )}
      <Modal
        show={isOpenModal}
        onHide={() => setIsOpenModal(false)}
        animation={true}
        dialogClassName="modal-25w"
        style={{ backgroundColor: '#EBEBEB !important' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong style={{ fontSize: '16px' }}>Ações da coluna</strong>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <button
              style={{ ...style.button }}
              onClick={() => {
                setIsOpenStatusModal(true);
              }}
            >
              Editar coluna/status
            </button>
            <button
              onClick={() => setConfirmationModal(true)}
              style={{ ...style.button, backgroundColor: '#C0C0C0' }}
            >
              Excluir coluna/status
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {isOpenStatusModal && (
        <KanbanStatusModal
          show={isOpenStatusModal}
          onCancel={() => setIsOpenStatusModal(false)}
          onSucess={() => {
            setIsOpenStatusModal(false);
            setIsOpenModal(false);
            handleSearchScheduling({
              initialDate,
              finalDate,
              showFinished,
            });
          }}
          name={name}
          id={columnId}
        />
      )}
      <AlertModal
        show={confirmationModal}
        onHide={() => setConfirmationModal(false)}
        onCancel={() => setConfirmationModal(false)}
        title="Exclusão de Coluna/Status"
        subtitle="Você tem certeza que deseja excluir a coluna/status?"
        message='O processo é irreversível. Uma vez excluído, todos os agendamentos com esse status, independente do período, moverá para a coluna "Sem Status".'
        onSubmit={() => deleteScheduling()}
      />
    </div>
  );
};

const style = {
  button: {
    width: '100%',
    textAlign: 'start',
    padding: '5px',
    border: 'none',
    borderRadius: '4px',
  },
};
