import React from 'react';
import { InputContainerStyled } from './styles';

export const InputContainer = ({
  children,
  variant = 'column',
  align,
  justify,
  gap = '5px',
  ...props
}) => {
  return (
    <InputContainerStyled
      align={align}
      justify={justify}
      variant={variant}
      gap={gap}
      {...props}
    >
      {children}
    </InputContainerStyled>
  );
};
