import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import TableByStatus from './TableByStatus';
import ChartByStatus from './ChartByStatus';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import billsToReceiveRepository from '../../../../../repositories/BillsToReceive';
import { useAuth } from 'contexts/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownloadXlsButton from 'components/DownloadXlsButton';
import {
  faCloudDownloadAlt,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { isValid } from 'date-fns';

const ReceivedByStatus = () => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [valuesStatusChartData, setValuesStatusChartData] = useState([]);
  const [formatTableData, setFormatTableData] = useState([]);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [valuesByStatusToExport, setValuesByStatusToExport] = useState([]);
  const [origin, setOrigin] = useState('billsToReceive');
  const [typeOfDate, setTypeOfDate] = useState('creation');
  const { companyId } = useAuth();

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning(
        'Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente'
      );
      return false;
    }

    return true;
  };

  const handleProcess = async () => {
    setLoading(true);
    setDisabled(false);
    try {
      const data = await billsToReceiveRepository.generateValuesByStatus({
        origin,
        typeOfDate,
        companyId,
        initialDate,
        finalDate,
      });

      if (!data?.billsByStatus.length)
        return toastr.warning(
          'Relatório não gerado',
          'Nenhuma informação foi localizada. Valide os filtros e tente novamente'
        );

      const formatedSalesTitle = data.totalizatorsBillsByStatus.rows.map(
        (item) => {
          return {
            valueTotal: data.totalizatorsBillsByStatus.total,
            quantityTotal: data.totalizatorsBillsByStatus.qtd,
            description: item.status,
            quantity: item.qtd,
            amount: item.total,
          };
        }
      );

      const dataXLS = data.billsByStatus
        .map((item) =>
          item.rows.map((row) => ({
            ...row,
            statusDescription: item.status,
          }))
        )
        .reduce((prev, curr) => [...prev, ...curr], [])
        .map((item) => {
          return {
            status: item.status,
            dueDate: item.dueDate ? item.dueDate : '',
            billCode: item.code,
            customer: item.customer ? item.customer : '',
            provider: item.provider ? item.provider : '',
            value: currency(item.liquidValue),
            feeValue: currency(item.feeValue),
            addedValue: currency(item.addedValue),
            discountValue: currency(item.discountValue),
            paymentDate: item.paymentDate ? item.paymentDate : '',
            account: item.account,
            saleCode:
              item.sales && item.sales.map((item) => item.saleCode).toString(),
          };
        });

      setValuesStatusChartData(
        data?.billsByStatus.map((item) => {
          return [
            item.billStatusDescription,
            Number(item.total),
            currency(Number(item.total)),
          ];
        })
      );

      setFormatTableData(formatedSalesTitle);
      setValuesByStatusToExport(dataXLS);
      setDisabled(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    window.open(
      window.location.origin +
        `/report/values-by-status?companyId=${companyId}&initialDate=${initialDate}&finalDate=${finalDate}&origin=${origin}&typeOfDate=${typeOfDate}`,
      '_blank'
    );
  };

  return (
    <div className="relatorio-por-pagamento-container">
      <header>
        <div>
          <span>
            <strong>Origem:</strong>
          </span>
          <select
            className="form-control foco-input"
            name="origin"
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
          >
            <option value={'billsToReceive'} selected>
              Contas a Receber
            </option>
            <option value={'billsToPay'}>Contas a Pagar</option>
          </select>
        </div>

        <div>
          <span>
            <strong>Tipo de data:</strong>
          </span>
          <select
            className="form-control foco-input"
            name="typeOfDate"
            value={typeOfDate}
            onChange={(e) => setTypeOfDate(e.target.value)}
          >
            <option value={'creation'} selected>
              Criação
            </option>
            <option value={'payment'}>Pagamento</option>
          </select>
        </div>

        <div>
          <span>
            <strong>Data Inicial:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="initialDate"
            max={finalDate}
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </div>

        <div>
          <span>
            <strong>Data Final:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="finalDate"
            min={initialDate}
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <button
            className="btn btn-sucesso"
            disabled={loading}
            onClick={handleSubmit}
            style={{ height: '35px' }}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />
            Processar
          </button>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <button
            className="component__pdf-button"
            onClick={handlePrint}
            disabled={loading || !valuesByStatusToExport.length}
            style={{ display: 'flex', alignItems: 'center', height: '35px' }}
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              size="lg"
              style={{ marginRight: '5px' }}
            />
            Exportar .pdf
          </button>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <DownloadXlsButton
            archiveName={`Listagem de Status - ${
              origin === 'billsToReceive' ? 'CT. Receber' : 'CT. Pagar'
            }`}
            data={valuesByStatusToExport}
            className="btn btn-export"
            disabled={loading || !valuesByStatusToExport.length}
            columns={[
              {
                name: 'Status',
                acessor: 'status',
              },
              {
                name: 'Vencimento',
                acessor: 'dueDate',
              },
              {
                name: 'Nº do título',
                acessor: 'billCode',
              },
              {
                name: 'Venda: Nº da venda',
                acessor: 'saleCode',
              },
              {
                name: 'Nome do cliente ou fornecedor',
                acessor: origin === 'billsToReceive' ? 'customer' : 'provider',
              },
              {
                name: 'Valor',
                acessor: 'value',
              },
              {
                name: 'Juros',
                acessor: 'feeValue',
              },
              {
                name: 'Acréscimo',
                acessor: 'addedValue',
              },
              {
                name: 'Desconto',
                acessor: 'discountValue',
              },
              {
                name: 'Data de pagamento',
                acessor: 'paymentDate',
              },
              {
                name: 'Conta',
                acessor: 'account',
              },
            ]}
            style={{ height: '35px' }}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </div>
      </header>

      <section>
        <div style={{ padding: 5 }}>
          <TableByStatus data={formatTableData} loading={loading} />
        </div>

        <div
          style={{
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '100px',
          }}
        >
          <ChartByStatus data={valuesStatusChartData} />
        </div>
      </section>
    </div>
  );
};

export default ReceivedByStatus;
