import { faLineChart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import '../styles.css';

const Header = () => {
  return (
    <>
      <div className="income-statement__header">
        <div className="income-statement__header-rectangle bg-primary" />
      </div>

      <div className="income-statement__title text-primary">
        <FontAwesomeIcon icon={faLineChart} style={{ marginRight: '10px' }} />
        DRE - Demonstrativo de Resultado de Exercício
      </div>

      <center>
        <div className="income-statement__line" />
      </center>
    </>
  );
};

export default Header;
