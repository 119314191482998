import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import {
  faImages,
  faPlusCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';

import AlertModal from 'components/AlertModal';

const AdvertisingImage = () => {
  const [isConfirmRemoveImageModalOpen, setIsConfirmRemoveImageModalOpen] =
    useState(false);

  const { file, imageUrl } = useSelector(
    (state) => state.form.advertising.values
  );
  const dispatch = useDispatch();
  const MAX_WIDTH = 540;
  const MAX_HEIGHT = 142;

  const { getRootProps, getInputProps, isDragReject, isDragAccept } =
    useDropzone({
      accept: 'image/jpeg',
      multiple: false,
      onDrop: ([file]) => {
        if (!file) return;

        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          if (image.height > MAX_HEIGHT || image.width > MAX_WIDTH) {
            return toastr.warning(
              'O tamanho máximo da imagem é 540x142px. Verifique a imagem e tente novamente.'
            );
          }

          const archive = Object.assign(file, {
            preview: URL.createObjectURL(file),
          });

          if (imageUrl) {
            const url = imageUrl;
            dispatch([
              change('advertising', 'imageUrlToRemove', url),
              change('advertising', 'imageUrl', null),
            ]);
          }

          dispatch(change('advertising', 'file', archive));
        };
      },
    });

  function renderImageContainerMessage() {
    if (isDragReject) {
      return 'Arquivo não compatível !';
    }
    if (isDragAccept) {
      return 'Solte a imagem aqui';
    }
    return 'Adicionar Imagem';
  }

  function handleRemoveImageFile() {
    if (file) {
      URL.revokeObjectURL(file.preview);
      dispatch(change('advertising', 'file', undefined));
    } else if (imageUrl) {
      const url = imageUrl;
      dispatch([
        change('advertising', 'imageUrlToRemove', url),
        change('advertising', 'imageUrl', null),
      ]);
    }
    setIsConfirmRemoveImageModalOpen(false);
  }

  return (
    <>
      {!file && !imageUrl ? (
        <div {...getRootProps()} className="advertising-image-dropzone">
          <input {...getInputProps()} />
          <FontAwesomeIcon icon={faImages} />
          <span>{renderImageContainerMessage()}</span>
        </div>
      ) : (
        <div className="advertising-image-container">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setIsConfirmRemoveImageModalOpen(true)}
          />
          <img src={!file ? imageUrl : file.preview} alt="Imagem Propaganda" />
          <label htmlFor="image-input">Alterar</label>
          <input {...getInputProps()} id="image-input" />
        </div>
      )}

      <AlertModal
        show={isConfirmRemoveImageModalOpen}
        title="Exclusão de imagem"
        message="Deseja excluir a imagem? Ao excluir a imagem, uma nova imagem deverá ser importada posteriormente."
        onHide={() => setIsConfirmRemoveImageModalOpen(false)}
        onCancel={() => setIsConfirmRemoveImageModalOpen(false)}
        onSubmit={handleRemoveImageFile}
      />
    </>
  );
};

export default AdvertisingImage;
