import api from 'v2/services/api';

const BASE_URL = 'branding/';

export const getAll = async () => {
  const response = await api.get(BASE_URL);
  return response.data;
};

export const BrandingRepository = { getAll };
