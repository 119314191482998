import { KanbanCard } from './KanbanCard/KanbanCard';
import { KanbanNewScheduling } from './KanbanNewScheduling';
import { KanbanRoot } from './KanbanRoot';
import { KanbanStatusHeader } from './KanbanStatusHeader';

export const Kanban = {
  Root: KanbanRoot,
  Card: KanbanCard,
  StatusHeader: KanbanStatusHeader,
  NewScheduling: KanbanNewScheduling,
};
