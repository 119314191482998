import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import { useDispatch } from 'react-redux';

import Form from './FormNewScheduling';

import schedulesRepository from '../../../../../repositories/Schedules';
import emailRepository from '../../../../../repositories/Emails';

import { useAuth } from 'contexts/auth';
import { format } from 'date-fns';

const FormNewScheduling = ({
  onCancel,
  onSubmit,
  schedulingId,
  isModal,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { companyId } = useAuth();

  useEffect(() => {
    if (!!schedulingId) {
      loadScheduling();
    }
  }, [schedulingId]);

  async function loadScheduling() {
    setLoading(true);
    try {
      const scheduling = await schedulesRepository.getById(schedulingId);
      const schedulingLoaded = {
        ...scheduling,
        prismBoxDescription: scheduling.PrismaBox.description,
        number: scheduling.Sales?.Code,
        customer: scheduling.Customer.Company_Name,
        customerId: scheduling.Customer.id,
        licensePlate:
          scheduling.Sales?.License_Plate || scheduling.Vehicle?.License_Plate,
        vehicle: scheduling.Vehicle?.Model,
        initialDate: format(new Date(scheduling.startScheduling), 'yyyy-MM-dd'),
        initialHour: format(new Date(scheduling.startScheduling), 'HH:mm'),
        finalDate: format(new Date(scheduling.endScheduling), 'yyyy-MM-dd'),
        finalHour: format(new Date(scheduling.endScheduling), 'HH:mm'),
      };

      dispatch(initialize('formNewScheduling', schedulingLoaded));
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro',
        'Ocorreu um problema ao carregar o agendamento. Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  }

  const validations = (values) => {
    const {
      boxPrism,
      initialDate,
      initialHour,
      finalDate,
      finalHour,
      customerId,
    } = values;

    if (!boxPrism || !initialDate || !finalDate || !initialHour || !finalHour) {
      toastr.warning(
        'OS Digital',
        'Por favor, preencha todos os campos obrigatórios para salvar o seu cadastro de agenda.'
      );

      return false;
    }

    if (!customerId) {
      toastr.warning(
        'OS Digital',
        'Selecione um cliente e preencha todos os campos obrigatórios (*) para efetuar o agendamento'
      );

      return false;
    }

    const initialDateTime = new Date(`${initialDate} ${initialHour}`);
    const finalDateTime = new Date(`${finalDate} ${finalHour}`);

    if (initialDateTime > finalDateTime) {
      toastr.warning(
        'Atenção',
        'Data inicial é posterior a data final. Por favor, corrija as informações.'
      );

      return false;
    }

    return true;
  };

  const submit = (values) => {
    setLoading(true);

    if (!validations(values)) {
      setLoading(false);
      return;
    }
    !!schedulingId ? update(values) : create(values);
  };

  const create = async (values) => {
    const {
      boxPrism,
      initialDate,
      initialHour,
      finalDate,
      finalHour,
      vehicleId,
      saleId,
      observations,
      customerId,
      kanbanStatusId,
    } = values;

    try {
      await schedulesRepository.create({
        companyId,
        customerId,
        boxPrism,
        startDate: initialDate + 'T' + initialHour,
        endDate: finalDate + 'T' + finalHour,
        vehicleId,
        saleId,
        observations,
        kanbanStatusId: kanbanStatusId === 'Sem Status' ? null : kanbanStatusId,
      });
      toastr.success('Agendamento cadastrado com sucesso.');
      await handleSendSchedullingMail({
        companyId,
        customerId,
        vehicleId,
        schedullingDate: initialDate + 'T' + initialHour,
      });
      onSubmit();
    } catch (err) {
      if (finalDate < initialDate) {
        toastr.warning(
          'O final do agendamento não pode ser menor que o inicial.'
        );
        return false;
      }

      if (
        err.response?.data?.error ===
        'Não foi possível realizar esse agendamento, pois já existe um agendamento para esse Box/Prisma neste horário.'
      ) {
        return toastr.warning(
          'Atenção',
          'Já existe um agendamento cadastrado para esse Box/Prima e horário, por favor, verifique.'
        );
      } else if (
        err.response?.data?.error ===
        'Desculpe, o envio de SMS está inoperante, contate nosso apoio para análise'
      ) {
        toastr.warning('Atenção', err.response?.data?.error);
        toastr.success('Agendamento cadastrado com sucesso.');

        await handleSendSchedullingMail({
          companyId,
          customerId,
          vehicleId,
          schedullingDate: initialDate + 'T' + initialHour,
        });

        onSubmit();
      } else {
        return toastr.error(
          'Erro',
          'Não foi possível realizar o agendamento. Verifique os dados e tente novamente!'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const update = async (values) => {
    const {
      boxPrism,
      initialDate,
      initialHour,
      finalDate,
      finalHour,
      vehicleId,
      saleId,
      observations,
      customerId,
      kanbanStatusId,
    } = values;
    try {
      console.log(kanbanStatusId);
      await schedulesRepository.update(schedulingId, {
        customerId,
        boxPrism,
        startDate: initialDate + 'T' + initialHour,
        endDate: finalDate + 'T' + finalHour,
        vehicleId,
        saleId,
        observations,
        companyId,
        kanbanStatusId:
          kanbanStatusId === 'Sem Status' || kanbanStatusId === null
            ? null
            : Number(kanbanStatusId),
      });

      toastr.success('Agendamento atualizado com sucesso.');

      await handleSendSchedullingMail({
        companyId,
        customerId,
        vehicleId,
        schedullingDate: initialDate + 'T' + initialHour,
      });

      onSubmit();
    } catch (err) {
      if (finalDate < initialDate) {
        return toastr.warning(
          'O final do agendamento não pode ser menor que o inicial.'
        );
      }

      if (
        err.response?.data?.error ===
        'Desculpe, o envio de SMS está inoperante, contate nosso apoio para análise'
      ) {
        toastr.warning('Atenção', err.response?.data?.error);
        toastr.success('Agendamento atualizado com sucesso.');

        await handleSendSchedullingMail({
          companyId,
          customerId,
          vehicleId,
          schedullingDate: initialDate + 'T' + initialHour,
        });
        onSubmit();
      } else {
        console.log(err);
        return toastr.warning(
          'Já existe um agendamento cadastrado para esse Box/Prima e horário, por favor, verifique.'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSendSchedullingMail = async ({
    companyId,
    customerId,
    vehicleId,
    schedullingDate,
  }) => {
    try {
      await emailRepository.sendSchedullingEmail({
        companyId,
        customerId,
        vehicleId,
        schedullingDate,
      });
    } catch (e) {
      toastr.error(
        'Erro',
        'Não foi possível enviar o email de agendamento. Verifique e tente novamente.'
      );
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <Form
        onSubmit={submit}
        loading={loading}
        onCancel={onCancel}
        schedulingId={schedulingId}
        isModal={isModal}
        closeModal={closeModal}
      />
    </div>
  );
};

export default FormNewScheduling;
