import config from '../config';
import api from '../services/api';

const getAllByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `services?Company_id=${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllPaginated = async (queryParams) => {
  const response = await api.get(config.endpoint + 'services', {
    params: queryParams,
  });
  return response;
};

const getAllByCompanyAndDefaults = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `services/by-company/${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getDefaults = async () => {
  try {
    const response = await api.get(config.endpoint + `services/defaults`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getById = async (id) => {
  try {
    const response = await api.get(config.endpoint + `services/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
const getServicesByIds = async (ids) => {
  try {
    const response = await api.get(config.endpoint + `services/find-all`, {
      params: { ids },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getCompanyLastCode = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `services/last-code/${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

// const search = async (companyId, attributes) => {
//   try {
//     const response = await api.post(config.endpoint + `services/search/${companyId}`, attributes)
//     return response.data
//   } catch(err) {
//     throw err
//   }
// }

const create = async (service) => {
  try {
    const response = await api.post(config.endpoint + `services`, service);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (id, service) => {
  try {
    await api.put(config.endpoint + `services/${id}`, service);
  } catch (err) {
    throw err;
  }
};

const importServices = async (params) => {
  try {
    await api.post(config.endpoint + 'services/import', params);
  } catch (err) {
    throw err;
  }
};

const deleteService = (serviceId) =>
  api.delete(config.endpoint + `services/${serviceId}`);

const verifyServiceExclusionEligibility = async ({ serviceId, companyId }) => {
  const { data } = await api.get(
    `services/verify/exclusion-eligibility/${serviceId}`,
    {
      params: { companyId },
    }
  );

  return data;
};

export default {
  getAllByCompany,
  getAllByCompanyAndDefaults,
  getAllPaginated,
  getById,
  getDefaults,
  getCompanyLastCode,
  create,
  update,
  importServices,
  deleteService,
  verifyServiceExclusionEligibility,
  getServicesByIds,
};
