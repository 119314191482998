import React, { useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import { useFinanceCalendarStore } from '../store/useFinanceCalendarStore';
import { CalendarEventCard } from './CalendarEventCard';
import { CalendarStyles } from './FinanceCalendar.styles';
import { useAuth } from 'contexts/auth';
import { useFormContext } from 'react-hook-form';

import { format } from 'date-fns';
import TitleQuickDischargeModal from 'client/components/TitleQuickDischargeModal';

export function Calendar({ selectedPeriod, calendarRef }) {
  const { companyId } = useAuth();
  const { getValues } = useFormContext();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalEventInfo, setModalEventInfo] = React.useState(null);

  const { getBills, calendarEvents, billsSummary } = useFinanceCalendarStore(
    (store) => ({
      getBills: store.getBills,
      calendarEvents: store.calendarEvents,
      billsSummary: store.billsSummary,
    })
  );

  const typeDate = getValues('typeDate');

  useEffect(() => {
    if (selectedPeriod) {
      getBills(getValues, companyId);
    }
  }, []);

  const getInfoBillsByDate = (info) => {
    const { type } = info.event.extendedProps;
    const clickedDate = info.event.start;
    const formattedDate = format(new Date(clickedDate), 'yyyy-MM-dd');

    const dayDetails = billsSummary.totalsByDate[formattedDate];

    if (dayDetails) {
      const eventInfo = {
        date: formattedDate,
        type: type === 'receivable' ? 'toReceive' : 'toPay',
      };

      setModalEventInfo(eventInfo);
      setIsModalOpen(true);
      return eventInfo;
    }

    return null;
  };

  const isViewWeek = typeDate.label === 'Semanal';

  return (
    <>
      <CalendarStyles />
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={calendarEvents}
        locale={ptBrLocale}
        headerToolbar={false}
        contentHeight="auto"
        dayHeaderContent={(args) => {
          const [dayAbbreviation] = args.text.split(' ');

          return (
            <div
              style={{
                height: '25px',
                fontSize: '16px',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: args.dow === 0 || args.dow === 6 ? '#F85F6A' : '#606060',
              }}
            >
              {args.view.type !== 'dayGridMonth' ? dayAbbreviation : args.text}
            </div>
          );
        }}
        dayCellContent={(cellInfo) => {
          const day = format(cellInfo.date, 'dd');

          return (
            <div
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                textAlign: 'left',
                color:
                  cellInfo.date.getDay() === 0 || cellInfo.date.getDay() === 6
                    ? '#F85F6A'
                    : '#606060',
              }}
            >
              {isViewWeek ? day : cellInfo.dayNumberText}
            </div>
          );
        }}
        eventContent={(eventInfo) => {
          const { title, extendedProps } = eventInfo.event;

          return (
            <CalendarEventCard title={title} extendedProps={extendedProps} />
          );
        }}
        eventInteractive={false}
        eventClick={(info) => {
          info.jsEvent.preventDefault();
          getInfoBillsByDate(info);
        }}
      />
      {isModalOpen && (
        <TitleQuickDischargeModal
          initialValues={{ page: 1 }}
          billType={modalEventInfo.type}
          calendarDate={modalEventInfo.date}
          onCancel={() => setIsModalOpen(false)}
          loadTitles={() => getBills(getValues, companyId)}
        />
      )}
    </>
  );
}
