import { PieChartGraphRoot } from './PieChartGraphRoot';
import { PieChartGraphCustom } from './PieChartGraphCustom';
import { PieChartGraphTitle } from './PieChartGraphTitle';
import { PieChartGraphSubtitle } from './PieChartGraphSubtitle';
export const PieChartGraph = {
  Root: PieChartGraphRoot,
  Graph: PieChartGraphCustom,
  Title: PieChartGraphTitle,
  Subtitle: PieChartGraphSubtitle,
};
