import api from '../services/api';
import config from '../config';

const getAll = async (queryParams) => {
  try {
    const response = await api.get(`${config.endpoint}news/`, {
      params: queryParams,
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const updateStatus = async (newsId, status) => {
  return await api.put(config.endpoint + `news/${newsId}/status`, {
    status,
  });
};

const getActiveNews = async (brandingId) => {
  try {
    const response = await api.get(config.endpoint + 'news/active', {
      params: {
        brandingId,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const getById = async (newsId) => {
  try {
    const response = await api.get(config.endpoint + `news/single/${newsId}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const getReport = async (newsId) => {
  try {
    const response = await api.get(
      config.endpoint + `news/click-report/${newsId}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const create = (values) => api.post(config.endpoint + 'news', values);

const update = (newsId, values) =>
  api.put(config.endpoint + `news/${newsId}`, values);

const click = (userId, newsId) =>
  api.post(config.endpoint + 'news/click', { userId, newsId });

export default {
  getAll,
  updateStatus,
  getActiveNews,
  getById,
  getReport,
  create,
  update,
  click,
};
