import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputText, InputLabel, InputContainer } from 'v2/components/Input';
import { SwitchRow } from 'v2/components/form';

import { Container } from './FormHeader.styles';
import AlertModal from 'components/AlertModal/AlertModal';
import { CopyProfileModal } from '../CopyProfileModal';
import { useModulesContext } from 'contexts/modules';

const MasterModalConfirmationMessage = () => {
  return (
    <div className="flex column gap-075">
      <p>
        Você tem certeza que deseja dar acesso total a esse perfil? Você poderá
        limitar as funções posteriormente.
      </p>
      <span className="text-center text-red">
        Uma vez liberado, todo usuário que estiver vinculado a esse perfil
        conseguirá acessar todas as funções disponíveis, inclusive as futuras.
      </span>
    </div>
  );
};

export function FormHeader() {
  const {
    isAnyFiscalModuleActive,
    isWorkshopPanelModuleActive,
    isNFeModuleActive,
    isNFCeModuleActive,
    isNFSeModuleActive,
  } = useModulesContext();

  const modules = {
    isAnyFiscalModuleActive: [
      'CFOP',
      'NCM/NBS',
      'Texto para Notas Fiscais',
      'Transportadora',
      'Tributo por Estado',
      'CNAE',
      'Intermediador',
      'Fiscal',
      'Notas Fiscais',
    ],
    isNFeModuleActive: ['Nota Fiscal (NF-e, NFC-e e SAT-CFe)'],
    isNFCeModuleActive: ['Nota Fiscal (NF-e, NFC-e e SAT-CFe)'],
    isNFSeModuleActive: ['Nota Fiscal de Serviço (NFS-e)'],
    isWorkshopPanelModuleActive: ['Painel da Oficina'],
  };

  const activeModules = {
    isAnyFiscalModuleActive,
    isWorkshopPanelModuleActive,
    isNFeModuleActive,
    isNFCeModuleActive,
    isNFSeModuleActive,
  };

  function hasActiveFeatureModule(feature) {
    for (let module in modules) {
      if (modules[module].includes(feature.description)) {
        return feature.isAllowedToUse && activeModules[module];
      }
    }
    return feature.isAllowedToUse;
  }

  const [isMasterConfirmationModalOpen, setIsMasterConfirmationModalOpen] =
    useState(false);
  const [isOpenCopyProfileModal, setIsOpenCopyProfileModal] = useState(false);

  const { register, watch, setValue } = useFormContext();

  const { isMaster, groupFeatures } = watch();

  function handleMasterToggleChange() {
    const newValue = !isMaster;

    if (newValue === true) {
      setIsMasterConfirmationModalOpen(true);
      return;
    }

    setValue('isMaster', !isMaster);
  }

  function activateMasterAccess() {
    setIsMasterConfirmationModalOpen(false);
    enableMasterAccess();

    const allFeaturesEnabled = groupFeatures.map((groupFeature) => ({
      ...groupFeature,
      status:
        groupFeature.isAllowedToUse && hasActiveFeatureModule(groupFeature),
      mainFeatures: groupFeature.mainFeatures.map((mainFeature) => ({
        ...mainFeature,
        status:
          mainFeature.isAllowedToUse && hasActiveFeatureModule(mainFeature),
        subFeatures: mainFeature.subFeatures.map((subFeature) => ({
          ...subFeature,
          status:
            subFeature.isAllowedToUse && hasActiveFeatureModule(subFeature),
        })),
      })),
    }));

    setValue(`groupFeatures`, allFeaturesEnabled);
  }

  function enableMasterAccess() {
    setValue(`isMaster`, true);
  }

  function handleCopyProfile() {
    setIsOpenCopyProfileModal(true);
  }

  return (
    <Container>
      <div>
        <InputContainer variant="row">
          <InputLabel htmlFor="input__isActive">Perfil de Acesso:</InputLabel>
          <InputText
            id="description"
            width={'130px'}
            {...register('profileDescription')}
            disabled
          />
        </InputContainer>
      </div>
      <div style={{ margin: '0 20px' }}>
        <SwitchRow
          width={'100%'}
          title={'Acesso Total'}
          subTitle={
            'Acesso irrestrito a todos os módulos, funções e ações, inclusive as lançadas no futuro.'
          }
          onChange={handleMasterToggleChange}
          checked={isMaster}
          visible={true}
          radius="full"
        />
        <button
          style={{ float: 'right', fontSize: '12px' }}
          className="btn-link"
          onClick={() => handleCopyProfile()}
        >
          Copiar permissões de outro perfil
        </button>
      </div>

      {isMasterConfirmationModalOpen && (
        <AlertModal
          title={'Permissão de Acesso'}
          show={isMasterConfirmationModalOpen}
          message={<MasterModalConfirmationMessage />}
          onCancel={() => setIsMasterConfirmationModalOpen(false)}
          onSubmit={() => {
            activateMasterAccess();
          }}
          onHide={null}
        />
      )}
      {isOpenCopyProfileModal && (
        <CopyProfileModal
          open={isOpenCopyProfileModal}
          onCancel={() => setIsOpenCopyProfileModal(false)}
        />
      )}
    </Container>
  );
}
