import React from 'react';
import { SidebarContainer } from './Sidebar.styles';
import { useSidebar } from 'v2/hooks/useSidebar/useSidebar';
import { SidebarItem } from './components/SidebarItem/SidebarItem';
import { GlobalBlockModals } from 'v2/client/components/GlobalBlockModals';

export function Sidebar({ active }) {
  const { sidebarItems } = useSidebar();

  return (
    <SidebarContainer>
      {sidebarItems.map((item) => {
        return (
          <SidebarItem {...item} currentActiveItem={active} key={item.title} />
        );
      })}

      <GlobalBlockModals />
    </SidebarContainer>
  );
}
