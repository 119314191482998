import api from 'v2/services/api';

export const getColumns = async (companyId) => {
  try {
    const res = await api.get(`/kanban-scheduling/column/${companyId}`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createColumn = async (column) => {
  const res = await api.post(`/kanban-scheduling/column`, { column });
  return res.data;
};

export const updateColumn = async (column, companyId) => {
  const res = await api.put(`/kanban-scheduling/column/${companyId}`, {
    column,
  });
  return res.data;
};

export const getKanban = async (
  { initialDate, finalDate, showFinished },
  companyId
) => {
  const res = await api.get(`/kanban-scheduling/${companyId}`, {
    params: {
      initialDate,
      finalDate,
      showFinished,
    },
  });
  return res.data;
};

export const deleteColumn = async (companyId, columnId) => {
  try {
    const res = await api.delete(
      `/kanban-scheduling/column/${companyId}/${columnId}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const statusCard = async (id, kanbanStatusId) => {
  try {
    const res = await api.put(`/kanban-scheduling/move-card/${id}`, {
      kanbanStatusId,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const KanbanSchedulingRepository = {
  getColumns,
  createColumn,
  updateColumn,
  getKanban,
  deleteColumn,
  statusCard,
};
