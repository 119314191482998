import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactTable from 'react-table';
import { useHistory } from 'react-router';

import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { currency } from '../../../../../client/components/ToNormalize/ToNormalize';

import CurrencyInput from '../../../../components/Currency';
import PercentInput from '../../../../components/Percent';

import PureLoader from '../../../../../components/PureLoader';

import massPriceChangeRepository from '../../../../../repositories/MassPriceChanges';
import productsRepository from 'repositories/Products';
import brandsRepository from 'repositories/Brands';
import familiesRepository from 'repositories/Families';
import ChangePriceModal from './ChangePriceModal';
import ConfirmModal from './ChangePriceModal/ConfirmModal';
import { PurchaseSelectModal } from './PurchaseSelectModal';
import { format } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import ConfirmChangePriceModal from './PurchaseSelectModal/ConfirmChangePriceModal';
import purchaseRepository from '../../../../../repositories/Purchases';

const FormNewBatchPrice = ({ priceChangeId }) => {
  const [products, setProducts] = useState([]);
  const [isFinished, setIsFinished] = useState(false);

  const [loading, setLoading] = useState(false);

  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState('');

  const [families, setFamilies] = useState([]);
  const [familyId, setFamilyId] = useState();
  const [exec, setExec] = useState(false);

  const [type, setType] = useState('Manual');
  const [numberPurchase, setNumberPurchase] = useState('');
  const [purchaseIdAux, setPurchaseIdAux] = useState('');
  const [provider, setProvider] = useState('');
  const [entryDate, setEntryDate] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);

  const [pages, setPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [status, setStatus] = useState('1');

  const [isTypePercentageOrValue, setIsTypePercentageOrValue] = useState('1');
  const [isAddOrSubtract, setIsAddOrSubtract] = useState('1');
  const [newValue, setNewValue] = useState('');

  const [isConfirmationModalOpen, setIsModalConfirmationModalOpen] =
    useState(false);
  const [isConfirmModalDataOpen, setIsConfirmModalDataOpen] = useState(false);
  const [isPurchaseSelectModalOpen, setIsPurchaseSelectModalOpen] =
    useState(false);
  const [isConfirmChangePriceModal, setIsConfirmChangePriceModal] =
    useState(false);

  const [changedPrice, setChangedPrice] = useState([]);

  const [isPureLoaderOpen, setIsPureLoader] = useState(false);

  const { userId, companyId } = useAuth();

  const history = useHistory();

  useEffect(() => {
    loadBrands();
    loadFamilies();
  }, []);

  useEffect(() => {
    if (priceChangeId) {
      handleLoadProductsById(priceChangeId);
    }
  }, [priceChangeId]);

  useEffect(() => {
    handleChangePrice();
  }, [isTypePercentageOrValue, isAddOrSubtract, newValue]);

  async function loadProducts(state, history) {
    setLoading(true);

    try {
      const response = await productsRepository.getAllPaginated({
        Company_id: companyId || 0,
        page: state.page + 1,
        limit: state.pageSize,
        IsActive: state.status,
        Brand_id: state.brandId,
        familyId: state.familyId,
      });

      const total = response.headers['x-total-count'];
      const serializeProducts = response.data.map((product) => ({
        ...product,
        Code: Number(product.Code),
        Family: product.Families?.Description,
        Brand: product.Brands?.Description,
      }));

      setPages(Math.round(total / state.pageSize));
      setProducts(serializeProducts);
    } catch (err) {
      console.error(err);
      if (!products) {
        toastr.warning(
          'Ocorreu um erro ao buscar os produtos. Por favor, tente novamente'
        );
      }
    } finally {
      setLoading(false);
    }
  }

  async function loadBrands() {
    try {
      const brands = await brandsRepository.getAllByCompany(companyId);
      const serializedBrands = brands.map((brand) => ({
        id: brand.id,
        description: brand.Description,
      }));
      setBrands(serializedBrands);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscas as marcas. Por favor, tente novamente'
      );
    }
  }
  async function loadFamilies() {
    setLoading(true);
    try {
      const families =
        await familiesRepository.getAllProductsFamiliesActiveByCompany(
          companyId
        );
      const serializedFamilies = families.map((family) => ({
        id: family.id,
        Description: family.Description,
      }));

      setFamilies(serializedFamilies);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as famílias. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleLoadProduct() {
    return await loadProducts({
      page: 0,
      pageSize,
      status,
      brandId,
      familyId,
    });
  }

  async function handleChangePrice() {
    setLoading(true);
    try {
      const productsWithNewPrice = products.map((product) => {
        const price =
          type === 'Manual'
            ? product.Sale_Price
            : priceChangeId
            ? product.Sale_Price
            : product.Products.Sale_Price;
        const changedPrice =
          isTypePercentageOrValue === '1'
            ? isAddOrSubtract === '1'
              ? Number(price) * (Number(newValue) / 100) + Number(price)
              : Number(price) - Number(price) * (Number(newValue) / 100)
            : isAddOrSubtract === '1'
            ? Number(price) + Number(newValue)
            : Number(price) - Number(newValue);

        return {
          ...product,
          newPrice: parseFloat(changedPrice.toFixed(2)),
        };
      });
      return setProducts(productsWithNewPrice);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao alterar os novos preços. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function selectedFunction(exec, redirect, openModal = false) {
    setExec(exec);

    if (exec && openModal) {
      if (type === 'Manual') {
        setIsConfirmModalDataOpen(true);
      } else {
        setIsConfirmChangePriceModal(true);
      }
    }

    const doRequest = !openModal ? true : false;

    submitChangePrice(exec, redirect, doRequest);
  }

  async function submitChangePrice(exec, redirect = false, doRequest = true) {
    if (exec && redirect) {
      setIsPureLoader(true);
      setIsModalConfirmationModalOpen(false);
    } else {
      setLoading(true);
    }

    try {
      if (!doRequest) return;

      const changedProduct = products.map((product) => {
        return {
          previousPrice:
            type === 'Manual'
              ? product?.Sale_Price || product?.Products?.Sale_Price
              : product?.Products?.Sale_Price || product?.Sale_Price,
          purchasePrice:
            type === 'Manual'
              ? product?.Purchase_Price || product?.Products?.Purchase_Price
              : product?.Products?.Purchase_Price || product?.Purchase_Price,
          productId:
            type === 'Manual'
              ? product?.id || product?.Products?.id
              : product?.Products?.id || product?.id,
          newPrice: product.newPrice,
          newValue,
          changeFactor: isTypePercentageOrValue,
          changeFunction: isAddOrSubtract,
          inventory: exec
            ? product?.Stock_Quantity || product?.Products?.Stock_Quantity
            : null,
          changeValue: parseFloat(Number(newValue).toFixed(2)),
        };
      });

      if (priceChangeId) {
        await massPriceChangeRepository.update(priceChangeId, {
          massPriceChangesItems: changedProduct,
          status: exec,
          userId,
          companyId,
          productsQuantity: products.length,
          type,
          purchaseId: purchaseIdAux || null,
        });
      } else {
        await massPriceChangeRepository.create({
          massPriceChangesItems: changedProduct,
          status: exec,
          userId,
          companyId,
          productsQuantity: products.length,
          type,
          purchaseId: purchaseIdAux || null,
        });
      }

      if (exec && redirect) {
        toastr.success('Preço dos produtos alterados com sucesso');
      } else {
        toastr.success('Alteração de preço dos produtos salva com sucesso');
      }
    } catch (error) {
      toastr.warning(
        'Ocorreu um erro ao criar novos preços. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
      setIsModalConfirmationModalOpen(false);
      if (exec) {
        // setIsConfirmModalDataOpen(false);
        setIsPureLoader(false);
      }

      if (!exec && redirect) {
        history.push('batch-price-changes');
      }

      if (redirect) history.push('batch-price-changes');
    }
  }

  async function handleLoadProductsById(id) {
    const { products, status, type, purchaseId } =
      await massPriceChangeRepository.getById(id);
    setIsFinished(status);
    setType(type);
    setIsUpdate(true);

    let purchaseInfo = {};

    if (purchaseId) {
      purchaseInfo = await purchaseRepository.getById(purchaseId);
      setNumberPurchase(purchaseInfo.code);
      setPurchaseIdAux(purchaseId);
      setProvider(
        purchaseInfo.Provider.companyName || purchaseInfo.Provider.tradingName
      );
      setEntryDate(
        format(
          new Date(getDateOnlyFromDate(purchaseInfo.entryAt)),
          'yyyy-MM-dd'
        )
      );
    }

    const serializedMassPriceChanges = await Promise.all(
      products.map(async (massPriceChageProduct) => {
        const product = await productsRepository.getById(
          massPriceChageProduct?.productId
        );
        setIsTypePercentageOrValue(massPriceChageProduct?.changeFactor);
        setIsAddOrSubtract(massPriceChageProduct?.changeFunction);
        setNewValue(massPriceChageProduct?.changeValue);
        return {
          ...product,
          Family: product?.Families?.Description,
          Brand: product?.Brands?.Description,
          Stock_Quantity:
            massPriceChageProduct.inventory === null
              ? 0
              : massPriceChageProduct.inventory,
          Sale_Price: massPriceChageProduct.previousPrice,
          newPrice:
            massPriceChageProduct.newPrice === null
              ? 0
              : massPriceChageProduct.newPrice,
        };
      })
    );

    setProducts(serializedMassPriceChanges);
  }

  async function handleChangeValueType(e) {
    setType(e.target.value);
    setProducts([]);
    setFamilyId();
    setBrandId('');
    setStatus('1');
  }

  const handleSelectPurchase = (purchase) => {
    setIsPurchaseSelectModalOpen(false);
    setPurchaseIdAux(purchase.id);
    setNumberPurchase(purchase.code);
    setProvider(purchase.Provider.companyName || purchase.Provider.tradingName);
    setEntryDate(
      format(new Date(getDateOnlyFromDate(purchase.entryAt)), 'yyyy-MM-dd')
    );
    setProducts(purchase.PurchaseItems);
  };

  const handleChangePriceItem = (e, index) => {
    const updatedProducts = [...products];
    updatedProducts[index].newPrice = +e.target.value;
    setTimeout(() => {
      document.getElementById(`newPrice-${index}`).focus();
    }, 50);
    setProducts(updatedProducts);
  };

  return (
    <div style={{ marginTop: '30px' }}>
      {isPureLoaderOpen && (
        <PureLoader
          message="Alteração de preço em andamento, por favor aguarde...."
          subMessage="Não saia ou feche a página"
        />
      )}
      <Row style={{ marginBottom: '20px' }}>
        <Col lg={2} md={3} sm={4} xs={12}>
          <label>Tipo de Alteração:</label>
          <select
            value={type}
            onChange={(e) => handleChangeValueType(e)}
            className="form-control foco-input"
            disabled={isFinished}
          >
            <option value="Manual">Manual</option>
            <option value="Purchase">Nota de Compra</option>
          </select>
        </Col>
      </Row>
      {type === 'Manual' ? (
        <>
          <Row>
            <Col lg={2} md={3} sm={4} xs={12}>
              <label>Família:</label>
              <select
                value={familyId}
                onChange={(e) => setFamilyId(e.target.value)}
                className="form-control foco-input"
                disabled={isFinished}
              >
                <option value="">Todas</option>
                <option value="null">Sem Família</option>
                {families.map((family) => (
                  <option key={family.id} value={family.id}>
                    {family.Description}
                  </option>
                ))}
              </select>
            </Col>
            <Col lg={2} md={3} sm={4} xs={12}>
              <label>Marcas:</label>
              <select
                className="form-control foco-input"
                name="searchBrand"
                value={brandId}
                onChange={(e) => setBrandId(e.target.value)}
                disabled={isFinished}
              >
                <option value="">Todos</option>
                {brands.map((brand) => (
                  <option value={brand.id} key={brand.id}>
                    {brand.description}
                  </option>
                ))}
              </select>
            </Col>
            <Col lg={2} md={3} sm={4} xs={12}>
              <label>Status:</label>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                disabled={isFinished}
              >
                <option value="1">Ativo</option>
                <option value="2">Inativo</option>
                <option value="">Ambos</option>
              </select>
            </Col>
            <div>
              <button
                style={{ marginTop: '40px' }}
                className="btn btn-sucesso"
                onClick={handleLoadProduct}
                type="submit"
                disabled={isFinished}
              >
                Processar Produtos
              </button>
            </div>
          </Row>
        </>
      ) : (
        <Row
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            width: '100%',
          }}
        >
          <div style={{ marginLeft: '5px' }}>
            <button
              style={{ marginTop: '40px', height: '35px' }}
              className="btn btn-warning"
              onClick={() => setIsPurchaseSelectModalOpen(true)}
              type="submit"
              disabled={isFinished}
            >
              Selecionar Compra
            </button>
          </div>

          <div
            style={{ display: 'flex', flexDirection: 'column', width: '150px' }}
          >
            <label htmlFor="numberPurchase">Nº Compra:</label>
            <input
              type="text"
              className="form-control"
              name="numberPurchase"
              id="numberPurchase"
              value={numberPurchase}
              onChange={(e) => setNumberPurchase(e.target.value)}
              style={{ height: '35px' }}
              disabled
            />
          </div>

          <div
            style={{ display: 'flex', flexDirection: 'column', width: '350px' }}
          >
            <label htmlFor="provider">Fornecedor:</label>
            <input
              type="text"
              className="form-control"
              name="provider"
              id="provider"
              value={provider}
              onChange={(e) => setProvider(e.target.value)}
              style={{ height: '35px' }}
              disabled
            />
          </div>

          <div
            style={{ display: 'flex', flexDirection: 'column', width: '150px' }}
          >
            <label htmlFor="entryDate">Data da Entrada:</label>
            <input
              type="date"
              className="form-control"
              name="entryDate"
              id="entryDate"
              value={entryDate}
              onChange={(e) => setEntryDate(e.target.value)}
              style={{ height: '35px' }}
              disabled
            />
          </div>
        </Row>
      )}
      <Row>
        <Col lg={2} md={3} sm={4} xs={12}>
          <label>Alterar por:</label>
          <select
            className="form-control foco-input"
            value={isTypePercentageOrValue}
            onChange={(e) => setIsTypePercentageOrValue(e.target.value)}
            disabled={!products.length || isFinished}
          >
            <option value="1">Porcentagem</option>
            <option value="2">Valor</option>
          </select>
        </Col>
        <Col lg={2} md={3} sm={4} xs={12}>
          <label>Função no preço:</label>
          <select
            className="form-control foco-input"
            value={isAddOrSubtract}
            onChange={(e) => setIsAddOrSubtract(e.target.value)}
            disabled={!products.length || isFinished}
          >
            <option value="1">Somar</option>
            <option value="2">Subtrair</option>
          </select>
        </Col>
        <Col lg={2} md={3} sm={4} xs={12}>
          {isTypePercentageOrValue === '1' ? (
            <PercentInput
              className="form-control foco-input"
              style={{
                marginTop: '40px',
              }}
              value={newValue}
              onChangeEvent={(e) => setNewValue(Number(e.target.value))}
              disabled={!products.length || isFinished}
            />
          ) : (
            <CurrencyInput
              className="form-control foco-input"
              style={{ marginTop: '40px' }}
              value={newValue}
              onChangeEvent={(e) => setNewValue(Number(e.target.value))}
              disabled={!products.length || isFinished}
            />
          )}
        </Col>
        <div>
          <button
            style={{ marginTop: '40px' }}
            className="btn btn-export"
            onClick={() => setIsModalConfirmationModalOpen(true)}
            disabled={!products.length || isFinished}
          >
            Alterar Preços
          </button>
        </div>
      </Row>
      <Row>
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
          style={{ marginTop: '15px' }}
        >
          <ReactTable
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              width: '100%',
            }}
            data={products}
            columns={[
              {
                Header: 'Código',
                accessor:
                  type === 'Manual'
                    ? 'Code'
                    : type === 'Purchase' && isUpdate
                    ? 'Code'
                    : 'Products.Code',
                headerClassName: 'text-center',
                className: 'texto',
                width: 110,
              },
              {
                Header: 'Descrição',
                accessor:
                  type === 'Manual'
                    ? 'Description'
                    : type === 'Purchase' && isUpdate
                    ? 'Description'
                    : 'Products.Description',
                headerClassName: 'text-center',
                className: 'texto',
              },
              {
                Header: 'Família',
                accessor:
                  type === 'Manual'
                    ? 'Family'
                    : type === 'Purchase' && isUpdate
                    ? 'Families.Description'
                    : 'Products.Families.Description',
                headerClassName: 'text-center',
                className: 'texto',
              },
              {
                Header: 'Marca',
                accessor:
                  type === 'Manual'
                    ? 'Brand'
                    : type === 'Purchase' && isUpdate
                    ? 'Brands.Description'
                    : 'Products.Brands.Description',
                headerClassName: 'text-center',
                className: 'texto',
              },
              {
                Header: 'Estoque',
                accessor:
                  type === 'Manual'
                    ? 'Stock_Quantity'
                    : type === 'Purchase' && isUpdate
                    ? 'Stock_Quantity'
                    : 'Products.Stock_Quantity',
                headerClassName: 'text-center',
                className: 'texto',
                width: 110,
              },
              {
                Header: 'Preço Atual',
                accessor:
                  type === 'Manual'
                    ? 'Sale_Price'
                    : type === 'Purchase' && isUpdate
                    ? 'Sale_Price'
                    : 'Products.Sale_Price',
                headerClassName: 'text-center',
                className: 'texto',
                width: 110,
                Cell: (props) => currency(props.value),
              },
              {
                Header: 'Novo Preço',
                accessor: 'newPrice',
                headerClassName: 'text-center',
                className: 'texto',
                width: 110,
                Cell: (props) => {
                  return type === 'Manual' ? (
                    <span style={{ color: '#5cb85c' }}>
                      {props.value === undefined ? '' : currency(props.value)}
                    </span>
                  ) : (
                    <CurrencyInput
                      id={`newPrice-${props.index}`}
                      className="form-control foco-input"
                      value={props.value}
                      disabled={isFinished}
                      onChangeEvent={(e) =>
                        handleChangePriceItem(e, props.index)
                      }
                      decimalSeparator=","
                      thousandSeparator="."
                      suffix=""
                    />
                  );
                },
              },
            ]}
            defaultPageSize={10}
            loading={loading}
            showPagination={true}
            sortable={true}
            showPaginationBottom={true}
            pages={pages}
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando dados..."
            noDataText="Selecione os produtos que deseja alterar o preço"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
            manual
            onFetchData={(state) => {
              if (pages > 0) {
                loadProducts({
                  ...state,
                  status,
                  brandId,
                  familyId,
                });
              }
            }}
            onPageSizeChange={(newPageSize, _) => setPageSize(newPageSize)}
          />
        </div>
      </Row>

      {isConfirmationModalOpen && (
        <ChangePriceModal
          onCancel={() => setIsModalConfirmationModalOpen(false)}
          data={products}
          onSubmit={selectedFunction}
          prices={changedPrice}
        />
      )}

      {!!isConfirmModalDataOpen && (
        <ConfirmModal
          onCancel={() => setIsConfirmModalDataOpen(false)}
          data={products}
          onSubmit={() => submitChangePrice(exec, true)}
          confirmModalData={{
            status,
            isTypePercentageOrValue,
            isAddOrSubtract,
            newValue,
          }}
        />
      )}

      {isPurchaseSelectModalOpen && (
        <PurchaseSelectModal
          onHide={() => setIsPurchaseSelectModalOpen(false)}
          onCancel={() => setIsPurchaseSelectModalOpen(false)}
          handleSelectPurchase={handleSelectPurchase}
        />
      )}

      {isConfirmChangePriceModal && (
        <ConfirmChangePriceModal
          onCancel={() => setIsConfirmChangePriceModal(false)}
          onSubmit={() => submitChangePrice(exec, true)}
        />
      )}
    </div>
  );
};

export default FormNewBatchPrice;
