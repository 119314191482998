import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { InputContainer, InputLabel, InputText } from '../Input';
import { toastr } from 'react-redux-toastr';
import OverwriteUserSessionModal from 'components/OverwriteUserSessionModal';
import { useAuth } from 'contexts/auth';
import { InputPassword } from '../Input/InputPassword';

export function ModalChangeOperator({ open, onClose }) {
  const { signIn, clearToken, companyId } = useAuth();
  const [
    isConfirmOverwriteUserSessionMoldalOpen,
    setIsConfirmOverwriteUserSessionMoldalOpen,
  ] = useState(false);
  const [newUserId, setNewUserId] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const serializeAuthentication = async (inputEmail, inputPassword) => {
    const newEmail = inputEmail.replace('DEVOSD_', '');
    const newPassword = inputPassword;
    const developerMode = inputEmail.includes('DEVOSD_');
    return {
      newEmail,
      newPassword,
      developerMode,
    };
  };

  const handleSubmit = async () => {
    try {
      const { newEmail, newPassword, developerMode } =
        await serializeAuthentication(email, password);

      await signIn({
        email: newEmail,
        password: newPassword,
        developerMode,
        changeOperator: companyId,
      });
      handleClose();
    } catch (err) {
      if (err.response.status === 406) {
        toastr.error(
          'Usuário não vinculado a essa empresa',
          'O email informado não pertence a essa empresa. Caso queira acessar com esse email, feche o PDV e clique em sair no sistema.'
        );
        return;
      }

      if (err.response.status === 401) {
        toastr.error(
          'E-mail e/ou senha inválidos',
          'Verifique os dados informados e tente novamente. Em caso de dúvidas, informe o problema ao administrador do sistema de sua empresa'
        );
        return;
      }

      if (err.response.status === 405) {
        setNewUserId(err.response.data.error.userId);
        setIsConfirmOverwriteUserSessionMoldalOpen(true);
        return;
      }
    }
  };

  async function handleOveriteUserSession() {
    if (undefined) {
    }
    await clearToken(newUserId);
    await handleSubmit();
    setIsConfirmOverwriteUserSessionMoldalOpen(false);
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal show={open} onHide={handleClose} dialogClassName="modal-35w">
        <Modal.Header>
          <Modal.Title>
            <strong>Alterar Operador</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Insira o Email e senha de acesso.</span>
          <br />
          <br />

          <div className="flex column center">
            <p
              style={{
                fontSize: '11px',
                color: '#d9534f',
                textAlign: 'center',
              }}
            >
              <strong>Apenas usuarios dessa empresa poderão acessar</strong>
            </p>
            <div className="flex column center">
              <InputContainer>
                <InputLabel htmlFor="input_email" required>
                  Email:
                </InputLabel>
                <InputText
                  style={{ fontSize: '14px', color: '#444444' }}
                  id="input_email"
                  maxLength={50}
                  width="300"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputContainer>
            </div>
            <div className="flex column center">
              <InputContainer>
                <InputLabel htmlFor="input_password" required>
                  Senha:
                </InputLabel>
                <InputPassword
                  id="input_password"
                  maxLength={18}
                  width="300"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InputContainer>
            </div>
            <p
              style={{
                fontSize: '11px',
                color: '#d9534f',
                textAlign: 'center',
              }}
            >
              Ao acessar, caso o usuário esteja logado em outra máquina, o
              acesso será derrubado
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 flex end gap-050">
            <button
              style={{ height: '38px' }}
              className="button button-red"
              onClick={handleClose}
            >
              Cancelar
            </button>
            <button
              style={{ height: '38px' }}
              className="button button-green"
              onClick={handleSubmit}
            >
              Acessar
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <OverwriteUserSessionModal
        show={isConfirmOverwriteUserSessionMoldalOpen}
        onCancel={() => setIsConfirmOverwriteUserSessionMoldalOpen(false)}
        onSubmit={() => handleOveriteUserSession()}
      />
    </>
  );
}
