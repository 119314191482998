import React from 'react';
import ProfileCardRanking from './ProfileCardRanking';

const RankingSalesBackground = ({ users, title }) => {
  return (
    <div style={styles.backgroundItem}>
      <span style={styles.title}>{title}</span>
      <div style={styles.ranking}>
        {users && users.length > 0 ? (
          users.map((user, index) => (
            <ProfileCardRanking
              key={user.IdEmployee}
              user={user}
              rank={index + 1}
            />
          ))
        ) : (
          <p>Nenhum dado disponível</p>
        )}
      </div>
    </div>
  );
};

const styles = {
  backgroundItem: {
    width: '100%',
    height: '255px',
    flexDirection: 'column',
    gap: '15px',
    marginTop: '10px',
    backgroundColor: '#ffffff',
    border: '1px solid #e6e6e6',
    borderRadius: '24px',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '20px',
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#428BCA',
    textTransform: 'uppercase',
  },
  ranking: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
};

export default RankingSalesBackground;
