import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import FormSale from './FormSale';

const NewSale = ({ match, location, history }) => {
  localStorage.setItem('selectedPath', 'client/NovaVenda');

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id="required-field-label">* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              'Início',
              'Vendas',
              match.params.id || history?.location?.state?.saleId
                ? 'Editar Venda'
                : 'Nova Venda',
            ]}
            path={['home', 'sales', null]}
          />
          <br />
          <FormSale
            saleId={match.params.id || history?.location?.state?.saleId}
            location={location}
          />
        </Row>
      </Grid>
    </div>
  );
};

export default NewSale;
