import { create } from 'zustand';
import FinanceCalendarRepository from 'v2/repositories/FinanceCalendarRepository';

export const useFinanceCalendarStore = create((set, get) => ({
  billsSummary: {},
  calendarEvents: [],
  optionsWeeks: [],
  isLoading: false,
  summary: {},

  getBills: async (getValues, companyId) => {
    set({ isLoading: true });
    const { month, year, typeRegister, typeDate, selectedWeek, initialDate } =
      getValues();
    const { groupedValues } = get();

    const formattedMonth =
      month.value < 10 ? `0${month.value}` : `${month.value}`;
    const period =
      typeDate.label === 'Semanal'
        ? initialDate
        : `${year.value}-${formattedMonth}`;
    const recordType = typeRegister.value;
    const week = selectedWeek?.value;

    try {
      const response =
        typeDate.label === 'Semanal'
          ? await FinanceCalendarRepository.getBillsByWeek(
              companyId,
              recordType,
              period,
              week,
              initialDate
            )
          : await FinanceCalendarRepository.getBillsMonth(
              companyId,
              recordType,
              period
            );

      const selectedMonth = parseInt(month.value, 10);
      const selectedYear = parseInt(year.value, 10);

      const filteredBills = Object.keys(response.totalsByDate).flatMap(
        (date) => {
          const bills = response.totalsByDate[date];
          const billDate = new Date(date);
          const billMonth = billDate.getMonth() + 1;
          const billYear = billDate.getFullYear();

          if (billMonth === selectedMonth && billYear === selectedYear) {
            return bills.details.map((bill) => ({
              ...bill,
              dueDate: date,
            }));
          }
          return [];
        }
      );

      set({
        billsSummary: {
          ...response,
          totalsByDate: Object.fromEntries(
            Object.entries(response.totalsByDate).filter(([date]) => {
              const billDate = new Date(date);
              const billMonth = billDate.getMonth() + 1;
              const billYear = billDate.getFullYear();
              return billMonth === selectedMonth && billYear === selectedYear;
            })
          ),
        },
      });

      groupedValues(filteredBills);
      return filteredBills;
    } catch (err) {
      console.error(err);
    } finally {
      set({ isLoading: false });
    }
  },
  groupedValues: (allBills) => {
    const groupedEvents = allBills.reduce(
      (acc, item) => {
        const date = item.dueDate;

        if (item.type === 'receivable') {
          if (!acc.receive[date]) {
            acc.receive[date] = {
              title: 'Títulos a Receber',
              date,
              value: 0,
              count: 0,
              type: item.type,
            };
          }
          acc.receive[date].value += item.total;
          acc.receive[date].count += 1;

          acc.totalReceive += item.total;
        }

        if (item.type === 'payable') {
          if (!acc.pay[date]) {
            acc.pay[date] = {
              title: 'Títulos a Pagar',
              date,
              value: 0,
              count: 0,
              type: item.type,
            };
          }
          acc.pay[date].value += item.total;
          acc.pay[date].count += 1;

          acc.totalPay += item.total;
        }

        return acc;
      },
      {
        receive: {},
        pay: {},
        totalReceive: 0,
        totalPay: 0,
      }
    );

    // Extrai eventos formatados
    const receiveEvents = Object.values(groupedEvents.receive);
    const payEvents = Object.values(groupedEvents.pay);
    const formattedEvents = [...receiveEvents, ...payEvents];

    set({
      calendarEvents: formattedEvents,
      summary: {
        totalReceive: groupedEvents.totalReceive,
        totalPay: groupedEvents.totalPay,
      },
    });
  },
  getWeeks: async (getValues) => {
    const { month, year } = getValues();
    try {
      const weeks = await FinanceCalendarRepository.getWeeksByMonthAndYear(
        year.value
      );

      const selectedMonth = weeks.find((week) => week.month === month.value);

      if (!selectedMonth) {
        console.warn('Mês selecionado não encontrado na resposta.');
        return;
      }

      const transformedData = {
        weeks: Array.from({ length: selectedMonth.weeks }, (_, index) => ({
          value: index + 1,
          label: `Semana ${index + 1}`,
        })),
      };
      set({ optionsWeeks: transformedData });
    } catch (err) {
      console.log(err);
    }
  },
}));
