import React, { useEffect, useState } from 'react';
import useOnboardingStore from '../store/onboarding-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'v2/components/Input';

export const ModulesTable = () => {
  const {
    rowsModules,
    legends,
    updateModuleStatus,
    updateModuleObservations,
    isLoading,
  } = useOnboardingStore();

  const handleStatusClick = (modulesRow, legendDescription) => {
    updateModuleStatus(modulesRow.id, legendDescription);
  };

  const getButtonOpacity = (rowStatus, legendDescription) => {
    return rowStatus === legendDescription ? 1 : 0.5;
  };

  const handleObservationChange = (modulesRow, value) => {
    updateModuleObservations(modulesRow.id, value);
  };
  return (
    <div className="flex column w-100 gap-050">
      <div className="w-100 flex justify-between">
        <div className="flex start text-bold" style={{ width: '1000px' }}>
          Itens para Checklist:
        </div>
        <div className="flex center w-100 text-bold">Observações</div>
      </div>
      {rowsModules.map((row) => (
        <div
          className="flex row w-100 justify-between align-center gap-050"
          style={{ marginLeft: '10px', paddingBottom: '10px' }}
        >
          <div
            className="flex row align-center justify-between "
            style={{
              width: '1000px',
            }}
          >
            <span
              className="text-left w-100"
              style={{
                width: '200px',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              {row.description}:
            </span>
            <div
              className="gap-050 h-35"
              style={{
                width: '280px',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {legends.map((legend) => (
                <button
                  className="flex center radius-4"
                  disabled={isLoading}
                  onClick={() => handleStatusClick(row, legend.description)}
                  style={{
                    backgroundColor: legend.color,
                    color: 'white',
                    width: '40px',
                    border: '1px solid transparent',
                    height: '100%',
                    opacity: getButtonOpacity(
                      row.module && row.module.status
                        ? row.module.status
                        : legend.description,
                      legend.description
                    ),
                  }}
                >
                  {legend.iconName && (
                    <span className="text-bold">{legend.iconName}</span>
                  )}
                  {legend.icon && (
                    <FontAwesomeIcon icon={legend.icon} size="1x" />
                  )}
                </button>
              ))}
            </div>
          </div>
          <div
            className="flex center w-100 text-bold"
            style={{ marginLeft: '15px' }}
          >
            <InputText
              height={'35px'}
              type="text"
              isDisabled={isLoading}
              value={(row.module && row.module.observations) || ''}
              onChange={(e) => handleObservationChange(row, e.target.value)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
