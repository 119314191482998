import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles.css';

const Card = (props) => {
  const [expanded, setExpanded] = useState(true);

  const handleCardExpansionChange = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  return (
    <div className="sale-card-main">
      <div className="sale-card-container">
        <div className="sale-card-header">
          <span style={{ color: '#1d7391', fontSize: '17px' }}>
            <FontAwesomeIcon color="#1d7391" icon={props.icon} />
            <strong style={{ marginLeft: '5px' }}>{props.title}</strong>
          </span>
          <a
            href="#"
            className="sale-card-action"
            onClick={() => handleCardExpansionChange()}
          >
            {expanded ? 'Ocultar' : 'Exibir'}
          </a>
        </div>

        <div
          className={`sale-card-content ${!expanded ? 'hidden' : ''} ${
            props.className
          }`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Card;
