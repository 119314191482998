import React from 'react';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import NewsRegisterForm from './NewsRegisterForm';
import newsRepository from '../../../../../repositories/News';
import { useAdminAuth } from 'contexts/adminAuth';
import { useEffect, useState } from 'react';
import s3Repository from 'repositories/S3';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { format } from 'date-fns';
import { isValidUrl } from 'client/components/ToNormalize/ToNormalize';
import { getBrandingName } from 'v2/helpers/brandingHelpers';
import { newDate } from 'v2/helpers/dateHelpers';

function NewsRegisterMain({ newsId, onSubmit, onCancel }) {
  const { admin } = useAdminAuth();
  const [imageFile, setImageFile] = useState({
    preview: null,
    file: null,
    uploaded: false,
  });

  const { isLoading, fetchLoading } = useLoading();

  useEffect(() => {
    if (newsId) {
      fetchLoading(loadNewsToUpdate);
    }
  }, [newsId]);

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      description: '',
      url: '',
      adminId: admin.id,
      brandingId: { label: 'Ambos', value: '' },
      imageUrl: '',
      initialDurationDate: null,
      finalDurationDate: null,
    },
  });

  const validations = (values) => {
    const {
      description,
      url,
      initialDurationDate,
      finalDurationDate,
      brandingId,
    } = values;

    if (!description) {
      toastr.warning(
        'Não foi possível salvar',
        'Preencha todos os campos com dados válidos e tente novamente'
      );
      return false;
    }

    if (!brandingId.length) {
      toastr.warning(
        'Não foi possível salvar',
        'Selecione ao menos um Produto para cadastrar a News'
      );
      return false;
    }

    if (!imageFile.file && !imageFile.uploaded && !imageFile.preview) {
      toastr.warning(
        'Selecione uma imagem',
        'É obrigatória a seleção de uma imagem para cadastrar as News'
      );
      return false;
    }

    if (initialDurationDate && finalDurationDate) {
      const initialDate = new Date(initialDurationDate);
      const finalDate = new Date(finalDurationDate);

      if (initialDate > finalDate) {
        toastr.warning(
          'Não foi possível salvar',
          'A data inicial não pode ser maior que a data final'
        );
        return false;
      }
    }

    const checkBlankSpace = (string) => string.trim().length === 0;

    if (checkBlankSpace(description) || isValidUrl(checkBlankSpace(url))) {
      toastr.warning(
        'Insira uma descrição e um link válido para cadastrar a sua notícia e tente novamente.'
      );
      return false;
    }

    const checkBlankSpaceURL = (string) => /\s/g.test(string);

    if (checkBlankSpaceURL(url)) {
      toastr.warning(
        'Insira um link válido para sua notícia e tente novamente.'
      );
      return false;
    }

    return true;
  };

  const loadNewsToUpdate = async () => {
    try {
      const newsToUpdate = await newsRepository.getById(newsId);

      const brandingFormatted = newsToUpdate.Brandings?.map((branding) => ({
        value: branding.BrandingNews.brandingId,
        label: getBrandingName(branding.product),
      }));

      const {
        description,
        imageUrl,
        url,
        initialDurationDate,
        finalDurationDate,
      } = newsToUpdate;

      if (imageFile) {
        setImageFile({
          name: imageUrl?.split('amazonaws.com/')[1],
          preview: imageUrl,
          uploaded: true,
        });
      }

      setValue('description', description);
      setValue('url', url);
      setValue('imageUrl', imageUrl);
      setValue(
        'initialDurationDate',
        initialDurationDate
          ? format(newDate(initialDurationDate), 'yyyy-MM-dd')
          : null
      );
      setValue(
        'finalDurationDate',
        finalDurationDate
          ? format(newDate(finalDurationDate), 'yyyy-MM-dd')
          : null
      );
      setValue('brandingId', brandingFormatted);
    } catch (err) {
      console.error(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar a notícia. Por favor, tente novamente'
      );
    }
  };

  async function verifyImageUpdate(photo, photoToDelete) {
    if (photoToDelete) await s3Repository.deleteImages([photoToDelete]);

    if (!photo) return null;

    if (!photo.uploaded) {
      try {
        const imageURL = await s3Repository.uploadImage(photo);
        return imageURL;
      } catch (err) {
        toastr.warning(
          'Ocorreu um erro ao salvar o logo',
          'Por favor, tente novamente'
        );
        return null;
      }
    }

    if (photo.uploaded === true) return photo.preview;

    return null;
  }

  const extractBrandingValues = (brandingArray) => {
    return brandingArray
      .filter((branding) => branding.value !== '*')
      .map((branding) => branding.value);
  };

  const serializeParams = async (values, imageFile) => {
    const brandingIds = extractBrandingValues(values.brandingId);
    const initialDurationDate = values.initialDurationDate || null;
    const finalDurationDate = values.finalDurationDate || null;
    const imageUrl = await verifyImageUpdate(
      imageFile,
      values.imageUrlToRemove
    );

    return { brandingIds, initialDurationDate, finalDurationDate, imageUrl };
  };

  const submit = (values) => {
    values.description = values.description.trim();
    values.url = values.url.trim();

    const isValid = validations(values);

    if (!isValid) {
      return;
    } else {
      fetchLoading(() => {
        if (newsId) {
          return update(values);
        } else {
          return create(values);
        }
      });
    }
  };

  const create = async (values) => {
    try {
      const { brandingIds, initialDurationDate, finalDurationDate, imageUrl } =
        await serializeParams(values, imageFile);

      await newsRepository.create({
        ...values,
        brandingIds,
        imageUrl,
        initialDurationDate,
        finalDurationDate,
      });

      toastr.success('Notícia cadastrada com sucesso.');
      onSubmit();
    } catch (err) {
      console.error(err);
      return toastr.warning(
        err.response?.data?.message ??
          'Ocorreu um erro ao registrar a notícia. Por favor, tente novamente'
      );
    }
  };

  const update = async (values) => {
    try {
      const { brandingIds, imageUrl, initialDurationDate, finalDurationDate } =
        await serializeParams(values, imageFile);

      await newsRepository.update(newsId, {
        ...values,
        brandingIds,
        imageUrl,
        initialDurationDate,
        finalDurationDate,
      });

      toastr.success('Notícia atualizada com sucesso.');
      onSubmit();
    } catch (err) {
      console.error(err);
      return toastr.warning(
        'Ocorreu um erro ao atualizar a notícia. Por favor, tente novamente'
      );
    }
  };

  return (
    <NewsRegisterForm
      onSubmit={submit}
      newsId={newsId}
      register={register}
      watch={watch}
      setValue={setValue}
      handleSubmit={handleSubmit}
      isDirty={isDirty}
      onCancel={onCancel}
      getValues={getValues}
      control={control}
      setImageFile={setImageFile}
      imageFile={imageFile}
      isLoading={isLoading}
    />
  );
}

export default NewsRegisterMain;
