import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faMedal } from '@fortawesome/free-solid-svg-icons';
import { currency } from 'client/components/ToNormalize/ToNormalize';

const medalColors = ['#FFD700', '#C0C0C0', '#CD7F32', '#606060'];

const ProfileCardRanking = ({ user, rank }) => {
  const medalColor = medalColors[rank - 1];

  return (
    <div style={styles.card}>
      <Badge rank={rank} medalColor={medalColor} />
      <ImageContainer user={user} medalColor={medalColor} />
      <Info user={user} />
    </div>
  );
};

const Badge = ({ rank, medalColor }) => (
  <div style={{ ...styles.badge, color: medalColor }}>
    <FontAwesomeIcon icon={faMedal} size="3x" />
    <div style={{ ...styles.rankCircle, backgroundColor: medalColor }}>
      {rank}º
    </div>
  </div>
);

const ImageContainer = ({ user, medalColor }) => (
  <div style={styles.imageContainer}>
    {user.ProfileImg ? (
      <img
        src={user.ProfileImg}
        alt="User"
        style={{ ...styles.image, borderColor: medalColor }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faCircleUser}
        size="3x"
        style={{ ...styles.image, borderColor: medalColor }}
      />
    )}
  </div>
);

const Info = ({ user }) => (
  <div style={styles.info}>
    <h2 style={styles.name}>{user.Name}</h2>
    <p style={styles.position}>{user.Position}</p>
    <Stats user={user} />
  </div>
);

const Stats = ({ user }) => (
  <div style={styles.stats}>
    <p style={styles.statItem}>
      <strong>
        {user.TotalSales} <br />
      </strong>{' '}
      Vendas
    </p>
    <p style={styles.statItem}>
      <strong>
        {currency(user.TotalRevenue)} <br />
      </strong>{' '}
      Lucro
    </p>
    <p style={styles.statItem}>
      <strong>
        {currency(user.TotalCommission)} <br />
      </strong>{' '}
      Comissão
    </p>
  </div>
);

const styles = {
  card: {
    position: 'relative',
    width: '222px',
    height: '167px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  badge: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rankCircle: {
    marginTop: '5px',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '12px',
  },
  imageContainer: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '50%',
    paddingBottom: '0px',
  },
  image: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    objectFit: 'cover',
    borderWidth: '3px',
    borderStyle: 'solid',
  },
  info: {
    backgroundColor: '#1D7391',
    color: 'white',
    textAlign: 'center',
    padding: '10px',
    height: '50%',
  },
  name: {
    fontSize: '13px',
    margin: '0',
  },
  position: {
    fontSize: '10px',
    fontWeight: '100',
    margin: '0',
  },
  stats: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px',
  },
  statItem: {
    fontSize: '10px',
    fontWeight: '100',
    margin: '0',
  },
};

export default ProfileCardRanking;
