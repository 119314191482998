import React, { useState } from 'react';
import CustomizedTabs from 'v2/components/Tabs/Tabs';
import { VehicleBrand } from './sections/VehicleBrand';
import { RankingIndicatorsCustomers } from './sections/RankingIndicatorsCustomers';
import { ServiceProduct } from './sections/ServiceProduct';
import Ranking from './sections/SellersAndMechanics';

export function RankingIndicatorsNavigator() {
  const [selectedTab, setSelectedTab] = useState(0);
  function renderTabContent(index) {
    switch (index) {
      case 0:
        return <Ranking />;
      case 1:
        return <VehicleBrand />;

      case 2:
        return <RankingIndicatorsCustomers />;

      case 3:
        return <ServiceProduct />;

      default:
        return 0;
    }
  }
  const renderTabs = () => {
    let tabLabels = [
      'MECÂNICO E VENDEDORES',
      'VEÍCULOS E MONTADORAS',
      'CLIENTES',
      'SERVIÇOS E PRODUTOS',
    ];
    return (
      <CustomizedTabs
        tabLabels={tabLabels}
        value={selectedTab}
        onChange={handleTabChange}
      />
    );
  };
  function handleTabChange(event, newValue) {
    setSelectedTab(newValue);
  }
  return (
    <div>
      {renderTabs()}
      {renderTabContent(selectedTab)}
    </div>
  );
}
