import React, { useState } from 'react';

import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../client/components/CustomButton/CustomButton';

import {
  currency,
  percentage,
} from '../../../components/ToNormalize/ToNormalize';

import { Modal } from 'react-bootstrap';

const TableData = ({
  loading,
  products,
  handleFilter,
  handleDeleteProduct,
  setIsConfirmationDeleteModalOpen,
  isConfirmationDeleteModalOpen,
}) => {
  const [productToDele, setProductToDelete] = useState();
  return (
    <>
      <ReactTable
        style={{
          width: '100%',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={products.filter(handleFilter)}
        columns={[
          {
            Header: 'Código',
            accessor: 'code',
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            width: 300,
          },
          {
            Header: 'R$ Compra',
            accessor: 'purchasePrice',
            Cell: (props) => currency(props.original.purchasePrice),
          },
          {
            Header: 'Margem',
            accessor: 'profitMargin',
            Cell: (props) => percentage(props.original.profitMargin),
          },
          {
            Header: 'R$ Venda',
            accessor: 'salePrice',
            Cell: (props) => currency(props.original.salePrice),
          },
          {
            Header: <span className="mandatory-column">Margem Dif.</span>,
            accessor: 'margin',
            Cell: (props) => percentage(props.original.margin),
          },
          {
            Header: <span className="mandatory-column">R$ Venda Dif.</span>,
            accessor: 'saleDif',
            Cell: (props) => currency(props.original.saleDif),
          },
          {
            width: 50,
            Cell: (props) => (
              <FontAwesomeIcon
                title="Deletar"
                cursor="pointer"
                style={{ height: '1.5em', width: '1.5em', color: 'red' }}
                icon={faTrashAlt}
                onClick={() => {
                  setIsConfirmationDeleteModalOpen(true);
                  setProductToDelete(props.original);
                }}
              />
            ),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum Produto encontrado"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
        defaultSorted={[{ id: 'Code', desc: false }]}
      />
      <Modal
        show={isConfirmationDeleteModalOpen}
        onHide={() => setIsConfirmationDeleteModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>
            Você tem certeza que deseja excluir o produto da tabela de preços
            diferenciados ?
          </strong>
          <br />
          <br />
          <span>
            O processo é irreversível. Ao excluir o produto, o mesmo poderá ser
            lançado novamente no futuro.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle="danger"
              onClick={() => setIsConfirmationDeleteModalOpen(false)}
              fill
            >
              Não
            </Button>
            <Button
              bsStyle="info"
              fill
              onClick={() => handleDeleteProduct(productToDele)}
            >
              Sim
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TableData;
