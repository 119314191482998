import CardForm from 'client/components/CardForm';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import React from 'react';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import ReactTable from 'react-table';

const NewRequisitionTable = ({ loading, data, control, hasId }) => {
  const [requisitionItems, setRequisitionItems] = useState(true);

  return (
    <CardForm
      style={{ marginTop: 20 }}
      show={requisitionItems}
      title="Itens da Requisição"
      onClick={() => setRequisitionItems(!requisitionItems)}
    >
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
        }}
        data={data}
        columns={[
          {
            Header: 'Código',
            accessor: 'productCode',
            width: Math.round((window.innerWidth - 55) * 0.07),
          },
          {
            Header: 'Ref. Fabricante',
            accessor: 'manufacturerRef',
            width: Math.round((window.innerWidth - 55) * 0.1),
          },
          {
            Header: 'Descrição',
            accessor: 'description',
          },
          {
            Header: 'Qtd.',
            accessor: 'quantityOnSale',
            width: Math.round((window.innerWidth - 55) * 0.07),
          },
          {
            Header: 'Qtd. Disponível',
            accessor: 'availableQuantity',
            width: Math.round((window.innerWidth - 55) * 0.1),
          },
          {
            Header: 'Qtd. Entregue',
            accessor: 'deliveredQuantity',
            width: Math.round((window.innerWidth - 55) * 0.1),
            Cell: (props) => {
              return (
                <Controller
                  name={`saleRequisitionItems.${props.index}.deliveredQuantity`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <input
                        style={{ textAlign: 'center' }}
                        type="number"
                        className="form-control"
                        {...field}
                        disabled={
                          hasId || data[props.index].availableQuantity === 0
                        }
                      />
                    );
                  }}
                />
              );
            },
          },
          {
            Header: 'Preço Unit.',
            accessor: 'unitValue',
            width: Math.round((window.innerWidth - 55) * 0.1),
            Cell: (props) => <span>{currency(props.value)}</span>,
          },
          {
            Header: 'Data de Entrega',
            accessor: 'deliveryDate',
            width: Math.round((window.innerWidth - 55) * 0.11),
            Cell: (props) => {
              return (
                <Controller
                  name={`saleRequisitionItems.${props.index}.deliveredDate`}
                  defaultValue={format(new Date(), 'yyyy-MM-dd')}
                  control={control}
                  render={({ field }) => {
                    return (
                      <input
                        type="date"
                        className="form-control"
                        {...field}
                        defaultValue={format(new Date(), 'yyyy-MM-dd')}
                        disabled={
                          hasId || data[props.index].availableQuantity === 0
                        }
                        max={format(new Date(), 'yyyy-MM-dd')}
                      />
                    );
                  }}
                />
              );
            },
          },
        ]}
        defaultPageSize={10}
        pageSize={10}
        manual
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        sortable={false}
        loading={loading}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={false}
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </CardForm>
  );
};

export default NewRequisitionTable;
