import { currency } from 'client/components/ToNormalize/ToNormalize';
import React from 'react';
export function CalendarEventCard({ title, extendedProps }) {
  return (
    <div
      style={{
        width: '80%',
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          border: '1px solid #0000001A',
          borderRadius: '4px',
          fontSize: '9px',
          boxShadow: '0px 3px 2px rgba(35, 35, 35, 0.07)',
          background:
            extendedProps.type === 'receivable' ? '#DDF0D1' : '#FFE9EE',
          border: '1px solid #0000001A !important',
          gap: '5px',
        }}
      >
        <div
          style={{
            width: '5px',
            height: '100%',
            backgroundColor:
              extendedProps.type === 'receivable' ? '#4CAE4C' : '#D9534F',
            borderRight: '1px solid #0000001A',
            borderRadius: '4px 0 0 4px',
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            color: '#000000',
            fontSize: '9px',
          }}
        >
          <span>{`${extendedProps.count} ${title}`}</span>
          <strong style={{}}>Total: R$ {currency(extendedProps.value)}</strong>
        </div>
      </div>
    </div>
  );
}
