import React, { useEffect, useState } from 'react';
import FormBillToPay from './FormBillToPay';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';
import { useDispatch, connect } from 'react-redux';
import { change, reduxForm, initialize } from 'redux-form';
import { Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import AlertModal from 'components/AlertModal/AlertModal';
import { format, isAfter, differenceInMonths } from 'date-fns';
import constants from '../../../../utils/constants';
import billsToPayRepository from '../../../../repositories/BillsToPay';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { useAuth } from 'contexts/auth';
import { cnpjMask } from 'client/components/ToNormalize/ToNormalize';

const initialValues = {
  issueDate: format(new Date(), 'yyyy-MM-dd'),
  providerId: null,
  searchProvider: '',
  billStatusId: constants.BILLS_STATUS.OPEN,
  amount: 0,
  discount: 0,
  addition: 0,
  liquidValue: 0,
  openValue: 0,
  payments: [
    {
      formOfPaymentId: '',
      value: 0,
      payDate: '',
      observations: '',
    },
  ],
  paidValue: 0,
  costCenterId: '',
  conciliationIdentifier: '',
  conciliationDate: null,
  billToPayId: null,
  haveBankConciliation: false,
};

function BundleFormBillToPay({ history, titleId, billToPay, defaultValues }) {
  const [loading, setLoading] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCancelBillModalOpen, setIsCancelBillModalOpen] = useState(false);
  const [isRemoveConciliationModalOpen, setIsRemoveConciliationModalOpen] =
    useState(false);
  const { company, companyId, userId } = useAuth();
  const [isPurchase, setIsPurchase] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!titleId) {
      getTitle();
    } else {
      initialValuesWithDefaultCashierBank();
    }
  }, []);

  async function initialValuesWithDefaultCashierBank() {
    const data = {
      ...initialValues,
      cashierBankId: company.defaultCashierBankId,
      accountPlanId: company.defaultPurchaseAccountPlanId,
    };

    if (defaultValues) {
      data.amount = defaultValues.total;
      data.isGrouped = defaultValues.grouped;
      data.providerId = defaultValues.providerId;
      data.searchProviderName = defaultValues.providerName;

      data.payments = [
        {
          formOfPaymentId: '',
          value: defaultValues.total,
          payDate: '',
          observations: '',
        },
      ];

      defaultValues.purchasesCode.sort((a, b) => {
        if (parseInt(a) < parseInt(b)) {
          return -1;
        }

        if (parseInt(a) > parseInt(b)) {
          return 1;
        }

        return 0;
      });

      data.purchasesIds = defaultValues.purchasesId;
      data.linkedPurchases = defaultValues.purchasesCode.join(', ');

      if (defaultValues.grouped) {
        data.observations = 'Título Agrupado';
      } else {
        data.code = defaultValues.code;
      }
    }

    dispatch(initialize('billToPay', data));
  }

  async function getTitle() {
    setLoading(true);
    try {
      const res = await billsToPayRepository.getById(titleId);
      const invoice = !res.invoice
        ? res.Purchases?.invoice
          ? res.Purchases?.invoice
          : ''
        : res.invoice;

      setIsPurchase(res.Purchases?.invoice);

      let purchasesCodes = '';

      if (res.isGrouped) {
        purchasesCodes = res.BillsToPayPurchases.map(
          (billToPayPurchase) => billToPayPurchase.Purchases.code
        )?.join(', ');
      }

      function handleLabel(provider) {
        return `${cnpjMask(provider.cpfCnpj)} | ${provider.companyName} | ${
          provider.tradingName
        }`;
      }

      dispatch([
        change(
          'billToPay',
          'dueDate',
          format(new Date(getDateOnlyFromDate(res.dueDate)), 'yyyy-MM-dd')
        ),
        change('billToPay', 'amount', res.subTotal),

        change('billToPay', 'searchProviderName', res.Provider.companyName),
        change('billToPay', 'providerId', res.providerId),
        change('billToPay', 'searchProvider', {
          label: handleLabel(res.Provider),
          value: {
            ...res.Provider,
            label: handleLabel(res.Provider),
          },
        }),
        change(
          'billToPay',
          'issueDate',
          format(new Date(getDateOnlyFromDate(res.issuedAt)), 'yyyy-MM-dd')
        ),
        change('billToPay', 'entryNumber', res.entryNumber),
        change('billToPay', 'invoice', invoice),
        change('billToPay', 'parcelNumber', res.installment),
        change('billToPay', 'linkedPurchases', purchasesCodes),
        change('billToPay', 'isGrouped', res.isGrouped),
        change(
          'billToPay',
          'entryDate',
          res.entryDate
            ? format(new Date(getDateOnlyFromDate(res.entryDate)), 'yyyy-MM-dd')
            : null
        ),
        change('billToPay', 'addition', res.addedValue),
        change('billToPay', 'discount', res.discountValue),
        change(
          'billToPay',
          'dischargeDate',
          res.lowDate
            ? format(new Date(getDateOnlyFromDate(res.lowDate)), 'yyyy-MM-dd')
            : null
        ),
        change('billToPay', 'observations', res.observations),
        change('billToPay', 'billStatusId', res.billStatusId),
        change('billToPay', 'code', res.code),
        change('billToPay', 'cashierBankId', res.cashierBankId),
        change('billToPay', 'accountPlanId', res.accountPlanId),
        change('billToPay', 'isPurchase', isPurchase),
        change('billToPay', 'costCenterId', res.costCenterId),
        change('billToPay', 'billToPayId', res.id),
        change('billToPay', 'haveBankConciliation', res.ofxHeaderId),
        change(
          'billToPay',
          'conciliationIdentifier',
          res.Conciliation ? res.Conciliation.identifier : null
        ),
        change(
          'billToPay',
          'conciliationDate',
          res.Conciliation
            ? format(
                new Date(getDateOnlyFromDate(res.Conciliation.updatedAt)),
                'yyyy-MM-dd'
              )
            : null
        ),
      ]);

      const payments = res.BillsToPayParcels.map((billToPayParcels) => ({
        id: billToPayParcels.id,
        value: billToPayParcels.amount,
        paymentDate: billToPayParcels.payDate
          ? format(
              new Date(getDateOnlyFromDate(billToPayParcels.payDate)),
              'yyyy-MM-dd'
            )
          : null,
        observations: billToPayParcels.observations,
        formOfPaymentId: billToPayParcels.formOfPaymentId,
      }));

      dispatch(change('billToPay', 'payments', payments));
      if (res.billStatusId === constants.BILLS_STATUS.CANCELED) {
        toastr.warning('Título Cancelado. Nenhuma ação poderá ser realizada.');
      }
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar o título. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(values) {
    const {
      amount,
      providerId,
      dueDate,
      openValue,
      payments,
      cashierBankId,
      accountPlanId,
      discount,
    } = values;

    if (
      !providerId ||
      !dueDate ||
      !amount ||
      !cashierBankId ||
      !accountPlanId
    ) {
      return toastr.warning('Por favor, insira os campos obrigatórios');
    }

    let isFullDiscount = discount === amount && Number(openValue) === 0;

    const anyPaymentFieldEmpty = payments.some(
      (payment) => !payment.formOfPaymentId || !payment.value
    );
    if (anyPaymentFieldEmpty && !isFullDiscount) {
      return toastr.warning(
        'Existem pagamentos sem Forma de pagamento ou Valor'
      );
    }

    let invalidDates = false;
    payments.forEach((payment) => {
      if (payment.payDate) {
        const isDateFuture = isAfter(new Date(payment.payDate), new Date());
        const differenceDatesInMonths = differenceInMonths(
          new Date(),
          new Date(payment.payDate)
        );

        if (isDateFuture || differenceDatesInMonths > 3) {
          toastr.warning(
            'Insira uma data de pagamento menor que 3 meses ou coloque a data atual.'
          );
          invalidDates = true;
          return;
        }
      }
    });

    if (invalidDates) return;

    if (openValue < 0) {
      return toastr.warning('Somatório dos pagamentos maior que o total');
    }

    if (openValue > 0) {
      if (titleId) {
        update(constants.BILLS_STATUS.OPEN, values);
      } else {
        create(constants.BILLS_STATUS.OPEN, values);
      }
    } else {
      setIsSaveModalOpen(true);
    }
  }

  function handleConfirmModal(billStatusId) {
    const { payments } = billToPay;
    setIsSaveModalOpen(false);
    setIsCancelBillModalOpen(false);

    if (billStatusId === constants.BILLS_STATUS.CLOSED) {
      const anyPaymentWithoutPaymentDate = payments.some(
        (payment) => !payment.paymentDate
      );

      if (anyPaymentWithoutPaymentDate) {
        return toastr.warning('Existem pagamentos sem datas inseridas');
      }

      const anyPaymentDateIsFuture = payments.some((payment) =>
        isAfter(new Date(getDateOnlyFromDate(payment.paymentDate)), new Date())
      );

      if (anyPaymentDateIsFuture) {
        return toastr.error(
          'Não é possível efetuar a baixa do título com data de pagamento futura. Valide a data e tente novamente'
        );
      }

      const anyPaymentDateIsPastTenYears = payments.some(
        (payment) =>
          new Date(getDateOnlyFromDate(payment.paymentDate)).getFullYear() <
          2010
      );

      if (anyPaymentDateIsPastTenYears) {
        return toastr.error(
          'Data inferior a 2010. Valide a data e tente novamente'
        );
      }
    }

    if (billStatusId === constants.BILLS_STATUS.CANCELED) {
      return update(billStatusId, billToPay);
    }

    if (!titleId) {
      create(billStatusId, billToPay);
    } else {
      update(billStatusId, billToPay);
    }
  }

  async function create(billStatusId, bill) {
    const {
      addition,
      amount,
      clientId,
      discount,
      dueDate,
      issueDate,
      liquidValue,
      observations,
      openValue,
      paidValue,
      payments,
      providerId,
      parcelNumber,
      entryNumber,
      invoice,
      entryDate,
      cashierBankId,
      accountPlanId,
      purchasesIds,
      isGrouped,
      costCenterId,
    } = bill;

    const billToPay = {
      dueDate,
      cashierBankId,
      issuedAt: issueDate,
      lowDate:
        billStatusId === constants.BILLS_STATUS.CLOSED ? new Date() : null,
      subTotal: amount,
      addedValue: addition,
      discountValue: discount,
      liquidValue,
      paidValue,
      openValue,
      observations,
      customerId: clientId,
      billStatusId,
      providerId,
      entryNumber,
      invoice,
      entryDate,
      installment: parcelNumber,
      accountPlanId,
      purchasesIds,
      isGrouped,
      costCenterId: costCenterId === '' ? null : costCenterId,
    };
    setLoading(true);

    try {
      await billsToPayRepository.create({
        billToPay,
        payments,
        companyId,
        purchasesIds,
      });

      toastr.success(
        `Título ${
          billStatusId === constants.BILLS_STATUS.OPEN ? 'Salvo' : 'Finalizado'
        } com sucesso`
      );
      history.push(constants.ROUTES.BILLS_TO_PAY);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao salvar o título. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function update(billStatusId, bill) {
    const {
      addition,
      amount,
      discount,
      dueDate,
      issueDate,
      liquidValue,
      observations,
      openValue,
      paidValue,
      payments,
      providerId,
      parcelNumber,
      entryNumber,
      invoice,
      entryDate,
      cashierBankId,
      accountPlanId,
      costCenterId,
    } = bill;

    const billToPay = {
      dueDate,
      cashierBankId,
      issuedAt: issueDate,
      subTotal: amount,
      addedValue: addition,
      discountValue: discount,
      liquidValue,
      paidValue,
      openValue,
      observations,
      billStatusId: billStatusId,
      companyId,
      providerId,
      lowDate:
        billStatusId === constants.BILLS_STATUS.CLOSED ? new Date() : null,
      entryNumber,
      invoice,
      entryDate,
      installment: parcelNumber,
      accountPlanId,
      costCenterId: costCenterId === '' ? null : costCenterId,
    };

    setLoading(true);
    try {
      await billsToPayRepository.update({
        billToPay,
        payments,
        titleId,
        companyId,
      });

      toastr.success(
        `Título ${
          billStatusId === constants.BILLS_STATUS.OPEN
            ? 'Salvo'
            : billStatusId === constants.BILLS_STATUS.CANCELED
            ? 'Cancelado'
            : 'Finalizado'
        } com sucesso`
      );
      history.push(constants.ROUTES.BILLS_TO_PAY);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao atualizar o título. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    if (
      (billToPay.billStatusId === constants.BILLS_STATUS.OPEN ||
        billToPay.billStatusId === constants.BILLS_STATUS.CLOSED) &&
      titleId
    ) {
      setIsCancelBillModalOpen(true);
    } else {
      setIsCancelModalOpen(true);
    }
  }

  const handleRemoveBankConciliation = async () => {
    try {
      await billsToPayRepository.removeBankConciliation(
        billToPay.billToPayId,
        userId
      );
      dispatch([
        change('billToPay', 'conciliationIdentifier', null),
        change('billToPay', 'conciliationDate', null),
        change('billToPay', 'haveBankConciliation', false),
      ]);
      toastr.success(
        'Conciliação Removida',
        'A conciliação foi removida com sucesso'
      );
    } catch (error) {
      console.log(error);
      toastr.error(
        'Erro ao remover conciliação',
        'Tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsRemoveConciliationModalOpen(false);
    }
  };

  return (
    <>
      <FormBillToPay
        initialValues={initialValues}
        onSubmit={handleSubmit}
        loading={loading}
        titleId={titleId}
        cancel={handleCancel}
        isPurchase={isPurchase}
        setIsRemoveConciliationModalOpen={setIsRemoveConciliationModalOpen}
      />

      <Modal
        dialogClassName="bill-to-receive-save-modal"
        show={isSaveModalOpen}
        size="sm"
        onHide={() => setIsSaveModalOpen(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>O.S Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span>Como você quer salvar esse título ?</span>
            <div>
              <Button
                bsStyle="info"
                fill
                onClick={() => handleConfirmModal(constants.BILLS_STATUS.OPEN)}
              >
                Salvar Título em Aberto
              </Button>
              <Button
                bsStyle="primary"
                fill
                onClick={() =>
                  handleConfirmModal(constants.BILLS_STATUS.CLOSED)
                }
              >
                Finalizar Título
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={isRemoveConciliationModalOpen}
        onHide={() => setIsRemoveConciliationModalOpen(false)}
        dialogClassName="modal-40w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Remover Conciliação</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <strong style={{ fontSize: '14px' }}>
              Você tem certeza que deseja remover a conciliação?
            </strong>
            <p style={{ fontSize: '14px' }}>
              O processo é irreversível e ao remover a conciliação, o vínculo
              será removido. Sendo obrigatório refazer novamente.
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end gap-050">
            <button
              className="button button-h35 button-red"
              onClick={() => setIsRemoveConciliationModalOpen(false)}
            >
              Não
            </button>
            <button
              className="button button-h35 button-green"
              onClick={handleRemoveBankConciliation}
            >
              Remover Conciliação
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isCancelModalOpen}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        onSubmit={() => history.push(constants.ROUTES.BILLS_TO_PAY)}
        message="Deseja realmente sair do título? Está ação não salvará qualquer alteração realizada"
      />

      <AlertModal
        show={isCancelBillModalOpen}
        onHide={() => setIsCancelBillModalOpen(false)}
        onCancel={() => setIsCancelBillModalOpen(false)}
        onSubmit={() => handleConfirmModal(constants.BILLS_STATUS.CANCELED)}
        message="Deseja cancelar o título? Esta ação não salvará as informações apresentadas na tela"
      />
    </>
  );
}

BundleFormBillToPay = reduxForm({
  form: 'billToPay',
  enableReinitialize: true,
})(BundleFormBillToPay);
function mapStateToProps(state) {
  return { billToPay: state.form.billToPay?.values };
}

export default connect(mapStateToProps)(withRouter(BundleFormBillToPay));
