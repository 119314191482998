import React, { useState } from 'react';
import useOnboardingStore from '../store/onboarding-store';

import { Modal } from 'react-bootstrap';
import { InputTextArea } from 'v2/components/Input';

export const FeedbackModal = ({ handleClose, handleReload }) => {
  const {
    isFeedbackModalOpen,
    setIsFeedbackModalOpen,
    companyName,
    emojiSelected,
    setEmojiSelected,
    feedbackObservation,
    setFeedbackObservation,
    handleValidateFinishedSave,
    isLoading,
  } = useOnboardingStore();
  const emojis = [
    {
      description: 'Desengajado',
      icon: '☹️',
    },
    {
      description: 'Neutro',
      icon: '😐',
    },
    {
      description: 'Engajado',
      icon: '😄',
    },
  ];

  const getButtonStyles = (description) => {
    return {
      fontWeight: description === emojiSelected ? 'bold' : 'regular',
      opacity:
        emojiSelected && emojiSelected !== '' && emojiSelected === description
          ? 1
          : 0.5,
    };
  };

  return (
    <Modal show={isFeedbackModalOpen} onHide={null} dialogClassName="modal-40w">
      <Modal.Header style={{ padding: '5px 10px' }}>
        <Modal.Title>
          <strong>Onboarding</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex column">
          <strong>Razão Social/Nome: {companyName}</strong>
          <span>
            Informe o engajamento do cliente após a conclusão do onboarding:
          </span>
          <div className="flex row evenly">
            {emojis.map((emoji) => (
              <button
                className="button button-icon flex column center radius-4"
                onClick={() => setEmojiSelected(emoji.description)}
                disabled={isLoading}
                style={{
                  ...getButtonStyles(emoji.description),
                  width: '95px',
                }}
              >
                <div
                  className="flex center"
                  style={{
                    fontSize: '45px',
                  }}
                >
                  {emoji.icon}
                </div>
                <>{emoji.description}</>
              </button>
            ))}
          </div>
          <InputTextArea
            className="mt-05"
            height="120px"
            disabled={isLoading}
            value={feedbackObservation}
            onChange={(e) => setFeedbackObservation(e.target.value)}
            placeholder="Insira aqui as informações que entenda como motivadoras para sua escolha"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end row gap-050">
          <button
            disabled={isLoading}
            className="button button-red"
            onClick={() => setIsFeedbackModalOpen(false)}
          >
            Voltar
          </button>
          <button
            className="button button-green"
            disabled={isLoading}
            onClick={async () => {
              if (await handleValidateFinishedSave()) {
                setIsFeedbackModalOpen(false);
                handleReload();
                handleClose();
              }
            }}
          >
            Finalizar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
