import api from '../services/api';

const getCustomers = async (companyId, period) => {
  const response = await api.get(`ranking-indicator/customer/${companyId}`, {
    params: { period },
  });

  return response.data;
};

const getVehicles = async (companyId, period) => {
  const response = await api.get(
    `ranking-indicator/vehicle-brand/${companyId}`,
    { params: { period } }
  );

  return response.data;
};

const getServiceProduct = async (companyId, period) => {
  const response = await api.get(
    `ranking-indicator/products-services/${companyId}`,
    { params: { period } }
  );

  return response.data;
};
const getSalesRanking = async (companyId, period) => {
  const response = await api.get(
    `ranking-indicator/seller-mechanic/${companyId}`,
    { params: { period } }
  );

  return response.data;
};

const RankingIndicatorRepository = {
  getCustomers,
  getVehicles,
  getServiceProduct,
  getSalesRanking,
};
export default RankingIndicatorRepository;
