import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';

import carImg from 'assets/img/checklist-images/carro.png';
import motoImg from 'assets/img/checklist-images/moto.png';
import pesadoImg from 'assets/img/checklist-images/pesado.png';
import vanImg from 'assets/img/checklist-images/van.png';

import { customChecklistSheetStyles } from '../../CustomChecklistSheet.styles';
import { FuelGaugeImage } from '../FuelGaugeImage/FuelGaugeImage';

export function CustomChecklistDraw({ checklist }) {
  switch (checklist.defaultDesign) {
    case 'Carros':
      return (
        <View style={{ flexDirection: 'row' }}>
          <View style={customChecklistSheetStyles.detailsLeft}>
            <Image src={carImg} style={customChecklistSheetStyles.carImg} />
            <Text style={{ fontWeight: 'bold', paddingTop: 2, paddingLeft: 5 }}>
              M = Manchado, R = Riscado, A = Amassado, Q = Quebrado, F =
              Faltando
            </Text>
          </View>

          <FuelGaugeImage />
        </View>
      );
    case 'Pesados':
      return (
        <View style={customChecklistSheetStyles.imgContainer}>
          <Image src={pesadoImg} />
          <Text
            style={[
              customChecklistSheetStyles.bold,
              { marginTop: 5, textAlign: 'center' },
            ]}
          >
            M = Manchado, R = Riscado, A = Amassado, Q = Quebrado, F = Faltando
          </Text>
        </View>
      );
    case 'Van':
      return (
        <View style={{ flexDirection: 'row' }}>
          <View
            style={[
              customChecklistSheetStyles.detailsLeft,
              { marginTop: '10px' },
            ]}
          >
            <Image src={vanImg} style={customChecklistSheetStyles.vanImg} />
            <Text
              style={{
                fontWeight: 'bold',
                paddingTop: 2,
                paddingLeft: 5,
                textAlign: 'center',
              }}
            >
              M = Manchado, R = Riscado, A = Amassado, Q = Quebrado, F =
              Faltando
            </Text>
          </View>
          <FuelGaugeImage />
        </View>
      );
    case 'Motos':
      return (
        <View
          style={[
            customChecklistSheetStyles.imgContainer,
            { marginTop: '10px' },
          ]}
        >
          <Image src={motoImg} />
          <Text
            style={[
              customChecklistSheetStyles.bold,
              { marginTop: 5, textAlign: 'center', width: '100%' },
            ]}
          >
            M = Manchado, R = Riscado, A = Amassado, Q = Quebrado, F = Faltando
          </Text>
        </View>
      );
    default:
      return <View />;
  }
}
