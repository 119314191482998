import React from 'react';
import { RankingIndicatorsNavigator } from './RankingIndicatorsNavigator';
import useManagementIndicatorsStore from '../../store/management-indicators-store';
export function RankingIndicatorsHeader() {
  return (
    <div>
      <div>
        <p style={{ color: '#1D7391', fontSize: '20px' }}>{`RANKINGS 🏆`}</p>
      </div>
      <RankingIndicatorsNavigator />
    </div>
  );
}
