import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';

import NFCesRepository from '../../../repositories/NFCes';
import NFesRepository from '../../../repositories/NFes';
import SATCFesRepository from '../../../repositories/SATCFe';
import settingsNFeRepository from '../../../repositories/SettingsNFe';
import useFilters from '../../../hooks/useFilters';
import { currency, encrypt } from '../../components/ToNormalize/ToNormalize';
import constants from '../../../utils/constants';
import { useAuth } from '../../../contexts/auth';
import { useModulesContext } from '../../../contexts/modules';

import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import DisableInvoiceModal from './DisableInvoiceModal';
import NFeActions from './NFeActions';
import NFCeActions from './NFCeActions';
import SATCFeActions from './SATCFeActions';
import UnavailableFeatureModal from '../../components/UnavailableFeatureModal';

import './styles.css';
import FiscalModuleInactiveModal from 'client/components/FiscalModuleInactiveModal';
import SearchInput from 'components/SearchInput';
import { InputContainer } from 'v2/components/Input';
import { SelectMulti } from 'v2/components/Select/Multi';
import { INVOICE_STATUS_OPTIONS } from 'v2/utils/constants';
import { InputLabel } from 'v2/components/Input';

const HomeInvoices = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [invoiceEnvironment, setInvoiceEnvironment] = useState('');
  const [showDisableInvoiceModal, setShowDisableInvoiceModal] = useState(false);
  const [blockTax, setBlockTax] = useState(false);
  const [isNFeSet, setIsNFeSet] = useState(false);
  const [dateFilterType, setDateFilterType] = useState('createdAt');
  const [isModuleInactiveModalOpen, setIsModuleInactiveModalOpen] =
    useState(false);

  const { companyId } = useAuth();
  const { queryFilter, dateFilter, typeFilter, statusFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter;
  const { type, setType, filterByType } = typeFilter;
  const { setStatus, filterByStatus } = statusFilter;

  const { nfeModule, isNFeModuleActive } = useModulesContext();

  const [isInitialFiltersDefined, setIsInitialFiltersDefined] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);

  useEffect(() => {
    if (!companyId) {
      setNoCompanyModal(true);
    } else {
      loadInvoices();
      checkNFeSettings(companyId);
    }
  }, []);

  const loadInvoices = async () => {
    setLoading(true);

    try {
      const NFCes = await NFCesRepository.getAllByCompany(companyId);
      const serializedNFCes = NFCes.map((NFCe) => ({
        ...NFCe,
        type: 'NFC-e',
        clientName: NFCe.Customer?.Company_Name || '',
      }));

      const NFes = await NFesRepository.getAllByCompany(companyId);
      const serializedNFes = NFes.map((NFe) => ({
        ...NFe,
        type: 'NF-e',
        clientName:
          NFe.Customer?.Company_Name || NFe.Provider?.companyName || '',
        totalValueProducts: NFe.total,
      }));

      const SATCFes = await SATCFesRepository.getAllByCompany(companyId);
      const serializedSATCFes = SATCFes.map((SATCFe) => ({
        ...SATCFe,
        type: 'SAT',
        clientName: SATCFe.SATCustomer?.Company_Name || '',
        totalValueProducts: SATCFe.total,
      }));
      setInvoices([
        ...serializedNFCes,
        ...serializedNFes,
        ...serializedSATCFes,
      ]);
    } catch (err) {
      console.error(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar as notas. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const renderBtnStatus = (value) => {
    let color = '';
    switch (value) {
      case 'Aberta':
        color = '#ffcc00';
        break;
      case 'Emitida':
        color = '#5cb85c';
        break;
      case 'Inutilizada':
        color = '#555555';
        break;
      case 'Cancelada':
        color = '#d9534f';
        break;
      case 'Contingência':
        color = '#7a43b6';
        break;
      case 'Aberta c/ Erro':
        color = '#ffcc00';
        break;
      case 'Denegada':
        color = '#000000';
        break;
      default:
        break;
    }

    return (
      <Button
        id="botaoReactTable"
        style={{
          backgroundColor: color,
          height: '25px',
          fontSize: '12px',
        }}
      >
        {value}
      </Button>
    );
  };

  function onSuccessDisableInvoiceModal() {
    setShowDisableInvoiceModal(false);
    loadInvoices();
  }

  function handleFilters(invoice) {
    const searchQuery = [
      invoice['clientName'],
      invoice['code'],
      invoice['totalValueProducts'].toLocaleString('pt-BR'),
    ];
    return (
      filterByQuery(searchQuery) &&
      filterByStatus('status', invoice) &&
      filterByDate(
        dateFilterType === 'createdAt' ? 'createdAt' : 'issuedAt',
        invoice
      ) &&
      filterByType('type', invoice) &&
      filterByEnvironment(invoice)
    );
  }

  function filterByEnvironment(invoice) {
    if (!invoiceEnvironment) return true;

    return invoice['environment'] === invoiceEnvironment;
  }

  function handleEditInvoice(invoice) {
    if (invoice.status.includes('Inutilizada')) {
      return toastr.warning('Numeração inutilizada não possui nota');
    }

    if (invoice.type === 'NFC-e') {
      handleEditNFCe(invoice);
    } else if (invoice.type === 'NF-e') {
      handleEditNFe(invoice);
    } else {
      handleEditSATCFe(invoice);
    }
  }

  async function handleEditNFCe(NFCe) {
    const hash = await encrypt(String(NFCe.id), '@OS-dig:NfceId');
    history.push(`${constants.ROUTES.EDIT_NFCE}?id=${hash}`);
  }

  async function handleEditNFe(NFe) {
    const hash = await encrypt(String(NFe.id), '@OS-dig:NfeId');
    history.push(`${constants.ROUTES.EDIT_NFE}?id=${hash}`);
  }

  async function handleEditSATCFe(SATCFe) {
    const hash = await encrypt(String(SATCFe.id), '@OS-dig:SATCFeId');
    history.push(`${constants.ROUTES.EDIT_SATCFE}?id=${hash}`);
  }

  async function checkNFeSettings() {
    try {
      const { data } = await settingsNFeRepository.search({
        companyId,
      });

      setIsNFeSet(data.isNFeModuleSet);
    } catch (err) {
      console.error(err);
      setIsNFeSet(false);
    }
  }

  function handleNewInvoiceButton() {
    if (!isNFeModuleActive) {
      return setIsModuleInactiveModalOpen(true);
    }

    if (!isNFeSet)
      return toastr.error(
        'Falha ao gerar NF-e',
        'Realize a configuração da NF-e em "Minha Empresa > Dados Fiscais" para emissão da nota.'
      );

    if (!!companyId) {
      history.push(constants.ROUTES.NFE);
    } else {
      setBlockTax(true);
    }
  }

  function handleSetInitialFilters() {
    const params = new URLSearchParams(window.location.search);
    const statusParam = params.get('status');

    const initialDateParam = params.get('initialDate');
    const finalDateParam = params.get('finalDate');

    if (statusParam) {
      const status = statusParam.split(',');
      const statusToSelect = INVOICE_STATUS_OPTIONS.filter((option) =>
        status.includes(option.value)
      );

      setSelectedStatus(statusToSelect);
    }

    if (initialDateParam) {
      setInitialDate(initialDateParam);
    }

    if (finalDateParam) {
      setFinalDate(finalDateParam);
    }

    setIsInitialFiltersDefined(true);
  }

  const fiscalTagsColors = {
    'NFC-e': '#FFA834',
    'NF-e': '#0844A4',
    SAT: '#5BC0DE',
  };

  useEffect(() => {
    if (!isInitialFiltersDefined) handleSetInitialFilters();
  }, [isInitialFiltersDefined]);

  useEffect(() => {
    setStatus(selectedStatus.map((status) => status.value));
  }, [selectedStatus]);
  async function handleDisableFlow() {
    !!companyId ? setShowDisableInvoiceModal(true) : setBlockTax(true);
  }

  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={handleNewInvoiceButton}
                style={{ fontSize: '12px' }}
              >
                + Nova Nota Fiscal
              </button>
            </div>

            <div>
              <SearchInput
                placeholder="Pesquisa por NF-e, NFC-e, SAT, Cliente e Valor"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span style={{ fontSize: '12px' }}>
                <strong>Tipo: </strong>
              </span>
              <select
                className="form-control foco-input"
                value={type}
                onChange={(e) => setType(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Todos</option>
                <option value="NF-e">NF-e</option>
                <option value="NFC-e">NFC-e</option>
                <option value="SAT">SAT</option>
              </select>
            </div>
            <InputContainer variant="column">
              <InputLabel
                style={{
                  fontSize: '12px',
                  margin: 0,
                  padding: 0,
                  fontWeight: 'bold',
                  position: 'relative',
                  top: '5px',
                }}
              >
                Status:
              </InputLabel>
              <SelectMulti
                width={130}
                height={35}
                options={INVOICE_STATUS_OPTIONS}
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e)}
                hasAllOption
                selectAllByDefault
                fontSize={'12px'}
                borderColor="#E3E3E3"
              />
            </InputContainer>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span style={{ fontSize: '12px' }}>
                <strong>Ambiente: </strong>
              </span>
              <select
                className="form-control foco-input"
                value={invoiceEnvironment}
                onChange={(e) => setInvoiceEnvironment(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="Produção">Produção</option>
                <option value="Homologação">Homologação</option>
              </select>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              marginBottom: '10px',
              padding: 0,
              gap: '10px',
            }}
          >
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Tipo de Data:</strong>
              </span>
              <select
                className="form-control foco-input"
                type="date"
                name="dateFilterType"
                value={dateFilterType}
                onChange={(e) => setDateFilterType(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="createdAt">Criação</option>
                <option value="issuedAt">Emissão</option>
              </select>
            </div>

            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Inicial:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="dataInicial"
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
                style={{ fontSize: '12px' }}
              />
            </div>

            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Final:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="dataFinal"
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
                style={{ fontSize: '12px' }}
              />
            </div>
            <div>
              <button
                className="btn btn-next"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '35px',
                  marginTop: '15px',
                  fontSize: '12px',
                }}
                onClick={() => handleDisableFlow()}
              >
                Inutilizar Numeração
              </button>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={invoices.filter(handleFilters)}
              columns={[
                {
                  Header: 'Criação',
                  accessor: 'createdAt',
                  width: 80,
                  Cell: (props) => (
                    <>
                      {props.value
                        ? new Date(props.value).toLocaleDateString()
                        : ''}
                    </>
                  ),
                },
                {
                  Header: 'Emissão',
                  accessor: 'issuedAt',
                  width: 80,
                  Cell: (props) => (
                    <>
                      {props.value
                        ? new Date(props.value).toLocaleDateString()
                        : ''}
                    </>
                  ),
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  width: 80,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor: fiscalTagsColors[props.value],
                        height: '25px',
                        fontSize: '12px',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Nota',
                  accessor: 'code',
                  width: 80,
                  Cell: (props) => (
                    <a onClick={() => handleEditInvoice(props.original)}>
                      <button className="btn-link">{props.value}</button>
                    </a>
                  ),
                },
                {
                  Header: 'Venda',
                  accessor: 'saleCode',
                  width: 80,
                  Cell: (props) => (
                    <span>
                      {props.value
                        ? props.value
                        : props.original.hasManySales === 1
                        ? 'Varias'
                        : ''}
                    </span>
                  ),
                },
                {
                  Header: 'Série',
                  accessor: 'serie',
                  width: 80,
                },
                {
                  Header: 'Cliente',
                  accessor: 'clientName',
                  headerClassName: 'text-center',
                  className: 'texto',
                },
                {
                  Header: 'Valor',
                  accessor: 'totalValueProducts',
                  width: 120,
                  Cell: (props) => <>{currency(props.value)}</>,
                },
                {
                  Header: 'Ambiente',
                  accessor: 'environment',
                  width: 100,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor:
                          props.value === 'Homologação' ? '#1c0c4f' : 'b70f0a',
                        height: '25px',
                        fontSize: '12px',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  width: 100,
                  Cell: (props) => renderBtnStatus(props.value),
                },
                {
                  Header: 'Ações',
                  accessor: 'type',
                  width: 150,
                  Cell: (props) =>
                    props.value === 'NF-e' ? (
                      <NFeActions
                        NFe={props.original}
                        onChange={loadInvoices}
                        onEdit={handleEditNFe}
                      />
                    ) : props.value === 'SAT' ? (
                      <SATCFeActions
                        SATCFe={props.original}
                        onChange={loadInvoices}
                        onEdit={handleEditSATCFe}
                      />
                    ) : (
                      <NFCeActions
                        NFCe={props.original}
                        onChange={loadInvoices}
                        onEdit={handleEditNFCe}
                      />
                    ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'createdAt',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhuma nota fiscal encontrada"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>

          <DisableInvoiceModal
            show={showDisableInvoiceModal}
            onCancel={() => setShowDisableInvoiceModal(false)}
            onHide={() => setShowDisableInvoiceModal(false)}
            onSuccess={onSuccessDisableInvoiceModal}
          />

          <UnavailableFeatureModal
            show={blockTax}
            onHide={() => setBlockTax(false)}
          />

          <FiscalModuleInactiveModal
            show={isModuleInactiveModalOpen}
            onHide={() => setIsModuleInactiveModalOpen(false)}
            module={nfeModule}
          />
        </div>
      }
    />
  );
};

export default withRouter(HomeInvoices);
