import { Container } from './ManagementIndicatorButton.styles.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPieChart } from '@fortawesome/free-solid-svg-icons';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import React, { useState } from 'react';
import UnavailableFeatureModal from 'client/components/UnavailableFeatureModal';
import { useAuth } from 'contexts/auth';

export function ManagementIndicatorButton() {
  const { isPlanStart, isPlanFree, isPlanBasic, isPlanWorkmotorFree } =
    usePlanSignatureContext();

  const { userPermissions } = useAuth();
  const [isOpenModalBlockOpen, setIsOpenModalBlockOpen] = useState(false);

  const switchModalBlock = () => {
    setIsOpenModalBlockOpen(!isOpenModalBlockOpen);
  };

  const verifyBrand = () => {
    const managementIndicatorPermission = userPermissions.subFeatures.find(
      (permission) => permission.description === 'Indicadores Gerenciais'
    );
    if (
      isPlanStart ||
      isPlanFree ||
      isPlanBasic ||
      isPlanWorkmotorFree ||
      !managementIndicatorPermission ||
      managementIndicatorPermission.status === false
    )
      return switchModalBlock();

    return window.open(
      window.location.origin + `/management-indicators`,
      '_blank'
    );
  };

  return (
    <Container onClick={verifyBrand}>
      <FontAwesomeIcon icon={faPieChart} fontSize={18} cursor="pointer" />
      <span style={{ whiteSpace: 'nowrap' }}>Indicadores Gerenciais</span>
      <UnavailableFeatureModal
        show={isOpenModalBlockOpen}
        onHide={switchModalBlock}
      />
    </Container>
  );
}
