import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { Controller, useWatch } from 'react-hook-form';

import AlertModal from 'components/AlertModal/AlertModal';
import CardForm from 'client/components/CardForm';
import FormSubmitButtons from 'client/components/FormSubmitButtons';

import ValidationModal from './ValidationModal';
import { NewsRegisterImage } from './NewsRegisterImage';

import './styles.css';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { SelectMultiBranding } from 'admin/v2/components/Input/Select/SelectMultiBranding';
import { isValidUrl } from 'client/components/ToNormalize/ToNormalize';

function NewsRegisterForm({
  onSubmit,
  newsId,
  register,
  getValues,
  watch,
  handleSubmit,
  isDirty,
  onCancel,
  control,
  setValue,
  setImageFile,
  imageFile,
  imageUrl,
  isLoading,
}) {
  const [basicData, setBasicData] = useState(true);
  const [cancelModal, setCancelModal] = useState(false);
  const [validationModal, setValidationModal] = useState(false);

  const history = useHistory();

  const link = useWatch({
    control,
    name: 'url',
  });

  const validationUrl = () => {
    const { url } = getValues();

    if (isValidUrl(url)) {
      setValidationModal(true);
    } else {
      toastr.warning('Insira um link válido para notícia e tente novamente');
    }
  };

  const submitValidationUrl = (submitValues) => {
    const { url } = getValues();
    if (isValidUrl(url)) {
      submitValues();
    } else {
      toastr.warning('Insira um link válido para notícia e tente novamente');
    }
  };

  return (
    <div>
      <div style={{ marginTop: 30 }}>
        <form style={{ display: 'flex', flexDirection: 'column' }}>
          <CardForm
            show={basicData}
            title="Dados da Notícia"
            onClick={() => setBasicData(!basicData)}
          >
            <div className="flex column gap-1">
              <div className="flex gap-1 w-80">
                <InputContainer>
                  <InputLabel required>Produto:</InputLabel>
                  <Controller
                    control={control}
                    name="brandingId"
                    render={({ field }) => (
                      <SelectMultiBranding
                        height={'35px'}
                        showAllOption
                        selectAllByDefault
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        {...field}
                      />
                    )}
                  />
                </InputContainer>
                <InputContainer className="w-100">
                  <InputLabel required>Descrição:</InputLabel>
                  <input
                    type="text"
                    {...register('description')}
                    className="form-control"
                    name="description"
                    id="description"
                    maxLength="50"
                    disabled={isLoading}
                    loading={isLoading}
                  />
                </InputContainer>
                <InputContainer>
                  <InputLabel>Vigência Inicial:</InputLabel>
                  <input
                    className="form-control foco-input"
                    type="date"
                    {...register('initialDurationDate')}
                    name="initialDurationDate"
                    id="initialDurationDate"
                    max={'9999-12-31'}
                    disabled={isLoading}
                    loading={isLoading}
                  />
                </InputContainer>

                <InputContainer>
                  <InputLabel>Vigência Final:</InputLabel>
                  <input
                    className="form-control foco-input"
                    type="date"
                    {...register('finalDurationDate')}
                    name="finalDurationDate"
                    id="finalDurationDate"
                    max={'9999-12-31'}
                    disabled={isLoading}
                    loading={isLoading}
                  />
                </InputContainer>
              </div>
              <div className="flex start">
                <InputContainer className="w-80">
                  <InputLabel required>Link de Redirecionamento:</InputLabel>
                  <input
                    type="text"
                    {...register('url')}
                    className="form-control"
                    name="url"
                    id="url"
                    disabled={isLoading}
                    loading={isLoading}
                  />
                  <a
                    className="link"
                    onClick={validationUrl}
                    disabled={isLoading}
                    loading={isLoading}
                  >
                    Validar Link
                  </a>
                </InputContainer>
                <InputContainer />
              </div>
              <div className="flex column gap-1">
                <div className="flex gap-1">
                  <InputLabel required>Imagem:</InputLabel>
                  <InputLabel>(Tamanho Máx.: 690x350px)</InputLabel>
                </div>
                <NewsRegisterImage
                  setValue={setValue}
                  imageFile={imageFile}
                  setImageFile={setImageFile}
                  watch={watch}
                  disabled={isLoading}
                />
              </div>
            </div>
          </CardForm>

          {validationModal && (
            <ValidationModal
              validationModal={validationModal}
              setValidationModal={setValidationModal}
              link={link}
            />
          )}

          <FormSubmitButtons
            onCancel={() => {
              if (isDirty) return setCancelModal(true);
              history.push('/admin/news');
            }}
            backLabel={newsId}
            hasId={newsId}
            loading={isLoading}
            onSubmit={() => submitValidationUrl(handleSubmit(onSubmit))}
          />

          {cancelModal && (
            <AlertModal
              show={cancelModal}
              onHide={() => setCancelModal(false)}
              onCancel={() => setCancelModal(false)}
              subtitle={
                !newsId
                  ? 'Você tem certeza que deseja cancelar o cadastro de notícia?'
                  : null
              }
              message={
                !newsId
                  ? `Ao cancelar o cadastro da News, você não conseguirá publicar a mesma. Você poderá cadastrar novamente mais tarde.`
                  : `Deseja cancelar a edição? Nenhuma alteração será salva.`
              }
              onSubmit={onCancel}
            />
          )}
        </form>
      </div>
    </div>
  );
}

export default NewsRegisterForm;
