import { createGlobalStyle } from 'styled-components';

export const CalendarStyles = createGlobalStyle`
  .fc {
    border-radius: 8px;
    overflow: hidden;
  }
  .fc .fc-daygrid-day-top {
    flex-direction: inherit;
  }

  .fc-event {
    border: none !important;
    background: none !important;
    border-radius: 4px;
    cursor: pointer !important;

  }

  .fc-event-main {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
  }

  stroke .fc-daygrid-event-harness {
    margin-bottom: 4px !important;
  }

  .fc .fc-daygrid-day.fc-day-today{
    background-color: #EFF6FF !important;
  }
  .fc-event,
  .fc-event:focus,
  .fc-event:active,
  .fc-event-selected {
    background: none !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }
`;
