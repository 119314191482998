import React from 'react';
import { useHistory } from 'react-router-dom';
import constants from 'utils/constants';

export const KanbanNewScheduling = ({ name }) => {
  const history = useHistory();

  return (
    <div
      id="newScheduling"
      className="div-a-modal w-100 flex center justify-center p-0"
    >
      {name !== 'Finalizado' && (
        <a
          onClick={() => {
            history.push(
              `${constants.ROUTES.NEW_SCHEDULING}?kanban=true&statusColumn=${name}`
            );
          }}
          className="underline pointer text-green typography__text-2"
        >
          Novo Agendamento
        </a>
      )}
    </div>
  );
};
