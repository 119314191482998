import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import suivCompanyRepository from 'repositories/SuivCompany';

import Select from 'react-select';
import CollapsibleSection from 'components/CollapsibleSection';

import '../../styles.css';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import CustomButton from 'client/components/CustomButton/CustomButton';
import { toastr } from 'react-redux-toastr';

export default function SuivCompanyFormMain({ suivCompany }) {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [notIntegratedCompaniesOptions, setNotIntegratedCompaniesOptions] =
    useState([]);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (suivCompany) {
      setSelectedCompany({
        label: `${cpfOrCnpjMask(suivCompany.cpfCNPJ)} - ${suivCompany.name}`,
        value: suivCompany,
      });
    }
    loadNotIntegratedCompanies();
  }, []);

  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
  };

  const loadNotIntegratedCompanies = async () => {
    try {
      const data = await suivCompanyRepository.getNotIntegratedCompanies();

      setNotIntegratedCompaniesOptions(
        data.map((company) => ({
          label: `${cpfOrCnpjMask(company.cpfCNPJ)} - ${company.name}`,
          value: company,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateToken = async () => {
    validateProccess();
    setLoading(true);
    try {
      await suivCompanyRepository.vinculateCompany({
        companyId: selectedCompany.value.id,
        email: selectedCompany.value.email,
      });

      toastr.success('Conta SUIV criada', 'Empresa cadastrada com sucesso');
      history.push('/admin/suiv');
    } catch (error) {
      console.log(error);
      return toastr.error(
        'Conta SUIV não foi criada',
        `${error.response.data.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  const validateProccess = () => {
    if (!selectedCompany) {
      return toastr.warning(
        'Não foi possível fazer o cadastro',
        'Selecione uma empresa e tente novamente'
      );
    }

    if (selectedCompany.value.email === null) {
      return toastr.warning(
        'Não foi possível fazer o cadastro',
        'A empresa selecionada não possui email cadastrado'
      );
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <form style={{ display: 'flex', flexDirection: 'column' }}>
        <CollapsibleSection title="Dados da Empresa" isExpanded={true}>
          <div className="suiv-register__row">
            <Select
              placeholder={'Pesquisar por CPF/CNPJ ou Nome/Razão Social'}
              filterOption={(company, input) => {
                return (
                  company.data.value?.cpfCnpj?.includes(input) ||
                  company.data.value?.name?.includes(input) ||
                  company.data.value?.email?.includes(input)
                );
              }}
              options={notIntegratedCompaniesOptions}
              value={selectedCompany}
              isDisabled={!!suivCompany}
              loadingMessage={() => 'Carregando...'}
              components={{
                DropdownIndicator: Icon,
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '30px',
                  minHeight: '40px',
                  width: '600px',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (base) => ({
                  ...base,
                  height: '40px',
                  padding: '0 0 0 10px',
                  marginTop: '-4px',
                  left: '15px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-4px',
                }),
              }}
              onChange={(e) => {
                handleSelectCompany(e);
              }}
              noOptionsMessage={() => 'Nenhuma empresa encontrada'}
            />
          </div>
          <div className="suiv-register__row">
            <div
              style={{
                width: '23%',
              }}
            >
              <label>CPF/CNPJ:</label>
              <input
                type="text"
                className="form-control"
                name="description"
                id="description"
                value={selectedCompany?.value?.cpfCNPJ}
                disabled
              />
            </div>
            <div
              style={{
                width: '33%',
              }}
            >
              <label>Nome/Razão Social:</label>
              <input
                type="text"
                className="form-control"
                name="description"
                id="description"
                value={selectedCompany?.value?.name}
                disabled
              />
            </div>
            <div
              style={{
                width: '33%',
              }}
            >
              <label>
                Email:<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="description"
                id="description"
                disabled={selectedCompany?.value?.isIntegrated}
                value={selectedCompany?.value?.email}
                onChange={(e) => {
                  setSelectedCompany({
                    ...selectedCompany,
                    value: {
                      ...selectedCompany.value,
                      email: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="suiv-register__row">
            <div
              style={{
                width: '100%',
              }}
            >
              <label>
                Status:<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="description"
                id="description"
                value={
                  selectedCompany && selectedCompany?.value?.isIntegrated
                    ? 'Integrada'
                    : 'Não Integrada'
                }
                disabled
              />
            </div>
          </div>
        </CollapsibleSection>

        <div style={{ marginTop: '10px', marginLeft: 'auto' }}>
          <CustomButton
            style={{ padding: '5px', fontWeight: 'normal', fontSize: '15px' }}
            onClick={() => history.push('/admin/suiv')}
            type="button"
            bsStyle="danger"
            fill
          >
            Cancelar
          </CustomButton>
          <CustomButton
            style={{
              marginLeft: '5px',
              padding: '5px',
              fontWeight: 'normal',
              fontSize: '15px',
              background: '#5cb85c',
              borderColor: '#5cb85c',
            }}
            onClick={() => handleCreateToken()}
            type="button"
            bsStyle="success"
            disabled={selectedCompany?.value?.isIntegrated}
            fill
          >
            Criar Token
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          </CustomButton>
        </div>
      </form>
    </div>
  );
}

const Icon = () => (
  <i
    id="iconepesquisar"
    style={{ cursor: 'pointer', marginLeft: '-600px', marginBottom: '2px' }}
    className="fa fa-search"
  />
);
