import React, { useState, useEffect } from 'react';
import { change, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTable from 'react-table';

import { currency } from '../../../../client/components/ToNormalize/ToNormalize';
import { onlyNumbers } from '../../../../client/components/ToNormalize/ToNormalize';
import { currencyMask } from '../../../../utils/masks';

import Button from '../../../../client/components/CustomButton/CustomButton.jsx';
import RenderField from '../../../../components/RenderField';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import ItemsModal from './ItemsModal';

const Items = ({ loading }) => {
  const [isItemsModalOpen, setIsItemsModalOpen] = useState(false);
  const [productIndex, setProductIndex] = useState(null);
  const [isRemoveItemModalOpen, setIsRemoveItemModalOpen] = useState(false);

  const dispatch = useDispatch();
  const {
    subTotal,
    servicesTotal,
    saleId,
    ISS,
    NFSeItems,
    discountValue,
    isInvoiceIssued,
    isInvoiceCanceled,
    isInvoiceProcessing,
  } = useSelector((state) => state.form.NFSe.values);

  useEffect(() => {
    const subTotal = !NFSeItems.length
      ? 0
      : NFSeItems.length === 1
      ? NFSeItems[0].quantity * NFSeItems[0].unitValue
      : NFSeItems.map((item) => item.quantity * item.unitValue).reduce(
          (prev, curr) => prev + curr
        );

    dispatch([
      change('NFSe', 'subTotal', subTotal),
      change('NFSe', 'servicesTotal', subTotal - discountValue),
    ]);
  }, [NFSeItems, discountValue]);

  useEffect(() => {
    dispatch(change('NFSe', 'ISSValue', servicesTotal * (ISS / 100)));
  }, [servicesTotal, ISS]);

  function handleAppendItems(items) {
    const newNFSeItems = [...NFSeItems];

    for (var item of items) {
      const index = newNFSeItems.findIndex(
        (child) => child.serviceId === item.serviceId
      );

      if (index !== -1) {
        newNFSeItems[index].quantity++;
      } else {
        newNFSeItems.push(item);
      }
    }

    dispatch(change('NFSe', 'NFSeItems', newNFSeItems));
    setIsItemsModalOpen(false);
  }

  function handleRemoveItem() {
    setIsRemoveItemModalOpen(false);
    const newNFeItems = [...NFSeItems].filter((_, i) => i !== productIndex);

    dispatch(change('NFSe', 'NFSeItems', newNFeItems));
  }

  function handleChangeQuantity(value, index) {
    const newNFSeItems = [...NFSeItems];
    newNFSeItems[index].quantity = value;
    dispatch(change('NFSe', 'NFSeItems', newNFSeItems));

    setTimeout(() => {
      document.getElementById(`NFSeItems[${index}].quantity`).focus();
    }, 5);
  }

  function handleOpenRemoveItemModal(index) {
    setProductIndex(index);
    setIsRemoveItemModalOpen(true);
  }

  return (
    <div id="NFSe-items">
      <Button
        type="button"
        className="btn btn-sucesso"
        bsStyle="info"
        fill
        onClick={() => setIsItemsModalOpen(true)}
        disabled={
          !!saleId ||
          isInvoiceIssued ||
          isInvoiceCanceled ||
          isInvoiceProcessing
        }
      >
        + Adicionar Serviço
      </Button>

      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: '10px',
        }}
        data={NFSeItems}
        columns={[
          {
            Header: 'Código',
            accessor: 'code',
            headerClassName: 'text-left',
            width: 70,
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            headerClassName: 'text-left',
          },
          {
            Header: 'QTD',
            accessor: 'quantity',
            headerClassName: 'text-left',
            width: 70,
            Cell: (props) => (
              <Field
                id={`NFSeItems[${props.index}].quantity`}
                name={`NFSeItems[${props.index}].quantity`}
                component={RenderField}
                normalize={onlyNumbers}
                disabled={
                  !!saleId ||
                  isInvoiceIssued ||
                  isInvoiceCanceled ||
                  isInvoiceProcessing
                }
                min={0}
                onChange={(e) =>
                  handleChangeQuantity(e.target.value, props.index)
                }
              />
            ),
          },
          {
            Header: 'Preço Unit',
            accessor: 'unitValue',
            headerClassName: 'text-left',
            width: 150,
            Cell: (props) => (
              <Field
                id={`NFSeItems[${props.index}].unitValue`}
                name={`NFSeItems[${props.index}].unitValue`}
                component={RenderField}
                {...currencyMask}
                disabled={
                  !!saleId ||
                  isInvoiceIssued ||
                  isInvoiceCanceled ||
                  isInvoiceProcessing
                }
                onChange={() =>
                  setTimeout(
                    () =>
                      document
                        .getElementById(`NFSeItems[${props.index}].unitValue`)
                        .focus(),
                    5
                  )
                }
              />
            ),
          },
          {
            Header: '',
            accessor: 'id',
            headerClassName: 'text-left',
            className: 'NFSe-items-table-actions',
            filterable: false,
            width: 100,
            Cell: (props) => (
              <FontAwesomeIcon
                icon={faTrashAlt}
                color="red"
                style={{ height: '1.5em', width: '1.5em' }}
                onClick={() =>
                  !saleId &&
                  !isInvoiceIssued &&
                  !isInvoiceCanceled &&
                  handleOpenRemoveItemModal(props.index)
                }
              />
            ),
          },
        ]}
        defaultPageSize={5}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        defaultSorted={[
          {
            id: 'createdAt',
            desc: true,
          },
        ]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText='Clique em "+ Adicionar Serviço" para adicionar itens na NFS-e'
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
      <div className="NFSe-items-footer">
        <div>
          <strong>Subtotal: </strong>
          <span>{currency(subTotal)}</span>
        </div>
        <div>
          <strong>Desconto: </strong>
          <Field
            name="discountValue"
            component={RenderField}
            {...currencyMask}
            disabled={
              isInvoiceIssued || isInvoiceCanceled || isInvoiceProcessing
            }
          />
        </div>
        <div>
          <strong>Valor Total dos Serviços: </strong>{' '}
          <span>{currency(servicesTotal)}</span>
        </div>
      </div>

      <AlertModal
        show={isRemoveItemModalOpen}
        animation={false}
        message="Deseja excluir o serviço da nota ?"
        onHide={() => setIsRemoveItemModalOpen(false)}
        onCancel={() => setIsRemoveItemModalOpen(false)}
        onSubmit={handleRemoveItem}
      />

      {isItemsModalOpen && (
        <ItemsModal
          onCancel={() => setIsItemsModalOpen(false)}
          onSubmit={handleAppendItems}
        />
      )}
    </div>
  );
};

export default Items;
