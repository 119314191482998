import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faEquals } from '@fortawesome/free-solid-svg-icons';
import { useFinanceCalendarStore } from '../store/useFinanceCalendarStore';
import { currency } from 'client/components/ToNormalize/ToNormalize';
export function FinanceCalendarSummary() {
  const { summary } = useFinanceCalendarStore((store) => {
    return {
      summary: store.summary,
    };
  });

  const sumResult = summary.totalReceive - summary.totalPay || 0;

  return (
    <div style={styles.container}>
      <span style={{ textAlign: 'center', fontSize: '14px' }}>
        Saldo do período
      </span>
      <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '.5rem',
          }}
        >
          <div style={styles.summaryBox}>
            <FontAwesomeIcon
              style={{ ...styles.icon, color: '#4CAE4C' }}
              icon={faPlus}
            />
            <span>Total a Receber:</span>
            <span>{currency(summary.totalReceive || 0)}</span>
          </div>
          <div style={styles.summaryBox}>
            <FontAwesomeIcon
              style={{ ...styles.icon, color: '#F85F6A' }}
              icon={faMinus}
            />
            <span>Total a Pagar:</span>
            <span>{currency(summary.totalPay || 0)}</span>
          </div>
        </div>
        <div style={{ borderBottom: '1px solid #000' }} />
        <div style={{ ...styles.summaryBox }}>
          <FontAwesomeIcon
            style={{ ...styles.icon, color: '#444444' }}
            icon={faEquals}
          />
          <span>Saldo do período:</span>
          <span>{currency(sumResult)}</span>
        </div>
      </div>
      <span style={styles.alert}>
        ** Os valores a receber podem ser alterados caso possua juros acumulado
      </span>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  summaryBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '.5rem',
    alignItems: 'center',
    fontSize: '12px',
  },
  icon: {
    width: '12px',
    height: '12px',
  },
  alert: {
    color: '#D9534F',
    fontSize: '12px',
    textAlign: 'center',
  },
};
