import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import CustomButton from 'client/components/CustomButton/CustomButton';
import AlertModal from 'components/AlertModal/AlertModal';
import MaintenanceRepository from 'repositories/Maintenance';
import { useWorkshopPanel } from 'contexts/workshopPanel';
import SharePanelAccessWithClientModal from './SharePanelAccessWithClient';

export default function StartServiceModal({ onCancel, maintenance }) {
  const { employees: contextEmployees } = useWorkshopPanel();

  const [loading, setLoading] = useState(false);
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [allEmployeesSelected, setAllEmployeesSelected] = useState(false);
  const [startServiceConfirmationModal, setStartServiceConfirmationModal] =
    useState(false);

  const [sharePanelAccessWithClientModal, setSharePanelAccessWithClientModal] =
    useState(false);

  const { setReloadPanelInformation } = useWorkshopPanel();

  const { control, watch, register } = useForm({
    defaultValues: {
      employees: [],
    },
  });

  const {
    fields: employeesFields,
    append: employeesAppend,
    replace: employeesReplace,
  } = useFieldArray({
    control,
    name: 'employees',
  });

  const handleSelectAllEmployees = () => {
    const selectedEmployees = employeesFields.map((field) => ({
      ...field,
      selected: true,
    }));

    employeesReplace(selectedEmployees);
  };

  function handleUnselectAllEmployees() {
    const unselectedEmployees = employeesFields.map((field) => ({
      ...field,
      selected: false,
    }));

    employeesReplace(unselectedEmployees);
  }

  const watchedEmployees = watch('employees');

  async function handleSetEmployeesOptions() {
    try {
      setLoading(true);

      const serializedEmployees = contextEmployees
        .filter((employee) => employee.show)
        .map((item) => ({
          id: item.id,
          name: item.name,
          employeeCommission: item.comissionRate,
          roleCommission: item.Position?.serviceComission,
          selected: false,
        }));

      serializedEmployees.forEach((item) => {
        employeesAppend({
          employeeId: item.id,
          selected: false,
          employeeCommission: item.employeeCommission,
          roleCommission: item.roleCommission,
        });
      });

      setEmployeesOptions(serializedEmployees);
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Erro ao carregar funcionários',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const allEmployeesLength = employeesOptions.length;
    if (allEmployeesLength > 0) {
      const selectedEmployeesLength = watchedEmployees.filter(
        (item) => item.selected
      ).length;

      if (selectedEmployeesLength === allEmployeesLength) {
        setAllEmployeesSelected(true);
      } else {
        setAllEmployeesSelected(false);
      }
    }
  });

  const handleStartService = async () => {
    setLoading(true);
    const employees = watchedEmployees.map((item) => {
      if (item.selected) {
        return item.employeeId;
      }
      return null;
    });

    const formatedEmployees = employees.filter((item) => item);

    try {
      const data = {
        status: 'Em Andamento',
        startDate: new Date(),
        employees: formatedEmployees,
      };
      await MaintenanceRepository.updateMaintenance(maintenance?.id, data);
      setLoading(false);
      toastr.success('Serviço iniciado!');

      setSharePanelAccessWithClientModal(true);
      setStartServiceConfirmationModal(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toastr.error(
        'Erro ao iniciar serviço!',
        'Tente novamente. Caso persista, contate o suporte!'
      );
      setReloadPanelInformation(true);
    }
  };

  const handleCancelShareServiceWithClient = () => {
    setSharePanelAccessWithClientModal(false);
    setReloadPanelInformation(true);
  };

  useEffect(() => {
    handleSetEmployeesOptions();
  }, [contextEmployees]);

  return (
    <>
      <Modal show={true} dialogClassName="modal-30w" animation>
        <Modal.Header>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>OS Nº: </strong>
          {maintenance?.Sales?.code} - {maintenance?.Sales?.Customer?.name}
          <br />
          <strong>Veículo: </strong>
          {maintenance?.Vehicle?.License_Plate} - {maintenance?.Vehicle.Model}
          <hr />
          <span>Selecione os mecânicos que irão executar a OS:</span>
          <div>
            <div style={{ textAlign: 'right' }}>
              <a
                style={{
                  textDecoration: 'underline',
                  textAlign: 'right',
                  color: '#176CF7',
                  cursor: 'pointer',
                  fontSize: '12px',
                }}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (!allEmployeesSelected) {
                    handleSelectAllEmployees();
                  } else {
                    handleUnselectAllEmployees();
                  }
                }}
              >
                {!allEmployeesSelected ? 'Selecionar ' : 'Remover '} todos
              </a>
            </div>
            <ReactTable
              data={employeesOptions}
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                height: '203px',
                fontSize: '14px',
                width: '100%',
                overflow: 'auto',
              }}
              columns={[
                {
                  Header: 'Nome',
                  accessor: 'name',
                  className: 'texto',
                  resizable: false,
                },
                {
                  Header: ' ',
                  accessor: 'id',
                  className: 'texto',
                  resizable: false,
                  width: 70,
                  Cell: (props) => {
                    return (
                      <>
                        <input
                          type="checkbox"
                          id={employeesFields[props.index].serviceId}
                          className="checkbox-input bulk-deletion-table"
                          {...register(`employees.${props.index}.selected`)}
                        />
                      </>
                    );
                  },
                },
              ]}
              showPagination={false}
              noDataText="Nenhum mecânico encontrado"
              loading={loading}
              minRows={4}
              loadingText="Carregando..."
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <CustomButton
            bsStyle="danger"
            style={{
              fontSize: '16px',
            }}
            name="voltar"
            onClick={onCancel}
            fill
          >
            Voltar
          </CustomButton>
          <CustomButton
            style={{
              background: 'rgb(2, 177, 2)',
              borderColor: 'rgb(2, 177, 2)',
              fontSize: '16px',
            }}
            name="iniciar"
            onClick={() => setStartServiceConfirmationModal(true)}
            fill
            disabled={
              watchedEmployees?.filter((employee) => employee.selected)
                .length === 0
                ? true
                : false
            }
          >
            Iniciar
          </CustomButton>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={startServiceConfirmationModal}
        onHide={() => setStartServiceConfirmationModal(false)}
        onCancel={() => setStartServiceConfirmationModal(false)}
        onSubmit={() => handleStartService()}
        title="Painel da Oficina:"
        onSubmitLabel="Iniciar"
        onCancelLabel="Voltar"
        loading={loading}
        message={
          <>
            <span>
              <strong>Você tem certeza que deseja iniciar o serviço?</strong>
            </span>
            <br />
            <br />
            <span>
              Ao iniciar o serviço, todos os mecânicos selecionados serão
              apontados para esse serviço e não poderão ser apontados em outra
              OS até finalizar ou suspender.
            </span>
          </>
        }
      />

      {sharePanelAccessWithClientModal && (
        <SharePanelAccessWithClientModal
          show={sharePanelAccessWithClientModal}
          onCancel={handleCancelShareServiceWithClient}
          onSubmit={() => console.log(maintenance)}
          maintenance={maintenance}
        />
      )}
    </>
  );
}
