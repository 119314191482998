import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';

const Header = () => {
  return (
    <View style={{ paddingTop: 10 }}>
      <View
        style={{
          flexDirection: 'row',
          height: 12,
          fontWeight: 'bold',
          width: '100%',
        }}
      >
        <View
          style={{ width: '21%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.text}>Item</Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>Estoq. Atual</Text>
        </View>
        <View
          style={{
            width: '7%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>Mín.</Text>
        </View>
        <View
          style={{
            width: '7%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>Máx.</Text>
        </View>
        <View
          style={{
            width: '25%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>Qtd. Venda (Últ. semana)</Text>
        </View>
        <View
          style={{
            width: '25%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>Qtd. Venda (Últ. 30 dias)</Text>
        </View>
      </View>

      <View style={[styles.line, { marginTop: 1 }]} />
    </View>
  );
};

const PurchaseSuggestionData = ({ product }) => {
  return (
    <View style={{ paddingTop: 2 }}>
      <View style={{ flexDirection: 'row', height: 12, width: '100%' }}>
        <View
          style={{ width: '21%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.content}>{product.description}</Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.content}>{product.stockQuantity}</Text>
        </View>
        <View
          style={{ width: '7%', textAlign: 'center', justifyContent: 'center' }}
        >
          <Text style={styles.content}>{product.minStock}</Text>
        </View>
        <View
          style={{ width: '7%', textAlign: 'center', justifyContent: 'center' }}
        >
          <Text style={styles.content}>{product.maxStock}</Text>
        </View>
        <View
          style={{
            width: '25%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.debitsText}>{product.weekSales}</Text>
        </View>

        <View
          style={{
            width: '25%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.content}>{product.monthSales}</Text>
        </View>
      </View>
    </View>
  );
};
const PurchaseSuggestion = ({ products }) => {
  return (
    <Document
      title={`sugestão-de-compra-do-período-${format(
        new Date(),
        'dd/MM/yyyy'
      )}`}
      subject="sugestão-de-compra"
    >
      <Page style={styles.page}>
        <View>
          <Text style={styles.title}>Sugestão de Compra</Text>
          <Text style={styles.text}>
            Sugestão gerada em:
            {format(new Date(), 'dd/MM/yyyy HH:mm')}
          </Text>
        </View>
        <View style={styles.line}></View>
        <Header />
        {products.map((product) => (
          <PurchaseSuggestionData key={product.id} product={product} />
        ))}
        <View style={styles.line}></View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    marginBottom: '5px',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  text: {
    fontSize: 10,
  },
  titleTexts: {
    fontWeight: 'bold',
  },
  header: {
    fontSize: 12,
    padding: 5,
    backgroundColor: '#c0c0c0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 10,
    paddingLeft: 10,
  },
});

export default PurchaseSuggestion;
