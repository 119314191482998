import { formatToDate } from 'brazilian-values';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { useBonus } from 'hooks/useBonus';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

export const CRMBonusSelectCampaignModal = ({
  isOpen,
  handleClose,
  customer,
  sale,
  setSelectedCampaign,
  handleCampaignAvailable,
  handleOpenCampaignConfirmationModal,
  handleInitCRMBonus,
  crmBonusCurrentSaleValue,
}) => {
  const {
    Company_Name: customerName,
    Email: customerEmail,
    Date_Birth: customerDateOfBirth,
  } = customer;
  const customerCell = customer?.Phones?.length
    ? customer.Phones[0].Number_Phone2
    : '';

  const { triggerSavePDV, campaigns } = useBonus();

  const [selectedCampaignInput, setSelectedCampaignInput] = useState('');
  const [hasCampaign, setHasCampaign] = useState(false);

  const handleChangeCampaign = (e) => {
    setSelectedCampaignInput(e.target.value);
    setSelectedCampaign(campaigns.find((item) => +item.id === +e.target.value));
  };

  const handleUseCampaign = () => {
    handleOpenCampaignConfirmationModal();
  };

  useEffect(
    () => {
      handleInitCRMBonus(customer?.id ? customer : sale?.selectedClient);
      if (
        (customer && !hasCampaign && sale?.total > 0) ||
        +crmBonusCurrentSaleValue > 0
      ) {
        handleCampaignAvailable(
          customer?.id ? customer : sale?.selectedClient,
          { total: sale?.total > 0 ? sale?.total : +crmBonusCurrentSaleValue }
        );
        setHasCampaign(true);
      }
    },
    [
      /* customer, sale */
    ]
  );

  return (
    <Modal.Dialog open={isOpen}>
      <Modal.Header>
        <Modal.Title>
          <strong>CRM Bônus</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex column gap-025">
          <span>
            <strong>Nome: </strong>
            {customerName ? `${customerName}` : 'Nome'}
          </span>
          <span>
            <strong>Email: </strong>
            {customerEmail ? `${customerEmail}` : 'email@email.com'}
          </span>
          <span>
            <strong>Celular: </strong>
            {customerCell ? `${phoneMask(customerCell)}` : '(99) 9-9999-9999'}
          </span>
          <span>
            <strong>Nascimento: </strong>
            {customerDateOfBirth
              ? `${formatToDate(new Date(customerDateOfBirth))}`
              : 'dd/mm/aaaa'}
          </span>
        </div>

        <hr />

        <div className="flex column gap-025">
          <p>
            <strong>Selecione a campanha que será utilizada:</strong>
          </p>

          <div className="flex column gap-025">
            {campaigns.map((campaign) => (
              <div key={campaign.id} className="flex align-center gap-050">
                <input
                  type="radio"
                  id={`campaign-${campaign.id}`}
                  name="selected_campaign"
                  value={campaign.id}
                  onChange={handleChangeCampaign}
                />
                <label htmlFor={`campaign-${campaign.id}`}>
                  <span>{campaign.name} - </span>
                  <span>{campaign.description}</span>
                </label>
              </div>
            ))}
          </div>

          <span
            className="text-red text-center mt-2"
            style={{ fontSize: '12px' }}
          >
            Caso a campanha não apareça, verifique as campanhas ativas no painel
            da CRM Bônus e clique no botão atualizar
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end gap-050">
          <button className="button button-red w-75px" onClick={handleClose}>
            Voltar
          </button>
          <button
            className="button button-yellow"
            onClick={() => handleCampaignAvailable(customer, sale?.total)}
          >
            Atualizar
          </button>
          <button
            disabled={triggerSavePDV === true}
            className="button button-green"
            onClick={handleUseCampaign}
          >
            Utilizar Campanha
          </button>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  );
};
