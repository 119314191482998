import styled from 'styled-components';
import { currentBranding } from 'v2/helpers/brandingHelpers';

const getHoverColor = () => {
  switch (currentBranding) {
    case 'OSD':
      return 'midnightGreen';
    case 'WM':
      return 'japaneseIndigo200';
    default:
      break;
  }
};

const getContainerTopValueByItemTitle = (subMenuTitle, subMenuDegreeTitle) => {
  switch (true) {
    case subMenuTitle === 'Relatórios' && subMenuDegreeTitle === 'Vendas':
      return 'top: -300px;';
    case subMenuTitle === 'Relatórios' && subMenuDegreeTitle === 'Financeiro':
      return 'top: -160px;';
    default:
      break;
  }
};

export const SidebarSubMenuContainerWithDegree = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: 10px;
  scrollbar-color: white;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.branding1000};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.gray100};
    border-radius: 5px;
  }

  display: none;
  cursor: default;
  min-width: 240px;
  width: max-content;
  padding: 5px;
  position: absolute;
  left: 235px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.branding1000};
  border-radius: ${(props) => props.theme.radii.sm};

  z-index: 2000 !important;

  ${({ subMenuTitle, subMenuDegreeTitle }) =>
    getContainerTopValueByItemTitle(subMenuTitle, subMenuDegreeTitle)}
`;

export const SidebarSubMenuItemsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 0px;

  & li {
    width: 100%;
  }
`;

export const SidebarSubMenuItem = styled.div`
  background-color: transparent;
  border: 0;
  font-size: 14px;

  text-align: left;
  width: 100%;
  height: 30px;
  padding: 0 15px;
  color: ${(props) => props.theme.colors.white};

  display: flex;
  align-items: center;

  border-radius: ${(props) => props.theme.radii.sm};

  &:hover {
    background-color: ${(props) => props.theme.colors[getHoverColor()]};
    cursor: pointer;
  }

  ${(props) =>
    props.disabledStyle
      ? `
     color: ${props.theme.colors.gray100};
  `
      : ''}
`;
