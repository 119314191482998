import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';
import companyModulesRepository from 'repositories/CompanyModules';
import format from 'date-fns/format';

import './styles.css';

const ModulesModal = ({ client, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [loadingModuleData, setLoadingModuleData] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);

  const [nfeModuleData, setNfeModuleData] = useState();
  const [nfceModuleData, setNfceModuleData] = useState();
  const [nfseModuleData, setNfseModuleData] = useState();
  const [workShopPanelModuleData, setWorkShopPanelModuleData] = useState();
  const [triggeringIndicatorsModuleData, setTriggeringIndicatorsModuleData] =
    useState();
  const [crmModule, setCrmModule] = useState();
  const [videoModuleData, setVideoModuleData] = useState();
  const [ticketIssuanceModuleData, setTicketIssuanceModuleData] = useState();

  const [nfeInitialStatus, setNfeInitialStatus] = useState();
  const [nfceInitialStatus, setNfceInitialStatus] = useState();
  const [nfseInitialStatus, setNfseInitialStatus] = useState();
  const [workShopPanelInitialStatus, setWorkShopPanelInitialStatus] =
    useState();
  const [
    triggeringIndicatorsInitialStatus,
    setTriggeringIndicatorsInitialStatus,
  ] = useState();
  const [crmModuleInitialStatus, setCrmModuleInitialStatus] = useState();
  const [videoModuleInitialStatus, setVideoModuleInitialStatus] = useState();
  const [ticketIssuanceInitialStatus, setTicketIssuanceInitialStatus] =
    useState();

  const [ticketIssuanceInitialQuantity, setTicketIssuanceInitialQuantity] =
    useState();

  const [changedModules, setChangedModules] = useState([]);

  useEffect(() => {
    loadFiscalModulesData(client.id);
  }, []);

  const loadFiscalModulesData = async (companyId) => {
    try {
      const { data: companyModules } =
        await companyModulesRepository.getCompanyModules({
          companyId,
        });

      // moduleId: 1 - NFe, 2 - NFCe, 3 - NFSe, 4 - Painel da Oficina, 5 - Emissão de Boleto, 6 - Disparo de Indicadores
      // status: 0 - Não Verificado, 1 - Não Disponível, 2 - Inativo, 3 - Ativo

      const moduleDataSetters = {
        1: setNfeModuleData,
        2: setNfceModuleData,
        3: setNfseModuleData,
        4: setWorkShopPanelModuleData,
        5: setTicketIssuanceModuleData,
        6: setTriggeringIndicatorsModuleData,
        7: setCrmModule,
        8: setVideoModuleData,
      };

      const initialStatusSetters = {
        1: setNfeInitialStatus,
        2: setNfceInitialStatus,
        3: setNfseInitialStatus,
        4: setWorkShopPanelInitialStatus,
        5: setTicketIssuanceInitialStatus,
        6: setTriggeringIndicatorsInitialStatus,
        7: setCrmModuleInitialStatus,
        8: setVideoModuleInitialStatus,
      };

      const initialQuantitySetters = {
        5: setTicketIssuanceInitialQuantity,
      };

      companyModules.forEach((module) => {
        const setModuleData = moduleDataSetters[module.moduleId];
        const setInitialStatus = initialStatusSetters[module.moduleId];
        const setInitialQuantity = initialQuantitySetters[module.moduleId];

        setModuleData(module);
        setInitialStatus(module.status);

        if (module.quantity) {
          setInitialQuantity(module.quantity);
        }
      });
    } catch (err) {
      console.log(err);
      toastr.error('Houve um erro ao carregar os módulos.');
    } finally {
      setLoadingModuleData(false);
    }
  };

  useEffect(() => {
    getChangedModules();
  }, [
    nfeModuleData,
    nfceModuleData,
    nfseModuleData,
    workShopPanelModuleData,
    triggeringIndicatorsModuleData,
    ticketIssuanceModuleData,
    crmModule,
    videoModuleData,
  ]);

  const updateModuleStatus = async () => {
    setLoading(true);

    try {
      changedModules.forEach(async (module) => {
        await companyModulesRepository.updateModuleStatus(
          client.id,
          module.moduleId,
          module.status,
          null,
          module.quantity
        );
      });

      toastr.success('Alterações salvas', 'Módulos atualizados com sucesso');
      onCancel();
    } catch (err) {
      console.log(err);
      toastr.error('Houve um erro ao atualizar o status.');
    } finally {
      setLoading(false);
    }
  };

  const getChangedModules = () => {
    if (loadingModuleData) return;

    let toChangeModules = [];

    if (nfeModuleData?.status != nfeInitialStatus) {
      toChangeModules.push(nfeModuleData);
    }

    if (nfceModuleData?.status != nfceInitialStatus) {
      toChangeModules.push(nfceModuleData);
    }

    if (nfseModuleData?.status != nfseInitialStatus) {
      toChangeModules.push(nfseModuleData);
    }

    if (workShopPanelModuleData?.status != workShopPanelInitialStatus) {
      toChangeModules.push(workShopPanelModuleData);
    }

    if (
      triggeringIndicatorsModuleData?.status !=
      triggeringIndicatorsInitialStatus
    ) {
      toChangeModules.push(triggeringIndicatorsModuleData);
    }
    if (crmModule?.status != crmModuleInitialStatus) {
      toChangeModules.push(crmModule);
    }
    if (videoModuleData?.status != videoModuleInitialStatus) {
      toChangeModules.push(videoModuleData);
    }

    if (
      ticketIssuanceModuleData?.status != ticketIssuanceInitialStatus ||
      ticketIssuanceModuleData?.quantity != ticketIssuanceInitialQuantity
    ) {
      // Caso mude a quantidade e depois inative o módulo, simplesmente irá ignorar a alteração de quantidade
      if (ticketIssuanceModuleData?.status == 2) {
        toChangeModules.push({
          ...ticketIssuanceModuleData,
          quantity: ticketIssuanceInitialQuantity,
        });
      } else {
        toChangeModules.push(ticketIssuanceModuleData);
      }
    }

    return setChangedModules(toChangeModules);
  };

  const validateInformations = () => {
    if (
      ticketIssuanceModuleData?.status == 3 &&
      ticketIssuanceModuleData?.quantity < 1
    ) {
      return toastr.warning(
        'Insira uma quantidade de boletos disponíveis para emissão para salvar as alterações.'
      );
    }

    setConfirmModal(true);
  };

  const formatDate = (date) => {
    if (date) {
      return format(new Date(date), 'dd/MM/yyyy');
    } else {
      return '---';
    }
  };

  const isPlanIdAllowedToEnableIndicators = (planId) => {
    const allowedIds = [
      2, 6, 7, 9, 11, 14, 16, 19, 21, 24, 25, 27, 28, 30, 31, 33,
    ];

    return allowedIds.includes(planId);
  };

  return (
    <div>
      <Modal show animation onHide={onCancel} className="custom-modal-width">
        <Modal.Header closeButton>
          <Modal.Title>
            <strong> Módulos Contratados </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modules-container">
            <div className="modules-header">
              <span className="module-desc">Módulo</span>
              <span className="status">Status</span>
              <span className="date">Data de ativação</span>
              <span className="date">Data de bloqueio</span>
            </div>
            {!loadingModuleData && (
              <>
                <div className="modules-body">
                  {/* NF-e */}
                  <div>
                    <span className="module-desc">
                      Nota Fiscal Eletrônica (<strong>NF-e</strong>):
                    </span>
                    <select
                      onChange={(e) => {
                        e.persist();

                        setNfeModuleData((nfeModuleData) => ({
                          ...nfeModuleData,
                          status: e.target.value,
                        }));
                      }}
                      className="form-control foco-input status"
                      defaultValue={nfeInitialStatus}
                    >
                      <option value={3}>Ativo</option>
                      <option value={2}>Inativo</option>
                    </select>
                    <input
                      className="form-control foco-input date"
                      name="activationDate"
                      defaultValue={formatDate(nfeModuleData?.activationDate)}
                      disabled
                    />
                    <input
                      className="form-control foco-input date"
                      name="inactivationDate"
                      defaultValue={formatDate(nfeModuleData?.inactivationDate)}
                      disabled
                    />
                  </div>

                  {/* NFC-e */}
                  <div>
                    <span className="module-desc">
                      Nota Fiscal do Consumidor (<strong>NFC-e</strong>):
                    </span>
                    <select
                      onChange={(e) => {
                        e.persist();

                        setNfceModuleData((nfceModuleData) => ({
                          ...nfceModuleData,
                          status: e.target.value,
                        }));
                      }}
                      className="form-control foco-input status"
                      defaultValue={nfceInitialStatus}
                    >
                      <option value={3}>Ativo</option>
                      <option value={2}>Inativo</option>
                    </select>
                    <input
                      className="form-control foco-input date"
                      name="activationDate"
                      defaultValue={formatDate(nfceModuleData?.activationDate)}
                      disabled
                    />
                    <input
                      className="form-control foco-input date"
                      name="inactivationDate"
                      defaultValue={formatDate(
                        nfceModuleData?.inactivationDate
                      )}
                      disabled
                    />
                  </div>

                  {/* NFS-e */}
                  <div>
                    <span className="module-desc">
                      Nota Fiscal de Serviço (<strong>NFS-e</strong>):
                    </span>
                    <select
                      onChange={(e) => {
                        e.persist();

                        setNfseModuleData((nfseModuleData) => ({
                          ...nfseModuleData,
                          status: e.target.value,
                        }));
                      }}
                      className="form-control foco-input status"
                      defaultValue={nfseInitialStatus === 3 ? 3 : 2}
                    >
                      <option value={3}>Ativo</option>
                      <option value={2}>Inativo</option>
                    </select>
                    <input
                      className="form-control foco-input date"
                      name="activationDate"
                      defaultValue={formatDate(nfseModuleData?.activationDate)}
                      disabled
                    />
                    <input
                      className="form-control foco-input date"
                      name="inactivationDate"
                      defaultValue={formatDate(
                        nfseModuleData?.inactivationDate
                      )}
                      disabled
                    />
                  </div>

                  {/* Aplicativo */}
                  <div>
                    <span className="module-desc">Painel da Oficina:</span>
                    <select
                      onChange={(e) => {
                        e.persist();

                        setWorkShopPanelModuleData(
                          (workShopPanelModuleData) => ({
                            ...workShopPanelModuleData,
                            status: e.target.value,
                          })
                        );
                      }}
                      className="form-control foco-input status"
                      defaultValue={workShopPanelInitialStatus}
                    >
                      <option value={3}>Ativo</option>
                      <option value={2}>Inativo</option>
                    </select>
                    <input
                      className="form-control foco-input date"
                      name="activationDate"
                      defaultValue={formatDate(
                        workShopPanelModuleData?.activationDate
                      )}
                      disabled
                    />
                    <input
                      className="form-control foco-input date"
                      name="inactivationDate"
                      defaultValue={formatDate(
                        workShopPanelModuleData?.inactivationDate
                      )}
                      disabled
                    />
                  </div>

                  <div>
                    <span className="module-desc">Disparo de Indicadores:</span>
                    <select
                      onChange={(e) => {
                        e.persist();

                        setTriggeringIndicatorsModuleData(
                          (triggeringIndicatorsModuleData) => ({
                            ...triggeringIndicatorsModuleData,
                            status: e.target.value,
                          })
                        );
                      }}
                      className="form-control foco-input status"
                      defaultValue={triggeringIndicatorsInitialStatus}
                      disabled={
                        !isPlanIdAllowedToEnableIndicators(
                          client?.lastSignature?.Plans?.id
                        )
                      }
                    >
                      <option value={3}>Ativo</option>
                      <option value={2}>Inativo</option>
                    </select>
                    <input
                      className="form-control foco-input date"
                      name="activationDate"
                      defaultValue={formatDate(
                        triggeringIndicatorsModuleData?.activationDate
                      )}
                      disabled
                    />
                    <input
                      className="form-control foco-input date"
                      name="inactivationDate"
                      defaultValue={formatDate(
                        triggeringIndicatorsModuleData?.inactivationDate
                      )}
                      disabled
                    />
                  </div>
                  <div>
                    <span className="module-desc">CRM:</span>
                    <select
                      onChange={(e) => {
                        e.persist();

                        setCrmModule((crmModule) => ({
                          ...crmModule,
                          status: e.target.value,
                        }));
                      }}
                      className="form-control foco-input status"
                      defaultValue={crmModuleInitialStatus}
                      disabled={
                        !isPlanIdAllowedToEnableIndicators(
                          client?.lastSignature?.Plans?.id
                        )
                      }
                    >
                      <option value={2}>Inativo</option>
                      <option value={3}>Ativo</option>
                    </select>
                    <input
                      className="form-control foco-input date"
                      name="activationDate"
                      defaultValue={formatDate(crmModule?.activationDate)}
                      disabled
                    />
                    <input
                      className="form-control foco-input date"
                      name="inactivationDate"
                      defaultValue={formatDate(crmModule?.inactivationDate)}
                      disabled
                    />
                  </div>
                  <div>
                    <span className="module-desc">Importação de Vídeo:</span>
                    <select
                      onChange={(e) => {
                        e.persist();

                        setVideoModuleData((videoModuleData) => ({
                          ...videoModuleData,
                          status: e.target.value,
                        }));
                      }}
                      className="form-control foco-input status"
                      defaultValue={videoModuleInitialStatus}
                      disabled={
                        !isPlanIdAllowedToEnableIndicators(
                          client?.lastSignature?.Plans?.id
                        )
                      }
                    >
                      <option value={2}>Inativo</option>
                      <option value={3}>Ativo</option>
                    </select>
                    <input
                      className="form-control foco-input date"
                      name="activationDate"
                      defaultValue={formatDate(videoModuleData?.activationDate)}
                      disabled
                    />
                    <input
                      className="form-control foco-input date"
                      name="inactivationDate"
                      defaultValue={formatDate(
                        videoModuleData?.inactivationDate
                      )}
                      disabled
                    />
                  </div>
                </div>

                <div className="line" />

                {/* Emissão de boleto */}
                <div className="modules-body">
                  <div className="modules-header">
                    <span className="module-desc">Módulo</span>
                    <span className="status">Status</span>
                    <span className="date">Data de ativação</span>
                    <span className="quantity-available">
                      Quantidade Disponível
                    </span>
                  </div>

                  <div>
                    <span className="module-desc">Emissão de Boleto:</span>
                    <select
                      onChange={(e) => {
                        e.persist();

                        setTicketIssuanceModuleData((ticketModuleData) => ({
                          ...ticketModuleData,
                          status: e.target.value,
                        }));
                      }}
                      className="form-control foco-input status"
                      defaultValue={ticketIssuanceInitialStatus}
                    >
                      <option value={2}>Inativo</option>
                      <option value={3}>Ativo</option>
                    </select>
                    <input
                      className="form-control foco-input date"
                      name="activationDate"
                      defaultValue={formatDate(
                        ticketIssuanceModuleData?.activationDate
                      )}
                      disabled
                    />
                    <input
                      type="number"
                      className="form-control foco-input quantity-available"
                      name="quantity"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        e.persist();

                        setTicketIssuanceModuleData((ticketModuleData) => ({
                          ...ticketModuleData,
                          quantity: Math.trunc(Number(e.target.value)),
                        }));
                      }}
                      disabled={ticketIssuanceModuleData?.status != 3}
                      defaultValue={ticketIssuanceModuleData?.quantity}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              gap: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <button
              className="button button-red button-h35 "
              disabled={loading}
              name="Voltar"
              onClick={onCancel}
              fill
            >
              Voltar
            </button>

            <button
              className="button button-green button-h35"
              disabled={!changedModules.length}
              name="Salvar"
              fill
              onClick={() => validateInformations()}
            >
              Salvar{' '}
              <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        title="OS Digital"
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        onCancel={() => setConfirmModal(false)}
        message="Deseja salvar as alterações realizadas acima? Ao ativar e/ou inativar um módulo,
           o resultado será exibido ao cliente na próxima atualização de tela do cliente. Você poderá
           ativar e/ou inativar um módulo novamente mais tarde."
        onSubmit={updateModuleStatus}
        loading={loading}
      />
    </div>
  );
};

export default ModulesModal;
