import React from 'react';
import CardForm from 'client/components/CardForm';
import SearchInput from 'components/SearchInput';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ReactTable from 'react-table';
import CurrencyInput from 'react-currency-input';
import { Controller } from 'react-hook-form';

const NewKitItems = ({
  data,
  control,
  setQuery,
  setIsAddItemModalOpen,
  handleDeleteItem,
  loading,
  totalItemsValue,
  totalKitValue,
  isItemsOpen,
  setIsItemsOpen,
  onChangeKitPrice,
}) => {
  return (
    <CardForm
      show={isItemsOpen}
      title="Itens do Kit (Obrigatório)"
      onClick={() => setIsItemsOpen(!isItemsOpen)}
    >
      <div className="new-promotional-price__items-header">
        <div className="new-promotional-price__row">
          <div className="new-promotional-price__button">
            <button
              className="btn btn-sucesso"
              onClick={() => setIsAddItemModalOpen(true)}
            >
              Adicionar Item
            </button>
          </div>

          <div
            style={{ width: '40rem' }}
            className="new-promotional-price__button"
          >
            <SearchInput
              placeholder="Pesquisa por Código, Ref. Fabricante ou Descrição"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>
      </div>

      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          marginTop: 20,
        }}
        data={data}
        columns={[
          {
            Header: 'Código',
            accessor: 'Code',
            width: Math.round((window.innerWidth - 55) * 0.07),
          },
          {
            Header: 'Ref. Fabricante',
            accessor: 'Manufacturer_Ref',
            width: Math.round((window.innerWidth - 55) * 0.1),
          },
          {
            Header: 'Descrição',
            accessor: 'Description',
          },
          {
            Header: 'Marca',
            accessor: 'Brand',
            width: Math.round((window.innerWidth - 55) * 0.1),
          },
          {
            Header: 'Família',
            accessor: 'Family',
            width: Math.round((window.innerWidth - 55) * 0.1),
          },
          {
            Header: 'Preço de Venda',
            accessor: 'Unit_Value',
            width: Math.round((window.innerWidth - 55) * 0.1),
            Cell: (props) => <a>{currency(props.value)}</a>,
          },
          {
            Header: 'Preço Kit',
            accessor: 'kitPrice',
            width: Math.round((window.innerWidth - 55) * 0.08),
            Cell: (props) => {
              return (
                <Controller
                  name={`kitItemsTable[${props.index}].kitPrice`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <CurrencyInput
                        prefix="R$ "
                        id={`kitItemsTable[${props.index}].kitPrice`}
                        decimalSeparator=","
                        thousandSeparator="."
                        value={props.value}
                        className="form-control foco-input"
                        {...field}
                        onChange={(e, value) => {
                          field.onChange(value);
                          onChangeKitPrice(props.index, value);
                        }}
                      />
                    );
                  }}
                />
              );
            },
          },
          {
            Header: '',
            accessor: 'id',
            width: Math.round((window.innerWidth - 55) * 0.04),
            Cell: (props) => (
              <FontAwesomeIcon
                title="Excluir"
                cursor="pointer"
                style={{
                  height: '1.5em',
                  width: '1.5em',
                  color: 'red',
                }}
                icon={faTrashAlt}
                onClick={() => handleDeleteItem(props.index)}
              />
            ),
          },
        ]}
        defaultPageSize={10}
        pageSize={10}
        manual
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        sortable={false}
        loading={loading}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={false}
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          fontSize: '16px',
          gap: '10px',
        }}
      >
        <span>
          <strong>Total dos Itens:</strong>
          {currency(totalItemsValue)}
        </span>

        <span>
          <strong>Valor do Kit:</strong>
          <span
            style={{
              color:
                totalKitValue > totalItemsValue
                  ? '#d9534f'
                  : totalKitValue < totalItemsValue
                  ? '#5cb85c'
                  : '#000',
            }}
          >
            {currency(totalKitValue)}
          </span>
        </span>
      </div>
    </CardForm>
  );
};

export default NewKitItems;
