import React, { useState } from 'react';
import Button from 'client/components/CustomButton/Button';
import { format } from 'date-fns';
import ReactTable from 'react-table';
import { faPrint, faEdit } from '@fortawesome/free-solid-svg-icons';
import InventoryCountPrintModal from './InventoryCountPrintModal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InventoryCountTable = ({
  history,
  loading,
  inventoryCountList,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  return (
    <div>
      <div>
        <ReactTable
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            width: '90%',
          }}
          data={inventoryCountList}
          columns={[
            {
              Header: 'Criação',
              accessor: 'createdAt',
              width: 120,
              Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
            },
            {
              Header: 'Descrição',
              accessor: 'description',
              width: 380,
            },
            {
              Header: 'Qtd. Produtos',
              accessor: 'InventoryProducts',
              width: 130,
              Cell: (props) => {
                return props.value.length || 0;
              },
            },
            {
              Header: 'Data Finalização',
              accessor: 'finishDate',
              width: 175,
              Cell: (props) =>
                props.value ? format(new Date(props.value), 'dd/MM/yyyy') : '-',
            },
            {
              Header: 'Status',
              accessor: 'status',
              width: 150,
              Cell: (props) => (
                <Button
                  id="botaoReactTable"
                  className="react-table__button-label"
                  style={{
                    width: '80%',
                    height: '100%',
                    backgroundColor:
                      props.value === 'Em Aberto' ? '#ffc40d' : '#5cb85c',
                  }}
                >
                  {props.value}
                </Button>
              ),
            },
            {
              Header: 'Ações',
              accessor: 'id',
              Cell: (props) => {
                const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

                return (
                  <div className="table-actions">
                    <Link
                      to={{ pathname: `inventory-count#${props.value}` }}
                      title="Editar"
                    >
                      <FontAwesomeIcon cursor="pointer" icon={faEdit} />
                    </Link>
                    {props.original.status === 'Finalizada' && (
                      <button
                        title="Impressão"
                        onClick={() => setIsPrintModalOpen(true)}
                        style={{
                          background: 'none',
                          border: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPrint}
                          style={{ width: '1.5em', height: '1.5em' }}
                        />
                      </button>
                    )}
                    {isPrintModalOpen && (
                      <InventoryCountPrintModal
                        isPrintModalOpen={isPrintModalOpen}
                        setIsPrintModalOpen={setIsPrintModalOpen}
                        inventoryCountId={props.original.id}
                        inventoryCountList={inventoryCountList}
                      />
                    )}
                  </div>
                );
              },
            },
          ]}
          pageSize={pageLimit}
          page={currentPage}
          onPageChange={setCurrentPage}
          manual
          onPageSizeChange={(value) => {
            setPageLimit(value);
          }}
          pages={totalPages}
          loading={loading}
          showPagination={true}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText="Nenhum inventário/contagem encontrado "
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </div>
    </div>
  );
};

export default InventoryCountTable;
