import React, { useEffect, useState } from 'react';
import { AppLoading } from 'client/routes/index.routes';
import AppError from 'components/AppError';
import { PDFViewer } from '@react-pdf/renderer';

import VehicleHistoryRepository from '../../../repositories/VehicleHistory';
import { format, addDays } from 'date-fns';

import { VehicleHistoryPDF } from './VehicleHistoryPDF';

export const VehicleHistoryReport = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [sales, setSales] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [totalsPerSaleType, setTotalsPerSaleType] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) return loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');
      const companyId = searchParams.get('companyId');
      const vehicleId = searchParams.get('vehicleId');
      const saleType = searchParams.get('saleType');

      const params = {
        initialDate,
        finalDate,
        saleType,
        companyId,
      };

      const vehicleHistory = await VehicleHistoryRepository.getByVehicleId(
        vehicleId,
        params
      );

      if (vehicleHistory.sales.length === 0) {
        throw new Error('there is no sales for this vehicle');
      }

      setVehicle(vehicleHistory.vehicle);
      setTotalsPerSaleType(vehicleHistory.totalsPerSaleType);
      setSales(vehicleHistory.sales);

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'));
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'));

      setHasError(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setHasError(true);
      setLoading(false);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <VehicleHistoryPDF
            sales={sales}
            initialDate={initialDate}
            finalDate={finalDate}
            totalsPerSaleType={totalsPerSaleType}
            vehicle={vehicle}
          />
        </PDFViewer>
      </main>
    </div>
  );
};
