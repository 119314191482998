import React, { useRef } from 'react';
import { DefaultContentContainer } from 'v2/components/layout';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { getBreadcrumbData } from './utils';
import { useForm, FormProvider } from 'react-hook-form';
import { FinanceCalendarFilters } from './components/FinanceCalendarFilters';
import { Calendar } from './components/Calendar';
import { FinanceCalendarSummary } from './components/FinanceCalendarSummary';
export function FinanceCalendar() {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const methods = useForm({
    defaultValues: {
      month: {
        value: currentMonth + 1,
        label: (() => {
          const monthName = new Date(0, currentMonth).toLocaleString('pt-BR', {
            month: 'long',
          });
          return monthName.charAt(0).toUpperCase() + monthName.slice(1);
        })(),
      },
      year: { value: currentYear, label: currentYear.toString() },
      typeDate: { value: '1', label: 'Mensal' },
      typeRegister: { value: 'both', label: 'Ambos' },
    },
  });

  const calendarRef = useRef(null);

  const { getValues } = methods;

  const filters = {
    month: getValues('month'),
    year: getValues('year'),
    typeDate: getValues('typeDate'),
    typeRegister: getValues('typeRegister'),
  };

  return (
    <DefaultContentContainer>
      <BreadCrumb {...getBreadcrumbData()} />
      <FormProvider {...methods}>
        <FinanceCalendarFilters calendarRef={calendarRef} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            marginTop: '2rem',
          }}
        >
          <div style={{ flex: 1, position: 'relative', zIndex: 1 }}>
            <Calendar calendarRef={calendarRef} selectedPeriod={filters} />
          </div>
          <div style={{ minWidth: '200px', maxWidth: '300px' }}>
            <FinanceCalendarSummary />
          </div>
        </div>
      </FormProvider>
    </DefaultContentContainer>
  );
}
