import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { faChartPie, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { cpfMask } from 'client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import newsRepository from 'repositories/News';
import DownloadXlsButton from '../../../../components/DownloadXlsButton';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import '../../Advertisements/styles.css';
import { getBrandingName } from 'v2/helpers/brandingHelpers';

const NewsActions = ({ id, clicks, description }) => {
  const { isLoading, fetchLoading } = useLoading();
  const [buttonColor, setButtonColor] = useState('black');
  const [reportData, setReportData] = useState([]);

  const fetchData = async () => {
    toastr.warning('Gerando relatório, por favor aguarde.');
    try {
      const response = await newsRepository.getReport(id);

      if (!response.length) {
        setReportData([]);
        return toastr.warning('Sem dados disponíveis');
      }

      const report = response.map(serializeReport);
      setReportData(report);
      setButtonColor('green');
      return toastr.success(
        'Relatório gerado com sucesso.',
        'Clique novamente para baixar'
      );
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados do relatório');
      return console.error(err);
    }
  };

  function serializeReport(report) {
    return {
      ...report,
      clickedAt: format(new Date(report.clickedAt), 'dd/MM/yyyy'),
      cpfCnpj:
        report?.cpfCnpj?.length <= 11
          ? cpfMask(report?.cpfCnpj || '00000000000')
          : cnpjMask(report?.cpfCnpj || '00000000000000'),
      companyName: report.companyName || report.userName,
      product: report.product ? getBrandingName(report.product) : '',
      plan: report.planDescription || '',
      segment: report.segment || '',
      phone: phoneMask(report.phone),
      email: report.userEmail,
      userName: report.userName,
      userPhone: phoneMask(report.userPhone),
    };
  }

  const FetchDataIcon = useCallback(() => {
    return (
      <span>
        <FontAwesomeIcon
          icon={faChartPie}
          cursor="pointer"
          onClick={() => fetchLoading(fetchData)}
          color={buttonColor}
          width="100%"
        />
      </span>
    );
  }, [buttonColor, fetchLoading, fetchData]);

  const LoadingState = () => (
    <span>
      <span className="fa fa-spinner fa-pulse fa-1x update-spinner" />
    </span>
  );

  const DownloadReportButton = ({ description }) => {
    if (isLoading) {
      return (
        <>
          <LoadingState isLoading={isLoading} />
        </>
      );
    }
    if (!reportData.length || buttonColor === 'black') {
      return (
        <>
          <FetchDataIcon />
        </>
      );
    }
    return (
      <>
        <DownloadXlsButton
          style={{ display: 'contents' }}
          archiveName={`${description} - ${format(
            new Date(),
            'dd/MM/aaaa HH:mm'
          )}`}
          onClick={() => setButtonColor('black')}
          data={reportData}
          columns={[
            {
              name: 'Data do Clique',
              acessor: 'clickedAt',
            },
            {
              name: 'CNPJ/CPF',
              acessor: 'cpfCnpj',
            },
            {
              name: 'Nome/Razão Social',
              acessor: 'companyName',
            },
            {
              name: 'Produto',
              acessor: 'product',
            },
            {
              name: 'Plano',
              acessor: 'plan',
            },
            {
              name: 'Segmento',
              acessor: 'segment',
            },
            {
              name: 'Telefone',
              acessor: 'phone',
            },
            {
              name: 'E-mail',
              acessor: 'email',
            },
            {
              name: 'Nome do Usuário',
              acessor: 'userName',
            },
            {
              name: 'Telefone do Usuário',
              acessor: 'userPhone',
            },
          ]}
        >
          <FontAwesomeIcon
            icon={faChartPie}
            cursor="pointer"
            color={buttonColor}
          />
        </DownloadXlsButton>
      </>
    );
  };

  return (
    <div className="table-actions-advertising">
      <Link to={{ pathname: `news-update#${id}` }}>
        <FontAwesomeIcon
          title="Editar notícia"
          cursor="pointer"
          icon={faEdit}
          color="black"
        />
      </Link>
      {clicks > 0 && (
        <>
          <DownloadReportButton description={description} />
        </>
      )}
    </div>
  );
};

export default NewsActions;
