import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    outline-color: ${(props) => props.theme.colors.branding900};
  }

  input:disabled, select:disabled, textarea:disabled {
    cursor: not-allowed;
    color: rgb(120, 120, 120);
    background-color: rgba(170, 166, 168, 0.30);
  }

`;
