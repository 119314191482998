import React from 'react';
import { useBranding } from 'hooks/useBranding';
import './index.css';

export const BrandingLabel = ({ brandingId, noBrandingText = 'Ambos' }) => {
  const { getBrandingAcronymById } = useBranding();
  const brandingAcronym = getBrandingAcronymById(brandingId);

  return (
    <button
      className={`branding-label ${
        brandingAcronym ? brandingAcronym.toLowerCase() : ''
      }`}
    >
      <span className="flex center">{brandingAcronym || noBrandingText}</span>
    </button>
  );
};
