import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import Button from 'client/components/CustomButton/Button.jsx';
import SearchInput from 'components/SearchInput';

import useDebounce from 'hooks/useDebounce';

import suivCompanyRepository from 'repositories/SuivCompany';

import DownloadXls from 'components/DownloadXlsButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudDownloadAlt,
  faEdit,
  faToggleOn,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';

import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { format, isAfter } from 'date-fns';
import AlertModal from 'components/AlertModal/AlertModal';

const SuivCompanyMain = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const [suivCompanies, setSuivCompanies] = useState([]);
  const [suivCompaniesXLS, setSuivCompaniesXLS] = useState([]);

  // consts
  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);

  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [initialDateDebounced, setInitialDateDebounced] = useState('');
  const [finalDateDebounced, setFinalDateDebounced] = useState('');
  const debouncedSaveInitialDate = useDebounce(setInitialDateDebounced, 800);
  const debouncedSaveFinalDate = useDebounce(setFinalDateDebounced, 800);

  const [status, setStatus] = useState('');

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [countRegisters, setCountRegisters] = useState(0);

  const [isDataXlsReady, setIsDataXlsReady] = useState(false);
  const [isDataXlsLoading, setIsDataXlsLoading] = useState(false);
  //others
  const [isCancelIntegrationModalOpen, setIsCancelIntegrationModalOpen] =
    useState(false);

  const [
    isIntegrateByTableActionModalOpen,
    setIsIntegrateByTableActionModalOpen,
  ] = useState(false);

  const [selectedSuivCompanyId, setSelectedSuivCompanyId] = useState(null);

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  const validateDate = () => {
    if (initialDate && finalDate) {
      if (isAfter(new Date(initialDate), new Date(finalDate))) {
        return toastr.warning(
          'Datas inválidas',
          'A data inicial deve ser menor que a data final'
        );
      }
    } else {
      return;
    }
  };

  const handleChangeInitialDate = (value) => {
    setInitialDate(value);
    debouncedSaveInitialDate(value);
  };

  const handleChangeFinalDate = (value) => {
    setFinalDate(value);
    debouncedSaveFinalDate(value);
  };

  const handleLoadSuivCompanies = async () => {
    setLoading(true);
    try {
      validateDate();

      const data = await suivCompanyRepository.getSuivCompaniesPaginated({
        initialDate: initialDateDebounced,
        finalDate: finalDateDebounced,
        query: queryDebounced,
        isIntegrated: status,
        page: page + 1,
        limit,
      });

      setSuivCompanies(data.companies);
      setCountRegisters(data.count[0].count);
      setTotalPages(Math.ceil(data.count[0].count / limit));

      setIsDataXlsReady(false);
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os dados. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const getAllCompanies = async () => {
    setIsDataXlsLoading(true);
    try {
      validateDate();

      const data = await suivCompanyRepository.getSuivCompaniesPaginated({
        initialDate: initialDateDebounced,
        finalDate: finalDateDebounced,
        query: queryDebounced,
        isIntegrated: status,
        page: page + 1,
        countRegisters,
      });

      setSuivCompaniesXLS(
        data.companies.map((company) => ({
          ...company,
          isIntegrated: Number(company.isIntegrated)
            ? 'Integrado'
            : 'Cancelado',
          vinculatedAt: format(new Date(company.vinculatedAt), 'dd/MM/yyyy'),
        }))
      );
      setIsDataXlsReady(true);

      toastr.info('Exportação Pronta', 'Clique no botão para baixar!');
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os dados para o XLS. Por favor, tente novamente.'
      );
    } finally {
      setIsDataXlsLoading(false);
    }
  };

  const handleIsCancelIntegrationModalOpen = (suivCompany) => {
    setIsCancelIntegrationModalOpen(true);
    setSelectedSuivCompanyId(suivCompany.suivCompanyId);
  };

  const handleCancelIntegration = async () => {
    setLoading(true);
    try {
      await suivCompanyRepository.updateSuivCompany(selectedSuivCompanyId, {
        isIntegrated: false,
      });

      toastr.success('Sucesso', 'Integração cancelada com sucesso');
      handleLoadSuivCompanies();
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao cancelar a integração. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
      setIsCancelIntegrationModalOpen(false);
    }
  };

  const handleIntegrateByTableAction = async () => {
    setLoading(true);
    try {
      await suivCompanyRepository.updateSuivCompany(selectedSuivCompanyId, {
        isIntegrated: true,
      });

      toastr.success('Conta SUIV criada', 'Empresa cadastrada com sucesso');
      handleLoadSuivCompanies();
    } catch (err) {
      return toastr.error(
        'Conta SUIV não foi criada',
        `${err.response.data.message}`
      );
    } finally {
      setLoading(false);
      setIsCancelIntegrationModalOpen(false);
    }
  };

  const handleIsIntegrateByTableActionModalOpen = async (suivCompany) => {
    setIsIntegrateByTableActionModalOpen(true);
    setSelectedSuivCompanyId(suivCompany.suivCompanyId);
  };

  useEffect(() => {
    handleLoadSuivCompanies();
  }, [
    page,
    limit,
    queryDebounced,
    initialDateDebounced,
    finalDateDebounced,
    status,
  ]);

  return (
    <>
      <main className="admin__nfse-settings-page">
        <section className="admin__nfse-settings-page__filter-row">
          <button
            className="btn btn-sucesso"
            onClick={() => history.push('suiv-register')}
          >
            + Nova Empresa
          </button>
          <div className="search-input-wrapper">
            <SearchInput
              placeholder="Pesquisa por CPF/CNPJ, Nome/Razão Social ou Email"
              value={query}
              onChange={(e) => handleChangeQuery(e.target.value)}
            />
          </div>
          <fieldset>
            <label style={{ width: '75px' }}>Status:</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="form-control foco-input"
            >
              <option value={''}>Ambos</option>
              <option value={true}>Integrado</option>
              <option value={false}>Cancelado</option>
            </select>
          </fieldset>
        </section>
        <section className="admin__nfse-settings-page__filter-row">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                width: 120,
              }}
            >
              <strong>Data Inicial: </strong>
            </span>
            <input
              className="form-control foco-input"
              type="date"
              name="dataInicial"
              style={{ marginRight: '10px' }}
              value={initialDate}
              onChange={(e) => handleChangeInitialDate(e.target.value)}
            />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                width: 110,
              }}
            >
              <strong>Data Final: </strong>
            </span>
            <input
              className="form-control foco-input"
              type="date"
              name="dataFinal"
              style={{ marginRight: '5px' }}
              value={finalDate}
              onChange={(e) => handleChangeFinalDate(e.target.value)}
            />
          </div>

          {isDataXlsReady ? (
            <DownloadXls
              archiveName="Empresas Integradas - SUIV"
              data={suivCompaniesXLS}
              className="btn btn-export"
              columns={[
                { name: 'Dt. Cadastro', acessor: 'vinculatedAt' },
                { name: 'CPF/CNPJ', acessor: 'cpfCNPJ' },
                { name: 'Nome/Razão Social', acessor: 'name' },
                { name: 'Email', acessor: 'email' },
                { name: 'Status', acessor: 'isIntegrated' },
              ]}
            >
              <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
              &nbsp; Baixar .xls
            </DownloadXls>
          ) : (
            <button
              className="btn btn-export"
              onClick={() => getAllCompanies()}
              disabled={isDataXlsLoading}
            >
              {isDataXlsLoading ? (
                <span className="fa fa-spinner fa-pulse fa-1x"></span>
              ) : (
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
              )}
              &nbsp; Exportar .xls
            </button>
          )}
        </section>

        <ReactTable
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            maxWidth: '100%',
          }}
          data={suivCompanies}
          columns={[
            {
              Header: 'Dt. Cadastro',
              accessor: 'vinculatedAt',
              width: 120,
              Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy'),
            },
            {
              Header: 'CPF/CNPJ',
              accessor: 'cpfCNPJ',
              width: 160,
              Cell: (props) => (
                <span>{props.value ? cpfOrCnpjMask(props?.value) : ''}</span>
              ),
            },
            {
              Header: 'Nome/Razão Social',
              accessor: 'name',
              width: 280,
            },
            {
              Header: 'Email',
              accessor: 'email',
              width: 350,
            },
            {
              Header: 'Status',
              accessor: 'isIntegrated',
              width: 130,
              Cell: (props) => (
                <Button
                  id="botaoReactTable"
                  className="react-table__button-label"
                  style={{
                    width: '80%',
                    height: '100%',
                    backgroundColor: props.value ? '#5CB85C' : '#D9534F',
                  }}
                >
                  {props.value ? 'Integrado' : 'Cancelado'}
                </Button>
              ),
            },
            {
              Header: 'Ações',
              accessor: 'id',
              width: 100,
              Cell: (props) => (
                <>
                  <span title="Editar Empresa">
                    <FontAwesomeIcon
                      cursor="pointer"
                      onClick={() =>
                        history.push(`suiv-register`, {
                          suivCompany: props.original,
                        })
                      }
                      icon={faEdit}
                      style={{ width: '1.2em', height: '1.2em' }}
                    />
                  </span>
                  &nbsp;
                  {props.original.isIntegrated ? (
                    <span title="Cancelar integração">
                      <FontAwesomeIcon
                        cursor="pointer"
                        onClick={() =>
                          handleIsCancelIntegrationModalOpen(props.original)
                        }
                        icon={faWindowClose}
                        color="red"
                        style={{ width: '1.2em', height: '1.2em' }}
                      />
                    </span>
                  ) : (
                    <span title="Cancelar integração">
                      <FontAwesomeIcon
                        cursor="pointer"
                        onClick={() =>
                          handleIsIntegrateByTableActionModalOpen(
                            props.original
                          )
                        }
                        icon={faToggleOn}
                        color="green"
                        style={{ width: '1.2em', height: '1.2em' }}
                      />
                    </span>
                  )}
                </>
              ),
            },
          ]}
          defaultPageSize={10}
          page={page}
          onPageChange={setPage}
          pageSize={limit}
          onPageSizeChange={(value) => {
            setLimit(value);
          }}
          showPagination
          loading={loading}
          pages={totalPages}
          manual
          sortable
          showPaginationTop={false}
          showPaginationBottom
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText="Não há informação"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </main>

      {isCancelIntegrationModalOpen && (
        <AlertModal
          title="OS Digital"
          show={isCancelIntegrationModalOpen}
          message={
            <>
              <strong>Você deseja cancelar o acesso do cliente na SUIV?</strong>
              <br />
              <br />
              <span>
                Ao cancelar, o acesso da SUIV será desabilitado e o cliente
                deverá contratar novamente.
              </span>
              <br />
              <br />
              <span
                style={{
                  color: 'red',
                  fontSize: '12px',
                  textAlign: 'center',
                  margin: '0 auto',
                }}
              >
                {' '}
                A função apenas impede o cliente de acessar, mas deverá
                solicitar a desativação com a SUIV.
              </span>
            </>
          }
          onCancel={() => setIsCancelIntegrationModalOpen(false)}
          onSubmit={() => {
            handleCancelIntegration();
            setIsCancelIntegrationModalOpen(false);
          }}
          onHide={null}
        />
      )}

      {isIntegrateByTableActionModalOpen && (
        <AlertModal
          title="OS Digital"
          show={isIntegrateByTableActionModalOpen}
          message={
            <>
              <strong>Você deseja reativar o acesso do cliente na SUIV?</strong>
              <br />
              <br />
              <span>
                Ao reativar, o acesso será reativado e o cliente deverá poder
                usar, mas será necessário solicitar com a SUIV a reativação do
                token
              </span>
              <br />
              <br />
              <span
                style={{
                  color: 'red',
                  fontSize: '12px',
                  textAlign: 'center',
                  margin: '0 auto',
                }}
              >
                {' '}
                A função apenas impede o cliente de acessar, mas deverá
                solicitar a desativação com a SUIV.
              </span>
            </>
          }
          onCancel={() => setIsIntegrateByTableActionModalOpen(false)}
          onSubmit={() => {
            handleIntegrateByTableAction();
            setIsIntegrateByTableActionModalOpen(false);
          }}
          onHide={() => setIsIntegrateByTableActionModalOpen(false)}
        />
      )}
    </>
  );
};

export default withRouter(SuivCompanyMain);
