import React from 'react';
import { TableRank } from '../components/TableRank';
import { useAuth } from 'contexts/auth';
import useManagementIndicatorsStore from '../../../store/management-indicators-store';
import { useEffect } from 'react';

export const VehicleBrand = () => {
  const { companyId } = useAuth();
  const { handleVehicleData, currentDate, isLoading, vehicles, brands } =
    useManagementIndicatorsStore((store) => {
      return {
        handleVehicleData: store.handleVehicleData,
        currentDate: store.currentDate,
        isLoading: store.isLoading,
        vehicles: store.vehicles,
        brands: store.brands,
      };
    });

  const getVehicleData = async () => {
    return await handleVehicleData(companyId);
  };
  useEffect(() => {
    getVehicleData();
  }, [currentDate]);

  return (
    <div
      style={{
        width: '100%',
        marginTop: '20px',
        marginBottom: '20px',
        gap: '15px',
        display: 'flex',
      }}
    >
      <TableRank
        rows={vehicles || []}
        labelKey="label"
        valueKey="totalSales"
        labelName="VEÍCULOS"
        valueName="QTD"
        valueWidth="40px"
        loading={isLoading}
      />
      <TableRank
        rows={brands || []}
        labelKey="Brand"
        valueKey="totalSales"
        labelName="MONTADORA"
        valueName="QTD"
        valueWidth="40px"
        loading={isLoading}
      />
    </div>
  );
};
