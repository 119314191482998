import React, { useEffect, useState } from 'react';

import NewsTable from './NewsTable';
import Card from '../../../../components/Card/Card';

import { useAdminAuth } from 'contexts/adminAuth';

import useFilters from 'hooks/useFilters';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import newsRepository from '../../../../repositories/News';
import NewsFilter from './NewsFilter';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import useDebounce from 'hooks/useDebounce';

function NewsMain({ history }) {
  //ID do admin = admin.id
  const { admin } = useAdminAuth();

  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  const [status, setStatus] = useState({
    label: 'Ambos',
    value: '',
  });
  const [type, setType] = useState({
    label: 'Criação',
    value: 'createdAt',
  });
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  const [branding, setBranding] = useState(null);
  const [news, setNews] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const { isLoading, fetchLoading } = useLoading();

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  useEffect(() => {
    handleFilters({
      branding,
      status,
      type,
      initialDate,
      finalDate,
    });
  }, [
    branding,
    queryDebounced,
    status,
    type,
    initialDate,
    finalDate,
    currentPage,
    pageLimit,
  ]);

  const serializeParams = () => {
    const brandingIds = branding.some((item) => item.value === '*')
      ? ''
      : branding.map((item) => item.value).join(',');

    return {
      limit: pageLimit,
      page: currentPage + 1,
      query: queryDebounced,
      status: status.value,
      type: type.value,
      initialDate,
      finalDate,
      brandingId: brandingIds,
    };
  };

  const loadNews = async () => {
    try {
      const response = await newsRepository.getAll(serializeParams());
      setNews(response.rows);
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as notícias. Por favor, tente novamente.'
      );
    } finally {
      if (!!queryDebounced && totalPages === 1) {
        setCurrentPage(0);
      }
    }
  };

  const handleFilters = ({
    branding,
    status,
    type,
    initialDate,
    finalDate,
  }) => {
    if (!!validations(branding, status, type, initialDate, finalDate)) {
      fetchLoading(loadNews);
    }
    return;
  };

  const validations = (branding, status, type, initialDate, finalDate) => {
    if (!branding || !type || !status) {
      return false;
    }

    if (finalDate && initialDate) {
      if (new Date(finalDate) < new Date(initialDate)) {
        toastr.warning('A data final deve ser maior que a data inicial.');
        return false;
      }
    }

    return true;
  };

  const handleUpdateStatus = async ({
    status,
    id,
    initialDurationDate,
    finalDurationDate,
  }) => {
    if (!initialDurationDate || !finalDurationDate) {
      return toastr.warning(
        'Não foi possível ativar a News',
        'É necessário selecionar uma data de vigência para ativar a News.'
      );
    }
    const newStatus = !status;

    try {
      await newsRepository.updateStatus(id, newStatus);
      toastr.success('Notícia atualizada com sucesso!');
      setNews(
        news.map((item) => {
          if (item.id === id) {
            return { ...item, status: newStatus };
          } else {
            return item;
          }
        })
      );
    } catch (err) {
      toastr.warning(
        'Não foi possível ativar a News',
        'Apenas uma única news por produto pode ser ativada com a essa vigência. Verifique as news ativadas e tente novamente.'
      );
    }
  };

  return (
    <Card
      content={
        <div className="flex column gap-1">
          <NewsFilter
            history={history}
            query={query}
            handleChangeQuery={handleChangeQuery}
            setQuery={setQuery}
            status={status}
            setType={setType}
            type={type}
            setStatus={setStatus}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            branding={branding}
            setBranding={setBranding}
            loading={isLoading}
          />

          <NewsTable
            news={news}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            loading={isLoading}
            handleUpdateStatus={(newsId, status) =>
              fetchLoading(() => handleUpdateStatus(newsId, status))
            }
          />
        </div>
      }
    />
  );
}

export default withRouter(NewsMain);
