import React from 'react';
import { useAuth } from '../../contexts/auth';
import ClientInitialRoutes from './initial.routes';
import ClientLoggedRoutes from './logged.routes';

export const AppLoading = ({ children }) => {
  return (
    <div id={`app-loading`} className="bg-primary">
      {children || 'Carregando ...'}
    </div>
  );
};
const ClientRoutes = () => {
  const { isSigned, loading } = useAuth();

  if (loading) return <AppLoading />;

  return !isSigned ? <ClientInitialRoutes /> : <ClientLoggedRoutes />;
};

export default ClientRoutes;
