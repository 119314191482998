import {
  faUserFriends,
  faHome,
  faChartPie,
  faReceipt,
  faBullhorn,
  faDollarSign,
  faFileInvoiceDollar,
  faNewspaper,
  faMagnifyingGlassChart,
  faBarcode,
  faPuzzlePiece,
} from '@fortawesome/free-solid-svg-icons';
import { faPix, faYoutube } from '@fortawesome/free-brands-svg-icons';

import constants from '../../../utils/constants';

export const sidebarData = [
  {
    title: 'Início',
    active: 'home',
    path: 'home',
    icon: faHome,
  },
  {
    title: 'Clientes',
    active: 'clients',
    path: 'clients',
    icon: faUserFriends,
  },
  {
    title: 'Integrações',
    active: 'integrations',
    icon: faPuzzlePiece,
    subMenu: {
      title: 'Integrações',
      list: [
        {
          title: 'Boleto Parcelado',
          role: constants.ADMIN_ROLES.ADMINISTRATOR,
          active: 'financing-bank-slip',
          path: 'financing-bank-slip',
          icon: faBarcode,
        },
        {
          title: 'Boleto Bancário',
          active: 'ticket-issuance',
          path: 'ticket-issuance',
          icon: faFileInvoiceDollar,
        },
        {
          title: 'Conta Digital Matera',
          active: 'matera',
          path: 'matera',
          icon: faPix,
        },
        {
          title: 'Integração Stone',
          active: 'stone-links',
          path: 'stone-links',
          icon: faDollarSign,
        },
        {
          title: 'SUIV',
          active: 'suiv',
          path: 'suiv',
        },
        {
          title: 'CRM Bônus',
          active: 'crm-bonus',
          path: 'crm-bonus',
        },
      ],
    },
  },
  {
    title: 'News',
    active: 'news',
    path: 'news',
    icon: faNewspaper,
  },
  {
    title: 'Nota Fiscal de Serviço',
    active: 'nfse',
    icon: faReceipt,
    subMenu: {
      title: 'Nota Fiscal de Serviço',
      list: [
        {
          title: 'Configurações NFS-e',
          path: 'nfse-settings',
        },
        {
          title: 'Listagem de Cidades',
          path: 'nfse-cities-list',
        },
      ],
    },
  },
  {
    title: 'Pesquisa',
    role: constants.ADMIN_ROLES.ADMINISTRATOR,
    active: 'polls',
    path: 'polls',
    icon: faMagnifyingGlassChart,
  },
  {
    title: 'Propaganda',
    role: constants.ADMIN_ROLES.ADMINISTRATOR,
    active: 'advertisements',
    path: 'advertisements',
    icon: faBullhorn,
  },
  {
    title: 'Relatórios',
    role: constants.ADMIN_ROLES.ADMINISTRATOR,
    active: 'reports',
    icon: faChartPie,
    subMenu: {
      title: 'Relatórios',
      list: [
        {
          title: 'Notas Fiscais por Cliente',
          path: 'invoice-by-customer',
          role: constants.ADMIN_ROLES.ADMINISTRATOR,
        },
        {
          title: 'Vendas por Cliente',
          path: 'sales-by-client',
          role: constants.ADMIN_ROLES.ADMINISTRATOR,
        },
        {
          title: 'Relatório de Pontos de Sucesso',
          path: 'success-points-report',
          role: constants.ADMIN_ROLES.ADMINISTRATOR,
        },
        // {
        //   title: 'Relatório de Uso de Sistema',
        //   path: 'system-usage-report',
        //   role: constants.ADMIN_ROLES.ADMINISTRATOR,
        // },
      ],
    },
  },
  {
    title: 'Vídeos',
    role: constants.ADMIN_ROLES.ADMINISTRATOR,
    active: 'videos',
    path: 'videos',
    icon: faYoutube,
  },
];
