import { useAuth } from 'contexts/auth';
import React, { useCallback, useEffect, useState } from 'react';

import advertisementsRepository from '../../../repositories/Advertisements';
import { UseBannerContext } from '../../../contexts/bannerContext';
import './styles.css';
import { useThemeContext } from 'v2/contexts/themeContext';
import { SimpleCarousel } from 'v2/components/SimpleCarousel';
import { useBranding } from 'hooks/useBranding';

const Advertisements = () => {
  const [advertisements, setAdvertisements] = useState({});
  const { isAdvertisementOpen, setIsAdvertisementOpen } = UseBannerContext();
  const { companyId, userId } = useAuth();
  const { brandingId } = useThemeContext();

  useEffect(() => {
    if (companyId) loadAdvertisements();
  }, [companyId]);

  const loadAdvertisements = async () => {
    try {
      let exclusiveAd =
        await advertisementsRepository.getExclusiveAdByCompanyId(
          companyId,
          brandingId
        );
      let globalAds = await advertisementsRepository.getActiveGlobalAd(
        companyId,
        brandingId
      );

      if ((exclusiveAd || globalAds) && (exclusiveAd !== '' || globalAds)) {
        setIsAdvertisementOpen(true);
      }

      setAdvertisements({ exclusiveAd, globalAds });
    } catch (err) {
      console.error(err);
    }
  };

  const openAdInNewTab = useCallback((ad) => {
    if (!ad.redirectUrl) return;
    window.open(ad.redirectUrl, '_blank');
    advertisementsRepository.click({ userId, advertisementId: ad.id });
  });

  return (
    <div className="advertisements-container">
      <a
        className="close-advertisement"
        onClick={() => setIsAdvertisementOpen(false)}
      >
        Fechar
      </a>
      <div className="ads-wrapper">
        {advertisements.globalAds && advertisements.globalAds.length > 0 && (
          <div className="left-ad-container">
            {advertisements.globalAds.length > 1 ? (
              <SimpleCarousel
                items={advertisements.globalAds}
                interval={4000}
                onClick={openAdInNewTab}
              />
            ) : (
              <img
                src={advertisements.globalAds[0].imageUrl}
                alt={advertisements.globalAds[0].description}
                onClick={() => openAdInNewTab(advertisements.globalAds[0])}
                className="advertisement-image"
              />
            )}
          </div>
        )}
        {advertisements.exclusiveAd &&
          advertisements.exclusiveAd.length > 0 && (
            <div className="right-ad-container">
              <img
                src={advertisements.exclusiveAd[0].imageUrl}
                alt={advertisements.exclusiveAd[0].description}
                onClick={() => openAdInNewTab(advertisements.exclusiveAd[0])}
                className="advertisement-image"
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default Advertisements;
