import React, { useMemo, useState } from 'react';
import AlertModal from 'components/AlertModal/AlertModal';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import Button from 'client/components/CustomButton/CustomButton';

export default function ChangeModal({
  show,
  setShow,
  // setNeededToCreateCustomerCredit,
  handleSubmit,
}) {
  const dispatch = useDispatch();
  const changeOptions = useMemo(
    () => [
      {
        description: 'Gerar Crédito de Troco',
        backgroundColor: '#428bca',
        onClick: () => setIsCreateCustomerCreditModalOpen(true),
      },
      {
        description: 'Devolver Troco',
        backgroundColor: '#5cb85c',
        onClick: () => setIsHandleSubmitModalOpen(true),
      },
    ],
    []
  );

  const [isCreateCustomerCreditModalOpen, setIsCreateCustomerCreditModalOpen] =
    useState(false);
  const [isHandleSubmitModalOpen, setIsHandleSubmitModalOpen] = useState(false);

  const handleCreateCredit = async () => {
    await dispatch(change('pdvOnline', 'neededToCreateCustomerCredit', true));
    setIsCreateCustomerCreditModalOpen(false);
    setShow(false);
    handleSubmit();
  };

  return (
    <>
      <Modal show={show} animation={true}>
        <Modal.Header>
          <Modal.Title>
            <strong>Troco para Cliente</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="change-modal-sale__body">
            <p>Selecione a opção desejada:</p>
            <div className="change-modal-sale__buttons">
              {changeOptions.map((option, index) => (
                <Button
                  key={index}
                  style={{
                    backgroundColor: option.backgroundColor,
                  }}
                  fill
                  onClick={option.onClick}
                >
                  {option.description}
                </Button>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {isCreateCustomerCreditModalOpen && (
        <AlertModal
          show={true}
          title="Troco para Cliente"
          subtitle="Você tem certeza que deseja gerar crédito no valor do troco?"
          message="O cliente poderá usar esse valor futuramente no pagamento de títulos ou vendas"
          onHide={() => {
            setIsCreateCustomerCreditModalOpen(false);
          }}
          onCancel={() => {
            setIsCreateCustomerCreditModalOpen(false);
          }}
          onSubmit={() => handleCreateCredit()}
        />
      )}

      {isHandleSubmitModalOpen && (
        <AlertModal
          show={true}
          title="Troco para Cliente"
          subtitle="Você tem certeza que deseja devolver o valor do troco?"
          message="Ao devolver, será gerada a saída do valor em questão e constará nos relatórios de caixa, se for utilizado."
          onHide={() => setIsHandleSubmitModalOpen(false)}
          onCancel={() => setIsHandleSubmitModalOpen(false)}
          onSubmit={() => {
            setIsHandleSubmitModalOpen(false);
            setShow(false);
            handleSubmit();
          }}
        />
      )}
    </>
  );
}
