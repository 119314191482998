import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { isValid, isBefore } from 'date-fns';
import { Col } from 'react-bootstrap';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import salesByAttendanceRepository from 'repositories/SalesByAttendance';
import { SelectMulti } from 'v2/components/Select/Multi';
import employeesRepository from 'repositories/Employees';

const ByAttendance = () => {
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [typeDate, setTypeDate] = useState('createdAt');
  const [saleType, setSaleType] = useState('');
  const [saleStatus, setSaleStatus] = useState('');
  const [reportType, setReportType] = useState('synthetic');

  const [sellerOptions, setSellerOptions] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState([]);
  const [seller, setSeller] = useState([]);

  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();

  useEffect(() => {
    loadSellers();
  }, [companyId]);

  useEffect(() => {
    if (selectedSeller.length > 0) {
      const transformedSeller =
        selectedSeller[0].value === '*'
          ? ''
          : selectedSeller
              .map((item) => item.value)
              .filter((value) => value !== '*')
              .join(',');
      setSeller(transformedSeller);
    } else {
      setSeller(false);
    }
  }, [selectedSeller]);

  const loadSellers = async () => {
    try {
      const response = await employeesRepository.getAllByCompany(companyId);

      const options = response.data;
      const transformedOptions = options.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      transformedOptions.unshift({
        label: 'Todos',
        value: '*',
      });

      setSellerOptions(transformedOptions);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Vendedores',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const handleProcess = async () => {
    setLoading(true);
    try {
      const res = await salesByAttendanceRepository.getReport({
        companyId,
        initialDate,
        finalDate,
        typeDate,
        saleType,
        saleStatus,
        seller,
      });

      if (res.totalStatus.length === 0)
        return toastr.warning(
          'Nenhuma informação para o relatório foi encontrada.'
        );

      handlePrint();
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu algum erro ao buscar as informações. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const validations = () => {
    if (seller === false) {
      toastr.warning(
        'Selecione pelo menos um Vendedor para gerar o relatório e tente novamente.'
      );
      return false;
    }

    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning(
        'Selecione um período válido para gerar o relatório e tente novamente.'
      );
      return false;
    }

    if (isBefore(new Date(finalDate), new Date(initialDate))) {
      toastr.warning(
        'Selecione um período válido para gerar o relatório e tente novamente.'
      );
      return false;
    }

    return true;
  };

  const handlePrint = () => {
    window.open(
      window.location.origin +
        `/report/sales-by-attendance?companyId=${companyId}&initialDate=${initialDate}&finalDate=${finalDate}&typeDate=${typeDate}&saleType=${saleType}&saleStatus=${saleStatus}&reportType=${reportType}&seller=${seller}`,
      '_blank'
    );
  };

  return (
    <>
      <Col style={{ display: 'flex', marginTop: '40px', gap: '10px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '170px',
          }}
        >
          <span>Tipo de Data:</span>
          <select
            value={typeDate}
            onChange={(e) => setTypeDate(e.target.value)}
            className="form-control foco-input"
          >
            <option value="updatedAt">Fechamento</option>
            <option value="createdAt">Criação</option>
          </select>
        </div>
        <div>
          <span>Data Inicial:</span>
          <input
            type="date"
            className="form-control foco-input"
            value={initialDate}
            max={finalDate}
            onChange={(e) => setInitialDate(e.target.value)}
            style={{
              width: '170px',
            }}
          />
        </div>
        <div>
          <span>Data Final:</span>
          <input
            type="date"
            className="form-control foco-input"
            value={finalDate}
            min={initialDate}
            onChange={(e) => setFinalDate(e.target.value)}
            style={{
              width: '170px',
            }}
          />
        </div>
      </Col>
      <Col style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '170px',
          }}
        >
          <span>Tipo de Venda:</span>
          <select
            value={saleType}
            onChange={(e) => setSaleType(e.target.value)}
            className="form-control foco-input"
          >
            <option value="">Todos</option>
            <option value="3">Ordem de Serviço</option>
            <option value="2">Venda</option>
            <option value="1">Orçamento</option>
            <option value="4">PDV</option>
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '170px',
          }}
        >
          <span>Status:</span>
          <select
            value={saleStatus}
            onChange={(e) => setSaleStatus(e.target.value)}
            className="form-control foco-input"
          >
            <option value="">Todos</option>
            <option value="5">Finalizadas</option>
            <option value="3">Em aberto</option>
            <option value="2">Cancelada</option>
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '120px',
          }}
        >
          <span>Tipo:</span>
          <select
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            className="form-control foco-input"
          >
            <option value="synthetic">Sintético</option>
            <option value="analytical">Analítico</option>
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '170px',
          }}
        >
          <span>Vendedor:</span>
          <SelectMulti
            width={170}
            height={35}
            options={sellerOptions}
            value={selectedSeller}
            onChange={(e) => setSelectedSeller(e)}
            selectAllByDefault
            hasAllOption
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <button
            className="component__pdf-button"
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />{' '}
            <FontAwesomeIcon
              icon={faFilePdf}
              size="lg"
              style={{ marginRight: '5px' }}
            />
            Exportar .pdf
          </button>
        </div>
      </Col>
    </>
  );
};

export default ByAttendance;
