import React, { useEffect, useState } from 'react';
import { Grid, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import NewScheduling from './FormNewScheduling';
import constants from '../../../../utils/constants';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { useLocation } from 'react-router-dom';

const ViewNewScheduling = ({ history, match }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isKanban = queryParams.get('kanban') === 'true';
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <BreadCrumb
              data={[
                'Início',
                'Vendas',
                'Agenda',
                `${!!match.params.id ? 'Editar' : 'Novo'} Agendamento`,
              ]}
              path={['home', null, constants.ROUTES.SCHEDULES, null]}
            />
            <span style={{ color: 'red', position: 'absolute', right: 10 }}>
              * Campos Obrigatórios
            </span>
            |
          </div>

          <NewScheduling
            schedulingId={match.params.id}
            onCancel={() => history.push(constants.ROUTES.SCHEDULES)}
            onSubmit={() => {
              history.goBack();
              /* history.push(constants.ROUTES.SCHEDULES); */
            }}
          />
        </Row>
      </Grid>
    </div>
  );
};

export default withRouter(ViewNewScheduling);
