import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import {
  cpfOrCnpjMask,
  cepMask,
  RgMask,
  percentage,
} from 'client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const ListEmployeesAnalyticalPDF = (responseData) => {
  const data = responseData.responseData.data;
  return (
    <Document
      title={`impresso-list-employees-${format(new Date(), 'dd/MM/yyyy')}`}
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View>
          <Text style={styles.title}>Listagem de Funcionários - Analítico</Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}
            >
              Tipo de Data:
            </Text>
            <Text style={[styles.text]}>
              {' '}
              {data.period.dateTypes === 'admissionDate'
                ? 'Admissão'
                : 'Demissão'}
            </Text>
          </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}
            >
              Data Inicio:
            </Text>
            <Text style={[styles.text]}>
              {' '}
              {format(new Date(`${data.period.end} 03:00:01`), 'dd/MM/yyyy')}
            </Text>
          </View>

          <View style={{ left: '6px' }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text
                style={[
                  styles.text,
                  { fontWeight: 'bold', marginRight: '3px' },
                ]}
              >
                Data Fim:
              </Text>
              <Text style={[styles.text]}>
                {format(new Date(`${data.period.end} 23:59:59`), 'dd/MM/yyyy')}
              </Text>
            </View>
          </View>
        </View>

        <TableHeader data={data} />
      </Page>
    </Document>
  );
};

const TableHeader = ({ data }) => {
  return (
    <>
      <View style={[styles.line]} />
      <View>
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Totais por Cargo
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '10%' }]}>Cargo</Text>
        <Text style={[styles.boldText, { width: '6%' }]}>Status</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Quantidade</Text>
      </View>

      <View style={styles.line} />

      {data.totalsPerTypeAndStatus.map((item) => {
        const type = item.reduce(
          (prevValue, currentValue) =>
            currentValue.Position?.description || 'Sem Cargo',
          ''
        );
        const status = item.reduce(
          (prevValue, currentValue) => currentValue.isActive,
          ''
        );
        const quantity = item.length;

        return (
          <TotalsByTypeStatusItems
            key={item.id}
            type={type}
            status={status}
            quantity={quantity}
          />
        );
      })}

      <View style={styles.line} />

      <View>
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Listagem de Funcionário
        </Text>
        <View style={[styles.line]} />
      </View>
      {data.employeeData.map((employee, index) => (
        <EmployeeDetails employee={employee} key={index} />
      ))}
    </>
  );
};

const TotalsByTypeStatusItems = ({ key, type, status, quantity }) => {
  return (
    <View key={key} style={styles.containerRow}>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{type}</Text>
      </View>
      <View style={{ width: '9%' }}>
        <Text style={styles.text}>{status === true ? 'Ativo' : 'Inativo'}</Text>
      </View>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{quantity}</Text>
      </View>
    </View>
  );
};

const EmployeeDetails = ({ employee, index }) => {
  const address = employee.Address.length ? employee.Address[0] : null;

  return (
    <View>
      <EmployeeDetailsTableItem
        employee={employee}
        key={index}
        found={address}
      />
    </View>
  );
};

const EmployeeDetailsTableItem = ({ employee, key, found }) => {
  return (
    <View key={key}>
      <View style={{ marginTop: '6px' }} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '8%' }]}>Data Admissão: </Text>
        <Text style={{ width: '5%', fontSize: '8px', right: '5px' }}>
          {employee.admissionDate
            ? format(
                getDateOnlyFromDate(new Date(employee.admissionDate), 1),
                'dd/MM/yyyy'
              )
            : ''}
        </Text>
        <Text style={[styles.boldText, { width: '9%' }]}>
          Data Desligamento:{' '}
        </Text>
        <Text style={{ width: '7%', fontSize: '8px' }}>
          {employee.resignationDate
            ? format(
                getDateOnlyFromDate(new Date(employee.resignationDate)),
                'dd/MM/yyyy'
              )
            : ''}
        </Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '8%' }]}>Nome Completo: </Text>
        <Text style={[{ width: '18%', fontSize: '8px' }]}>{employee.name}</Text>
        <Text style={[styles.boldText]}>CPF: </Text>
        <Text style={[{ width: '8%', fontSize: '8px' }]}>
          {cpfOrCnpjMask(employee.cpf)}
        </Text>
        <Text style={[styles.boldText]}>RG: </Text>
        <Text style={[{ width: '10%', fontSize: '8px' }]}>
          {RgMask(employee?.rg)}
        </Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { fontSize: '8px', width: '7%' }]}>
          Data de Nasc:{' '}
        </Text>
        <Text style={[{ fontSize: '8px', width: '6%', right: '4px' }]}>
          {format(new Date(employee?.birthDate), 'dd/MM/yyyy')}
        </Text>
        <Text style={[styles.boldText, { fontSize: '8px', width: '4%' }]}>
          Cargo:{' '}
        </Text>
        <Text style={[{ fontSize: '8px', width: '10%', right: '5px' }]}>
          {employee.Position?.description}
        </Text>
        <Text style={[styles.boldText, { fontSize: '8px', width: '5%' }]}>
          Comissão:{' '}
        </Text>
        <Text style={[{ fontSize: '8px', width: '5%' }]}>
          {percentage(employee?.comissionRate)}
        </Text>
        <Text
          style={[
            styles.boldText,
            { fontSize: '8px', width: '4%', right: '3px' },
          ]}
        >
          Email:{' '}
        </Text>
        <Text style={[{ fontSize: '8px', width: '28%', right: '10px' }]}>
          {employee.email}
        </Text>
        <Text
          style={[
            styles.boldText,
            { fontSize: '8px', width: '5%', left: '5px' },
          ]}
        >
          Usuário:{' '}
        </Text>
        <Text style={[{ fontSize: '8px' }]}>{employee.User?.Email}</Text>
      </View>
      <View style={{ margin: '8px' }} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '3%', fontSize: '8px' }]}>
          CEP:{' '}
        </Text>
        <Text style={[{ width: '6%', fontSize: '8px' }]}>
          {cepMask(found?.Zipcode)}
        </Text>
        <Text style={[styles.boldText]}>Endereço: </Text>
        <Text style={[{ width: '23%', fontSize: '8px' }]}>
          {found?.Address}
        </Text>
        <Text style={[styles.boldText]}>N: </Text>
        <Text style={[{ width: '5%', fontSize: '8px' }]}>
          {found?.Address_Numbe}
        </Text>
        <Text style={[styles.boldText]}>Bairro: </Text>
        <Text style={[{ width: '14%', fontSize: '8px' }]}>
          {found?.Neighborhood}
        </Text>
      </View>

      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '7%' }]}>Complemento: </Text>
        <Text style={[{ width: '13%', fontSize: '8px' }]}>
          {found?.Complement}
        </Text>
        <Text style={[styles.boldText, { width: '10%' }]}>
          Ponto de Referência:{' '}
        </Text>
        <Text style={[{ width: '20%', fontSize: '8px' }]}>
          {found?.Reference_Point}
        </Text>
        <Text style={[styles.boldText, { width: '4%', left: '12px' }]}>
          UF:{' '}
        </Text>
        <Text style={[{ width: '6%', fontSize: '8px', right: '3px' }]}>
          {found?.State}
        </Text>
        <Text style={[styles.boldText, { width: '4%', fontSize: '8px' }]}>
          Cidade:{' '}
        </Text>
        <Text style={[{ width: '22%', fontSize: '8px' }]}>{found?.City}</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  );
};

export default ListEmployeesAnalyticalPDF;

const styles = StyleSheet.create({
  page: {
    fontSize: 8,
    padding: 8,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 8,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 8,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
});
