import React, { useEffect, useState } from 'react';
import FormPrintQuickQuoteModal from './FormPrintQuickQuoteModal';
import { onlyNumbers } from '../../ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

import PureLoader from '../../../../components/PureLoader';

import './styles.css';
import { useAuth } from 'contexts/auth';

import quickQuotesRepository from '../../../../repositories/QuickQuotes';

const PrintQuickQuoteModal = ({
  show,
  onHide,
  items,
  quickQuoteInfo,
  onSuccess,
}) => {
  const [lastCode, setLastCode] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFromSaveButton, setIsFromSaveButton] = useState(false);
  const [clients, setClients] = useState([]);

  const dispatch = useDispatch();
  const { companyId, userId } = useAuth();

  const [quickQuoteId, setQuickQuoteId] = useState(null);

  useEffect(() => {
    if (companyId) getQuickQuoteLastCode();
  }, [companyId]);

  useEffect(() => {
    if (quickQuoteInfo) {
      setQuickQuoteId(quickQuoteInfo.id);
      dispatch([
        change('printQuickQuote', 'name', quickQuoteInfo.customerName),
        change('printQuickQuote', 'email', quickQuoteInfo.customerEmail),
        change('printQuickQuote', 'phone', quickQuoteInfo.customerTelephone),
        change('printQuickQuote', 'model', quickQuoteInfo.vehicle),
        change('printQuickQuote', 'licensePlate', quickQuoteInfo.licensePlate),
        change('printQuickQuote', 'observations', quickQuoteInfo.observations),
      ]);
    }
  }, [quickQuoteInfo]);

  const onSubmit = (values) => {
    if (!!quickQuoteInfo) {
      update(values, items);
    } else {
      create(values, items);
    }
  };

  const getQuickQuoteLastCode = async () => {
    const lastCode = await quickQuotesRepository.getLastCode(companyId);
    return setLastCode(lastCode);
  };

  const update = async (values, items) => {
    try {
      setLoading(true);
      const data = await buildObjectToUpdate(values, items);
      await quickQuotesRepository.update(quickQuoteInfo.id, data);

      toastr.success('Orçamento rápido atualizado com sucesso!');

      if (!isFromSaveButton) {
        window.open(
          window.location.origin +
            `/quick-quote?Id=v723dha5tHSSpS83711${quickQuoteId}5422hsGsdAQLk8423oPL31`,
          '_blank'
        );
      }

      return onSuccess();
    } catch (err) {
      if (err?.response) {
        return toastr.warning(err?.response?.message);
      }
      return toastr.warning(err.message);
    } finally {
      setLoading(false);
    }
  };

  const create = async (values, items) => {
    try {
      setLoading(true);
      const data = await buildObject(values, items, 1);
      const createdData = await quickQuotesRepository.create(data);

      if (!isFromSaveButton) {
        console.log(createdData);
        window.open(
          window.location.origin +
            `/quick-quote?Id=v723dha5tHSSpS83711${createdData.data.id}5422hsGsdAQLk8423oPL31`,
          '_blank'
        );
      }

      toastr.success('Orçamento rápido registrado com sucesso!');
      return onSuccess();
    } catch (err) {
      if (err?.response) {
        return toastr.warning(err?.response?.message);
      }
      return toastr.warning(err.message);
    } finally {
      setLoading(false);
    }
  };

  const buildObject = async (values, items, quickQuoteStatusId) => {
    let totalProducts = 0,
      totalServices = 0;

    var itemsArray = [];

    items.map((item) => {
      if (item.Type == 'Serviço') {
        totalServices += item.Quantity * item.Unit_Value;
      }

      if (item.Type == 'Produto') {
        totalProducts += item.Quantity * item.Unit_Value;
      }

      let newItem = {
        type: item.Type,
        productId: item.Type == 'Produto' ? item.Product_id : '',
        serviceId: item.Type == 'Serviço' ? item.Product_id : '',
        description: item.Description,
        quantity: item.Quantity,
        unitValue: item.Unit_Value,
        unitDiscountValue: 0,
        discountValue: 0,
        amount: item.Quantity * item.Unit_Value,
      };

      return itemsArray.push(newItem);
    });

    var quickQuote = {
      date: new Date(),
      quickQuoteStatusId: quickQuoteStatusId,
      vehicle: !!values.model ? values.model : '',
      licensePlate: !!values.licensePlate ? values.licensePlate : '',
      observations: !!values.observations ? values.observations : '',
      productsValue: totalProducts,
      productsDiscountValue: 0,
      servicesValue: totalServices,
      servicesDiscountValue: 0,
      totalDiscountValue: 0,
      subtotal: totalProducts + totalServices,
      discountType: '%',
      finalValue: totalProducts + totalServices,
      customerName: !!values.name ? values.name : '',
      customerTelephone: !!values.phone ? onlyNumbers(values.phone) : '',
      customerEmail: !!values.email ? values.email : '',
      companyId: companyId,
      userId: userId,
      code: lastCode,
      productsDiscountPercentage: 0,
      servicesDiscountPercentage: 0,
      totalDiscountPercentage: 0,
    };

    var object = {
      quickQuote,
      items: itemsArray,
    };

    return object;
  };

  const buildObjectToUpdate = async (values, items) => {
    let totalProducts = 0,
      totalServices = 0;

    var itemsArray = [];

    items.map((item) => {
      if (item.Type == 'Serviço') {
        totalServices += item.Quantity * item.Unit_Value;
      }

      if (item.Type == 'Produto') {
        totalProducts += item.Quantity * item.Unit_Value;
      }

      let newItem = {
        type: item.Type,
        productId: item.Type == 'Produto' ? item.Product_id : '',
        serviceId: item.Type == 'Serviço' ? item.Product_id : '',
        description: item.Description,
        quantity: item.Quantity,
        unitValue: item.Unit_Value,
        unitDiscountValue: 0,
        discountValue: 0,
        amount: item.Quantity * item.Unit_Value,
      };

      return itemsArray.push(newItem);
    });

    var quickQuote = {
      date: values.date,
      quickQuoteStatusId: 1,
      vehicle: values.model,
      licensePlate: values.licensePlate,
      observations: values.observations,
      productsValue: totalProducts,
      productsDiscountValue: 0,
      servicesValue: totalServices,
      servicesDiscountValue: 0,
      totalDiscountValue: 0,
      subtotal: totalProducts + totalServices,
      discountType: '%',
      finalValue: totalProducts + totalServices,
      customerName: values.name,
      customerTelephone: values.phone,
      customerEmail: values.email,
      companyId: companyId,
      userId: userId,
      code: values.code,
      productsDiscountPercentage: 0,
      servicesDiscountPercentage: 0,
      totalDiscountPercentage: 0,
    };

    return {
      quickQuote,
      items: itemsArray,
    };
  };

  return (
    <>
      <FormPrintQuickQuoteModal
        show={show}
        onHide={onHide}
        onSubmit={onSubmit}
        isFromSaveButton={() => setIsFromSaveButton(true)}
      />

      {loading && <PureLoader message="Aguarde..." />}
    </>
  );
};

export default PrintQuickQuoteModal;
