import React from 'react';
export function PieChartGraphSubtitle({
  data,
  colors,
  styleLegend,
  styleColorBox,
  styleText,
  styleLegendItem,
}) {
  const defaultColors = [
    '#002344',
    '#17BC4F',
    '#FFBB28',
    '#FF5733',
    '#428BCA',
    '#808080',
    '#000000',
    '#8A2BE2',
    '#A52A2A',
    '#DEB887',
  ];

  return (
    <div style={{ ...styles.legend, ...styleLegend }}>
      {data.map((entry, index) => (
        <div
          key={index}
          style={{
            ...styles.legendItem,
            ...styleLegendItem,
          }}
        >
          <div
            style={{
              ...styles.colorBox,
              ...styleColorBox,
              backgroundColor: colors
                ? colors[index % colors.length]
                : defaultColors[index % defaultColors.length],
            }}
          ></div>
          <span style={{ ...styles.text, ...styleText }}>{entry.name}</span>
        </div>
      ))}
    </div>
  );
}

const styles = {
  legend: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    borderRadius: '24px',
  },
  colorBox: {
    width: '8px',
    height: '8px',
    marginRight: '8px',
    borderRadius: '999px',
  },
  text: {
    fontSize: '14px',
  },
};
