import React, { useState } from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal as ReactBootstrapModal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import './styles.css';

import FormMyCompanyTaxData from '../../../MyCompanyTaxData/FormCompanyTaxData';
import FormProduct from '../../../Products/NewProduct/FormProduto';
import FormClient from '../../../Clients/NewClient/FormClient';
import NFesRepository from '../../../../../repositories/NFes';

export function FiscalTable({ data, retry }) {
  const [modal, setModal] = useState(false);

  return (
    <>
      <ReactTable
        style={{
          display: data.length <= 8 ? 'block' : '',
          marginTop: 10,
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={data}
        columns={[
          {
            Header: '',
            headerClassName: 'text-left',
            width: 50,
            Cell: (
              <FontAwesomeIcon
                title="Erro"
                style={{ height: '1.4em', width: '1.5em', color: 'red' }}
                cursor="pointer"
                icon={faTimesCircle}
              />
            ),
          },
          {
            Header: 'Item',
            accessor: 'item',
            headerClassName: 'text-left',
            width: 200,
            Cell: (props) => (
              <div>
                <span onClick={() => setModal(true)} id="hyper-link">
                  {props.value}
                </span>
              </div>
            ),
          },
          {
            Header: 'Erro',
            accessor: 'error',
            headerClassName: 'text-left',
            width: 350,
          },
          {
            Header: 'Correção',
            accessor: 'correction',
            headerClassName: 'text-left',
          },
        ]}
        pageSize={data?.length ?? 5}
        loading={false}
        showPagination={false}
        sortable={true}
        noDataText="Não há informação"
      />

      <Modal show={modal} title="Dados Fiscais">
        <FormMyCompanyTaxData
          onCancel={() => setModal(false)}
          onSubmit={retry}
        />
      </Modal>
    </>
  );
}

export function ClientTable({ data, retry, customerId }) {
  const [modal, setModal] = useState(false);
  const [client, setClient] = useState({});

  function handleOpenModal(row) {
    setClient(row.client);
    setModal(true);
  }

  return (
    <>
      <ReactTable
        style={{
          display: data.length <= 8 ? 'block' : '',
          marginTop: 10,
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={data}
        columns={[
          {
            Header: '',
            headerClassName: 'text-left',
            width: 50,
            Cell: (
              <FontAwesomeIcon
                title="Erro"
                style={{ height: '1.4em', width: '1.5em', color: 'red' }}
                cursor="pointer"
                icon={faTimesCircle}
              />
            ),
          },
          {
            Header: 'Item',
            accessor: 'item',
            headerClassName: 'text-left',
            width: 200,
            Cell: (props) => (
              <div>
                <a
                  onClick={() => handleOpenModal(props.original)}
                  id="hyper-link"
                  href="#"
                >
                  {props.value}
                </a>
              </div>
            ),
          },
          {
            Header: 'Erro',
            accessor: 'error',
            headerClassName: 'text-left',
            width: 350,
          },
          {
            Header: 'Correção',
            accessor: 'correction',
            headerClassName: 'text-left',
          },
        ]}
        pageSize={data?.length ?? 5}
        loading={false}
        showPagination={false}
        sortable={true}
        noDataText="Não há informação"
      />

      <Modal
        show={modal}
        title="Dados do Cliente"
        style={{
          maxHeight: 'calc(100vh - 120px)',
          overflowY: 'auto',
          margin: 0,
        }}
      >
        <FormClient
          modal
          hideModal={() => setModal(false)}
          handleModal={retry}
          ClientId={customerId}
        />
      </Modal>
    </>
  );
}

export function ProductTable({ data, retry, items }) {
  const [modal, setModal] = useState(false);
  const [product, setProduct] = useState({});

  async function handleOpenModal(code) {
    const product = items.find((x) => x.code === code);
    await setProduct(product);
    await setModal(true);
  }

  async function closeModal() {
    try {
      await NFesRepository.updateNfeItems(product.id, {
        nfeId: product.NFeId,
        productId: product.productId,
      });

      await setModal(false);

      await retry();
    } catch (err) {
      await setModal(false);

      return toastr.warning('Ocorreu um erro. Por favor, tente novamente');
    }
  }

  return (
    <>
      <ReactTable
        style={{
          display: data.length <= 8 ? 'block' : '',
          marginTop: 10,
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={data}
        columns={[
          {
            Header: '',
            headerClassName: 'text-left',
            width: 50,
            Cell: (
              <FontAwesomeIcon
                title="Erro"
                style={{ height: '1.4em', width: '1.5em', color: 'red' }}
                cursor="pointer"
                icon={faTimesCircle}
              />
            ),
          },
          {
            Header: 'Item',
            accessor: 'itemCode',
            headerClassName: 'text-left',
            width: 200,
            Cell: (props) => (
              <div>
                <a
                  onClick={() => handleOpenModal(props.value)}
                  id="hyper-link"
                  href="#"
                >
                  {props.value}
                </a>
              </div>
            ),
          },
          {
            Header: 'Erro',
            accessor: 'error',
            headerClassName: 'text-left',
            width: 350,
          },
          {
            Header: 'Correção',
            accessor: 'correction',
            headerClassName: 'text-left',
          },
        ]}
        pageSize={data?.length ?? 5}
        loading={false}
        showPagination={false}
        sortable={true}
        noDataText="Não há informação"
      />

      <Modal show={modal} title={`Cadastro do Produto ${product.description}`}>
        <FormProduct
          ProductId={product.productId}
          onSuccess={() => closeModal()}
          onCancel={() => setModal(false)}
        />
      </Modal>
    </>
  );
}

export function Modal({ show, title, children, style }) {
  return (
    <ReactBootstrapModal
      show={show}
      dialogClassName="modal-formProduto"
      animation
    >
      <ReactBootstrapModal.Header>
        <ReactBootstrapModal.Title>
          <strong>{title}</strong>
        </ReactBootstrapModal.Title>
      </ReactBootstrapModal.Header>
      <ReactBootstrapModal.Body id="modal-edit-customer" style={style || {}}>
        {children}
      </ReactBootstrapModal.Body>
    </ReactBootstrapModal>
  );
}
