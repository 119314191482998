import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAuth } from 'contexts/auth';
import { usePlanSignatureContext } from 'contexts/plan-signature';

import companiesRepository from 'repositories/Companies';
import usersRepository from 'repositories/Users';
import signaturesRepository from 'repositories/Signatures';

import './styles.css';
import { useBranding } from 'hooks/useBranding';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

const OnboardingModal = () => {
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const [isFirstPayedSignature, setIsFirstPayedSignature] = useState(false);

  const { user, company, doNotShowOnboardingModalAgain } = useAuth();
  const { isPlanFree } = usePlanSignatureContext();
  const { isWorkMotor, isPO360 } = useBranding();

  const onboardingVideoByBranding = () => {
    if (isWorkMotor) {
      return 'https://www.youtube.com/embed/8oQ3v8_t6G0';
    }

    return 'https://www.youtube.com/embed/wmf0-pdOAXU';
  };

  useEffect(() => {
    const checkModalConditions = () => {
      if (
        user.DoNotShowAgain === true ||
        (user.DoNotShowAgain === false && isFirstPayedSignature) ||
        isPO360
      ) {
        setShowOnboardingModal(false);
      } else {
        setShowOnboardingModal(true);

        localStorage.setItem('hasModalBeenShown', 'true');
      }
    };

    const hasModalBeenShownStored = localStorage.getItem('hasModalBeenShown');

    if (!user || !company || isPlanFree || hasModalBeenShownStored) {
      return;
    }

    checkSignatures();
    checkModalConditions();
  }, [user, company, isFirstPayedSignature]);

  const checkSignatures = async () => {
    const signatures = await signaturesRepository.getByCompany(company.id);
    const payedSignatures = signatures.filter(
      (signature) => signature.Plan_id !== 4
    );

    if (payedSignatures.length === 1) {
      setIsFirstPayedSignature(true);
    }
  };

  const handleSubmitModal = async () => {
    try {
      const date = new Date();

      await usersRepository.mainUpdate(user.id, {
        OnboardingVideoVisualizedDate: date,
        DoNotShowAgain: doNotShowAgain,
      });

      if (!company.OnboardingVideoVisualizedDate) {
        await companiesRepository.mainUpdate(company.id, {
          OnboardingVideoVisualizedDate: date,
        });
      }

      if (doNotShowAgain) {
        doNotShowOnboardingModalAgain(true);
      }

      localStorage.setItem('hasModalBeenShown', 'true');
    } catch (err) {
      console.error(err);
    } finally {
      setShowOnboardingModal(false);
    }
  };

  return (
    <Modal show={showOnboardingModal} dialogClassName="onboarding-modal">
      <Modal.Header>
        <Modal.Title>
          <strong>Seja bem-vindo(a) ao {currentBrandingName}!</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          É muito importante que veja o vídeo abaixo para entender como funciona
          o nosso atendimento.
        </p>
        <iframe
          width="600"
          height="337"
          src={onboardingVideoByBranding()}
          frameBorder="0"
          allowFullScreen
          title="Vídeo de Onboarding"
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-wrapper">
          <button className="success-button" onClick={handleSubmitModal}>
            Ok, entendi
          </button>
          <fieldset>
            <span>Não exibir novamente</span>
            <input
              type="checkbox"
              checked={doNotShowAgain}
              onChange={() =>
                setDoNotShowAgain((doNotShowAgain) => !doNotShowAgain)
              }
            />
          </fieldset>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default OnboardingModal;
