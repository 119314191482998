import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import Button from '../../../../components/CustomButton/CustomButton';
import { format } from 'date-fns';
import InputNumber from 'client/components/InputNumber';
import RenderField from 'components/RenderField';
import AlertModal from 'components/AlertModal/AlertModal';
import constants from '../../../../../utils/constants';
import { Modal } from 'react-bootstrap';
import ModalMoreInformation from './ModalMoreInformation';
import { createNumberMask } from 'redux-form-input-masks';

import ItemsModal from 'client/views/NFe/NewNFe/ItemsModal';
import { toastr } from 'react-redux-toastr';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

const currencyMask = createNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
});

const Items = ({ loading, disableInputs, typePurchase }) => {
  const { items, total, productsValue, purchaseStatusId, allItems, issuedAt } =
    useSelector((state) => state.form.purchases.values);
  const [aprovedItemsModal, setAprovedItemsModal] = useState(false);
  const [moreInformationModal, setMoreInformationModal] = useState(false);

  const [isItemsModalOpen, setIsItemsModalOpen] = useState(false);

  const isCanceled = purchaseStatusId === constants.PURCHASES_STATUS.CANCELED;
  const isClosed = purchaseStatusId === constants.PURCHASES_STATUS.CLOSED;

  const dispatch = useDispatch();

  function handleAppendItems(selectedItems) {
    const newPurchaseItems = [...items];

    selectedItems.forEach((item) => {
      const index = newPurchaseItems.findIndex(
        (child) => child.productId === item.productId
      );

      if (index !== -1) {
        newPurchaseItems[index].quantity =
          newPurchaseItems[index].quantity + item.quantity;
        newPurchaseItems[index].total =
          newPurchaseItems[index].total +
          item.quantity * newPurchaseItems[index].unitValue;
      } else {
        const formatedItem = {
          ...item,
          productCode: item.code,
          description: item.description,
          multiplierBase: item.multiplierBase || 1,
          unitValue: item.Purchase_Price,
          total: item.Purchase_Price * item.quantity,
          approvedDate: '',
        };

        newPurchaseItems.push(formatedItem);
      }
    });

    dispatch(change('purchases', 'items', newPurchaseItems));
    dispatch(change('purchases', 'allItems', newPurchaseItems));
    setIsItemsModalOpen(false);
  }

  function aprovedItems() {
    // verifica os itens que não tem data de aprovação e adicionar
    const allItems = [...items];
    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i].approvedDate === '') {
        dispatch(
          change(
            'purchases',
            `items[${i}].approvedDate`,
            format(new Date(), 'yyyy-MM-dd')
          )
        );
      }
    }
    setAprovedItemsModal(false);
  }

  const handleChangeMultiplierBase = async (value, props) => {
    dispatch(
      change('purchases', `items[${props.index}].multiplierBase`, value)
    );
    setTimeout(() => {
      document.getElementById(`input-${props.index}.multiplierBase`).focus();
    }, 25);
  };

  const handleChangeDescription = async (value, props) => {
    dispatch(change('purchases', `items[${props.index}].description`, value));
    setTimeout(() => {
      document.getElementById(`input-${props.index}.description`).focus();
    }, 25);
  };

  const handleChangeProductUnitValue = (value, props) => {
    const rawValue = value.replace('R$', '').replace('.', ',').replace(',', '');

    const parsedValueStr =
      rawValue.slice(0, rawValue.length - 2) +
      '.' +
      rawValue.slice(rawValue.length - 2);

    const parsedValue = parseFloat(parsedValueStr);

    dispatch(change('purchases', `items[${props.index}].unitValue`, value));
    dispatch(
      change(
        'purchases',
        `items[${props.index}].total`,
        parsedValue * items[props.index].quantity
      )
    );
    setTimeout(() => {
      document.getElementById(`input-${props.index}.unitValue`).focus();
    }, 25);
  };

  const handleChangeProductQuantity = (value, props) => {
    dispatch(change('purchases', `items[${props.index}].quantity`, value));
    dispatch(
      change(
        'purchases',
        `items[${props.index}].total`,
        value * items[props.index].unitValue
      )
    );
    setTimeout(() => {
      document.getElementById(`input-${props.index}.quantity`).focus();
    }, 25);
  };

  const verifyDate = async (index, data) => {
    setTimeout(
      () => document.getElementById(`input-${index}.approvedDate`).focus(),
      50
    );

    // fazer metodo para verificar se data é maior que data de emissão e retornar alerta se n for
    if (!data) {
      await dispatch(
        change('purchases', 'purchaseStatusId', constants.PURCHASES_STATUS.OPEN)
      );
    }
  };

  useEffect(() => {
    if (!items.disableInputs && items.length > 0) {
      const totalSum = items.reduce((prev, curr) => prev + curr.total, 0);
      dispatch(change('purchases', 'productsValue', totalSum));
    }
  }, [items, disableInputs]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div>
        <Button
          type="button"
          className="btn btn-sucesso"
          bsStyle="info"
          fill
          onClick={() => !disableInputs && setIsItemsModalOpen(true)}
          disabled={disableInputs}
          style={{ width: 'auto' }}
        >
          + Adicionar Item
        </Button>
      </div>

      <ReactTable
        id="items-table"
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={[...items, { lastLine: true }]}
        columns={[
          {
            Header: '',
            accessor: 'approvedDate',
            width: 50,
            Cell: (props) =>
              !props.original.lastLine && props.value ? (
                <FontAwesomeIcon
                  style={{ width: '2rem', height: '2rem' }}
                  cursor="pointer"
                  icon={faCalendarCheck}
                  color="green"
                />
              ) : (
                <FontAwesomeIcon
                  style={{ width: '2rem', height: '2rem' }}
                  icon={faCalendar}
                  color={props.value ? 'green' : 'gray'}
                />
              ),
          },
          {
            Header: 'Código',
            accessor: 'productCode',
            width: 90,
            Cell: (props) =>
              !props.original.lastLine && (
                <Field
                  id={`select-${props.index}.productCode`}
                  name={`items[${props.index}].productCode`}
                  component={RenderField}
                  type="text"
                  disabled
                />
              ),
          },
          {
            Header: 'Cód. Fornecedor',
            width: 120,
            Cell: (props) =>
              !props.original.lastLine && (
                <Field
                  id={`input-${props.index}.productProviderCode`}
                  name={`items[${props.index}].productProviderCode`}
                  component={RenderField}
                  maxLength={60}
                  disabled
                />
              ),
          },
          {
            Header: 'Descrição',
            width: 250,
            Cell: (props) =>
              !props.original.lastLine && (
                <Field
                  id={`input-${props.index}.description`}
                  name={`items[${props.index}].description`}
                  onChange={(e) => handleChangeDescription(e, props)}
                  component={RenderField}
                  maxLength={60}
                  disabled={disableInputs}
                />
              ),
          },
          {
            Header: 'Bs. Mult',
            width: 80,
            Cell: (props) =>
              !props.original.lastLine &&
              (isCanceled ? (
                <Field
                  name={`items[${props.index}].multiplierBase`}
                  component={RenderField}
                  disabled={disableInputs}
                  maxLength={60}
                />
              ) : (
                <InputNumber
                  id={`input-${props.index}.multiplierBase`}
                  className="form-control foco-input"
                  disabled={disableInputs}
                  onChange={(e) => handleChangeMultiplierBase(e, props)}
                  value={items[props.index].multiplierBase}
                  name={`items[${props.index}].multiplierBase`}
                />
              )),
          },
          {
            Header: 'QTD',
            width: 80,
            Cell: (props) =>
              !props.original.lastLine && (
                <Field
                  id={`input-${props.index}.quantity`}
                  name={`items[${props.index}].quantity`}
                  component={RenderField}
                  onChange={(e) =>
                    handleChangeProductQuantity(e.target.value, props)
                  }
                  disabled={disableInputs}
                />
              ),
          },
          {
            Header: 'Dt. Entrada',
            width: 170,
            Cell: (props) =>
              !props.original.lastLine && (
                <Field
                  id={`input-${props.index}.approvedDate`}
                  name={`items[${props.index}].approvedDate`}
                  component={RenderField}
                  type="date"
                  disabled={isCanceled || isClosed}
                  onChange={(e) => verifyDate(props.index, e.target.value)}
                />
              ),
          },
          {
            Header: 'Vlr. Unitário',
            width: 110,
            Cell: (props) =>
              !props.original.lastLine && (
                <Field
                  id={`input-${props.index}.unitValue`}
                  name={`items[${props.index}].unitValue`}
                  component={RenderField}
                  maxLength={60}
                  disabled={isCanceled || disableInputs ? true : false}
                  {...currencyMask}
                  onChange={(e) => {
                    handleChangeProductUnitValue(e.target.value, props);
                  }}
                />
              ),
          },
          {
            Header: 'Total',
            width: 130,
            Cell: (props) =>
              !props.original.lastLine && (
                <Field
                  id={`input-${props.index}.total`}
                  name={`items[${props.index}].total`}
                  component={RenderField}
                  maxLength={60}
                  disabled
                  {...currencyMask}
                />
              ),
          },
          typePurchase !== 'XML' && {
            Header: 'Ações',
            width: 60,
            Cell: (props) => {
              const [
                isDeleteConfirmationModalOpen,
                setIsDeleteConfirmationModalOpen,
              ] = useState(false);

              const handleDeleteItem = () => {
                const newItems = items.filter(
                  (_, index) => index !== props.index
                );

                const newTotal = total - Number(props.original.total);
                const newProductsValue =
                  productsValue - Number(props.original.total);

                dispatch(change('purchases', 'items', newItems));
                dispatch(change('purchases', 'total', newTotal));
                dispatch(
                  change('purchases', 'productsValue', newProductsValue)
                );

                toastr.success(
                  'Exclusão de item',
                  'O item foi excluído com sucesso'
                );
              };

              if (!props.original.lastLine && !isClosed && !isCanceled)
                return (
                  <>
                    <FontAwesomeIcon
                      title="Remover Item"
                      icon={faTrashAlt}
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => setIsDeleteConfirmationModalOpen(true)}
                    />

                    {isDeleteConfirmationModalOpen && (
                      <AlertModal
                        show
                        title={currentBrandingName}
                        message="Você deseja excluir o item dessa compra?"
                        onCancel={() => setIsDeleteConfirmationModalOpen(false)}
                        onHide={() => setIsDeleteConfirmationModalOpen(false)}
                        onSubmit={handleDeleteItem}
                      />
                    )}
                  </>
                );

              return '';
            },
          },
        ]}
        defaultPageSize={5}
        loading={loading}
        showPagination={true}
        sortable={false}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          minHeight: '100px',
        }}
      >
        <div>
          <Button
            bsStyle="primary"
            fill
            style={{ marginRight: '15px' }}
            onClick={() => setMoreInformationModal(true)}
            disabled={typePurchase !== 'XML'}
          >
            Mais Informações
          </Button>
          <Button
            bsStyle="info"
            fill
            onClick={() => setAprovedItemsModal(true)}
            disabled={isCanceled ? true : false}
          >
            Aprovar Itens
          </Button>
        </div>
      </div>

      <AlertModal
        show={aprovedItemsModal}
        onHide={() => setAprovedItemsModal(true)}
        onCancel={() => setAprovedItemsModal(false)}
        onSubmit={() => aprovedItems()}
        message="Deseja aprovar todos os itens com a data de hoje?"
      />

      {isItemsModalOpen && (
        <ItemsModal
          showCostValueAsUnitValue={true}
          onCancel={() => setIsItemsModalOpen(false)}
          onSubmit={handleAppendItems}
        />
      )}

      <Modal
        show={moreInformationModal}
        animation
        onHide={() => setMoreInformationModal(false)}
        dialogClassName="modal-information"
      >
        <ModalMoreInformation
          initialValues={{
            companyId: localStorage.getItem('ID_EMPRESA'),
            issuedAt: issuedAt,
            purchaseStatusId: purchaseStatusId,
          }}
          onCancel={() => setMoreInformationModal(false)}
          // onSubmit={handleSubmit}
          aprovedItems={() => setAprovedItemsModal(true)}
          loading={loading}
          items={allItems}
        />
      </Modal>
    </div>
  );
};

export default Items;
