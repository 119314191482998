import create from 'zustand';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import RankingIndicatorRepository from 'v2/repositories/RankingIndicatorRepository';
import { currency } from 'client/components/ToNormalize/ToNormalize';

const useManagementIndicatorsStore = create((set, get) => ({
  selectedOption: 'Financeiro',
  currentDate: new Date(),
  customersTotalValues: [],
  customersTotalQuantity: [],
  customersTotalValuesPercentages: [],
  customersTotalQuantityPercentages: [],
  vehicles: [],
  brands: [],
  productsByQuantity: [],
  productByRentalValue: [],
  productsFamilyPercentageByQuantity: [],
  productsFamilyPercentageByRentalValue: [],
  servicesByQuantity: [],
  servicesByRentalValue: [],
  servicesFamilyPercentageByQuantity: [],
  servicesFamilyPercentageByRentalValue: [],
  salesRanking: [],
  isLoading: false,

  setSelectedOption: (newOption) => set({ selectedOption: newOption }),

  getCurrentMonthAndYear: () => {
    const { currentDate } = get();
    if (currentDate instanceof Date && !isNaN(currentDate)) {
      const monthName = format(currentDate, 'MMMM', { locale: ptBR });
      const capitalizedMonthName =
        monthName.charAt(0).toUpperCase() + monthName.slice(1);
      const year = format(currentDate, 'yyyy', { locale: ptBR });
      return { monthName: capitalizedMonthName, year };
    }
  },
  updateCurrentDate: (newDate) => {
    set({
      currentDate: newDate,
    });
  },

  handleCustomerData: async (companyId) => {
    const { currentDate, formatCustomerData, processPercentages } = get();

    set({ isLoading: true });

    try {
      const month = format(currentDate, 'MM');
      const year = format(currentDate, 'yyyy');

      const monthYear = `${year}-${month}`;

      const customers = await RankingIndicatorRepository.getCustomers(
        companyId,
        monthYear
      );
      const customersValues = formatCustomerData(
        customers.result.byValue.customers
      );
      const customersQuantity = formatCustomerData(
        customers.result.byQuantity.customers,
        customers.result.byQuantity
      );

      const dataValues = processPercentages(
        customers.result.byValue.percentages
      );
      const dataQuantity = processPercentages(
        customers.result.byQuantity.percentages
      );

      set({
        customersTotalValues: customersValues,
        customersTotalQuantity: customersQuantity,
        customersTotalValuesPercentages: dataValues,
        customersTotalQuantityPercentages: dataQuantity,
      });

      return customers;
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoading: false });
    }
  },

  handleServiceProductData: async (companyId) => {
    const { currentDate, handleSerializeProductAndServiceData } = get();

    set({ isLoading: true });

    try {
      const month = format(currentDate, 'MM');
      const year = format(currentDate, 'yyyy');

      const monthYear = `${year}-${month}`;

      const { products, services } =
        await RankingIndicatorRepository.getServiceProduct(
          companyId,
          monthYear
        );

      set({
        productsByQuantity: handleSerializeProductAndServiceData(
          products.productsByQuantity
        ),
        productsByRentalValue: handleSerializeProductAndServiceData(
          products.productsByRentalValue
        ),
        productsFamilyPercentageByQuantity:
          products.familiesPercentageByQuantity,
        productsFamilyPercentageByRentalValue:
          products.familiesPercentageByRentalValue,
        servicesByQuantity: handleSerializeProductAndServiceData(
          services.servicesByQuantity
        ),
        servicesByRentalValue: handleSerializeProductAndServiceData(
          services.servicesByRentalValue
        ),
        servicesFamilyPercentageByQuantity:
          services.familiesPercentageByQuantity,
        servicesFamilyPercentageByRentalValue:
          services.familiesPercentageByRentalValue,
      });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoading: false });
    }
  },

  handleVehicleData: async (companyId) => {
    const { currentDate } = get();

    set({ isLoading: true });

    try {
      const month = format(currentDate, 'MM');
      const year = format(currentDate, 'yyyy');

      const monthYear = `${year}-${month}`;

      const data = await RankingIndicatorRepository.getVehicles(
        companyId,
        monthYear
      );

      set({
        vehicles: data.vehicles,
        brands: data.brands,
      });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoading: false });
    }
  },

  handleSerializeProductAndServiceData: (value) => {
    return value.map((item) => ({
      ...item,
      value: `${item.totalQuantity} - ${currency(item.totalValue)}`,
    }));
  },

  handleSalesRankingData: async (companyId) => {
    const { currentDate } = get();

    set({ isLoading: true });

    try {
      const month = format(currentDate, 'MM');
      const year = format(currentDate, 'yyyy');

      const monthYear = `${year}-${month}`;

      const data = await RankingIndicatorRepository.getSalesRanking(
        companyId,
        monthYear
      );

      set({
        salesRanking: data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoading: false });
    }
  },
  formatCustomerData: (customers, quantity) => {
    return customers.map((customer) => ({
      label: `${customer.type} - ${customer.customerName}`,
      totalSales: quantity
        ? customer.countSales + ' - ' + currency(customer.customerFinalValue)
        : customer.countSales + ' - ' + currency(customer.customerFinalValue),
    }));
  },

  processPercentages: (percentages) => {
    return percentages
      ? Object.entries(percentages).map(([name, value]) => ({
          name:
            name === 'PF'
              ? 'Pessoa Física'
              : name === 'PJ'
              ? 'Pessoa Jurídica'
              : name,
          value: parseFloat(value),
        }))
      : [];
  },
}));

export default useManagementIndicatorsStore;
