import React, { useEffect, useState } from 'react';

import SearchInput from '../../../../../components/SearchInput';
import useFilters from '../../../../../hooks/useFilters';
import useDebounce from '../../../../../hooks/useDebounce';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { SelectMulti } from 'v2/components/Select/Multi';
import { useCallback } from 'react';

const PartApplicationFilterForm = ({
  brandsOptions,
  vehiclesFipe,
  setFilteredVehiclesFipe,
}) => {
  const { queryFilter, multiplesOptionsFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSaveQuerySearch = useDebounce(setSearchQuery, 800);

  const [brand, setBrand] = useState([]);

  const {
    multiplesOptions: brandFilter,
    setMultiplesOptions: setBrandsOptionsFilter,
    filterByMultiplesOptions: filterByBrands,
  } = multiplesOptionsFilter;

  const handleChangeQuerySearch = (value) => {
    setQuery(value);
    debouncedSaveQuerySearch(value);
  };

  useEffect(() => {
    handleSetBrandsFilter();
  }, [brand]);

  useEffect(() => {
    if (!!vehiclesFipe.length) {
      setFilteredVehiclesFipe(vehiclesFipe.filter(handleFilter));
    }
  }, [vehiclesFipe, query, brand, brandFilter]);

  function handleFilter(vehicle) {
    const querySearch = [
      vehicle['Code_FIPE'],
      vehicle['Model'],
      vehicle['Brand'],
    ];

    return filterByQuery(querySearch) && filterByBrands('Brand', vehicle);
  }

  const handleSetBrandsFilter = useCallback(() => {
    const serializedBrandsDescription = brand.map((brand) => brand.value);
    setBrandsOptionsFilter(serializedBrandsDescription);
  }, [brand]);

  useEffect(() => {
    setFilteredVehiclesFipe(vehiclesFipe.filter(handleFilter));
  }, [query, brand]);

  return (
    <div className="products-filter-row">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '10px',
        }}
      >
        <InputContainer variant="row">
          <InputLabel>Marca:</InputLabel>
          <SelectMulti
            value={brand}
            options={brandsOptions}
            onChange={(e) => setBrand(e)}
            hasAllOption
            customAllLabel="Todas"
            customVariousLabel="Várias"
            selectAllByDefault
            height={'35px'}
          />
        </InputContainer>
      </div>

      <SearchInput
        placeholder="Pesquisa por Código Fipe e Modelo"
        value={query}
        style={{
          fontSize: 12,
          width: 350,
        }}
        containerStyle={{ margin: '0 10px' }}
        onChange={(e) => handleChangeQuerySearch(e.target.value)}
      />
    </div>
  );
};

export default PartApplicationFilterForm;
