import React, { useState, useEffect } from 'react';
import { SelectMulti } from 'v2/components/Select/Multi';

export default function AdvertisementsFilter({
  productsOptions,
  selectedProducts,
  setSelectedProducts,
}) {
  const handleProductChange = (value) => {
    setSelectedProducts(value);
  };

  return (
    <fieldset className="foco-input">
      <span className="typography__text-2">Produtos:</span>
      <SelectMulti
        height={35}
        width={130}
        options={productsOptions}
        value={selectedProducts}
        onChange={handleProductChange}
        hasAllOption={true}
        customAllLabel="Todos"
        className="foco-input"
      />
    </fieldset>
  );
}
