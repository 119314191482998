import React, { useEffect, useState } from 'react';
import useOnboardingStore from '../store/onboarding-store';

export const FooterButtons = ({ handleClose, handleReload }) => {
  const {
    handleHasIncompleteModule,
    handleHasIncompleteTraining,
    handleValidateOpenSave,
    setIsFeedbackModalOpen,
    isLoading,
  } = useOnboardingStore();
  return (
    <div className="flex end row gap-050">
      <button className="button button-red" onClick={handleClose}>
        Fechar
      </button>
      <button
        className="button button-blue"
        disabled={isLoading}
        onClick={async () => {
          if (await handleValidateOpenSave()) {
            handleReload();
            handleClose();
          }
        }}
      >
        Salvar em Aberto
      </button>
      <button
        className="button button-green"
        disabled={
          !handleHasIncompleteModule() ||
          !handleHasIncompleteTraining() ||
          isLoading
        }
        onClick={() => setIsFeedbackModalOpen(true)}
      >
        Finalizar
      </button>
    </div>
  );
};
