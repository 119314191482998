import React from 'react';

import SearchInput from 'components/SearchInput';
import { SelectMultiBranding } from 'admin/v2/components/Input/Select/SelectMultiBranding';
import { InputContainer } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';

function NewsFilter({
  history,
  query,
  handleChangeQuery,
  status,
  setStatus,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  branding,
  setBranding,
  type,
  setType,
  loading,
}) {
  return (
    <div className="flex column gap-1 mt-1">
      <div className="flex align-end gap-050">
        <button
          className="button button-h35 btn-sucesso"
          onClick={() => history.push('news-register')}
        >
          + Nova Notícia
        </button>

        <SearchInput
          placeholder="Pesquisa por Descrição"
          value={query}
          onChange={(e) => handleChangeQuery(e.target.value)}
          disabled={loading}
        />

        <InputContainer>
          <span className="typography__text-2">Status:</span>
          <InputSelectDefault
            width={100}
            height={35}
            value={status}
            onChange={(e) => setStatus(e)}
            options={[
              {
                label: 'Ambos',
                value: '',
              },
              {
                label: 'Ativas',
                value: '1',
              },
              {
                label: 'Inativas',
                value: '0',
              },
            ]}
            disabled={loading}
          />
        </InputContainer>

        <InputContainer>
          <span className="typography__text-2">Produto:</span>
          <SelectMultiBranding
            value={branding}
            height={'35px'}
            onChange={(e) => setBranding(e)}
            showAllOption
            selectAllByDefault
            isDisabled={loading}
            isLoading={loading}
          />
        </InputContainer>
      </div>

      <div className="flex align-end gap-050">
        <InputContainer>
          <span className="typography__text-2">Tipo de Data:</span>
          <InputSelectDefault
            width={120}
            height={35}
            value={type}
            onChange={(e) => setType(e)}
            options={[
              {
                label: 'Criação',
                value: 'createdAt',
              },
              {
                label: 'Vigência',
                value: 'duration',
              },
            ]}
            disabled={loading}
            isLoading={loading}
          />
        </InputContainer>
        <InputContainer>
          <span className="typography__text-2">Data Inicial:</span>
          <input
            className="form-control foco-input"
            type="date"
            name="dataInicial"
            value={initialDate}
            max={'9999-12-31'}
            onChange={(e) => {
              setInitialDate(e.target.value);
            }}
            disabled={loading}
          />
        </InputContainer>

        <InputContainer>
          <span className="typography__text-2">Data Final:</span>
          <input
            className="form-control foco-input"
            type="date"
            name="dataFinal"
            value={finalDate}
            max={'9999-12-31'}
            onChange={(e) => {
              setFinalDate(e.target.value);
            }}
            disabled={loading}
          />
        </InputContainer>
      </div>
    </div>
  );
}

export default NewsFilter;
