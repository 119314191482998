import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import ProductsServicesTable from '../../../components/ProductsServicesTable';
import NFeSheet from '../../../components/ProductsServicesTable/Sheets/NFeSheet';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import AlertModal from 'components/AlertModal/AlertModal';

import { toastr } from 'react-redux-toastr';

const ItemsModal = ({
  onCancel,
  onSubmit,
  discountPriceProducts,
  showCostValueAsUnitValue,
}) => {
  const [items, setItems] = useState([]);
  const [isClearSheetModalOpen, setIsClearSheetModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const handleAdd = (item) => {
    const {
      id,
      Sale_Price,
      salePriceWholesale,
      PIS_Exit,
      COFINS_Exit,
      Brands,
      Families,
      CFOP_InState_Code,
      IPI,
      Origin,
      CFOP_OutState_Code,
      CST,
      CSOSN,
      Code,
      Description,
      NCM_Code,
      CEST_Code,
      Bar_Code,
      AlternativeCode,
      Tribute_BarCode,
      Unit_Type,
      ANP,
      ANP_Description,
      ANP_UF_Code,
      Purchase_Price,
      Profit_Margin,
      profitMarginWholesale,
      Manufacturer_Ref,
      Multiplier_Base,
      Weight,
    } = item;

    if (discountPriceProducts) {
      const isAlreadyInTable = discountPriceProducts.map(
        (product) => product.Product.id
      );

      if (isAlreadyInTable.includes(item.id)) {
        return toastr.error(
          'Produto faz parte dessa tabela de preços diferenciados'
        );
      }
    }

    const newItems = [...items];
    const index = newItems.findIndex((item) => item.productId === id);

    if (index !== -1) {
      newItems[index].quantity++;
    } else {
      const product = {
        productId: id,
        STPIS: PIS_Exit,
        STCOFINS: COFINS_Exit,
        CFOP_InState_Code,
        IPI,
        CFOP_OutState_Code,
        CST,
        Brand: Brands?.Description,
        Family: Families?.Description,
        CSOSN,
        code: Code,
        description: Description,
        unitType: Unit_Type,
        NCM: NCM_Code,
        CEST: CEST_Code,
        barCode: Bar_Code,
        alternativeCode: AlternativeCode,
        tributeBarCode: Tribute_BarCode,
        origin: Origin,
        unitValue: Sale_Price,
        salePriceWholesale,
        quantity: 1,
        quantityDevolution: 0,
        multiplierBase: Multiplier_Base,
        ANP,
        ANPDescription: ANP_Description,
        ANPUF: ANP_UF_Code,
        Purchase_Price,
        Profit_Margin,
        profitMarginWholesale,
        Manufacturer_Ref,
        Weight,
      };

      newItems.push(product);
    }

    setItems(newItems);
  };

  const handleClearSheet = () => {
    setItems([]);
    setIsClearSheetModalOpen(false);
  };

  return (
    <>
      <Modal
        show
        onHide={() => (!items.length ? onCancel() : setIsCancelModalOpen(true))}
        animation
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Adicionar Itens</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="NFe-items-modal">
            <ProductsServicesTable
              productsList
              onInclude={handleAdd}
              discountPriceProducts={discountPriceProducts}
            />
            <NFeSheet
              items={items}
              setItems={setItems}
              showCostValueAsUnitValue={showCostValueAsUnitValue}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end align-center">
            <Button
              style={{ height: '35px' }}
              type="button"
              bsStyle="danger"
              pullRight
              fill
              onClick={() =>
                !items.length ? onCancel() : setIsCancelModalOpen(true)
              }
            >
              Cancelar
            </Button>
            <Button
              style={{ height: '35px' }}
              disabled={items.length === 0}
              pullRight
              type="button"
              bsStyle="primary"
              onClick={() => setIsClearSheetModalOpen(true)}
              fill
            >
              Limpar Itens
            </Button>
            <Button
              style={{ height: '35px' }}
              type="button"
              bsStyle="info"
              pullRight
              fill
              onClick={() => onSubmit(items)}
            >
              Adicionar Itens
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        message="Deseja limpar todos os itens ?"
        show={isClearSheetModalOpen}
        onHide={() => setIsClearSheetModalOpen(false)}
        onCancel={() => setIsClearSheetModalOpen(false)}
        onSubmit={handleClearSheet}
      />

      <AlertModal
        message="Deseja fechar o modal e não adicionar os itens selecionados ?"
        show={isCancelModalOpen}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        onSubmit={onCancel}
      />
    </>
  );
};

export default ItemsModal;
