import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { useSelector, useDispatch } from 'react-redux';
import { useRecoilState } from 'recoil';

import FinancingClientStep from './Steps/Client';
import FinancingAuthorizedStep from './Steps/Authorized';
import FinancingConfirmedStep from './Steps/Confirmed';
import FinancingDeniedStep from './Steps/Denied';

import FinancingStepWizard from './StepWizard';
import FinancingModalFooter from './Footer';

import LoadingModal from 'client/components/LoadingModal';
import FinancingCancelConfirmationDialog from './CancelConfirmationDialog';

import {
  handleChange,
  handleSaleStatusAndType,
} from '../NewSale/FormSale/redux/actions';

import { useAuth } from 'contexts/auth';

import { getUserIpv4 } from 'utils/geolocation';

import {
  FINANCING_AMOUNT,
  FINANCING_BOLETOFLEX_INSTALLMENTS,
  FINANCING_BOLETOFLEX_REDIRECT_URL,
  FINANCING_CURRENT_STEP,
  FINANCING_KOIN_FRAUDID,
  FINANCING_KOIN_INSTALLMENTS,
  FINANCING_KOIN_SELECTED_INCOMING_OPTION,
  FINANCING_KOIN_INCOMING_OPTIONS,
  FINANCING_KOIN_PAYMENTID,
  FINANCING_KOIN_TOKEN,
  FINANCING_LOADING,
  FINANCING_LOADING_MESSAGE,
  FINANCING_PARTNERID,
  FINANCING_TRANSACTIONID,
  FINANCING_TRIGGER_CREATE_TRANSACTION,
  FINANCING_TRIGGER_SAVE_SALE,
  FINANCING_FAILED_MESSAGE,
} from './recoil';

import financingStrings from './strings';

import koinRepository from 'repositories/KoinRepository';
import boletoFlexTransactionsRepository from 'repositories/BoletoFlexTransactions';

import './styles.css';
import { usePlanSignatureContext } from 'contexts/plan-signature';

function handleFinancingStep(currentStep) {
  switch (currentStep) {
    case 1:
      return <FinancingClientStep />;
    case 2:
      return <FinancingAuthorizedStep />;
    case 3:
      return <FinancingConfirmedStep />;
    case 4:
      return <FinancingDeniedStep />;
    default:
      return;
  }
}

function handleChangeModalWidthByStep(currentStep) {
  const width = {
    1: '70w',
    2: '40w',
    3: '50w',
    4: '50w',
  };

  return width[currentStep];
}

export default function FinancingModal({ open, handleClose, financingModule }) {
  const [isLoading, setIsLoading] = useRecoilState(FINANCING_LOADING);
  const [partnerId, setPartnerId] = useRecoilState(FINANCING_PARTNERID);
  const [boletoFlexUrl, setBoletoFlexUrl] = useRecoilState(
    FINANCING_BOLETOFLEX_REDIRECT_URL
  );

  const [loadingMessage, setLoadingMessage] = useRecoilState(
    FINANCING_LOADING_MESSAGE
  );

  const [triggerSaveSale, setTriggerSaveSale] = useRecoilState(
    FINANCING_TRIGGER_SAVE_SALE
  );

  const [triggerCreateTransaction, settriggerCreateTransaction] =
    useRecoilState(FINANCING_TRIGGER_CREATE_TRANSACTION);

  const [currentStep, setCurrentStep] = useRecoilState(FINANCING_CURRENT_STEP);

  const [isFinancingDenied, setIsFinancingDenied] = useState(false);

  const stepWizardPercent =
    currentStep === 1 ? 0 : currentStep === 2 ? 50 : 100;
  const stepWizardVariant = isFinancingDenied ? 'denied' : 'full';

  const modalOnHideFn = currentStep === 4 ? handleCloseModal : null;

  const modalClasses = `modal-${handleChangeModalWidthByStep(
    currentStep
  )} financing-modal`;

  const [koinFraudId, setKoinFraudId] = useRecoilState(FINANCING_KOIN_FRAUDID);
  const [koinToken, setKoinToken] = useRecoilState(FINANCING_KOIN_TOKEN);
  const [koinPaymentId, setKoinPaymentId] = useRecoilState(
    FINANCING_KOIN_PAYMENTID
  );
  const [koinInstallments, setKoinInstallments] = useRecoilState(
    FINANCING_KOIN_INSTALLMENTS
  );

  const [koinIncomingOptions, setKoinIncomingOptions] = useRecoilState(
    FINANCING_KOIN_INCOMING_OPTIONS
  );

  const [koinSelectedIncomingOption, setKoinSelectedIncomingOption] =
    useRecoilState(FINANCING_KOIN_SELECTED_INCOMING_OPTION);

  const [boletoFlexInstallments, setBoletoFlexInstallments] = useRecoilState(
    FINANCING_BOLETOFLEX_INSTALLMENTS
  );

  const [financingTransactionId, setFinancingTransactionId] = useRecoilState(
    FINANCING_TRANSACTIONID
  );

  const [financingAmount, setFinancingAmount] =
    useRecoilState(FINANCING_AMOUNT);

  const [financingFailedMessage, setFinancingFailedMessage] = useRecoilState(
    FINANCING_FAILED_MESSAGE
  );

  const { company, userId } = useAuth();
  const { isPlanFree, isPlanStart, isPlanBasic, isPaidWorkmotor } =
    usePlanSignatureContext();

  const { saleReducer } = useSelector((state) => state);

  const dispatch = useDispatch();

  const { items, KoinTransaction, saleId, readyForSimulateFinancing, total } =
    useSelector((state) => state.saleReducer);

  function handleResetRecoil() {
    setKoinFraudId(null);
    setKoinInstallments(null);
    setLoadingMessage(financingStrings.FINANCING_LOADING_CLIENT);
    setFinancingAmount(0);
    setFinancingFailedMessage(null);

    setCurrentStep(1);
  }

  function handleRemoveKoinFrame() {
    const koinFrame = document.getElementById('koin-frame');
    koinFrame.remove();
  }

  function handleCloseModal() {
    handleResetRecoil();
    if (partnerId === 1) {
      handleRemoveKoinFrame();
    }
    handleClose();
  }

  async function handleSaveSale() {
    try {
      const hasServices = items.filter((c) => c.Type === 'Serviço').length > 0;

      setIsLoading(true);
      setLoadingMessage(financingStrings.FINANCING_SAVING_SALE);

      dispatch(handleChange(true, 'keepOnScreenAfterSave'));

      await dispatch(
        handleSaleStatusAndType(
          saleReducer,
          hasServices ? 'os-aberta' : 'venda-aberta',
          null,
          company,
          userId,
          isPlanFree,
          isPlanStart,
          isPlanBasic,
          isPaidWorkmotor
        )
      );
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      dispatch(handleChange(true, 'keepOnScreenAfterSave'));
      toastr.error('OS Digital', financingStrings.FINANCING_SAVING_SALE_ERROR);
    }
  }

  function handleKoinSimulationSuccess({ incomingOptions, token }) {
    setIsFinancingDenied(false);
    setFinancingAmount(incomingOptions[0].installments[0]?.originalValue);
    setKoinToken(token);
    setKoinIncomingOptions(incomingOptions);
    setKoinSelectedIncomingOption(incomingOptions[0].installments);
    setKoinInstallments(incomingOptions[0].installments);
    setCurrentStep(2);
    setIsLoading(false);
    dispatch(handleChange(false, 'keepOnScreenAfterSave'));
  }

  function handleKoinSimulationRejection() {
    setIsFinancingDenied(true);
    setFinancingAmount(total);
    setCurrentStep(4);
    setIsLoading(false);
    dispatch(handleChange(false, 'keepOnScreenAfterSave'));
  }

  async function handleKoinSimulation() {
    try {
      const params = {
        saleId,
        ip: await getUserIpv4(),
      };

      const { status, incomingOptions, token } =
        await koinRepository.authorizate(params);

      switch (status) {
        case 'APPROVED':
          return handleKoinSimulationSuccess({ incomingOptions, token });
        default:
          return handleKoinSimulationRejection();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toastr.error('OS Digital', financingStrings.FINANCING_SIMULATION_ERROR);
    }
  }

  function handleKoinTransactionSuccess(transactionId) {
    setFinancingAmount(total);
    setFinancingTransactionId(transactionId);
    setIsLoading(false);
    setCurrentStep(3);
    dispatch(handleChange(false, 'keepOnScreenAfterSave'));
    dispatch(handleChange(6, 'saleStatusId'));
  }

  function handleKoinTransactionRejection() {
    setFinancingAmount(total);
    setIsLoading(false);
    setIsFinancingDenied(true);
    setCurrentStep(4);
    dispatch(handleChange(false, 'keepOnScreenAfterSave'));
  }

  function handleKoinTransactionFailed(failedMessage) {
    setFinancingAmount(total);
    setFinancingFailedMessage(failedMessage);
    setIsLoading(false);
    setIsFinancingDenied(true);
    setCurrentStep(4);
    dispatch(handleChange(false, 'keepOnScreenAfterSave'));
  }

  async function handleKoinTransaction() {
    try {
      const data = {
        requestTransaction: {
          saleId,
          fraudId: koinFraudId,
          paymentType: koinPaymentId,
        },
        installments: koinInstallments,
        token: koinToken,
      };

      const res = await koinRepository.createTransaction(data);

      const status = res.status || res.createdTransaction?.status;

      if (status === 'PENDING' || status === 'APPROVED') {
        dispatch(handleChange(res.createdTransaction, 'KoinTransaction'));
        handleKoinTransactionSuccess(res.createdTransaction.transactionId);
      } else if (status === 'FAILED') {
        handleKoinTransactionFailed(res.message);
      } else {
        handleKoinTransactionRejection();
      }
    } catch (err) {
      console.log(err);
      toastr.error('OS Digital', financingStrings.FINANCING_SIMULATION_ERROR);
      setIsLoading(false);
    }
  }

  function handleBoletoFlexApproval(installments) {
    setBoletoFlexInstallments(installments);
    setIsFinancingDenied(false);
    setFinancingAmount(total);
    setCurrentStep(2);
    setIsLoading(false);
  }

  function handleBoletoFlexRejection() {
    setIsFinancingDenied(true);
    setCurrentStep(4);
    setIsLoading(false);
  }

  async function handleBoletoFlexSimulation() {
    try {
      const simulation = await boletoFlexTransactionsRepository.simulate(
        saleId
      );
      const isEligible = simulation.status === 'ELIGIBLE' ? true : false;

      dispatch(handleChange(simulation, 'BoletoFlexTransaction'));

      if (isEligible) {
        handleBoletoFlexApproval(simulation?.installments);
      } else {
        handleBoletoFlexRejection();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toastr.error('OS Digital', financingStrings.FINANCING_SIMULATION_ERROR);
    }
  }

  async function handleBoletoFlexTransaction() {
    try {
      const transaction = await boletoFlexTransactionsRepository.create(saleId);

      const approved =
        transaction.status === 'ELIGIBLE' || transaction.status === 'CREATED'
          ? true
          : false;

      dispatch(handleChange(transaction, 'BoletoFlexTransaction'));

      if (approved) {
        handleBoletoFlexTransactionSuccess(
          transaction.transactionId,
          transaction.redirectUrl
        );
      } else {
        handleBoletoFlexTransactionRejection();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toastr.error('OS Digital', financingStrings.FINANCING_SIMULATION_ERROR);
    }
  }

  function handleBoletoFlexTransactionSuccess(transactionId, redirectUrl) {
    setFinancingTransactionId(transactionId);
    setBoletoFlexUrl(redirectUrl);
    setFinancingAmount(total);
    setIsLoading(false);
    setCurrentStep(3);
    dispatch(handleChange(false, 'keepOnScreenAfterSave'));
    dispatch(handleChange(6, 'saleStatusId'));
  }

  function handleBoletoFlexTransactionRejection() {
    setIsFinancingDenied(true);
    setCurrentStep(4);
    dispatch(handleChange(false, 'keepOnScreenAfterSave'));
  }

  async function handleFinancingPartner() {
    try {
      setLoadingMessage(financingStrings.FINANCING_SIMULATION_LOADING);
      setIsLoading(true);

      switch (partnerId) {
        case 1:
          return handleKoinSimulation();
        case 2:
          return handleBoletoFlexSimulation();
        default:
          setIsLoading(false);
          toastr.error('OS Digital', financingStrings.INVALID_PARTNER);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toastr.error('OS Digital', financingStrings.FINANCING_SIMULATION_ERROR);
    }
  }

  async function handleTransaction() {
    try {
      setLoadingMessage(financingStrings.FINANCING_SIMULATION_LOADING);
      setIsLoading(true);

      switch (partnerId) {
        case 1:
          return handleKoinTransaction();
        case 2:
          return handleBoletoFlexTransaction();
        default:
          setIsLoading(false);
          toastr.error('OS Digital', financingStrings.INVALID_PARTNER);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toastr.error('OS Digital', financingStrings.FINANCING_SIMULATION_ERROR);
    }
  }

  function handleSetFraudId() {
    const script = document.createElement('script');
    script.src = 'https://resources.koin.com.br/scripts/koin.min.js';
    document.getElementsByTagName('head')[0].appendChild(script);
    script.onload = function () {
      var fraudId = window.GetKoinFraudID(function (guid) {
        setKoinFraudId(guid);
      });
    };
  }

  function handleFinancingStatus(partnerId) {
    switch (partnerId) {
      case 1:
        const koinTransaction = KoinTransaction;
        if (
          koinTransaction?.status === 'REJECTED' ||
          koinTransaction?.status === 'FAILED'
        ) {
          setIsLoading(false);
          setFinancingAmount(total);
          setFinancingFailedMessage(koinTransaction?.message ?? null);
          setCurrentStep(4);
        }
        break;
      case 2:
        setIsLoading(false);
        break;
      default:
        return;
    }
  }

  useEffect(() => {
    if (triggerSaveSale) {
      setTriggerSaveSale(false);
      handleSaveSale();
    }
  }, [triggerSaveSale]);

  useEffect(() => {
    if (triggerCreateTransaction) {
      settriggerCreateTransaction(false);
      handleTransaction();
    }
  }, [triggerCreateTransaction]);

  useEffect(() => {
    if (readyForSimulateFinancing) {
      handleFinancingPartner();
      dispatch(handleChange(false, 'readyForSimulateFinancing'));
    }
  }, [readyForSimulateFinancing]);

  useEffect(() => {
    setPartnerId(financingModule.partnerId);

    handleFinancingStatus(financingModule.partnerId);

    if (financingModule.partnerId === 1) {
      handleSetFraudId();
    }
  }, []);

  return (
    <Modal show={open} onHide={modalOnHideFn} dialogClassName={modalClasses}>
      <Modal.Header>
        <FinancingStepWizard
          percent={stepWizardPercent}
          variant={stepWizardVariant}
        />
      </Modal.Header>
      <Modal.Body>
        {handleFinancingStep(currentStep)}
        {isLoading && (
          <LoadingModal
            showLoadingModal={true}
            customMessage={loadingMessage}
          />
        )}

        <FinancingCancelConfirmationDialog
          handleCloseFinancingModal={handleCloseModal}
        />
      </Modal.Body>
      <Modal.Footer>
        <FinancingModalFooter handleCloseFinancingModal={handleCloseModal} />
      </Modal.Footer>
    </Modal>
  );
}
