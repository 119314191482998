import React from 'react';
import { useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import useManagementIndicatorsStore from '../../../store/management-indicators-store';
import { TableRank } from '../components/TableRank';
import { PieChartGraph } from 'v2/components/PieChartGraph';
export function RankingIndicatorsCustomers() {
  const { companyId } = useAuth();
  const {
    handleCustomerData,
    currentDate,
    customersTotalValues,
    customersTotalQuantity,
    customersTotalValuesPercentages,
    customersTotalQuantityPercentages,
    isLoading,
  } = useManagementIndicatorsStore((store) => {
    return {
      handleCustomerData: store.handleCustomerData,
      currentDate: store.currentDate,
      customersTotalValues: store.customersTotalValues,
      customersTotalQuantity: store.customersTotalQuantity,
      customersTotalValuesPercentages: store.customersTotalValuesPercentages,
      customersTotalQuantityPercentages:
        store.customersTotalQuantityPercentages,
      isLoading: store.isLoading,
    };
  });

  const getDataCustomers = async () => {
    return await handleCustomerData(companyId);
  };
  useEffect(() => {
    getDataCustomers();
  }, [currentDate]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        marginBottom: '2rem',
        marginTop: '2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1.5rem',
        }}
      >
        <TableRank
          rows={customersTotalQuantity || []}
          labelKey="label"
          valueKey="totalSales"
          labelName="CLIENTES COM MAIS VENDAS"
          valueName="QTD/R$"
          valueWidth="110px"
        />
        <TableRank
          rows={customersTotalValues || []}
          labelKey="label"
          valueKey="totalSales"
          labelName="CLIENTES QUE MAIS GASTARAM"
          valueName="QTD/R$"
          valueWidth="110px"
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1.5rem',
          width: '100%',
          minHeight: '400px',
        }}
      >
        <PieChartGraph.Root>
          {!isLoading && customersTotalQuantity.length > 0 && (
            <PieChartGraph.Subtitle
              data={customersTotalValuesPercentages || []}
              styleLegend={{ marginTop: '1.5rem', gap: '10rem' }}
            />
          )}

          <PieChartGraph.Graph
            data={customersTotalValuesPercentages || []}
            tooltipNameFormatter={(value) => `${value}%`}
          />
        </PieChartGraph.Root>
        <PieChartGraph.Root>
          {!isLoading && customersTotalValues.length > 0 && (
            <PieChartGraph.Subtitle
              data={customersTotalQuantityPercentages || []}
              styleLegend={{ marginTop: '1.5rem', gap: '10rem' }}
            />
          )}

          <PieChartGraph.Graph
            data={customersTotalQuantityPercentages || []}
            tooltipNameFormatter={(value) => `${value}%`}
          />
        </PieChartGraph.Root>
      </div>
    </div>
  );
}
