import styled from 'styled-components';

export const InputContainerStyled = styled.div`
  display: flex;
  gap: ${({ gap }) => gap};

  white-space: nowrap;

  ${(props) =>
    props.variant === 'row' &&
    `
      flex-direction: row;
      align-items: baseline;
    `}

  ${(props) =>
    props.variant === 'column' &&
    `
      flex-direction: column;
      justify-content: center;
    `}

  ${(props) =>
    props.align &&
    `
    align-items: ${props.align};
  `}

  ${(props) =>
    props.justify &&
    `
    justify-content: ${props.justify};
  `}
`;
