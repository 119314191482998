import React, { useState, useEffect } from 'react';

import CardForm from '../../../components/CardForm';
import { Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Button from '../../../../client/components/CustomButton/CustomButton';
import discountGroupRepository from '../../../../repositories/DiscountGroups';
import discountPriceProductsRepository from '../../../../repositories/DiscountPriceProducts';

import { toastr } from 'react-redux-toastr';

import { useAuth } from 'contexts/auth';
import useFilters from 'hooks/useFilters';

import SearchInput from 'components/SearchInput';
import TableData from './TableData';

import ItemsModal from '../../NFe/NewNFe/ItemsModal';
import PercentInput from '../../../components/Percent/index';
import AlertModal from '../../../../components/AlertModal';

import './styles.scss';

const ProductsTable = ({ history }) => {
  const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [isConfirmationDeleteModalOpen, setIsConfirmationDeleteModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [discountGroupProduct, setDiscountGroupProduct] = useState([]);
  const [discountGroupProductMargin, setDiscountGroupProductMargin] =
    useState(0);
  const [productsTable, setProductsTable] = useState([]);

  const [discountGroupId, setDiscountGroupId] = useState(null);

  useEffect(() => {
    setDiscountGroupId(history.location.hash.split('#')[1]);
  }, [history]);

  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;

  useEffect(() => {
    if (discountGroupId) loadDiscountGroup(discountGroupId);
  }, [discountGroupId]);
  useEffect(() => {
    if (discountGroupId) loadDiscountPriceProducts(discountGroupId);
  }, [discountGroupId]);

  async function loadDiscountGroup(id) {
    try {
      setLoading(true);

      const data = await discountGroupRepository.findById(id);

      setDiscountGroupProduct(data);
      setDiscountGroupProductMargin(data.margin);
    } catch (err) {
      toastr.error(
        'Erro ao carregar dados da tabela',
        'Ocorreu um erro ao carregar os dados da tabela de preços diferenciados, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function loadDiscountPriceProducts(id) {
    try {
      setLoading(true);

      const data = await discountPriceProductsRepository.show(id);

      const formattedProduct = data.map((data) => {
        const saleDif =
          data.Product.Sale_Price * (data.margin / 100) +
          data.Product.Sale_Price;
        return {
          ...data,
          saleDif,
          margin: data.margin,
          code: data.Product.Code,
          description: data.Product.Description,
          purchasePrice: data.Product.Purchase_Price,
          profitMargin: data.Product.Profit_Margin,
          salePrice: data.Product.Sale_Price,
        };
      });

      setProductsTable(formattedProduct);
    } catch (err) {
      toastr.error(
        'Erro ao carregar dados da tabela',
        'Ocorreu um erro ao carregar os dados da tabela, tente novamente'
      );
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(values) {
    try {
      setLoading(true);

      await discountPriceProductsRepository.create({
        products: values.map((product) => product.productId),
        groupId: discountGroupId,
        margin: discountGroupProduct.margin,
      });

      setIsNewProductModalOpen(false);

      loadDiscountPriceProducts(discountGroupId);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function handleChangeDiscontPrice() {
    try {
      setLoading(true);

      const updateProducts = productsTable.map((product) => {
        return {
          id: product.id,
          productId: product.productId,
        };
      });

      await discountPriceProductsRepository.update({
        margin: discountGroupProductMargin,
        groupId: discountGroupId,
        products: updateProducts,
      });

      loadDiscountPriceProducts(discountGroupId);
      toastr.success('Margem alterada com sucesso');
    } catch (err) {
      toastr.error('Erro ao salvar os dados da tabela');
      console.error(err);
    } finally {
      setLoading(false);
      setOpenConfirmationModal(false);
    }
  }

  async function handleDeleteProduct(productsToDelete) {
    try {
      setLoading(true);

      await discountPriceProductsRepository.deleteProduct(productsToDelete.id);

      await loadDiscountPriceProducts(discountGroupId);

      setIsConfirmationDeleteModalOpen(false);
      toastr.success('Produto removido com sucesso');
    } catch (err) {
      toastr.error('Erro ao exlcuir produto da tabela');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  function handleFilter(discountGroup) {
    const querySearch = [
      discountGroup['code'],
      discountGroup['description'],
      discountGroup['Manufacturer_Ref'],
    ];

    return filterByQuery(querySearch);
  }

  return (
    <CardForm style={{ marginTop: '30px' }} title="Dados Principais">
      <header>
        <Col lg={12} md={12} sm={12} xs={12} style={{ width: '50%' }}>
          <Col lg={5} md={5} sm={5} xs={12}>
            <label htmlFor="description">Descrição: </label>
            <input
              style={{ paddingLeft: '10px' }}
              name="description"
              className="input"
              id="description"
              disabled
              value={discountGroupProduct.name}
            />
          </Col>
          <Col lg={5} md={5} sm={5} xs={12}>
            <label htmlFor="description">Margem Diferenciada: </label>
            {/* <input name='description' className='input' id='description' /> */}
            <PercentInput
              suffix="%"
              className="form-control foco-input"
              decimalSeparator=","
              inputType="text"
              value={discountGroupProductMargin}
              onChangeEvent={(e) =>
                setDiscountGroupProductMargin(e.target.value)
              }
            />
          </Col>
          <Col lg={2} md={2} sm={2} xs={12}>
            <Button
              bsStyle="primary"
              name="apply-margin"
              fill
              style={{ marginTop: '36px' }}
              onClick={() => setOpenConfirmationModal(true)}
            >
              Aplicar Margem
            </Button>
          </Col>
        </Col>

        <Col
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ margin: '16px 0 16px 0' }}
        >
          <div className="search">
            <div>
              <Button
                bsStyle="info"
                name="save"
                fill
                onClick={() => setIsNewProductModalOpen(true)}
              >
                + Novo Produto
              </Button>
            </div>
            <div className="input-search">
              <div id="review-search">
                <SearchInput
                  placeholder="Pesquisar por Código interno, Ref. Fabricante ou Descrição"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Col>
      </header>

      <TableData
        loading={loading}
        products={productsTable}
        handleFilter={handleFilter}
        handleDeleteProduct={handleDeleteProduct}
        setIsConfirmationDeleteModalOpen={setIsConfirmationDeleteModalOpen}
        isConfirmationDeleteModalOpen={isConfirmationDeleteModalOpen}
      />
      {isNewProductModalOpen && (
        <ItemsModal
          onCancel={() => setIsNewProductModalOpen(false)}
          onSubmit={handleSubmit}
          discountPriceProducts={productsTable}
        />
      )}

      <AlertModal
        show={openConfirmationModal}
        title="OS Digital"
        message={
          <>
            <p>
              <strong>
                Você tem certeza que deseja alterar a margem diferenciada da
                tabela de preços?
              </strong>
            </p>
            <p>
              Ao alterar a margem, será replicado para todos os itens da tabela.
              Você poderá alterar novamente mais tarde.
            </p>
          </>
        }
        onCancel={() => setOpenConfirmationModal(false)}
        onHide={() => setOpenConfirmationModal(false)}
        onSubmit={() => handleChangeDiscontPrice()}
      />
    </CardForm>
  );
};

export default withRouter(ProductsTable);
