import React, { useEffect, useRef, useState } from 'react';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { DefaultContentContainer } from 'v2/components/layout';
import { getBreadcrumbData } from './utils';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { toastr } from 'react-redux-toastr';
import { KanbanSchedulingRepository } from 'v2/repositories/KanbanSchedulingRepository';
import { useAuth } from 'contexts/auth';
import { FormProvider, useForm } from 'react-hook-form';
import { Kanban } from './components/Kanban';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { KanbanStatusModal } from './components/StatusModal';
import { useKanbanScheduling } from './hooks/usekanbanScheduling';

export function KanbanScheduling() {
  const { companyId } = useAuth();
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const { getInitialValuesFromUrl, moveCard, updateUrlWithDate } =
    useKanbanScheduling();
  const [columns, setColumns] = useState([]);
  const [draggingCard, setDraggingCard] = useState(null);
  const dragItem = useRef();
  const dragOverItem = useRef();

  const initialValues = getInitialValuesFromUrl();

  const methods = useForm({
    defaultValues: initialValues,
  });

  const initialDate = methods.watch('initialDate');
  const finalDate = methods.watch('finalDate');
  const showFinished = methods.watch('showFinished');

  const handleSearchScheduling = async () => {
    try {
      const columns = await KanbanSchedulingRepository.getKanban(
        {
          initialDate,
          finalDate,
          showFinished,
        },
        companyId
      );
      setColumns(columns);
    } catch (error) {
      console.error(error);
      toastr.error('Não foi possível buscar os dados da Agenda');
    }
  };

  useEffect(() => {
    handleSearchScheduling();
  }, [initialDate, finalDate, showFinished]);

  const handleDragStart = (e, index, columnIndex) => {
    dragItem.current = {
      card: columns[columnIndex].cards[index],
      columnIndex,
      cardIndex: index,
      oldColumnIndex: columnIndex,
      oldCardIndex: index,
    };
    setDraggingCard(columns[columnIndex].cards[index]);
  };

  const handleDragEnter = (e, columnIndex, cardIndex) => {
    const {
      card,
      columnIndex: oldColumnIndex,
      cardIndex: oldCardIndex,
    } = dragItem.current;

    if (oldColumnIndex !== columnIndex) {
      dragItem.current.columnIndex = columnIndex;
      dragItem.current.cardIndex = cardIndex === -1 ? 0 : cardIndex;
    }
  };

  const handleDragEnd = async () => {
    const newColumns = [...columns];
    const { card, columnIndex, cardIndex } = dragItem.current;
    const oldColumnIndex = dragItem.current.oldColumnIndex;
    const oldCardIndex = dragItem.current.oldCardIndex;

    if (
      newColumns[oldColumnIndex].cards &&
      newColumns[oldColumnIndex].cards.length > 1
    ) {
      newColumns[oldColumnIndex].cards.splice(oldCardIndex, 1);
    } else {
      newColumns[oldColumnIndex].cards = [];
    }
    if (
      !newColumns[columnIndex].cards ||
      newColumns[columnIndex].cards.length === 0
    ) {
      newColumns[columnIndex].cards = [card];
    } else {
      newColumns[columnIndex].cards.splice(cardIndex, 0, card);
    }

    const updatedColumn = newColumns[columnIndex];

    setColumns(newColumns);
    setDraggingCard(null);
    dragItem.current = null;
    dragOverItem.current = null;
    await moveCard(card.id, updatedColumn.id);
  };

  return (
    <FormProvider {...methods}>
      <DefaultContentContainer>
        <BreadCrumb {...getBreadcrumbData()} />
        <form className="gap-1">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '2.5rem',
              gap: '10px',
              alignItems: 'flex-end',
            }}
          >
            <InputContainer gap="0">
              <InputLabel style={{ fontWeight: 'bold' }} required>
                Data Inicial:
              </InputLabel>
              <InputText
                {...methods.register('initialDate')}
                maxLength={50}
                height={'35px'}
                type="date"
                onChange={(e) => {
                  methods.setValue('initialDate', e.target.value);
                  updateUrlWithDate('initialDate', e.target.value);
                }}
              />
            </InputContainer>
            <InputContainer gap="0">
              <InputLabel style={{ fontWeight: 'bold' }} required>
                Data Final:
              </InputLabel>
              <InputText
                {...methods.register('finalDate')}
                maxLength={50}
                height={'35px'}
                type="date"
                onChange={(e) => {
                  methods.setValue('finalDate', e.target.value);
                  updateUrlWithDate('finalDate', e.target.value);
                }}
              />
            </InputContainer>
            <div className="h-35 flex align-center gap-025">
              <input
                {...methods.register('showFinished')}
                type="checkbox"
                style={{ margin: '0' }}
                onChange={(e) => {
                  methods.setValue('showFinished', e.target.checked);
                  updateUrlWithDate(
                    'showFinished',
                    e.target.checked ? 'true' : null
                  );
                }}
              />
              Exibir Finalizados
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '20px',
              gap: '15px',
              overflowX: 'auto',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
              >
                {columns.length
                  ? columns.map((column, columnIndex) => (
                      <Kanban.Root key={column.id}>
                        <Kanban.StatusHeader
                          name={column.name}
                          color={column.color}
                          total={column.cards.length}
                          haveOptionsButton
                          columnId={column.id}
                          handleSearchScheduling={handleSearchScheduling}
                        />
                        <Kanban.NewScheduling name={column.name} />
                        <div
                          className="flex column gap-025"
                          style={{ height: '100%', gap: '1rem' }}
                          onDragEnter={(e) =>
                            handleDragEnter(e, columnIndex, column.cards.length)
                          }
                        >
                          {column.cards.length
                            ? column.cards.map((card, cardIndex) => (
                                <Kanban.Card
                                  column={columns[columnIndex]}
                                  key={card.id}
                                  id={card.id}
                                  card={card}
                                  draggable
                                  onDragStart={(e) =>
                                    handleDragStart(e, cardIndex, columnIndex)
                                  }
                                  onDragEnd={handleDragEnd}
                                  handleSearchScheduling={
                                    handleSearchScheduling
                                  }
                                />
                              ))
                            : null}
                        </div>
                      </Kanban.Root>
                    ))
                  : null}
              </div>
              <button
                type="button"
                style={{
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  gap: '5px',
                  padding: '10px',
                  color: '#FFFFFF',
                  borderRadius: '5px',
                  backgroundColor: '#4E4B49',
                  width: '200px',
                  height: '35px',
                  cursor: 'pointer',
                }}
                onClick={() => setIsStatusModalOpen(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                Adicionar nova coluna
              </button>
            </div>
          </div>
        </form>
        {isStatusModalOpen && (
          <KanbanStatusModal
            show={isStatusModalOpen}
            onCancel={() => setIsStatusModalOpen(false)}
            onSucess={() => {
              setIsStatusModalOpen(false);
              handleSearchScheduling({
                initialDate: methods.getValues('initialDate'),
                finalDate: methods.getValues('finalDate'),
              });
            }}
          />
        )}
      </DefaultContentContainer>
    </FormProvider>
  );
}
