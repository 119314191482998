import React from 'react';

import { currency } from 'client/components/ToNormalize/ToNormalize';

import ReactTable from 'react-table';

import './styles.css';

const ProductsTable = ({ products, loading, handleSelectedProductsChange }) => {
  return (
    <div style={{ marginBottom: '60px' }}>
      <ReactTable
        className="bulk-deletion-table"
        style={{
          width: '100%',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={products}
        columns={[
          {
            Header: 'Código',
            id: 'Code',
            accessor: (props) => Number(props.Code),
            width: 100,
          },
          {
            Header: 'Ref. Fabricante',
            accessor: 'Manufacturer_Ref',
          },
          {
            Header: 'Descrição',
            accessor: 'Description',
            width: 300,
          },
          {
            Header: 'Estoque',
            accessor: 'Stock_Quantity',
            width: 80,
          },
          {
            Header: 'Marca',
            accessor: 'Brands',
            Cell: (props) =>
              props.value?.Description ? props.value?.Description : '',
          },
          {
            Header: 'Valor',
            accessor: 'Sale_Price',
            Cell: (props) => currency(props.value),
            width: 90,
          },
          {
            Header: '',
            accessor: 'selected',
            filterable: false,
            width: 50,
            sortable: false,
            Cell: (props) => (
              <>
                <input
                  type="checkbox"
                  checked={props.value}
                  className="checkbox-input bulk-deletion-table"
                  onChange={() => handleSelectedProductsChange(props.original)}
                />
              </>
            ),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum Produto encontrado"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
        defaultSortMethod={[{ id: 'Code', desc: false }]}
      />
    </div>
  );
};

export default ProductsTable;
