import React, { useState } from 'react';

import PageSelector from './PageSelector';
import Tray from './pages/Tray';

const Integrations = () => {
  const [pageSelected, setPageSelected] = useState('tray');

  return (
    <div>
      <PageSelector
        pageSelected={pageSelected}
        setPageSelected={setPageSelected}
      />
      <Integration pageSelected={pageSelected} />
    </div>
  );
};

const Integration = ({ pageSelected }) => {
  const pageComponents = {
    tray: <Tray />,
  };

  return pageComponents[pageSelected];
};

export default Integrations;
