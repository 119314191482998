import api from 'v2/services/api';

export const getExit = async () => {
  const res = await api.get(`/cst-ipi/saida`);
  return res.data;
};

export const getEntry = async () => {
  const res = await api.get(`/cst-ipi/entrada`);
  return res.data;
};

export const CstIpiRepository = {
  getExit,
  getEntry,
};
