import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { format } from 'date-fns';

import sobreRodasLogo from 'assets/img/sobre-rodas-logo.png';

import environmentConfig from 'config';

import '../index.css';
import LoadingSpinnerFullHeight from 'client/views/WorkshopPanel/components/LoadingSpinnerFullHeight';
import { getStatusIndicatorColor } from 'v2/views/client/WorkshopPanel/AppointmentByEmployeePanel/utils';
import { useBranding } from 'hooks/useBranding';

export const ExternalWorkshopTable = ({ maintenance, isLoading }) => {
  const customer =
    maintenance?.Sales?.Customer?.Company_Name ||
    maintenance?.Sales?.Customer?.Trading_Name;

  const handleOpenSaleDocument = () => {
    const hash = `Vend=v723dha5tHSSpS83711${maintenance?.saleId}5422hsGsdAQLk8423oPL31`;

    const link = `${window.location.origin}/share-sheets/sale/${hash}`;
    window.open(link, '_blank');
  };

  return (
    <>
      <WorkshopTable
        maintenance={maintenance}
        isLoading={isLoading}
        handleOpenSaleDocument={handleOpenSaleDocument}
      />
      <MobileTable
        maintenance={maintenance}
        customer={customer}
        handleOpenSaleDocument={handleOpenSaleDocument}
        isLoading={isLoading}
      />
    </>
  );
};

// web

const WorkshopTable = ({ maintenance, isLoading, handleOpenSaleDocument }) => {
  const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();
  const textColorByBrand = isWorkmotor
    ? 'wm-text-primary'
    : isTecnomotor
    ? 'tecnomotor-text-primary'
    : isBarros
    ? 'barros-text-primary'
    : isPO360
    ? 'po360-text-primary'
    : '';
  return (
    <div className="workshop-panel-frame__external">
      <div className="workshop-panel-frame-content__external">
        <div className="workshop-panel-maintenances__external">
          {isLoading ? (
            <LoadingSpinnerFullHeight />
          ) : (
            <table>
              <thead>
                <tr>
                  <th className={`${textColorByBrand}`} style={{ width: '5%' }}>
                    OS
                  </th>
                  <th className={textColorByBrand} style={{ width: '25%' }}>
                    Cliente
                  </th>
                  <th
                    className={textColorByBrand}
                    style={{ width: '15%', textAlign: 'center' }}
                  >
                    Serviços
                  </th>
                  <th className={textColorByBrand} style={{ width: '25%' }}>
                    Mecânicos/Reparos
                  </th>
                  <th
                    className={textColorByBrand}
                    style={{ width: '10%', textAlign: 'center' }}
                  >
                    Início
                  </th>
                  <th
                    className={textColorByBrand}
                    style={{ width: '10%', textAlign: 'center' }}
                  >
                    Status
                  </th>
                  <th
                    className={textColorByBrand}
                    style={{ width: '10%', textAlign: 'center' }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                <MaintenanceTableRow
                  maintenance={maintenance}
                  handleOpenSaleDocument={handleOpenSaleDocument}
                  textColorByBrand={textColorByBrand}
                />
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className={`workshop-panel-last-update ${textColorByBrand}`}>
        Atualizado em {format(new Date(), 'dd/MM/yyyy')} -{' '}
        {format(new Date(), 'HH:mm')}
      </div>
    </div>
  );
};

const MaintenanceTableRow = ({
  maintenance,
  handleOpenSaleDocument,
  textColorByBrand,
}) => {
  const getBackgroundColorByStatus = (status) => {
    const colors = {
      'Em Andamento': '#5cb85c',
      'Não Iniciado': '#ffc40d',
      Parado: '#d9534f',
      Finalizado: '#5bc0de',
    };

    return colors[status];
  };

  return (
    <tr>
      <td style={{ width: '5%' }}>
        <span>{maintenance?.saleCode}</span>
      </td>
      <td style={{ width: '25%', textAlign: 'left' }}>
        <p>
          {maintenance?.customer?.Trading_Name ||
            maintenance?.customer?.Company_Name}
        </p>
        <p>
          <strong>{`${maintenance?.vehicle || ''}`}</strong>
        </p>
      </td>
      <td style={{ width: '15%', textAlign: 'center' }}>
        {maintenance?.services?.map((item) => (
          <p
            key={item.id}
            style={{ textAlign: 'left' }}
            className="flex gap-075 align-center"
          >
            <span
              title={item.status}
              style={{
                textAlign: 'center',
                display: 'block',
                fontSize: '11px',
                width: '14px',
                height: '14px',
                borderRadius: '3px',
                flexShrink: 0,
                color: '#fff',
                boxSizing: 'border-box',
                boxShadow: '0px 1px 19px 1px rgba(0, 0, 0, 0.2)',
                WebkitBoxShadow: '0px 1px 19px 1px rgba(0, 0, 0, 0.2)',
                backgroundColor: getStatusIndicatorColor(item.shortStatus),
              }}
            >
              {item.shortStatus}
            </span>{' '}
            {item.description}
          </p>
        ))}
      </td>
      <td style={{ width: '25%' }}>
        <div className="flex column">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '10px',
              marginBottom: '15px',
            }}
          >
            {maintenance?.employees?.map((item) => (
              <div className="flex column gap-050">
                <EmployeeTableCard key={item.id} name={item?.name} />
                <ul
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                >
                  {item?.services?.map((item) => (
                    <li
                      style={{ textAlign: 'left', fontSize: '10px' }}
                      key={item.description}
                    >
                      {item.description}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </td>
      <td style={{ width: '10%' }}>
        <p style={{ fontSize: '11px', textAlign: 'center' }}>
          {maintenance?.start &&
            format(new Date(maintenance?.start), 'dd/MM/yyyy HH:mm')}
        </p>
      </td>
      <td style={{ width: '10%', textAlign: 'center' }}>
        <div className="external-workshop-panel__table__maintenance-status">
          <button
            className="external-workshop-panel__table__status-button"
            style={{
              backgroundColor: getBackgroundColorByStatus(maintenance?.status),
            }}
          >
            {/* status da manutenção */}
            {maintenance?.status}
          </button>
          <span className="external-workshop-panel__table__elapsed-time">
            {/* Tempo decorrido */}
            Tempo decorrido: {maintenance?.elapsedTime}
          </span>
        </div>
        <br />
      </td>
      <td style={{ width: '10%', textAlign: 'center' }}>
        <div className="external-workshop-panel__table__services-info-visualize-OS--mobile">
          <a
            className={textColorByBrand}
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenSaleDocument()}
          >
            Visualizar OS
          </a>
        </div>{' '}
      </td>
    </tr>
  );
};

const EmployeeTableCard = ({ name }) => {
  const splitedName = name.split(' ');

  return (
    <div className="employee-table-card">
      <FontAwesomeIcon icon={faUser} size={'lg'} />
      <span>{`${splitedName[0]}`}</span>
    </div>
  );
};

// mobile

const MobileTable = ({
  maintenance,
  customer,
  handleOpenSaleDocument,
  isLoading,
}) => {
  return (
    <>
      {isLoading ? (
        <LoadingSpinnerFullHeight />
      ) : (
        <div>
          <div
            style={{
              width: '100%',
            }}
            className="external-workshop-panel__table"
          >
            <div>
              <BasicInfo
                saleCode={maintenance?.saleCode}
                vehicle={maintenance?.vehicle}
                customer={maintenance?.customer}
              />
              <StatusInfo
                startDate={maintenance?.start}
                elapsedTime={maintenance?.elapsedTime}
                status={maintenance?.status}
              />
              <ServicesInfo
                services={maintenance?.services}
                handleOpenSaleDocument={handleOpenSaleDocument}
              />
              <EmployeesInfo employees={maintenance?.employees} />
              <UpdatedAtInfo />
            </div>
          </div>
          <SobreRodasLink />
        </div>
      )}
    </>
  );
};

const BasicInfo = ({ saleCode, vehicle, customer }) => {
  return (
    <>
      <div className="external-workshop-panel__table__basic-info--mobile">
        <strong className="external-workshop-panel__table__title">OS</strong>
        <strong className="external-workshop-panel__table__title">
          Cliente
        </strong>
      </div>
      <div className="external-workshop-panel__table__basic-info-content--mobile">
        <span
          className="external-workshop-panel__table__title"
          style={{
            alignSelf: 'center',
          }}
        >
          {saleCode}
        </span>
        <div
          className="external-workshop-panel__table__text"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Customer Name */}
          <span>{customer?.Trading_Name || customer?.Company_Name}</span>
          {/* Vehicle Info */}
          <strong>{vehicle}</strong>
        </div>
      </div>
    </>
  );
};

const StatusInfo = ({ startDate, elapsedTime, status }) => {
  const getBackgroundColorByStatus = (status) => {
    const colors = {
      'Em Andamento': '#5cb85c',
      'Não Iniciado': '#ffc40d',
      Parado: '#d9534f',
      Finalizado: '#5bc0de',
    };

    return colors[status];
  };

  return (
    <>
      <div
        className="external-workshop-panel__table__status-info--mobile"
        style={{
          marginTop: '1.5rem',
        }}
      >
        <strong className="external-workshop-panel__table__title">
          Início
        </strong>
        <strong
          className="external-workshop-panel__table__title"
          style={{
            justifySelf: 'center',
          }}
        >
          Status
        </strong>
      </div>
      <div className="external-workshop-panel__table__status-info-content--mobile">
        <span
          style={{
            alignSelf: 'center',
          }}
        >
          {/* início da manutenção */}
          {startDate && format(new Date(startDate), 'dd/MM/yyyy HH:mm')}
        </span>
        <div className="external-workshop-panel__table__maintenance-status">
          <button
            className="external-workshop-panel__table__status-button"
            style={{
              backgroundColor: getBackgroundColorByStatus(status),
            }}
          >
            {/* status da manutenção */}
            {status}
          </button>
          <span className="external-workshop-panel__table__elapsed-time">
            {/* Tempo decorrido */}
            Tempo decorrido: {elapsedTime}
          </span>
        </div>
      </div>
    </>
  );
};

const ServicesInfo = ({ services, handleOpenSaleDocument }) => {
  return (
    <>
      <div
        className="external-workshop-panel__table__services-info--mobile"
        style={{
          marginTop: '1.5rem',
        }}
      >
        <strong className="external-workshop-panel__table__title">
          Serviços
        </strong>
      </div>
      <div className="flex column center gap-050" style={{ marginTop: '10px' }}>
        {services?.map((item) => (
          <p
            key={item.id}
            style={{ textAlign: 'left' }}
            className="flex gap-075 align-center"
          >
            <span
              title={item.status}
              style={{
                textAlign: 'center',
                display: 'block',
                fontSize: '11px',
                width: '14px',
                height: '14px',
                borderRadius: '3px',
                flexShrink: 0,
                color: '#fff',
                boxSizing: 'border-box',
                boxShadow: '0px 1px 19px 1px rgba(0, 0, 0, 0.2)',
                WebkitBoxShadow: '0px 1px 19px 1px rgba(0, 0, 0, 0.2)',
                backgroundColor: getStatusIndicatorColor(item.shortStatus),
              }}
            >
              {item.shortStatus}
            </span>{' '}
            {item.description}
          </p>
        ))}
      </div>

      <div className="external-workshop-panel__table__services-info-visualize-OS--mobile">
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => handleOpenSaleDocument()}
        >
          Visualizar OS
        </a>
      </div>
    </>
  );
};

const UpdatedAtInfo = () => {
  return (
    <>
      <div className="external-workshop-panel__table__updatedAtInfo">
        <span>
          Atualizado em {format(new Date(), 'dd/MM/yyyy')} -{' '}
          {format(new Date(), 'HH:mm')}
        </span>
      </div>
    </>
  );
};

// employees

const EmployeesInfo = ({ employees }) => {
  return (
    <>
      <div
        className="external-workshop-panel__table__services-info--mobile"
        style={{
          marginTop: '1.5rem',
        }}
      >
        <strong className="external-workshop-panel__table__title">
          Mecânicos / Reparos
        </strong>
      </div>
      <div
        style={{
          marginTop: '10px',
          display: 'grid',
          gridTemplateColumns: '1fr',
          padding: '0px 20px',
          gap: '10px',
          marginBottom: '15px',
        }}
      >
        {employees?.map((item) => (
          <div className="flex center column gap-050">
            <EmployeeTableCard key={item.id} name={item?.name} />
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
              }}
            >
              {item?.services?.map((item) => (
                <li
                  style={{ textAlign: 'left', fontSize: '10px' }}
                  key={item.description}
                >
                  {item.description}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

const EmployeeCard = ({ employee }) => {
  return (
    <div className="external-workshop-panel__table__employee-card--mobile">
      <FontAwesomeIcon icon={faUser} size={'lg'} />
      <span>{employee}</span>
    </div>
  );
};

// sobreRodas

const SobreRodasLink = () => {
  return (
    <div className="external-workshop-panel__sobreRodas-link">
      <a href={`${environmentConfig.sobreRodasWebEndpoint}/client/login/`}>
        Histórico Completo do veículo
      </a>
      <img src={sobreRodasLogo} alt="Sobre Rodas" />
    </div>
  );
};
