import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { format } from 'date-fns';
import { change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { isAfter } from 'date-fns';

import { useAuth } from 'contexts/auth';
import useFilters from 'hooks/useFilters';
import billsToReceiveRepository from 'repositories/BillsToReceive';
import billsToPayRepository from 'repositories/BillsToPay';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import constants from 'utils/constants';
import SearchInput from 'components/SearchInput';
import { currency } from 'client/components/ToNormalize/ToNormalize';

import '../../styles.css';
import { date } from 'yup/lib/locale';

const TitleSelector = ({
  billType,
  accountPlans,
  debitAccountPlans,
  calendarDate,
}) => {
  const {
    filteredBills: storedBills = [],
    storedInitialDate,
    storedFinalDate,
    storedHasPaymentForm,
    storedSelectedAccountPlan,
  } = useSelector((state) => state.form.titleQuickDischarge.values);

  const [bills, setBills] = useState(storedBills);
  const [initialDate, setInitialDate] = useState(storedInitialDate);
  const [finalDate, setFinalDate] = useState(storedFinalDate);
  const [hasPaymentForm, setHasPaymentForm] = useState(
    storedHasPaymentForm || 'both'
  );
  const [loading, setLoading] = useState(false);
  const [isDateValid, setIsDateValid] = useState(false);
  const [noDataText, setNoDataText] = useState(
    'Selecione um período e clique em processar'
  );

  const [selectedAccountPlan, setSelectedAccountPlan] = useState(
    storedSelectedAccountPlan
  );

  const [allProductsSelected, setAllProductsSelected] = useState(false);

  const { queryFilter } = useFilters();

  const { query, setQuery, filterByQuery } = queryFilter;

  const dispatch = useDispatch();

  const { companyId } = useAuth();

  const todayDate = format(new Date(), 'yyyy-MM-dd');

  useEffect(() => {
    if (!bills.length)
      return setNoDataText('Selecione o período e clique em processar.');

    const billParcels =
      billType === 'toPay' ? 'BillsToPayParcels' : 'BillsToReceiveParcels';

    const selectedTitles = bills
      .filter((bill) => bill.selected)
      .map((bill) => ({
        ...bill,
        billId: bill.id,
        paymentFormId: bill[billParcels][0]?.FormOfPayment.id,
        lowDate: todayDate,
      }));

    dispatch([
      change('titleQuickDischarge', 'selectedTitles', selectedTitles),
      change('titleQuickDischarge', 'filteredBills', bills),
    ]);
  }, [bills]);

  useEffect(() => {
    validateDate();
  }, [initialDate, finalDate]);

  useEffect(() => {
    storeFilters();
  }, [initialDate, finalDate, hasPaymentForm, selectedAccountPlan]);

  const loadBills = async (date) => {
    setLoading(true);

    try {
      const billRepository = getBillRepository();

      const response = await billRepository.index({
        initialDate: initialDate || date,
        finalDate: finalDate || date,
        billStatusId: constants.BILLS_STATUS.OPEN,
        companyId,
      });

      const serializedBills = response.data.bills.map((bill) => ({
        ...bill,
        originalOpenValue: bill.openValue,
        originalFeeValue: bill.feeValue,
        originalAddedValue: bill.addedValue,
        originalDiscountValue: bill.discountValue,
        selected: false,
      }));

      serializedBills.forEach((bill) => {
        if (bill.Sales === null) {
          if (bill.BillsToReceiveSales?.length === 1) {
            bill.Sales = bill.BillsToReceiveSales[0].Sales;
          } else if (bill.BillsToReceiveSales.length > 1) {
            bill.Sales = {
              Code: 'Vários',
            };
          }
        }
      });

      setBills(serializedBills);
    } catch (err) {
      console.error(err);
      toastr.warning('Ocorreu um erro ao carregar os títulos.');
    } finally {
      setLoading(false);
    }
  };

  const handleWithDateCalendar = (date) => {
    loadBills(calendarDate);
  };

  useEffect(() => {
    if (calendarDate && bills.length === 0) {
      setInitialDate(calendarDate);
      setFinalDate(calendarDate);

      handleWithDateCalendar(calendarDate);
    }
  }, [calendarDate]);

  const handleSelectAll = () => {
    const billsCopy = [...bills];
    const filteredBills = billsCopy.filter(handleFilters);
    const filteredBillIds = filteredBills.map((bill) => bill.id);

    billsCopy.forEach((bill) => {
      bill.selected = filteredBillIds.includes(bill.id) ? true : bill.selected;
    });

    setBills(billsCopy);
    setAllProductsSelected(filteredBills.length === billsCopy.length);
  };

  const handleUnselectAll = () => {
    const billsCopy = [...bills];
    billsCopy.forEach((bill) => {
      bill.selected = false;
    });

    setBills(billsCopy);
    setAllProductsSelected(false);
  };

  const handleBillSelectedChange = (selectedBill) => {
    const billsCopy = [...bills];
    const billIndex = billsCopy.findIndex(
      (bill) => bill.id === selectedBill.id
    );
    billsCopy[billIndex].selected = !billsCopy[billIndex].selected;

    setBills(billsCopy);
  };

  const getBillRepository = () => {
    if (billType === 'toPay') return billsToPayRepository;
    return billsToReceiveRepository;
  };

  const handleFilters = (bill) => {
    const querySearch = [
      bill.code,
      bill.Customer?.Company_Name,
      bill.Sales?.Code,
      bill.Provider?.companyName,
      bill.Purchases?.code,
    ];
    return (
      filterByQuery(querySearch) &&
      filterByPaymentForm(bill) &&
      filterByAccountPlan(bill)
    );
  };

  const validateDate = () => {
    setIsDateValid(false);

    if (!initialDate || !finalDate) return;

    const formatedInitialDate = new Date(initialDate);
    const formatedFinalDate = new Date(finalDate);

    if (
      formatedInitialDate.getFullYear() < 999 ||
      formatedFinalDate.getFullYear() < 999
    )
      return;

    if (isAfter(formatedInitialDate, formatedFinalDate)) {
      return toastr.warning('A data inicial deve ser menor que a data final.');
    }

    setIsDateValid(true);
  };

  const storeFilters = () => {
    dispatch([
      change('titleQuickDischarge', 'storedInitialDate', initialDate),
      change('titleQuickDischarge', 'storedFinalDate', finalDate),
      change('titleQuickDischarge', 'storedHasPaymentForm', hasPaymentForm),
      change(
        'titleQuickDischarge',
        'storedSelectedAccountPlan',
        selectedAccountPlan
      ),
    ]);
  };

  const filterByPaymentForm = (bill) => {
    if (hasPaymentForm === 'both') return true;

    const billsParcels =
      billType === 'toPay' ? 'BillsToPayParcels' : 'BillsToReceiveParcels';

    const billHasPaymentForm = bill[billsParcels].some(
      (parcel) => parcel.FormOfPayment.Desciption
    );

    if (hasPaymentForm === 'true') return billHasPaymentForm;
    return !billHasPaymentForm;
  };

  const filterByAccountPlan = (bill) => {
    if (!selectedAccountPlan) return true;

    return bill.AccountPlans.description === selectedAccountPlan;
  };

  return (
    <>
      <section className="filters">
        <div className="filter-row">
          <div
            style={{
              // width: 600,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <SearchInput
              style={{ width: 450 }}
              placeholder={
                billType === 'toPay'
                  ? 'Pesquisa por Título, Fornecedor ou Nº de Compra'
                  : 'Pesquisa por Título, Cliente ou  Nº da Venda/Ordem de Serviço'
              }
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <div style={{ width: 250, display: 'flex' }}>
              <label style={{ width: 150 }}>Data Inicial:</label>
              <input
                type="date"
                className="form-control foco-input"
                value={initialDate}
                max={finalDate || '9999-12-31'}
                onChange={(e) => {
                  setInitialDate(e.target.value);
                }}
              />
            </div>
            <div style={{ width: 250, display: 'flex' }}>
              <label style={{ width: 150 }}>Data Final:</label>
              <input
                type="date"
                className="form-control foco-input"
                value={finalDate}
                min={initialDate}
                max="9999-12-31"
                onChange={(e) => {
                  setFinalDate(e.target.value);
                }}
              />
            </div>
            <button
              className="btn btn-sucesso"
              style={{
                padding: '7px 30px',
                margin: '0 20px',
              }}
              onClick={loadBills}
              disabled={!isDateValid}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />
              Processar
            </button>
          </div>
        </div>
        <div className="filter-row">
          <div style={{ width: 300, display: 'flex', height: 35 }}>
            <label style={{ width: 200 }}>Possui forma de pagamento:</label>
            <select
              style={{ width: 100 }}
              className="form-control foco-input"
              value={hasPaymentForm}
              onChange={(e) => setHasPaymentForm(e.target.value)}
            >
              <option value="both">Ambos</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
          <div style={{ display: 'flex', height: 35 }}>
            <label style={{ width: 180 }}>Plano de Contas:</label>
            <select
              className="form-control foco-input"
              value={selectedAccountPlan}
              onChange={(e) => {
                setSelectedAccountPlan(e.target.value);
              }}
            >
              <option value="">Todas</option>
              {accountPlans?.map((accountPlan) => (
                <option key={accountPlan.Id} value={accountPlan.Id}>
                  {accountPlan.description}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>
      <div
        style={{
          marginLeft: 'auto',
        }}
        className="select-all-products hyperlink"
      >
        <a
          href="#"
          onClick={!allProductsSelected ? handleSelectAll : handleUnselectAll}
        >
          {allProductsSelected ? 'Remover Todos' : 'Selecionar Todos'}
        </a>
      </div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          fontSize: '12px',
          textAlign: 'center',
          width: '100%',
        }}
        data={bills.filter(handleFilters)}
        columns={[
          {
            Header: '',
            accessor: 'selected',
            width: 40,
            Cell: (props) => (
              <input
                type="checkbox"
                checked={props.value}
                className="checkbox-input"
                onChange={() => handleBillSelectedChange(props.original)}
              />
            ),
          },
          {
            Header: 'Título',
            accessor: 'code',
            width: 100,
          },
          {
            Header: 'Vencimento',
            accessor: 'dueDate',
            width: 150,
            Cell: (props) =>
              format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy'),
          },
          {
            Header: billType === 'toReceive' ? 'Venda/OS' : 'Compra',
            accessor:
              billType === 'toReceive' ? 'Sales.Code' : 'Purchases.code',
            width: 100,
          },
          {
            Header: billType === 'toReceive' ? 'Cliente' : 'Fornecedor',
            accessor:
              billType === 'toReceive'
                ? 'Customer.Company_Name'
                : 'Provider.companyName',
          },
          {
            Header: 'Valor',
            accessor: 'openValue',
            width: 125,
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Forma de Pagto.',
            accessor:
              billType === 'toReceive'
                ? 'BillsToReceiveParcels[0].FormOfPayment.Desciption'
                : 'BillsToPayParcels[0].FormOfPayment.Desciption',
          },
          {
            Header: 'Juros',
            accessor: 'monthlyInterestValue',
            show: billType === 'toReceive',
            width: 100,
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Acrésc.',
            accessor: 'addedValue',
            width: 100,
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Desc.',
            accessor: 'discountValue',
            width: 100,
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Conta',
            accessor: 'AccountPlans.description',
            width: 160,
          },
          {
            Header: 'Caixa/Banco',
            accessor: 'CashierBanks.description',
            width: 140,
          },
        ]}
        defaultPageSize={5}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        loading={loading}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={noDataText}
        pageText="Página"
        ofText="de"
        rowsText="linhas"
        defaultSorted={[
          {
            id: 'dueDate',
            desc: true,
          },
        ]}
      />
    </>
  );
};

export default TitleSelector;
