import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './styles.css';

import InputMask from 'react-input-mask';
import Button from 'client/components/CustomButton/CustomButton';
import { cpfMask, cnpjMask } from 'client/components/ToNormalize/ToNormalize';

export default function ChangeCompanyDocument({
  companyCpfCnpj,
  handleSubmit,
  onCancel,
}) {
  const [newDocument, setNewDocument] = useState('');

  return (
    <Modal show animation onHide={onCancel}>
      <Modal.Header closeButton={onCancel}>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Insira o dado que deseja trocar</p>

        <div className="informations-container">
          <label>CPF/CNPJ atual:</label>
          <input
            className="form-control foco-input"
            type="text"
            value={
              companyCpfCnpj.length <= 11
                ? cpfMask(companyCpfCnpj)
                : cnpjMask(companyCpfCnpj)
            }
            disabled
          />
          <label>CPF/CNPJ novo:</label>
          <InputMask
            className="form-control foco-input"
            type="text"
            mask={
              companyCpfCnpj.length === 14
                ? '99.999.999/9999-99'
                : '999.999.999-99'
            }
            value={newDocument}
            onChange={(e) => setNewDocument(e.target.value)}
            autoFocus
          />
        </div>

        <div className="footer-alert-message">
          <span style={{ color: '#d43f3a', fontSize: '12px' }}>
            A troca de CPF para CNPJ deverá ocorrer dentro do OS Digital pelo
            cliente.
          </span>
          <br />
          <span style={{ color: '#d43f3a', fontSize: '12px' }}>
            A ferramenta permite apenas troca para informações do mesmo tipo.
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button fill bsStyle="danger" name="cancelar" onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          fill
          bsStyle="info"
          name="update-document"
          onClick={() => handleSubmit(newDocument)}
        >
          Atualizar CPF/CNPJ
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
