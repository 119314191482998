import { text } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { Link } from 'react-router-dom';

export function KanbanCardHeader({ card }) {
  const { vehiclePlate, saleCode, vehicleModel, saleId } = card;
  return (
    <header
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '5px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <strong
          style={{
            fontSize: '12px',
            width: '60px',
            textTransform: 'uppercase',
          }}
        >
          {vehiclePlate}
        </strong>
        {saleCode && (
          <div
            style={{
              fontSize: '11px',
              color: '#00A4D3',
              flex: 1,
              justifyContent: 'end',
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
            }}
          >
            <span>Venda:</span>

            <Link
              style={{
                textDecoration: 'underline',
                color: '#00A4D3',
                fontWeight: 'bold',
              }}
              to={{ pathname: `sale#${saleId}` }}
            >
              {saleCode}
            </Link>
          </div>
        )}
      </div>
      <span style={{ fontSize: '11px', color: '#444444' }}>{vehicleModel}</span>
    </header>
  );
}
