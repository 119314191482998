import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { initialize, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import ClosedSaleModal from '../../components/PdvOnline/components/ClosedSaleModal';
import FormPDV from './FormSale';
import AlertModal from '../../../components/AlertModal';
import OpenOrcamentsModal from '../../components/PdvOnline/components/OpenOrcamentsModal';
import IncludeCpfCnpjModal from '../../components/PdvOnline/components/IncludeCpfCnpjModal';
import { isDefaultCustomer } from '../../../utils/isDefaultCustomer';
import ClosedInvoiceModal from '../../components/PdvOnline/components/ClosedInvoiceModal';
import InvoiceProcessLoader from '../../components/InvoiceProcessLoader';
import NFCeErrorsModal from '../../components/PdvOnline/components/NFCeErrorsModal';
import InvoiceDetailsModal from 'client/components/PdvOnline/components/InvoiceDetailsModal';
import NFCeList from 'client/components/PdvOnline/components/NFCeList';
import CashierBankPDVModal from '../../components/PdvOnline/components/CashierBankPDVModal';
import BlockSalesModals from 'client/components/BlockSalesModals';
import StonePaymentProgressModal from 'client/components/StonePaymentProgressModal';
import MateraQRCodeModal from 'client/components/MateraQRCodeModal/MateraQRCodeModal';
import { cashierBankHistoricValidations } from 'client/components/BlockSalesModals/actions';
import { getProductsUnderStock } from 'client/components/MinStockAlertButton/redux/actions';
import ShareQRCodeModal from 'client/components/ShareQRCodeModal/ShareQRCodeModal';
import QuickQuotesRepository from 'repositories/QuickQuotes';
import { useAuth } from '../../../contexts/auth';
import pdvsRepository from '../../../repositories/PDVs';
import companiesRepository from '../../../repositories/Companies';
import salesRepository from '../../../repositories/Sales';
import NFCesRepository from '../../../repositories/NFCes';
import SATCFeRepository from '../../../repositories/SATCFe';
import stoneTransactionRepository from '../../../repositories/StoneTransaction';
import materaTransactionRepository from '../../../repositories/MateraTransaction';
import { INVOICE_STATUS_DESCRIPTION } from '../../../utils/constants';
import customerCreditRepository from 'repositories/CustomerCredit';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import './styles.css';
import { useBonus } from 'hooks/useBonus';
import { CRMBonusModals } from 'client/components/CRMBonus';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import { AppLoading } from 'client/routes/index.routes';
import { useThemeContext } from 'v2/contexts/themeContext';

const PDV = () => {
  const [loading, setLoading] = useState(false);
  const [isClosedSaleModalOpen, setIsClosedSaleModalOpen] = useState(false);
  const [hasSettingsNFCe, setHasSettingsNFCe] = useState(false);
  const [NFCeSettings, setNFCeSettings] = useState([]);
  const [fiscalDocumentType, setFiscalDocumentType] = useState('');
  const [showSaleCanceledModal, setShowSaleCanceledModal] = useState(false);
  const [isOrcamentsModalOpen, setIsOrcamentsModalOpen] = useState(false);
  const [isIncludeCpfCnpjModalOpen, setIsIncludeCpfCnpjModalOpen] =
    useState(false);
  const [isClosedInvoiceModalOpen, setIsClosedInvoiceModalOpen] =
    useState(false);
  const [isInvoiceDetailsModalOpen, setIsInvoiceDetailsModalOpen] =
    useState(false);
  const [isNFCeList, setIsNFCeList] = useState(false);
  const [hasCashierControl, setHasCashierControl] = useState(false);
  const [isCashierBankPDVModalOpen, setIsCashierBankPDVModalOpen] =
    useState(false);
  const [isStonePaymentProgressModalOpen, setIsStonePaymentProgressModalOpen] =
    useState(false);

  const [quickQuoteId, setQuickQuoteId] = useState(null);

  const [clientCpfCnpj, setClientCpfCnpj] = useState('');

  const [errors, setErrors] = useState([]);
  const [isNFCeErrorsModalOpen, setIsNFCeErrorsModalOpen] = useState(false);
  const [isInvoiceLoaderOpen, setIsInvoiceLoaderOpen] = useState(false);

  const [saleId, setSaleId] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);
  const [customerCpfCnpj, setCustomerCpfCnpj] = useState('');
  const [nfceIncludeCpfCnpj, setNfceIncludeCpfCnpj] = useState(null);
  const [values, setValues] = useState([]);

  const [downloadNFCeLink, setDownloadNFCeLink] = useState('');

  const [transactionId, setTransactionId] = useState('');
  const [processingPayment, setProcessingPayment] = useState(false);
  const [processedPayment, setProcessedPayment] = useState(false);

  const [isMateraQRCodeModalOpen, setIsMateraQRCodeModalOpen] = useState(false);
  const [materaPixValue, setMateraPixValue] = useState(0);
  const [materaPixCode, setMateraPixCode] = useState('');
  const [isShareQRCodeModalOpen, setIsShareQRCodeModalOpen] = useState(false);

  const { isPaidWorkmotor } = usePlanSignatureContext();

  const pdvOnline = useSelector((state) => state.form.pdvOnline);

  const {
    companyId,
    userId,
    userName,
    company,
    user,
    isSigned,
    loading: loadingAuth,
    signOut,
  } = useAuth();
  const dispatch = useDispatch();

  const { crmBonusCurrentCustomer, pdvSale, handleFinishPDV } = useBonus();

  useEffect(() => {
    if (!loadingAuth && isSigned) {
      return loadCompanyTaxData();
    }

    if (!loadingAuth && !isSigned) {
      window.location.href = '/client-login';
      signOut();
    }
  }, [isSigned, loadingAuth]);

  useEffect(() => {
    if (companyId && company?.hasCashierControl)
      dispatch(cashierBankHistoricValidations(companyId));
  }, [companyId, company]);

  const { setDocumentTitle, brandingId } = useThemeContext();
  useEffect(() => {
    if (brandingId) {
      setDocumentTitle('PDV Online');
    }
  }, [brandingId]);

  async function loadOrcament(saleId, type) {
    setIsOrcamentsModalOpen(false);
    setLoading(true);

    try {
      if (type === 'Orç. Rápido') {
        const { data } = await QuickQuotesRepository.getById(saleId);

        const {
          id,
          finalValue,
          QuickQuotesItems,
          observations,
          totalDiscountValue,
          totalDiscountPercentage,
        } = data;

        const initOrcament = {
          Comments: observations,
          cart: QuickQuotesItems.map((quickQuotesItems) => ({
            ...quickQuotesItems,
            Service_id: quickQuotesItems.Services?.id,
            Product_id: quickQuotesItems.Products?.id,
            Type: quickQuotesItems.type,
            Description: quickQuotesItems.description,
            Quantity: quickQuotesItems.quantity,
            Total: quickQuotesItems.amount,
            Unit_Type: quickQuotesItems.Products
              ? quickQuotesItems.Products.Unit_Type
              : null,
            Code: quickQuotesItems.Products
              ? quickQuotesItems.Products.Code
              : quickQuotesItems.Services.Code,
            Sale_Price:
              quickQuotesItems.type === 'Produto'
                ? quickQuotesItems.unitValue
                : quickQuotesItems.unitValue,
            Discount_Rate: quickQuotesItems.discountValue,
          })),
          paidValue: 0,
          installments: [],
          totalSale: finalValue,
          client: 'Cliente Consumidor',
          step: 1,
          typeBtn: '',
          productSelected: [],
          freightValue: 0,
          generalDiscount: totalDiscountValue,
          generalDiscountPercentage: totalDiscountPercentage,
          codeOrDescriptionProductSearch: '',
          vehicleProductSearch: '',
          generateTitleAfterCloseSale: true,
        };

        dispatch(initialize('pdvOnline', initOrcament));
        setQuickQuoteId(id);
      } else {
        const orcament = await salesRepository.getById(saleId);

        const {
          SalesSubtype,
          Final_Value,
          SalesItems,
          Comments,
          Payments,
          Customer,
          id,
          Discount_Value,
          Discount_Value_Percentage,
        } = orcament;

        const initOrcament = {
          saleId: id,
          Comments: Comments,
          cart: SalesItems.map((saleItem) => ({
            ...saleItem,
            lineNumber: saleItem.lineNumber,
            Total: saleItem.Amount,
            Unit_Type: saleItem.Products ? saleItem.Products.Unit_Type : null,
            Code: saleItem.Products
              ? saleItem.Products.Code
              : saleItem.Services.Code,
            Sale_Price: saleItem.Unit_Value,
            Discount_Rate: saleItem.Discount_Value,
          })),
          totalSale: Final_Value,
          customerId: isDefaultCustomer(Customer) ? null : Customer.id,
          paidValue: !Payments ? 0 : Payments.Amount,
          generateTitleAfterCloseSale:
            !!SalesSubtype?.generateTitleAfterCloseSale
              ? SalesSubtype?.generateTitleAfterCloseSale
              : true,
          installments: !Payments
            ? []
            : Payments.Parcels.map((parcel) => ({
                ...parcel,
                dueDate: parcel.Due_Date,
                formOfPaymentId: parcel.FormOfPayment_id,
                value: parcel.Amount,
              })),
          paymentConditionId: !Payments
            ? null
            : String(Payments.CondPayment_id),
          client: isDefaultCustomer(Customer)
            ? 'Cliente Consumidor'
            : {
                name: Customer.Company_Name,
                cpfCnpj: Customer.Cpf_Cnpj,
              },
          step: 1,
          typeBtn: '',
          productSelected: [],
          freightValue: 0,
          generalDiscount: Discount_Value,
          generalDiscountPercentage: Discount_Value_Percentage,
          codeOrDescriptionProductSearch: '',
          vehicleProductSearch: '',
        };

        dispatch(initialize('pdvOnline', initOrcament));
        setClientCpfCnpj(initOrcament.client.CpfCnpj);
        setSaleId(id);
      }
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar o orçamento. Por favor, tente novamente'
      );
    }

    setLoading(false);
  }

  async function loadCompanyTaxData() {
    try {
      const companyTaxData = await companiesRepository.getTaxData(companyId);
      const company = await companiesRepository.getById(companyId);
      setHasCashierControl(company.hasCashierControl);
      setHasSettingsNFCe(!!companyTaxData.SettingsNFCe);

      setNFCeSettings(companyTaxData.SettingsNFCe);
      setFiscalDocumentType(companyTaxData.SettingsNFCe?.fiscalDocumentType);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as informações fiscais. Por favor, tente novamente'
      );
    }
  }

  function handleProductsDiscount({ productValue, productValueWithDiscount }) {
    let discountProducts = productValue - productValueWithDiscount;
    let discountProductsPercentage = 0;

    if (productValueWithDiscount !== 0) {
      discountProductsPercentage = (discountProducts / productValue) * 100;
    }

    return {
      discountProducts,
      discountProductsPercentage,
    };
  }

  async function handleSubmit(values) {
    const { installments, typeBtn, client, generateTitleAfterCloseSale } =
      values;

    const customer = {
      Cpf_Cnpj: client.cpfCnpj,
    };
    if (!isDefaultCustomer(customer)) {
      setCustomerCpfCnpj(client.cpfCnpj);
    }

    if (typeBtn !== 'submit') {
      setValues(values);
      setShowSaleCanceledModal(true);
      return;
    }

    const hasInstallmentWithoutValue = installments.some(
      (installment) => !installment.value
    );
    const hasInstallmentWithoutDueDate = installments.some(
      (installment) => !installment.dueDate
    );
    const hasInstallmentWithoutPaymentForm = installments.some(
      (installment) => !installment.formOfPaymentId
    );

    let materaTransactionId = '';

    if (generateTitleAfterCloseSale) {
      if (hasInstallmentWithoutValue) {
        setLoading(false);
        dispatch(change('pdvOnline', 'isSubmitting', false));
        return toastr.warning(
          'Existe parcela sem valor lançado. Ajuste o valor das parcelas e tente novamente.'
        );
      }
      if (hasInstallmentWithoutDueDate) {
        setLoading(false);
        dispatch(change('pdvOnline', 'isSubmitting', false));
        return toastr.warning(
          'Existe parcela sem data. Ajuste a data e tente novamente.'
        );
      }
      if (hasInstallmentWithoutPaymentForm) {
        setLoading(false);
        dispatch(change('pdvOnline', 'isSubmitting', false));
        return toastr.warning(
          'Existe parcela sem forma de pagamento. Ajuste a forma de pagamento e tente novamente.'
        );
      }

      if (company?.hasCashierControl) {
        const isValid = await dispatch(
          cashierBankHistoricValidations(companyId)
        );
        if (!isValid.payload) return;
      }

      const hasStoneIntegratedPayment = installments.some(
        (installment) => installment.isStoneIntegrated === true
      );

      const hasMateraIntegratedPayment = installments.some(
        (installment) => installment.isMateraIntegrated === true
      );

      const sleep = (time) => new Promise((s) => setTimeout(s, time));

      let transactionId = '';
      if (hasStoneIntegratedPayment) {
        setIsStonePaymentProgressModalOpen(hasStoneIntegratedPayment);

        const { stonePreTransactionId } =
          await stoneTransactionRepository.store({
            amount: values.paidValue,
            installments,
            companyId,
          });

        setTransactionId(stonePreTransactionId);
        transactionId = stonePreTransactionId;

        let transactionStatus = '';

        while (
          transactionStatus !== 'processed' &&
          transactionStatus !== 'undone' &&
          transactionStatus !== 'canceled'
        ) {
          await sleep(3000);

          const { status } = await stoneTransactionRepository.show(
            stonePreTransactionId
          );

          if (status === 'processing') {
            setProcessingPayment(true);
          } else if (status === 'processed') {
            setProcessedPayment(true);
          }

          transactionStatus = status;
        }
        if (
          transactionStatus === 'undone' ||
          transactionStatus === 'canceled'
        ) {
          setIsStonePaymentProgressModalOpen(false);

          return toastr.warning('Transação cancelada na maquininha');
        }
      }

      if (hasMateraIntegratedPayment) {
        const pixInstallment = installments.find(
          (installment) => installment.isMateraIntegrated === true
        );

        let today = new Date();
        today.setHours(-3, 0, 0, 0);

        let pixDueDate = new Date(pixInstallment.dueDate);
        let dueDateIsNotToday =
          today.toISOString() !== pixDueDate.toISOString();

        if (dueDateIsNotToday) {
          dispatch(change('pdvOnline', 'isSubmitting', false));
          return toastr.warning(
            'O Pix integrado com a conta digital só pode ser usado para pagamentos à vista'
          );
        }

        try {
          const {
            data: { data },
          } = await materaTransactionRepository.create({
            amount: pixInstallment.value,
            companyId,
          });

          setMateraPixCode(data.qrCode);
          setMateraPixValue(pixInstallment.value);
          setIsMateraQRCodeModalOpen(true);

          materaTransactionId = data.transactionId;

          let transactionStatus = '';

          while (
            transactionStatus !== 'PAID' &&
            transactionStatus !== 'EXPIRED' &&
            data.qrCode
          ) {
            await sleep(3000);

            const { data } = await materaTransactionRepository.show(
              materaTransactionId
            );

            transactionStatus = data.status;
          }

          if (transactionStatus === 'EXPIRED') {
            return toastr.error('QR Code expirado.');
          }
        } catch (err) {
          return toastr.error(
            'Ocorreu um erro ao gerar o código PIX. Tente novamente!'
          );
        }
        await sleep(3000);
        setMateraPixCode('');
        setMateraPixValue(0);
      }

      setIsMateraQRCodeModalOpen(false);
      setIsStonePaymentProgressModalOpen(false);
    }
    setLoading(true);

    if (!saleId) {
      return create({ values, transactionId, materaTransactionId });
    } else {
      return update({ values, transactionId, materaTransactionId });
    }
  }

  async function createCustomerCreditMovimentation(
    generateTitleAfterCloseSale,
    values
  ) {
    if (
      !isDefaultCustomer(pdvOnline.values.client, 'cpfCnpj') &&
      values.customerCreditPaymentValue &&
      isPaidWorkmotor &&
      generateTitleAfterCloseSale &&
      company?.companyConfig?.manageInternalCredit
    ) {
      await customerCreditRepository.create({
        customerId: values.customerId,
        value: values.customerCreditPaymentValue,
        type: 'Saída',
        reason: 'Venda',
      });
    }
  }

  async function create({ values, transactionId, materaTransactionId }) {
    const { paidValue, installments, paymentConditionId } = values;

    const payment = !paymentConditionId
      ? undefined
      : {
          Amount: paidValue,
          CondPayment_id: paymentConditionId,
          Installments: installments.map((installment) => ({
            Due_Date: installment.dueDate,
            FormOfPayment_id: installment.formOfPaymentId,
            Amount: installment.value,
            amountReceived: installment.amountReceived,
          })),
        };

    try {
      const saleValues = getSaleFromValues(values);
      saleValues.sale.SalesStatus_id = 5;
      saleValues.sale.userIdWhoClosedSale = userId;
      saleValues.sale.employeeId = user.employeeId;
      saleValues.payment = payment;
      saleValues.quickQuoteId = quickQuoteId;
      if (transactionId) saleValues.stonePreTransactionId = transactionId;
      if (materaTransactionId)
        saleValues.materaTransactionId = materaTransactionId;

      const sale = await pdvsRepository.create(saleValues);

      await createCustomerCreditMovimentation(
        saleValues.sale.generateTitleAfterCloseSale,
        values
      );

      setSaleId(sale.sale.id);
      setIsClosedSaleModalOpen(true);
      if (
        saleValues.sale.usedCRMBonus &&
        sale.totalSales?.message.toLowerCase().includes('sucesso')
      ) {
        toastr.success(
          'Venda Finalizada',
          'A Venda foi finalizada utilizando Bônus com sucesso'
        );
      }
      return dispatch(getProductsUnderStock(companyId));
    } catch (err) {
      console.error(err);
      return toastr.warning(
        'Ocorreu um erro ao salvar a venda. Por favor, tente novamente'
      );
    } finally {
      handleFinishPDV();
      setProcessingPayment(false);
      setProcessedPayment(false);
      setLoading(false);
    }
  }

  async function update({ values, transactionId, materaTransactionId }) {
    const { paidValue, installments, paymentConditionId } = values;

    const payment = {
      Amount: paidValue,
      CondPayment_id: paymentConditionId,
      Installments: installments.map((installment) => ({
        ...installment,
        Due_Date: installment.dueDate,
        FormOfPayment_id: installment.formOfPaymentId,
        Amount: installment.value,
        amountReceived: installment.amountReceived,
      })),
    };

    try {
      const saleValues = getSaleFromValues(values);

      saleValues.sale.SalesStatus_id = 5;
      saleValues.sale.userIdWhoClosedSale = userId;
      saleValues.sale.employeeId = user.employeeId;
      // saleValues.sale.Vehicle_Id = vehicleId
      saleValues.payment = payment;
      saleValues.stonePreTransactionId = transactionId;
      saleValues.materaTransactionId = materaTransactionId;

      await pdvsRepository.update(saleId, saleValues);
      setIsClosedSaleModalOpen(true);

      dispatch(getProductsUnderStock(companyId));
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao atualizar a venda. Por favor, tente novamente'
      );
    } finally {
      setProcessingPayment(false);
      setProcessedPayment(false);
      setLoading(false);
      dispatch(change('pdvOnline', 'isSubmitting', false));
      dispatch(change('pdvOnline', 'isSubmitting', false));
    }
  }

  async function cancel() {
    setLoading(true);

    try {
      const saleValues = getSaleFromValues(values);
      saleValues.sale.SalesStatus_id = 2;

      if (!saleValues?.payment?.paymentConditionId) {
        saleValues.payment = undefined;
      }

      if (!saleId) {
        await pdvsRepository.create(saleValues);
      } else {
        await pdvsRepository.update(saleId, saleValues);
      }
      setShowSaleCanceledModal(false);
      setSaleId(null);
      dispatch(initialize('pdvOnline', initialValues));
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao cancelar a venda. Por favor, tente novamente'
      );
    }
    setLoading(false);
  }

  function getSaleFromValues(values) {
    const {
      Comments,
      cart,
      totalSale,
      customerId,
      paidValue,
      freightValue,
      vehicleId,
      redeemedBonus,
      totalSaleWithBonus,
      usedCRMBonus,
      bonusId,
      orderId,
      generalDiscount,
      generalDiscountPercentage,
      discountType,
      salesSubtypeId,
      generateTitleAfterCloseSale,
      neededToCreateCustomerCredit,
    } = values;

    let Product_Value = 0;
    let productValueWithDiscount = 0;

    let Service_Value = 0;
    let totalCart = 0;

    for (const item of cart) {
      if (item.Type === 'Produto') {
        Product_Value += item.Sale_Price * item.Quantity;
        productValueWithDiscount += item.Total;
      } else {
        Service_Value += item.Sale_Price * item.Quantity;
      }
      totalCart += item.Sale_Price * item.Quantity;
    }

    const {
      discountProducts: Discount_Products,
      discountProductsPercentage: Discount_Products_Percentage,
    } = handleProductsDiscount({
      productValue: Product_Value,
      productValueWithDiscount,
    });

    const Discount_Value = generalDiscount;
    const Discount_Value_Percentage = generalDiscountPercentage;

    const saleValues = {
      sale: {
        User_id: userId,
        Company_id: companyId,
        Customer_id: customerId,
        Vehicle_id: vehicleId,
        Comments,
        Product_Value: productValueWithDiscount,
        Discount_Type: discountType === 'real' ? 'R$' : '%',
        Discount_Products,
        Discount_Products_Percentage,
        Discount_Value,
        Discount_Value_Percentage,
        SubTotal: totalSale + Discount_Value,
        Final_Value: totalSaleWithBonus > 0 ? totalSaleWithBonus : totalSale,
        changeValue:
          !neededToCreateCustomerCredit && paidValue > totalSale
            ? paidValue - totalSale
            : null,
        openDate: new Date().getTime(),
        freightValue,
        usedCRMBonus: usedCRMBonus,
        redeemedBonus: redeemedBonus || 0,
        bonusId: usedCRMBonus ? bonusId || '' : '',
        orderId: orderId || '',
        sellerName: userName ? userName : 'Vendedor',
        consumerName: crmBonusCurrentCustomer?.Company_Name || 'Consumidor',
        consumerCellphone:
          !!usedCRMBonus && !!crmBonusCurrentCustomer
            ? onlyNumbers(crmBonusCurrentCustomer?.Phones[0]?.Number_Phone2)
            : null,
        itemsQuantity: pdvOnline?.values?.cart.reduce(
          (total, cart) => total + cart.Quantity,
          0
        ),
        salesSubtypeId,
        generateTitleAfterCloseSale,
      },
      items: cart.map(serializeCartItem),
    };

    return saleValues;
  }

  function serializeCartItem(item) {
    const {
      Description,
      Quantity,
      Sale_Price,
      Type,
      id,
      Total,
      Discount_Rate,
      Product_id,
      Service_id,
      Value_Cost,
    } = item;

    let Unit_Discount_Value = 0;

    if (Discount_Rate) {
      const discount = Quantity * Sale_Price - Total;
      Unit_Discount_Value = Number((discount / Quantity).toFixed(2));
    }

    return {
      id: saleId ? id : undefined,
      Description,
      Quantity,
      Unit_Value: Sale_Price,
      Unit_Discount_Value,
      Value_Cost,
      Amount: Total,
      Discount_Value: Discount_Rate || 0,
      Type,
      Product_id,
      Service_id,
    };
  }

  function handleInitNewSale() {
    if (isClosedSaleModalOpen) {
      setIsClosedSaleModalOpen(false);
    }
    if (isClosedInvoiceModalOpen) {
      setIsClosedInvoiceModalOpen(false);
    }
    if (isInvoiceDetailsModalOpen) {
      setIsInvoiceDetailsModalOpen(false);
    }

    setSaleId(null);
    setInvoiceId(null);
    setNfceIncludeCpfCnpj(null);
    setCustomerCpfCnpj('');
    setValues([]);

    dispatch(initialize('pdvOnline', initialValues));
  }

  function handleOpenIncludeCpfCnpjModal(invoiceId) {
    setInvoiceId(invoiceId);
    setIsClosedSaleModalOpen(false);
    setIsIncludeCpfCnpjModalOpen(true);
  }

  function validationData(includeCpfCnpj = nfceIncludeCpfCnpj) {
    const arrayCpfCnpj = customerCpfCnpj.split('');
    const hasOnlyZeros =
      arrayCpfCnpj.length && arrayCpfCnpj.every((e) => e === 0);
    if (includeCpfCnpj && !customerCpfCnpj) {
      return toastr.warning('CPF/CNPJ não informado');
    }
    if (hasOnlyZeros) {
      return toastr.warning('Documento inválido');
    }
    if (nfceIncludeCpfCnpj === null) {
      setNfceIncludeCpfCnpj(includeCpfCnpj);
    }
    if (isIncludeCpfCnpjModalOpen) {
      setIsIncludeCpfCnpjModalOpen(false);
    }
    if (isNFCeErrorsModalOpen) {
      setIsNFCeErrorsModalOpen(false);
    }
    if (isInvoiceDetailsModalOpen) {
      setIsInvoiceDetailsModalOpen(false);
    }

    if (NFCeSettings.fiscalDocumentType === 'SAT') {
      handleEmitSAT(includeCpfCnpj);
    } else {
      handleEmitNFCe(includeCpfCnpj);
    }
  }

  async function handleEmitSAT(includeCpfCnpj) {
    try {
      setIsInvoiceLoaderOpen(true);
      const emittedSAT = await SATCFeRepository.emit(invoiceId, {
        includeCpfCnpj,
        customerCpfCnpj,
        issuedAt: new Date().getTime(),
      });

      const { status } = emittedSAT;
      const isInvoiceIssued = status === 'Emitida';

      if (isInvoiceIssued) {
        setIsClosedInvoiceModalOpen(true);
      } else {
        setIsInvoiceDetailsModalOpen(true);
      }
    } catch (err) {
      console.error(err);
      if (
        !err.response?.data?.validated &&
        err.response?.data?.errors?.length
      ) {
        handleOpenErrorsModal(err.response.data.errors);
      } else {
        toastr.warning(
          'Ocorreu uma falha ao enviar a nota',
          err.response.data.message
        );
        setIsInvoiceDetailsModalOpen(true);
      }
    } finally {
      setIsInvoiceLoaderOpen(false);
    }
  }

  async function handleEmitNFCe(includeCpfCnpj) {
    try {
      setIsInvoiceLoaderOpen(true);
      const result = await NFCesRepository.emit(invoiceId, {
        includeCpfCnpj,
        customerCpfCnpj,
        issuedAt: new Date().getTime(),
      });
      const { status, docPdfDownload } = result;
      const isInvoiceIssued = status === INVOICE_STATUS_DESCRIPTION.ISSUED;
      setIsInvoiceLoaderOpen(false);

      setDownloadNFCeLink(docPdfDownload);

      if (isInvoiceIssued) {
        setIsClosedInvoiceModalOpen(true);
      } else {
        setIsInvoiceDetailsModalOpen(true);
      }
    } catch (err) {
      console.error(err);
      if (
        !err.response?.data?.validated &&
        err.response?.data?.errors?.length
      ) {
        handleOpenErrorsModal(err.response.data.errors);
      } else {
        toastr.warning(
          'Ocorreu uma falha ao enviar a nota',
          err.response.data.message
        );
        setIsInvoiceDetailsModalOpen(true);
      }
    } finally {
      setIsInvoiceLoaderOpen(false);
    }
  }

  function handleOpenErrorsModal(errors) {
    handleFinishPDV();
    setErrors(errors);
    setIsNFCeErrorsModalOpen(true);
  }

  const handleBlockSalesModalSubmit = () => {
    const { cart } = pdvOnline.values;
    if (cart.length > 0) {
      dispatch(change('pdvOnline', 'step', 2));
    }
  };

  const initialValues = {
    cart: [],
    productSelected: {
      query: '',
      Total: 0,
      Quantity: 0,
    },
    discountType: 'real',
    generalDiscountPercentage: 0,
    generalDiscount: 0,
    installments: [],
    query: '',
    totalSale: 0,
    step: 1,
    vehicleId: null,
    customerId: null,
    customerCreditPaymentValue: false,
    saleId: null,
    client: 'Cliente Consumidor',
    paidValue: 0,
    step: 1,
    typeBtn: '',
    freightValue: 0,
    redeemedBonus: 0,
    orderId: 0,
    bonusId: 0,
    usedCRMBonus: false,
    totalSaleWithBonus: 0,
    salesSubtypeId: 1,
    generateTitleAfterCloseSale: true,
    neededToCreateCustomerCredit: false,
  };

  return (
    <>
      {loadingAuth ? (
        <AppLoading />
      ) : (
        <>
          <FormPDV
            initialValues={initialValues}
            onSubmit={handleSubmit}
            loading={loading}
            onOpenOrcaments={() => setIsOrcamentsModalOpen(true)}
            onOpenListNFCe={() => setIsNFCeList(true)}
            hasSettingsNFCe={hasSettingsNFCe}
            hasCashierControl={hasCashierControl}
            onOpenCashierBankHistoric={() => setIsCashierBankPDVModalOpen(true)}
          />

          <ClosedSaleModal
            loading={loading}
            setLoading={setLoading}
            show={isClosedSaleModalOpen}
            saleId={saleId}
            invoiceId={invoiceId}
            onInitNewSale={handleInitNewSale}
            hasSettingsNFCe={hasSettingsNFCe}
            NFCeSettings={NFCeSettings}
            fiscalDocumentType={fiscalDocumentType}
            onEmitInvoice={handleOpenIncludeCpfCnpjModal}
            transactionId={transactionId}
            companyId={companyId}
            clientCpfCnpj={clientCpfCnpj}
          />

          <AlertModal
            show={showSaleCanceledModal}
            message="Deseja realmente cancelar essa venda? Ao cancelar a venda, o estoque não será modificado e uma nova venda será iniciada."
            onHide={() => !loading && setShowSaleCanceledModal(false)}
            onCancel={() => {
              setShowSaleCanceledModal(false);
              setLoading(false);
            }}
            onSubmit={cancel}
            loading={loading}
          />

          {isOrcamentsModalOpen && (
            <OpenOrcamentsModal
              onCancel={() => setIsOrcamentsModalOpen(false)}
              onSelectOrcament={loadOrcament}
            />
          )}

          {isIncludeCpfCnpjModalOpen && (
            <IncludeCpfCnpjModal
              fiscalDocumentType={fiscalDocumentType}
              customerCpfCnpj={customerCpfCnpj}
              setCustomerCpfCnpj={setCustomerCpfCnpj}
              onSubmit={validationData}
            />
          )}

          {isInvoiceLoaderOpen && (
            <InvoiceProcessLoader message="Estamos realizando o envio da cupom fiscal. Por favor, aguarde." />
          )}

          <ClosedInvoiceModal
            show={isClosedInvoiceModalOpen}
            fiscalDocumentType={fiscalDocumentType}
            invoiceId={invoiceId}
            downloadNFCeLink={downloadNFCeLink}
            saleId={saleId}
            onInitNewSale={handleInitNewSale}
          />

          {isNFCeList && <NFCeList onCancel={() => setIsNFCeList(false)} />}

          {isNFCeErrorsModalOpen && (
            <NFCeErrorsModal
              errors={errors}
              onCancel={() => {
                setIsNFCeErrorsModalOpen(false);
                setIsClosedSaleModalOpen(true);
              }}
              onRetry={validationData}
            />
          )}

          {isInvoiceDetailsModalOpen && (
            <InvoiceDetailsModal
              invoiceId={invoiceId}
              fiscalDocumentType={fiscalDocumentType}
              onInitNewSale={handleInitNewSale}
              onRetry={validationData}
            />
          )}

          {isCashierBankPDVModalOpen && (
            <CashierBankPDVModal
              onCancel={() => setIsCashierBankPDVModalOpen(false)}
            />
          )}
          <BlockSalesModals onSubmit={handleBlockSalesModalSubmit} />
          <StonePaymentProgressModal
            show={isStonePaymentProgressModalOpen}
            processing={processingPayment}
            processed={processedPayment}
          />

          <MateraQRCodeModal
            show={isMateraQRCodeModalOpen}
            setShow={setIsMateraQRCodeModalOpen}
            pixValue={materaPixValue}
            pixCode={materaPixCode}
            setPixCode={setMateraPixCode}
            setIsShareQRCodeModalOpen={setIsShareQRCodeModalOpen}
          />

          {isShareQRCodeModalOpen ? (
            <ShareQRCodeModal
              show={isShareQRCodeModalOpen}
              setShow={setIsShareQRCodeModalOpen}
              clientName={'Cliente Consumidor'}
              pixCode={materaPixCode}
            />
          ) : (
            ''
          )}

          <CRMBonusModals sale={{ pdvSale }} />
        </>
      )}
    </>
  );
};

export default PDV;
