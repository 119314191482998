import React, { useRef } from 'react';
import { useAuth } from 'contexts/auth';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Table } from 'v2/components/Table';
import {
  InputContainer,
  InputLabel,
  InputSelectDefault,
  InputSelectInTable,
} from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { companySegments as companySegmentsAtom } from 'storage/companyDetailsStorage';
import { useRecoilState } from 'recoil';
import handleExports from './utils';
import {
  listAllChecklist,
  getBySaleId,
  createChecklistSales,
  updateChecklistSales,
} from 'v2/repositories/ChecklistSalesRepository';
import { listDefects } from 'v2/repositories/DefectsRepository';
import { toastr } from 'react-redux-toastr';

export function ChecklistModal({ open, handleClose, saleId, handlePrint }) {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [checklistOptions, setChecklistOptions] = useState([]);
  const [checklists, setChecklists] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [selectedOptionsOrderBySelect, setSelectedOptionsOrderBySelect] =
    useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [defectOptions, setDefectOptions] = useState([]);
  const [correctionOptions, setCorrectionOptions] = useState([]);
  const [checklistSaleId, setChecklistSaleId] = useState(null);

  const [companySegments] = useRecoilState(companySegmentsAtom);

  const numberToStringSegments = {
    1: 'Carros',
    2: 'Motos',
    3: 'Pesados',
    7: 'Van',
    8: 'Bicicleta',
  };

  const availableSegments = companySegments
    .map((number) => numberToStringSegments[number])
    .filter((segment) => segment !== undefined);

  const optionsDefaultDesign = [
    {
      label: 'Não Exibir',
      value: 'Não Exibir',
    },
  ].concat(
    availableSegments.map((option) => ({
      label: option,
      value: option,
    }))
  );

  const {
    optionsOrderBySelect,
    defectAndCorrectionInPrintOptions,
    itensStatus,
    getStatusDictionary,
  } = handleExports();

  const [defectCorrectionOnReport, setDefectCorrectionOnReport] = useState({
    value: false,
    label: 'Não Exibir',
  });

  const [selectedDefaultDesign, setSelectedDefaultDesign] = useState(null);

  useEffect(() => {
    if (companyId && saleId) {
      fetchData();
    }
  }, [companyId, saleId]);

  const formatOptions = (data, valueField, labelField) => {
    const formattedData = [];

    if (data && Array.isArray(data) && data.length > 0) {
      data.forEach((item) => {
        formattedData.push({
          value: item[valueField],
          label: item[labelField],
        });
      });
    }

    return formattedData;
  };

  const handleTableData = (data) => {
    const formattedData = [];

    if (data && Array.isArray(data) && data.length > 0) {
      data.forEach((item) => {
        formattedData.push({
          ...item,
          status: getStatusDictionary(item.status),
          observations: item.observations || '',
        });
      });
    }
    return formattedData;
  };

  const handleTableDataChange = (index, field, value) => {
    const updatedData = [...tableData];
    updatedData[index][field] = value;
    setTableData(updatedData);
  };

  const handleSelectDefect = (index, field, value) => {
    handleTableDataChange(index, field, value);
    setCorrectionOptions(
      value.correctionsAssociated.map((item) => ({
        value: item,
        label: item.description,
      }))
    );
    handleTableDataChange(index, 'Corrections', null);
  };

  const handleSelectChecklistSale = (selectedChecklist) => {
    setSelectedChecklist({
      label: selectedChecklist.checklists.description,
      value: selectedChecklist.checklists.id,
    });
    setSelectedOptionsOrderBySelect({
      label:
        selectedChecklist.orderBy === 'ALPHABETICAL'
          ? 'Ordem Alfabética'
          : 'Lançamento na tabela itens',
      value: selectedChecklist.orderBy,
    });
    setDefectCorrectionOnReport({
      label:
        selectedChecklist.defectCorrectionOnReport === 0
          ? 'Não Exibir'
          : 'Exibir',
      value: selectedChecklist.defectCorrectionOnReport === 0 ? false : true,
    });
    setSelectedDefaultDesign({
      label: selectedChecklist.defaultDesign,
      value: selectedChecklist.defaultDesign,
    });
    const formatTableData = handleTableData(
      selectedChecklist.checklistItems,
      true
    );
    setTableData(formatTableData);
    setPageSize(selectedChecklist.checklistItems.length);
  };

  const handleSelectCheckList = (selectedChecklist) => {
    const findSelectedChecklist = (checklists, selectedChecklistValue) => {
      return checklists.find(
        (checklist) => checklist.id === selectedChecklistValue
      );
    };

    setSelectedChecklist(selectedChecklist);

    const selectedChecklistObj = findSelectedChecklist(
      checklists,
      selectedChecklist.value
    );

    setSelectedOptionsOrderBySelect({
      label:
        selectedChecklistObj.orderBy === 'ALPHABETICAL'
          ? 'Ordem Alfabética'
          : 'Lançamento na tabela itens',
      value: selectedChecklistObj.orderBy,
    });
    setSelectedDefaultDesign({
      label: selectedChecklistObj.defaultDesign,
      value: selectedChecklistObj.defaultDesign,
    });
    setTableData(handleTableData(selectedChecklistObj.ChecklistItems));
    setPageSize(selectedChecklistObj.ChecklistItems.length);
  };

  const fetchDefectsOptions = async () => {
    try {
      const res = await listDefects({
        companyId,
        limit: 9999,
        status: true,
      });

      setDefectOptions(
        res.rows.map((defect) => ({
          label: defect.description,
          value: {
            ...defect,
          },
        }))
      );
    } catch (err) {
      console.log(err);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const handleSetChecklistOptions = async (options) => {
    setChecklists(options);
    const formatChecklistOptions = formatOptions(options, 'id', 'description');
    setChecklistOptions(formatChecklistOptions);
  };

  const fetchData = async () => {
    try {
      const listAll = await listAllChecklist({
        status: true,
        companyId,
        limit: 9999,
      });

      await handleSetChecklistOptions(listAll.rows);

      const checklistSaleResponse = await getBySaleId(saleId, companyId);
      if (checklistSaleResponse) {
        setChecklistSaleId(checklistSaleResponse.id);

        const serializedChecklist = {
          id: checklistSaleResponse.checklistId,
          checklistItems: checklistSaleResponse.ChecklistSalesItems,
          defaultDesign: checklistSaleResponse.defaultDesign,
          description: checklistSaleResponse.description,
          orderBy: checklistSaleResponse.orderBy,
          status: checklistSaleResponse.status,
          defectCorrectionOnReport:
            checklistSaleResponse.defectCorrectionOnReport,
          checklists: checklistSaleResponse.Checklists,
        };
        handleSelectChecklistSale(serializedChecklist);
      }

      fetchDefectsOptions();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const columns = [
    {
      width: 300,
      Header: 'Itens a serem verificados',
      accessor: 'itemToCheck',
    },
    {
      width: 220,
      Header: 'Grupo',
      Cell: (props) => {
        return props.original?.GroupItems?.description || null;
      },
    },
    {
      Header: 'Status',
      width: 90,
      Cell: (props) => (
        <InputContainer variant="column">
          <InputSelectInTable
            width={'80px'}
            height={'35px'}
            options={itensStatus}
            value={props.original.status}
            onChange={(e) => handleTableDataChange(props.index, 'status', e)}
            fontSize={'12px'}
            isLoading={loading}
          />
        </InputContainer>
      ),
    },
    {
      width: 300,
      Header: 'Defeito',
      Cell: (props) => (
        <InputContainer variant="column">
          <InputSelectInTable
            width={'240px'}
            height={'35px'}
            options={defectOptions}
            fontSize={'12px'}
            value={
              props.original.Defects
                ? {
                    label: props.original.Defects.description,
                    value: props.original.Defects,
                  }
                : null
            }
            onChange={(e) =>
              handleSelectDefect(props.index, 'Defects', e.value)
            }
            isLoading={loading}
          />
        </InputContainer>
      ),
    },
    {
      width: 300,
      Header: 'Correção',
      Cell: (props) => (
        <InputContainer variant="column">
          <InputSelectInTable
            width={'240px'}
            height={'35px'}
            options={correctionOptions}
            value={
              props.original.Corrections
                ? {
                    label: props.original.Corrections.description,
                    value: props.original.Corrections,
                  }
                : null
            }
            fontSize={'12px'}
            onChange={(e) =>
              handleTableDataChange(props.index, 'Corrections', e.value)
            }
            isLoading={loading}
            inTable
          />
        </InputContainer>
      ),
    },
    {
      width: 350,
      Header: 'Observação',
      Cell: (props) => {
        const [localValue, setLocalValue] = useState(
          props.original.observations
        );
        const inputRef = useRef(null);

        const handleInputChange = (e) => {
          setLocalValue(e.target.value);
        };

        const handleInputBlur = () => {
          handleTableDataChange(props.index, 'observations', localValue);
          inputRef.current.focus();
        };

        return (
          <InputContainer style={{ width: '500px' }} variant="column">
            <InputText
              width={'500px'}
              height={'35px'}
              value={localValue}
              fontSize={'12px'}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              maxLength={200}
              isLoading={loading}
              ref={inputRef}
            />
          </InputContainer>
        );
      },
    },
  ];

  const serializeTableData = () => {
    const checklistSalesItems = tableData.map((item) => ({
      itemToCheck: item.itemToCheck,
      groupItemsId: item.GroupItems?.id,
      defectId: item.Defects?.id,
      correctionId: item.Corrections?.id,
      defectDescription: item.Defects?.description,
      defectDetails: item.Defects?.details,
      correctionDescription: item.Corrections?.description,
      correctionDetails: item.Corrections?.details,
      groupItemsDescription: item.GroupItems?.description,
      observations: item.observations,
      status: item.status.value,
    }));

    return {
      companyId,
      checklistId: selectedChecklist.value,
      saleId,
      description: selectedChecklist.label,
      defaultDesign: selectedDefaultDesign.value,
      orderBy: selectedOptionsOrderBySelect.value,
      defectCorrectionOnReport: defectCorrectionOnReport.value,
      checklistSalesItems,
    };
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const serializedData = serializeTableData();

      if (checklistSaleId) {
        await updateChecklistSales(checklistSaleId, serializedData);
        toastr.success('Checklist atualizado com sucesso');
        handleClose();
      } else {
        await createChecklistSales(serializedData);
        toastr.success(
          'Checklist criado com sucesso',
          'Os dados da análise foram salvos'
        );
        handleClose();
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
    }
    setLoading(false);
  };

  const handlePrintChecklist = async () => {
    await onSubmit();
    handlePrint();
  };

  return (
    <Modal show={open} onHide={() => null} dialogClassName="modal-90w">
      <Modal.Header>
        <Modal.Title>
          <strong>Checklist Personalizado</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 flex column gap-1">
          <div className="flex gap-1">
            <InputContainer>
              <InputLabel>Selecione o Checklist: </InputLabel>
              <InputSelectDefault
                width={'245px'}
                height={'35px'}
                isLoading={loading}
                options={checklistOptions}
                value={selectedChecklist}
                onChange={(e) => handleSelectCheckList(e)}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Desenho Padrão: </InputLabel>
              <InputSelectDefault
                width={'120px'}
                height={'35px'}
                isLoading={loading}
                options={optionsDefaultDesign}
                value={selectedDefaultDesign}
                onChange={(e) => setSelectedDefaultDesign(e)}
                disabled={!selectedChecklist}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>
                Ordenação de Exibição de Grupo no impresso:{' '}
              </InputLabel>
              <InputSelectDefault
                width={'300px'}
                height={'35px'}
                isLoading={loading}
                options={optionsOrderBySelect}
                value={selectedOptionsOrderBySelect}
                onChange={(e) => setSelectedOptionsOrderBySelect(e)}
                disabled={!selectedChecklist}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Defeito e Correção no impresso: </InputLabel>
              <InputSelectDefault
                width={'200px'}
                height={'35px'}
                isLoading={loading}
                options={defectAndCorrectionInPrintOptions}
                value={defectCorrectionOnReport}
                onChange={(e) => setDefectCorrectionOnReport(e)}
                disabled={!selectedChecklist}
              />
            </InputContainer>
          </div>
          <div>
            <p
              className="flex end"
              style={{ fontSize: '12px', color: '#D43F3A', fontWeight: 'bold' }}
            >
              Observações não serão exibidas no impresso de checklist
            </p>
            <Table
              data={tableData}
              columns={columns}
              loading={loading}
              pageSize={pageSize}
              fontSize="12px"
              showPagination={false}
              style={{
                maxHeight: tableData.length > 7 ? '400px' : '',
                overflow: 'hidden',
              }}
              getTBodyProps={() => ({
                overflow: 'hidden',
                overflowX: 'scroll',
              })}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end gap-050">
          <button
            className="button button-h35 button-red"
            onClick={handleClose}
            disabled={loading}
          >
            Voltar
          </button>
          <button
            className="button button-h35 button-blue"
            onClick={handlePrintChecklist}
            disabled={loading}
          >
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            Salvar e Imprimir
          </button>
          <button
            className="button button-h35 button-green"
            onClick={onSubmit}
            disabled={loading}
          >
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            Salvar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
