import { pad } from 'highcharts';
import React from 'react';

export function PieChartGraphTitle({
  children,
  containerStyles = {},
  titleStyles = {},
}) {
  return (
    <div style={{ ...styles.container, ...containerStyles }}>
      <span style={{ ...styles.title, ...titleStyles }}>{children}</span>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    width: '100%',
    padding: '10px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#428BCA',
  },
};
