import api from 'v2/services/api';
const getBillsMonth = async (companyId, recordType, period) => {
  try {
    const response = await api.get(
      `financial-summary/${companyId}?recordType=${recordType}&period=${period}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
const getWeeksByMonthAndYear = async (year) => {
  try {
    const response = await api.get(`financial-summary/weeks/${year}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar resumo financeiro por semanas:', error);
    throw error;
  }
};

async function getBillsByWeek(companyId, recordType, period, week) {
  try {
    const response = await api.get(
      `financial-summary/${companyId}?recordType=${recordType}&period=${period}&week=${week}`
    );
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar resumo financeiro por semana:', error);
    throw error;
  }
}

const FinanceCalendarRepository = {
  getBillsMonth,
  getWeeksByMonthAndYear,
  getBillsByWeek,
};

export default FinanceCalendarRepository;
