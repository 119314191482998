import create from 'zustand';
import { format } from 'date-fns';
import { OnboardingRepository } from 'v2/repositories/OnboardingRepository';
import {
  faCheck,
  faStarHalfStroke,
  faWarning,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

const initialStates = {
  selectedTab: 0,
  companyId: null,
  onboardingHeaderId: null,
  isLoading: false,
  isFetching: false,
  rowsTraining: [],
  rowsModules: [],
  responsable: '',
  initialDate: null,
  legends: [
    {
      description: 'Concluído',
      icon: faCheck,
      color: '#5cb85c',
    },
    {
      description: 'Incompleto',
      icon: faStarHalfStroke,
      color: '#f0ad4e',
    },
    {
      description: 'Não Realizado',
      icon: faXmark,
      color: '#d9534f',
    },
    {
      description: 'Pendente',
      icon: faWarning,
      color: '#FFB400',
    },
    {
      description: 'Não se aplica',
      color: '#428bca',
      iconName: 'N/A',
    },
  ],
  status: null,
  completeTrainingStatus: false,
  completeModuleStatus: false,
  isFeedbackModalOpen: false,
  companyName: '',
  emojiSelected: null,
  feedbackObservation: '',
};

const useOnboardingStore = create((set, get) => ({
  ...initialStates,

  setSelectedTab: (newOption) => set({ selectedTab: newOption }),
  setCompanyId: (newOption) => set({ companyId: newOption }),
  setOnboardingHeaderId: (newOption) => set({ onboardingHeaderId: newOption }),
  setResponsable: (newOption) => set({ responsable: newOption }),
  setIsFeedbackModalOpen: (newOption) =>
    set({ isFeedbackModalOpen: newOption }),
  setCompanyName: (newOption) => set({ companyName: newOption }),
  setEmojiSelected: (newOption) => set({ emojiSelected: newOption }),
  setFeedbackObservation: (newOption) =>
    set({ feedbackObservation: newOption }),

  handleHasIncompleteTraining: () => {
    const { rowsTraining } = get();
    const incompleteTraining = rowsTraining.find(
      (training) =>
        !training.training ||
        (training.training.status !== 'Não se aplica' &&
          training.training.status !== 'Concluído')
    );
    return !incompleteTraining ? true : false;
  },

  updateTrainingStatus: (trainingId, status) => {
    const { handleHasIncompleteTraining } = get();
    set((state) => ({
      rowsTraining: state.rowsTraining.map((training) =>
        training.id === trainingId
          ? { ...training, training: { ...training.training, status } }
          : training
      ),
    }));

    set({ completeTrainingStatus: handleHasIncompleteTraining() });
  },

  updateTrainingObservations: (trainingId, observations) => {
    set((state) => ({
      rowsTraining: state.rowsTraining.map((training) =>
        training.id === trainingId
          ? { ...training, training: { ...training.training, observations } }
          : training
      ),
    }));
  },

  handleHasIncompleteModule: () => {
    const { rowsModules } = get();
    const incompleteModule = rowsModules.find(
      (module) =>
        !module.module ||
        (module.module.status !== 'Não se aplica' &&
          module.module.status !== 'Concluído')
    );
    return !incompleteModule ? true : false;
  },

  updateModuleStatus: (moduleId, status) => {
    const { handleHasIncompleteModule } = get();
    set((state) => ({
      rowsModules: state.rowsModules.map((module) =>
        module.id === moduleId
          ? { ...module, module: { ...module.module, status } }
          : module
      ),
    }));

    set({ completeModuleStatus: handleHasIncompleteModule() });
  },

  updateModuleObservations: (moduleId, observations) => {
    set((state) => ({
      rowsModules: state.rowsModules.map((module) =>
        module.id === moduleId
          ? { ...module, module: { ...module.module, observations } }
          : module
      ),
    }));
  },

  handleSerializeObject: () => {
    const {
      companyId,
      responsable,
      initialDate,
      finalDate,
      rowsTraining,
      rowsModules,
      status,
      emojiSelected,
      feedbackObservation,
    } = get();

    return {
      onboarding: {
        companyId,
        responsable,
        initialDate,
        status,
        ...(status === 'finished' ? { finalDate: new Date() } : {}),
        training: rowsTraining
          .filter(
            (row) =>
              row.training && (row.training.status || row.training.observations)
          )
          .map((row) => ({
            id: row.training && row.training.id,
            trainingItemsId: row.id,
            status: row.training && row.training.status,
            observations: row.training && row.training.observations,
          })),
        modules: rowsModules
          .filter(
            (row) =>
              row.module && (row.module.status || row.module.observations)
          )
          .map((row) => ({
            ...(row.module && row.module.id && { id: row.module.id }),
            modulesItemsId: row.id,
            status: (row.module && row.module.status) || '',
            observations: (row.module && row.module.observations) || '',
          })),
      },
      ...(emojiSelected && emojiSelected !== ''
        ? {
            feedback: {
              companyId,
              satisfaction: emojiSelected,
              ...(feedbackObservation && { observation: feedbackObservation }),
            },
          }
        : {}),
    };
  },

  createOnboarding: async () => {
    const { handleSerializeObject } = get();

    try {
      set({ isLoading: true });
      const serializedObject = handleSerializeObject();
      await OnboardingRepository.create(serializedObject);
      toastr.success(
        'Onboarding Finalizado',
        'Onboarding foi criado com sucesso!'
      );
      return true;
    } catch (error) {
      toastr.error('Ocorreu um erro ao criar o onboarding');
      console.error(error);
      return false;
    } finally {
      set({ isLoading: false });
    }
  },

  updateOnboarding: async () => {
    const { handleSerializeObject, onboardingHeaderId } = get();

    try {
      set({ isLoading: true });
      const serializedObject = handleSerializeObject();
      await OnboardingRepository.update(serializedObject, onboardingHeaderId);
      toastr.success(
        'Onboarding Finalizado',
        'O onboarding foi atualizado com sucesso!'
      );
      return true;
    } catch (error) {
      toastr.error('Ocorreu um erro ao criar o onboarding');
      console.error(error);
      return false;
    } finally {
      set({ isLoading: false });
    }
  },

  saveOnboarding: async () => {
    const { onboardingHeaderId, updateOnboarding, createOnboarding } = get();

    if (onboardingHeaderId) {
      return await updateOnboarding();
    } else {
      return await createOnboarding();
    }
  },

  handleValidateOpenSave: async () => {
    const { rowsModules, rowsTraining, responsable, saveOnboarding } = get();

    if (!responsable || responsable === '') {
      toastr.warning('Necessário preencher o campo Responsável');
      return false;
    }
    if (
      !rowsModules.find((module) => module.module && module.module.status) &&
      !rowsTraining.find(
        (training) => training.training && training.training.status
      )
    ) {
      toastr.warning('Necessário preencher algum Status');
      return false;
    }

    set({ status: 'doing' });

    return await saveOnboarding();
  },

  handleValidateFinishedSave: async () => {
    const {
      handleHasIncompleteModule,
      handleHasIncompleteTraining,
      responsable,
      saveOnboarding,
      emojiSelected,
    } = get();

    if (!responsable || responsable === '') {
      toastr.warning('Necessário preencher o campo Responsável');
      return false;
    }
    if (!handleHasIncompleteModule() || !handleHasIncompleteTraining()) {
      toastr.warning('Necessário preencher todos os Status');
      return false;
    }

    if (!emojiSelected || emojiSelected === '') {
      toastr.warning('Obrigatório selecionar o emoji de satisfação');
      return false;
    }

    set({ status: 'finished' });

    return await saveOnboarding();
  },

  handleLoadData: async () => {
    const {
      companyId,
      onboardingHeaderId,
      handleHasIncompleteModule,
      handleHasIncompleteTraining,
    } = get();

    set({ isFetching: true });

    try {
      let onboarding;
      if (!onboardingHeaderId) {
        onboarding = await OnboardingRepository.getNewOnboarding();
      } else {
        onboarding = await OnboardingRepository.getOnboarding({
          companyId,
          id: onboardingHeaderId,
        });
      }

      set({
        responsable:
          onboarding.header && onboarding.header.responsable
            ? onboarding.header.responsable
            : '',
        initialDate:
          onboarding.header && onboarding.header.initialDate
            ? onboarding.header.initialDate.split('T')[0]
            : format(new Date(), 'yyyy-MM-dd'),
        finalDate:
          onboarding.header && onboarding.header.finalDate
            ? onboarding.header.finalDate.split('T')[0]
            : null,
        rowsTraining: onboarding.trainings || [],
        rowsModules: onboarding.modules || [],
        emojiSelected: null,
        feedbackObservation: '',
        isLoading:
          onboarding.header && onboarding.header.finalDate ? true : false,
      });
      set({
        completeModuleStatus: handleHasIncompleteModule(),
        completeTrainingStatus: handleHasIncompleteTraining(),
      });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isFetching: false });
    }
  },
}));

export default useOnboardingStore;
