export const useFinanceCalendar = () => {
  const months = [
    { value: 1, label: 'Janeiro' },
    { value: 2, label: 'Fevereiro' },
    { value: 3, label: 'Março' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Maio' },
    { value: 6, label: 'Junho' },
    { value: 7, label: 'Julho' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Setembro' },
    { value: 10, label: 'Outubro' },
    { value: 11, label: 'Novembro' },
    { value: 12, label: 'Dezembro' },
  ];

  const typeOptions = [
    { value: '1', label: 'Mensal' },
    { value: '2', label: 'Semanal' },
  ];

  const typeRegisterOptions = [
    { value: 'both', label: 'Ambos' },
    { value: 'receivable', label: 'Receber' },
    { value: 'payable', label: 'Pagar' },
  ];

  const generateYearOptions = () => {
    const years = [];

    for (let i = 2024; i <= 2040; i++) {
      years.push({ value: i, label: i.toString() });
    }

    return years;
  };

  const years = generateYearOptions();

  const goToSelectedMonthYear = (calendarRef, getValues) => {
    const { month, year } = getValues();
    const selectedMonthIndex = months.findIndex((m) => m.value === month.value);
    const selectedYearValue = year.value;

    const date = new Date(selectedYearValue, selectedMonthIndex, 1);
    calendarRef.current.getApi().gotoDate(date);
  };

  const getStartOfWeekInMonth = (year, month, targetWeek) => {
    const daysInWeek = 7;
    const startOfMonth = new Date(year, month - 1, 1);

    const firstDayOfWeek = startOfMonth.getDay();

    const daysToAdd = (targetWeek - 1) * daysInWeek - firstDayOfWeek;

    const startDate = new Date(startOfMonth);
    startDate.setDate(startDate.getDate() + daysToAdd);

    return startDate;
  };

  return {
    months,
    generateYearOptions,
    typeOptions,
    typeRegisterOptions,
    years,
    goToSelectedMonthYear,
    getStartOfWeekInMonth,
  };
};
