import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import { useAuth } from '../../../contexts/auth';
import productsRepository from '../../../repositories/Products';
import servicesRepository from '../../../repositories/Services';
import { currency } from '../ToNormalize/ToNormalize';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';
import TableActions from './TableActions';
import AdvancedSearchButton from '../AdvancedSearchButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import NewProductModal from './NewProductModal';
import NewServiceModal from './NewServiceModal';
import companyConfigRepository from 'repositories/CompanyConfig';
import kitRepository from 'repositories/Kit';
import GeneralSearch from 'client/components/GeneralSearch';
import './styles.css';
import SearchModal from 'client/components/SearchByApllication';
import { InputLabel } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';
import { InputContainer } from 'v2/components/Input';
import { Table } from 'v2/components/Table';
import {
  getPricesOptions,
  getDefaultPriceOptions,
  getSelectedPriceOption,
  getProductPromotionalPrices,
} from 'v2/helpers/priceHelpers';
import { useConfigurationsContext } from 'v2/contexts/configurationsContext';
import PromotionalPriceTable from 'repositories/PromotionalPriceTable';
const ProductsServicesTable = ({
  productsList,
  servicesList,
  kitList,
  onInclude,
  discountGroupProducts,
  discountGroupServices,
  hideConsumption,
  cleanInputFlag,
  setCleanInputFlag,
  allowPromotionalPrices,
  alreadyAddedItems,
  hasKitSearch,
  customerId,
  DiscountPriceProductsTable,
}) => {
  const { companyId, company } = useAuth();
  const { sellingPriceType } = useConfigurationsContext();
  const typeOptions = getPricesOptions(sellingPriceType, hasKitSearch);
  const [type, setType] = useState(`${productsList ? 'Produto' : 'Serviço'}`);
  const [activeType, setActiveType] = useState({});

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);

  const [pageSize, setPageSize] = useState(10);
  const [query, setQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
  const [isNewServiceModalOpen, setIsNewServiceModalOpen] = useState(false);
  const [isGeneralSearchModalOpen, setIsGeneralSearchModalOpen] =
    useState(false);
  const [isApplicationSearchModalOpen, setIsApplicationSearchModalOpen] =
    useState(false);
  const [promotionalTableisValid, setPromotionalTableisValid] = useState(false);

  const [advancedSearchType, setAdvancedSearchType] = useState(null);
  const [searchType, setSearchType] = useState('Produtos');

  const debouncedSaveQuerySearch = useDebounce(setSearchQuery, 800);
  const hasToLoadProducts =
    productsList &&
    (type === 'Produto' ||
      type === 'Produtos - Varejo' ||
      type === 'Produtos - Atacado');

  const hasToLoadServices = servicesList && type === 'Serviço';
  const loadDiscountPriceProductsTables =
    servicesList && DiscountPriceProductsTable === 'Serviço';

  const loadOptions = async () => {
    setType(await getSelectedPriceOption(sellingPriceType, customerId, type));
    setActiveType(await getDefaultPriceOptions(sellingPriceType, customerId));
  };

  useEffect(() => {
    if (companyId && sellingPriceType) {
      loadOptions();
    }
  }, [companyId, sellingPriceType]);

  useEffect(() => {
    if (!!companyId) {
      if (hasToLoadProducts) {
        loadProducts({
          page: 0,
          pageSize: 10,
          query: searchQuery,
        });
      } else if (hasToLoadServices || loadDiscountPriceProductsTables) {
        loadServices({
          page: 0,
          pageSize: 10,
          query: searchQuery,
        });
      } else if (type === 'Kit') {
        loadKits({
          page: 0,
          pageSize: 10,
          query: searchQuery,
        });
      }
    }
  }, [
    productsList,
    servicesList,
    searchQuery,
    pageSize,
    type,
    page,
    companyId,
    company,
  ]);

  useEffect(() => {
    if (cleanInputFlag) {
      handleChangeQuerySearch('');
      setCleanInputFlag(false);
    }
  }, [cleanInputFlag]);

  const handleEmptyItems = async (itemsList) => {
    if (itemsList.length === 0) {
      setItems([]);
      setLoading(false);
      return toastr.warning(`Nenhum ${type} cadastrado atualmente`);
    }
  };

  async function loadKits() {
    setLoading(true);
    try {
      const response = await kitRepository.getAll({
        companyId,
        page: page + 1,
        limit: pageSize,
        query: searchQuery,
        status: 1,
      });

      await handleEmptyItems(response.kits.rows);

      const formattedKit = response.kits.rows.map((kit) => ({
        ...kit,
        Products: response.kitItemsProducts.filter(
          (product) => product.kitId === kit.id
        ),
        Services: response.kitItemsServices.filter(
          (service) => service.kitId === kit.id
        ),
        Description: kit.description,
        Sale_Price: kit.priceTotal,
        Purchase_Price: kit.costPrice,
        Code: '-',
        Type: 'Kit',
      }));

      setItems(formattedKit);
      const total = response.kits.count;
      setPages(Math.floor(total / pageSize) + 1);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os kits. Por favor, tente novamente'
      );
    }
    setLoading(false);
  }

  async function loadProducts() {
    setLoading(true);

    try {
      const response = await productsRepository.getAllPaginated({
        Company_id: companyId,
        page: page + 1,
        limit: pageSize,
        query: searchQuery,
        IsActive: '1',
        havePrice: true,
        hideConsumption: hideConsumption ? 'true' : 'false',
      });

      let serializedItems = response.data;

      await handleEmptyItems(serializedItems);

      const activePromotionalTable =
        await PromotionalPriceTable.getActiveByCompanyId(companyId);
      const currentDate = new Date().toISOString().split('T')[0];

      const promotionalTableisValid =
        currentDate >= activePromotionalTable.initialDate &&
        currentDate <= activePromotionalTable.finalDate;

      setPromotionalTableisValid(promotionalTableisValid);

      serializedItems = await Promise.all(
        serializedItems.map(async (product) => {
          const productPrices = await getProductPromotionalPrices(
            product.Sale_Price,
            product.salePriceWholesale,
            promotionalTableisValid ? allowPromotionalPrices : false,
            product.promotionalPrice,
            product.promotionalPriceWholesale,
            type
          );
          return {
            ...product,
            ...productPrices,
            originalPrices: {
              Sale_Price: product.Sale_Price,
              salePriceWholesale: product.salePriceWholesale,
            },
            Type: 'Produto',
          };
        })
      );

      if (!!discountGroupProducts?.length) {
        serializedItems = serializedItems
          .filter((item) => !item.hasPromotionalPrice)
          .flatMap((product) => {
            const findProduct = discountGroupProducts.find(
              (data) => product.id === data.productId
            );

            if (findProduct) {
              return {
                ...product,
                Sale_Price:
                  findProduct.Product.Sale_Price * (findProduct.margin / 100) +
                  findProduct.Product.Sale_Price,
              };
            } else {
              return product;
            }
          });
      }
      setItems(serializedItems);
      const total = response.headers['x-total-count'];
      setPages(Math.floor(total / pageSize) + 1);
      setLoading(false);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os produtos. Por favor, tente novamente'
      );
    }
  }

  async function loadServices() {
    setLoading(true);

    try {
      const response = await servicesRepository.getAllPaginated({
        Company_id: companyId,
        page: page + 1,
        limit: pageSize,
        query: searchQuery,
        IsActive: '1',
        priceGreaterThanZero: true,
      });
      const activeServices = response.data
        .map((service) => ({
          ...service,
          Type: 'Serviço',
        }))
        .filter((s) => s.Price !== 0);

      let serializedServices = activeServices;

      await handleEmptyItems(serializedServices);

      const activePromotionalTable =
        await PromotionalPriceTable.getActiveByCompanyId(companyId);
      const currentDate = new Date().toISOString().split('T')[0];

      const promotionalTableisValid =
        currentDate >= activePromotionalTable.initialDate &&
        currentDate <= activePromotionalTable.finalDate;

      setPromotionalTableisValid(promotionalTableisValid);

      if (allowPromotionalPrices) {
        serializedServices = activeServices.map((service) => {
          return {
            ...service,
            Price:
              promotionalTableisValid && service.pricePromotional > 0
                ? service.pricePromotional
                : service.Price,
            hasPromotionalPrice: service.pricePromotional > 0,
          };
        });
      }

      if (!!discountGroupServices?.length) {
        serializedServices = serializedServices
          .filter((item) => !item.hasPromotionalPrice)
          .flatMap((service) => {
            const findService = discountGroupServices.find(
              (data) => service.id === data.serviceId
            );

            if (findService) {
              return {
                ...service,
                Type: 'Serviço',
                Price:
                  findService.Service.Price * (findService.margin / 100) +
                  findService.Service.Price,
              };
            } else {
              return {
                ...service,
                Type: 'Serviço',
              };
            }
          });
      }

      setItems(serializedServices);
      const total = response.headers['x-total-count'];
      setPages(Math.floor(total / pageSize) + 1);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar os serviços. Por favor, tente novamente'
      );
    }
    setLoading(false);
  }

  function handleChangeQuerySearch(value) {
    setQuery(value);
    debouncedSaveQuerySearch(value);
  }

  const hasProductsAndServices = servicesList && productsList;

  const getAdvancedSearchInfo = async () => {
    setLoading(true);
    try {
      const { advancedSearchType } = await companyConfigRepository.show(
        companyId
      );

      setAdvancedSearchType(advancedSearchType);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível carregar o tipo de pesquisa avançada.',
        'Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdvancedSearchInfo();
  }, []);

  const handleAdvancedSearch = () => {
    if (advancedSearchType === 'Por Aplicação') {
      setIsApplicationSearchModalOpen(true);
    } else {
      setIsGeneralSearchModalOpen(true);
    }
  };

  const getLinkedServices = async (ids) => {
    try {
      const linkedServices = await servicesRepository.getServicesByIds(ids);

      return linkedServices || [];
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const handleAddProductFromModal = async (values) => {
    try {
      const linkedServices = await getLinkedServices(values.linkedServices);
      const { Sale_Price, priceType } = getProductPromotionalPrices(
        values.productSalePrice,
        values.productSalePriceWholesale,
        allowPromotionalPrices,
        values.promotionalPrice,
        values.promotionalPriceWholesale,
        activeType.value
      );
      const data = {
        Description: values.productDescription,
        Code: values.productCode,
        Type: 'Produto',
        Stock_Quantity: values.productQuantity,
        Purchase_Price: values.productPurchasePrice,
        Sale_Price,
        Product_id: values.productId,
        id: values.productId,
        priceType,
      };

      await onInclude({
        ...data,
        Services: linkedServices,
        Type: 'Produto',
      });

      setIsGeneralSearchModalOpen(false);
      setIsApplicationSearchModalOpen(false);
      setQuery('');
      setSearchQuery('');
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível adicionar o produto.',
        'Por favor, tente novamente.'
      );
    }
  };

  const verifyIfProductIsIncluded = (item) => {
    return item.Type === 'Produto'
      ? !!alreadyAddedItems.find(
          (data) => item.id === data.productId || item.id === data.Product_id
        )
      : !!alreadyAddedItems.find(
          (data) =>
            item.id === data.serviceId ||
            item.id === data.Service_id ||
            item.id === data.Product_id
        );
  };

  const handleSetType = async (e) => {
    if (customerId) {
      const customerDefaultOption = await getSelectedPriceOption(
        sellingPriceType,
        customerId
      );
      if (
        e.value.includes('Produtos') &&
        !customerDefaultOption.includes(e.value.split(' - ')[1])
      ) {
        return toastr.warning(
          'Selecione outro tipo ou cliente.',
          `O cliente selecionado utiliza apenas o preço de ${customerDefaultOption}`
        );
      }
    }
    setActiveType(e);
    setPage(0);
    setType(e.value);
  };

  const hasCustoColumn =
    company.showPurchasePriceOnSale && activeType.value !== 'Serviço';

  const columns = [
    {
      Header: 'Código',
      accessor: 'Code',
      width: 70,
      Footer: type !== 'Kit' && (
        <a
          style={{
            cursor: 'pointer',
          }}
          onClick={() =>
            type === 'Produto' ||
            type === 'Produtos - Varejo' ||
            type === 'Produtos - Atacado'
              ? setIsNewProductModalOpen(true)
              : setIsNewServiceModalOpen(true)
          }
        >
          <FontAwesomeIcon
            color="#5cb85c"
            style={{
              height: '17px',
              width: '17px',
            }}
            icon={faCirclePlus}
          />
        </a>
      ),
    },
    {
      Header: 'Descrição',
      accessor: 'Description',
      Cell: (props) => {
        return (
          <div className="flex center w-100">
            <span
              className="text-center overflow-ellipsis"
              style={{ padding: '0 5px', width: '90%', whiteSpace: 'nowrap' }}
            >
              {props.value}
            </span>
          </div>
        );
      },
      Footer:
        type === 'Produto' ||
        type === 'Produtos - Varejo' ||
        type === 'Produtos - Atacado' ? (
          <div className="flex center">
            <a
              id="hiperlink-text"
              onClick={() => setIsNewProductModalOpen(true)}
            >
              Cadastrar Novo Produto
            </a>
          </div>
        ) : type === 'Serviço' ? (
          <div className="flex-center">
            <a
              id="hiperlink-text"
              onClick={() => setIsNewServiceModalOpen(true)}
            >
              Cadastrar Novo Serviço
            </a>
          </div>
        ) : null,
    },
    {
      Header: hasProductsAndServices ? 'Tipo' : null,
      accessor: hasProductsAndServices ? 'Type' : null,
      width: hasProductsAndServices ? 50 : 0,
      Cell: (props) =>
        hasProductsAndServices ? (
          <div>
            <strong>
              {props.value === 'Produto'
                ? 'P'
                : props.value === 'Serviço'
                ? 'S'
                : 'K'}
            </strong>
          </div>
        ) : (
          <></>
        ),
    },
    {
      Header: 'Estoque',
      accessor: 'Stock_Quantity',
      width: 100,
      Cell: (props) => (
        <div>
          <span style={{ color: props.value < 0 ? 'red' : '' }}>
            {props.original.Type === 'Produto' ? props.value : '--'}
          </span>
        </div>
      ),
    },

    hasCustoColumn && {
      Header: 'Custo',
      accessor: 'Purchase_Price',
      width: 100,
      Cell: (props) => (
        <div>
          {currency(
            props.original.Type === 'Produto' || props.original.Type === 'Kit'
              ? props.original.Purchase_Price
              : ''
          )}
        </div>
      ),
    },

    {
      Header: company.showPurchasePriceOnSale ? 'Venda' : 'Valor',
      accessor: 'Sale_Price',
      width: 100,
      Cell: (props) => (
        <div>
          {promotionalTableisValid &&
          allowPromotionalPrices &&
          props.original.hasPromotionalPrice ? (
            <span style={{ color: '#629c44' }}>
              {currency(props.value || props.original.Price)}
            </span>
          ) : (
            currency(
              props.original.Type === 'Produto' || props.original.Type === 'Kit'
                ? props.original.Sale_Price
                : props.original.Price
            )
          )}
        </div>
      ),
    },
    {
      accessor: 'Add',
      width: 60,
      Cell: (props) => {
        let isProductIncluded = false;
        if (alreadyAddedItems?.length > 0) {
          isProductIncluded = verifyIfProductIsIncluded(props.original);
        }
        return (
          <TableActions
            isProductIncluded={isProductIncluded}
            row={props}
            onInclude={onInclude}
          />
        );
      },
    },
  ].filter((column) => column);

  return (
    <>
      <div>
        <header>
          <div className="flex align-end" style={{ gap: '5px' }}>
            <InputContainer variant="column">
              <SearchInput
                placeholder={
                  type === 'Produto' ||
                  type === 'Produtos - Varejo' ||
                  type === 'Produtos - Atacado'
                    ? 'Código / Descrição / Apelido / Ref. Fab / Código de Barras'
                    : 'Pesquisar por Código e Descrição'
                }
                value={query}
                style={{
                  fontSize: '12px',
                  width: '400px',
                  height: '35px',
                  margin: '0',
                }}
                onChange={(e) => handleChangeQuerySearch(e.target.value)}
                containerStyle={{ maxWidth: '435px', margin: '0' }}
              />
            </InputContainer>
            <InputContainer variant="column">
              <AdvancedSearchButton
                style={{ width: '150px' }}
                onClick={() => handleAdvancedSearch()}
                isLoading={loading}
              />
            </InputContainer>
            {hasProductsAndServices && (
              <InputContainer variant="column">
                <InputLabel fontSize={'12px'} fontWeight="bold">
                  Tipo de Pesquisa:
                </InputLabel>
                <InputSelectDefault
                  style={{ fontSize: '12px' }}
                  width={'150px'}
                  height={'35px'}
                  options={typeOptions}
                  value={activeType}
                  defaultValue={{
                    label: 'Produtos',
                    value: 'Produto',
                  }}
                  fontSize={'12px'}
                  onChange={(e) => handleSetType(e)}
                />
              </InputContainer>
            )}
          </div>
        </header>

        <div style={{ marginTop: '5px', width: '100%' }}>
          <Table
            columns={columns}
            data={items}
            pageSizeOptions={[10, 20, 25, 50, 100]}
            pageSize={pageSize}
            page={page}
            onPageChange={setPage}
            manual
            onPageSizeChange={(newPageSize, _) => setPageSize(newPageSize)}
            pages={pages}
            loading={loading}
            style={{
              fontSize: '12px',
              height: '430px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          />
        </div>
      </div>

      {isNewProductModalOpen && (
        <NewProductModal
          handleClose={() => setIsNewProductModalOpen(false)}
          companyId={companyId}
          onInclude={onInclude}
        />
      )}

      {isNewServiceModalOpen && (
        <NewServiceModal
          handleClose={() => setIsNewServiceModalOpen(false)}
          companyId={companyId}
          onInclude={onInclude}
        />
      )}

      {isGeneralSearchModalOpen && (
        <GeneralSearch
          productQueryA={query}
          handleChangeQuerySearch={handleChangeQuerySearch}
          isSearchModalOpen={isGeneralSearchModalOpen}
          setIsSearchModalOpen={() => {
            setIsGeneralSearchModalOpen(false);
            setQuery('');
            setSearchQuery('');
          }}
          handleAddProduct={handleAddProductFromModal}
          allowPromotionalPrices={allowPromotionalPrices}
          searchType={searchType}
          priceType={activeType}
          handleSetType={handleSetType}
          setSearchType={setSearchType}
          isGeneralSearch={true}
        />
      )}

      {isApplicationSearchModalOpen && (
        <SearchModal
          productQuery={query}
          handleClose={() => {
            setIsApplicationSearchModalOpen(false);
            setQuery('');
            setSearchQuery('');
          }}
          handleAddItem={handleAddProductFromModal}
          searchType={searchType}
          setSearchType={setSearchType}
          setQuery={setQuery}
          setSearchQuery={setSearchQuery}
          priceType={activeType}
          handleSetType={handleSetType}
        />
      )}
    </>
  );
};

export default ProductsServicesTable;
