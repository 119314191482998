import React from 'react';
import { useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import useManagementIndicatorsStore from '../../../store/management-indicators-store';
import { TableRank } from '../components/TableRank';
import { PieChartGraph } from 'v2/components/PieChartGraph';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
export function ServiceProduct() {
  const { companyId } = useAuth();
  const {
    handleServiceProductData,
    currentDate,
    isLoading,
    productsByQuantity,
    productsByRentalValue,
    productsFamilyPercentageByQuantity,
    productsFamilyPercentageByRentalValue,
    servicesByQuantity,
    servicesByRentalValue,
    servicesFamilyPercentageByQuantity,
    servicesFamilyPercentageByRentalValue,
  } = useManagementIndicatorsStore((store) => {
    return {
      handleServiceProductData: store.handleServiceProductData,
      currentDate: store.currentDate,
      isLoading: store.isLoading,
      productsByQuantity: store.productsByQuantity,
      productsByRentalValue: store.productsByRentalValue,
      productsFamilyPercentageByQuantity:
        store.productsFamilyPercentageByQuantity,
      productsFamilyPercentageByRentalValue:
        store.productsFamilyPercentageByRentalValue,
      servicesByQuantity: store.servicesByQuantity,
      servicesByRentalValue: store.servicesByRentalValue,
      servicesFamilyPercentageByQuantity:
        store.servicesFamilyPercentageByQuantity,
      servicesFamilyPercentageByRentalValue:
        store.servicesFamilyPercentageByRentalValue,
    };
  });

  const [filterOption, setFilterOption] = useState('all');

  const getDataServiceProduct = async () => {
    return await handleServiceProductData(companyId);
  };

  useEffect(() => {
    getDataServiceProduct();
  }, [currentDate]);

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  const renderTablesAndGraphs = () => {
    switch (filterOption) {
      case 'services':
        return (
          <>
            <ServiceTables
              isLoading={isLoading}
              servicesByQuantity={servicesByQuantity}
              servicesByRentalValue={servicesByRentalValue}
            />
            <ServiceGraphs
              isLoading={isLoading}
              servicesFamilyPercentageByQuantity={
                servicesFamilyPercentageByQuantity
              }
              servicesFamilyPercentageByRentalValue={
                servicesFamilyPercentageByRentalValue
              }
            />
          </>
        );
      case 'products':
        return (
          <>
            <ProductTables
              isLoading={isLoading}
              productsByQuantity={productsByQuantity}
              productsByRentalValue={productsByRentalValue}
            />
            <ProductGraphs
              isLoading={isLoading}
              productsFamilyPercentageByQuantity={
                productsFamilyPercentageByQuantity
              }
              productsFamilyPercentageByRentalValue={
                productsFamilyPercentageByRentalValue
              }
            />
          </>
        );
      default:
        return (
          <>
            <ProductTables
              isLoading={isLoading}
              productsByQuantity={productsByQuantity}
              productsByRentalValue={productsByRentalValue}
            />
            <ProductGraphs
              isLoading={isLoading}
              productsFamilyPercentageByQuantity={
                productsFamilyPercentageByQuantity
              }
              productsFamilyPercentageByRentalValue={
                productsFamilyPercentageByRentalValue
              }
            />
            <ServiceTables
              isLoading={isLoading}
              servicesByQuantity={servicesByQuantity}
              servicesByRentalValue={servicesByRentalValue}
            />
            <ServiceGraphs
              isLoading={isLoading}
              servicesFamilyPercentageByQuantity={
                servicesFamilyPercentageByQuantity
              }
              servicesFamilyPercentageByRentalValue={
                servicesFamilyPercentageByRentalValue
              }
            />
          </>
        );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        marginBottom: '2rem',
        marginTop: '2rem',
      }}
    >
      <select
        className="form-control foco-input"
        onChange={handleFilterChange}
        value={filterOption}
        style={{ width: '150px' }}
      >
        <option value="all">Exibir tudo</option>
        <option value="products">Produtos</option>
        <option value="services">Serviços</option>
      </select>
      {renderTablesAndGraphs()}
    </div>
  );
}

const ServiceTables = ({
  isLoading,
  servicesByQuantity,
  servicesByRentalValue,
}) => (
  <div style={{ display: 'flex', flexDirection: 'row', gap: '1.5rem' }}>
    <TableRank
      rows={servicesByQuantity || []}
      labelKey="serviceDescription"
      valueKey="value"
      labelName="SERVIÇOS MAIS VENDIDOS"
      valueName="QTD/R$"
      valueWidth="100px"
      loading={isLoading}
    />
    <TableRank
      rows={servicesByRentalValue || []}
      labelKey="serviceDescription"
      valueKey="value"
      labelName="SERVIÇOS COM MAIOR RENTABILIDADE"
      valueName="QTD/R$"
      valueWidth="100px"
      loading={isLoading}
    />
  </div>
);

const ProductTables = ({
  isLoading,
  productsByQuantity,
  productsByRentalValue,
}) => (
  <div style={{ display: 'flex', flexDirection: 'row', gap: '1.5rem' }}>
    <TableRank
      rows={productsByQuantity || []}
      labelKey="label"
      valueKey="value"
      labelName="PRODUTOS MAIS VENDIDOS"
      valueName="QTD/R$"
      valueWidth="100px"
      loading={isLoading}
    />
    <TableRank
      rows={productsByRentalValue || []}
      labelKey="label"
      valueKey="value"
      labelName="PRODUTOS COM MAIOR RENTABILIDADE"
      valueName="QTD/R$"
      valueWidth="100px"
      loading={isLoading}
    />
  </div>
);

const ServiceGraphs = ({
  isLoading,
  servicesFamilyPercentageByQuantity = [],
  servicesFamilyPercentageByRentalValue = [],
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      gap: '15px',
      width: '100%',
      minHeight: '400px',
    }}
  >
    <PieChartGraph.Root style={{ width: '100%' }}>
      <PieChartGraph.Title>
        {isLoading ? (
          <Skeleton height={20} width={200} />
        ) : (
          'FAMÍLIAS DE SERVIÇOS MAIS VENDIDAS'
        )}
      </PieChartGraph.Title>
      {!isLoading && servicesFamilyPercentageByQuantity.length > 0 && (
        <>
          <PieChartGraph.Subtitle
            data={servicesFamilyPercentageByQuantity || []}
            styleLegend={{
              marginTop: '1.5rem',
              gap: '10px',
              flexWrap: 'wrap',
            }}
            styleColorBox={{ marginRight: '5px' }}
          />
          <PieChartGraph.Graph
            data={servicesFamilyPercentageByQuantity || []}
            tooltipNameFormatter={(value) => `${value}%`}
          />
        </>
      )}
    </PieChartGraph.Root>
    <PieChartGraph.Root style={{ width: '100%' }}>
      <PieChartGraph.Title>
        {isLoading ? (
          <Skeleton height={20} width={200} />
        ) : (
          'FAMÍLIAS DE SERVIÇOS MAIS RENTÁVEIS'
        )}
      </PieChartGraph.Title>
      {!isLoading && servicesFamilyPercentageByRentalValue.length > 0 && (
        <>
          <PieChartGraph.Subtitle
            data={servicesFamilyPercentageByRentalValue || []}
            styleLegend={{
              marginTop: '1.5rem',
              gap: '10px',
              flexWrap: 'wrap',
            }}
            styleColorBox={{ marginRight: '5px' }}
          />
          <PieChartGraph.Graph
            data={servicesFamilyPercentageByRentalValue || []}
            tooltipNameFormatter={(value) => `${value}%`}
          />
        </>
      )}
    </PieChartGraph.Root>
  </div>
);

const ProductGraphs = ({
  isLoading,
  productsFamilyPercentageByQuantity = [],
  productsFamilyPercentageByRentalValue = [],
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      gap: '1.5rem',
      width: '100%',
      minHeight: '400px',
    }}
  >
    <PieChartGraph.Root style={{ width: '100%' }}>
      <PieChartGraph.Title>
        {isLoading ? (
          <Skeleton height={20} width={200} />
        ) : (
          'FAMÍLIAS DE PRODUTOS MAIS VENDIDAS'
        )}
      </PieChartGraph.Title>
      {!isLoading && productsFamilyPercentageByQuantity.length > 0 && (
        <>
          <PieChartGraph.Subtitle
            data={productsFamilyPercentageByQuantity || []}
            styleLegend={{
              marginTop: '1.5rem',
              gap: '10px',
              flexWrap: 'wrap',
            }}
            styleColorBox={{ marginRight: '5px' }}
          />
          <PieChartGraph.Graph
            data={productsFamilyPercentageByQuantity || []}
            tooltipNameFormatter={(value) => `${value}%`}
          />
        </>
      )}
    </PieChartGraph.Root>
    <PieChartGraph.Root style={{ width: '100%' }}>
      <PieChartGraph.Title>
        {isLoading ? (
          <Skeleton height={20} width={200} />
        ) : (
          'FAMÍLIAS DE PRODUTOS MAIS RENTÁVEIS'
        )}
      </PieChartGraph.Title>
      {!isLoading && productsFamilyPercentageByRentalValue.length > 0 && (
        <>
          <PieChartGraph.Subtitle
            data={productsFamilyPercentageByRentalValue || []}
            styleLegend={{
              marginTop: '1.5rem',
              gap: '10px',
              flexWrap: 'wrap',
            }}
            styleColorBox={{ marginRight: '5px' }}
          />
          <PieChartGraph.Graph
            data={productsFamilyPercentageByRentalValue || []}
            tooltipNameFormatter={(value) => `${value}%`}
          />
        </>
      )}
    </PieChartGraph.Root>
  </div>
);
