import { useCallback, useEffect, useState } from 'react';
import { useLoading } from '../useLoading/useLoading';

export function useFetch(repository) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const { isLoading, fetchLoading } = useLoading();

  const fetchDataFromRepository = async (repository) => {
    try {
      const data = await repository();
      setResponse(data);
    } catch (error) {
      setError(error);
    }
  };

  const fetchData = useCallback(async () => {
    await fetchLoading(() => fetchDataFromRepository(repository));
  }, [fetchLoading, repository]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    response,
    loading: isLoading,
    error,
  };
}
